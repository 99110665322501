import React, { useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { 
  CheckCircle, 
  ArrowRight, 
  X, 
  MapPin, 
  CreditCard,
  Sparkles,
  Award,
  Flame,
  Clock,
  Building,
  Globe,
  Mail
} from 'lucide-react';

const PurchaseSteps = ({ 
  isOpen, 
  onClose, 
  pack, 
  student, 
  convertPrice,
  onConfirm 
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation("global");
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedBillingAddress, setSelectedBillingAddress] = useState(null);
  const [selectedLecture, setSelectedLecture] = useState(null);
  const totalSteps = 2;

  const hasAddress = student?.Address && Object.keys(student.Address).length > 0;

  const handleNext = () => {
    if (currentStep === totalSteps - 1) {
      // Make sure we're passing the complete address object
      onConfirm({ 
        billingAddress: selectedBillingAddress, // This should be the full address object
        lectureDetails: selectedLecture || {
          count: pack?.Number_of_Lectures,
          finalPrice: pack?.Package_Amount
        }
      });
      return;
    }
    setCurrentStep(prev => Math.min(prev + 1, totalSteps - 1));
  };

  const handlePrev = () => {
    setCurrentStep(prev => Math.max(prev - 1, 0));
  };

  const handleChangeAddress = () => {
    navigate('/Student-dashboard/profile');
    onClose();
  };

  const getPlanTag = (lecture) => {
    if (lecture.discount >= 30) return { icon: Sparkles, label: t("PurchaseSteps.BestValue"), color: "bg-gradient-to-r from-purple-600 to-blue-500" };
    if (lecture.count >= 20) return { icon: Award, label: t("PurchaseSteps.Popular"), color: "bg-gradient-to-r from-orange-500 to-red-500" };
    if (lecture.pricePerLecture <= 20) return { icon: Flame, label: t("PurchaseSteps.HotDeal"), color: "bg-gradient-to-r from-red-500 to-pink-500" };
    return { icon: Clock, label: t("PurchaseSteps.LimitedTime"), color: "bg-gradient-to-r from-green-500 to-teal-500" };
  };

  const formatLectureOptions = (lectures) => {
    return lectures?.map(lecture => {
      const lectureData = lecture._doc || lecture;
      return {
        ...lectureData,
        pricePerLecture: lectureData.finalPrice / lectureData.count
      };
    }) || [];
  };

  const renderLectureCard = (lecture, index) => {
    const tag = getPlanTag(lecture);
    const TagIcon = tag.icon;

    return (
      <div
        key={index}
        onClick={() => setSelectedLecture(lecture)}
        className="group relative overflow-hidden"
      >
        <div className={`relative p-6 rounded-2xl border-2 transition-all duration-300 transform 
          ${selectedLecture?._id === lecture._id 
            ? 'border-violet-500 bg-gradient-to-br from-violet-50 to-white shadow-xl scale-[1.02]' 
            : 'border-gray-200 hover:border-violet-300 hover:shadow-lg hover:scale-[1.01]'}`}
        >
          {/* Tag */}
          <div className={`absolute -right-12 top-6 ${tag.color} text-white px-12 py-1 rotate-45 
            text-sm font-semibold shadow-lg`}>
            <div className="flex items-center justify-center gap-1">
              <TagIcon className="w-4 h-4" />
              <span>{tag.label}</span>
            </div>
          </div>

          <div className="flex items-start gap-6">
            <div className="flex items-center h-5 mt-1">
              <div className={`w-6 h-6 rounded-full border-2 transition-all duration-300 flex items-center justify-center
                ${selectedLecture?._id === lecture._id ? 'border-violet-500 bg-violet-500' : 'border-gray-300'}`}>
                {selectedLecture?._id === lecture._id && <CheckCircle className="w-4 h-4 text-white" />}
              </div>
            </div>

            <div className="flex-grow">
              <div className="flex justify-between items-start">
                <div>
                <h3 className="text-2xl font-bold text-gray-900 mb-2">
  {lecture.count} {lecture.count === 1 ? t("PurchaseSteps.Lecture") : t("PurchaseSteps.Lectures")}
</h3>
                  <div className="flex items-center gap-2 text-gray-600">
                    <Clock className="w-4 h-4" />
                    <p>{t("PurchaseSteps.PricePerLecture")}: {convertPrice(lecture.pricePerLecture)}</p>
                  </div>
                </div>

                <div className="text-right">
                  <div className="text-3xl font-bold bg-gradient-to-r from-violet-600 to-purple-600 
                    bg-clip-text text-transparent">
                    {convertPrice(lecture.finalPrice)}
                  </div>
                  {lecture.discount > 0 && (
                    <>
                      <div className="text-sm line-through text-gray-400 mt-1">
                        {convertPrice(lecture.basePrice)}
                      </div>
                      <div className="mt-3">
                        <span className="inline-flex items-center px-4 py-1 rounded-full text-sm font-medium 
                          bg-green-100 text-green-800">
                          {t("PurchaseSteps.SavePercent", { percent: lecture.discount })}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {lecture.discount > 0 && (
                <div className="mt-4 flex items-center gap-2 text-green-600">
                  <Sparkles className="w-4 h-4" />
                  <span className="font-medium">
                    {t("PurchaseSteps.YouSave", { amount: convertPrice(lecture.basePrice - lecture.finalPrice) })}
                  </span>
                </div>
              )}

              {/* Features list */}
              <div className="mt-6 grid grid-cols-2 gap-3">
              {[
  t("PurchaseSteps.SupportAccess"),
  t("PurchaseSteps.HDVideo"),
  t("PurchaseSteps.DownloadableResources"),
  t("PurchaseSteps.CertificateIncluded")
].map((feature, idx) => (
  <div key={idx} className="flex items-center gap-2 text-gray-600">
    <CheckCircle className="w-4 h-4 text-violet-500" />
    <span className="text-sm">{feature}</span>
  </div>
))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAddressStep = () => {
    if (!hasAddress) {
      return (
        <div className="text-center p-8 bg-gradient-to-br from-gray-50 to-white rounded-2xl border-2 border-gray-100">
          <MapPin className="w-16 h-16 text-violet-500 mx-auto mb-4" />
          <p className="text-xl text-gray-600 mb-6">{t("PurchaseSteps.NoBillingAddress")}</p>
          <button 
            className="py-4 px-8 bg-gradient-to-r from-violet-600 to-purple-600 text-white rounded-xl 
              hover:from-violet-700 hover:to-purple-700 transition-all duration-300 shadow-lg 
              hover:shadow-xl transform hover:-translate-y-1"
            onClick={handleChangeAddress}
          >
            {t("PurchaseSteps.AddNewAddress")}
          </button>
        </div>
      );
    }
  
    const address = student.Address;
    
    // Validate address has required fields
    const isAddressComplete = address && 
      address.street && 
      address.city && 
      address.postalCode && 
      address.country;
      
    if (!isAddressComplete) {
      return (
        <div className="text-center p-8 bg-gradient-to-br from-gray-50 to-white rounded-2xl border-2 border-gray-200">
          <MapPin className="w-16 h-16 text-orange-500 mx-auto mb-4" />
          <p className="text-xl text-gray-600 mb-4">{t("PurchaseSteps.IncompleteAddress")}</p>
          <p className="text-gray-500 mb-6">{t("PurchaseSteps.PleaseCompleteAddress")}</p>
          <button 
            className="py-4 px-8 bg-gradient-to-r from-violet-600 to-purple-600 text-white rounded-xl 
              hover:from-violet-700 hover:to-purple-700 transition-all duration-300 shadow-lg 
              hover:shadow-xl transform hover:-translate-y-1"
            onClick={handleChangeAddress}
          >
            {t("PurchaseSteps.UpdateAddress")}
          </button>
        </div>
      );
    }

    return (
      <div className="space-y-6">
        <div
          onClick={() => setSelectedBillingAddress(address)}
          className={`relative p-6 rounded-xl border-2 cursor-pointer transition-all duration-300 
            transform hover:scale-[1.02]
            ${selectedBillingAddress === address 
              ? 'border-violet-500 bg-gradient-to-br from-violet-50 to-white shadow-lg' 
              : 'border-gray-200 hover:border-violet-300'}`}
        >
          <div className="flex items-start gap-6">
            <div className="flex items-center h-5 mt-1">
              <div className={`w-6 h-6 rounded-full border-2 transition-all duration-300 
                flex items-center justify-center
                ${selectedBillingAddress === address 
                  ? 'border-violet-500 bg-violet-500' 
                  : 'border-gray-300'}`}
              >
                {selectedBillingAddress === address && <CheckCircle className="w-4 h-4 text-white" />}
              </div>
            </div>
            <div className="flex-grow">
              <div className="flex items-center gap-3 mb-4">
                <h3 className="text-xl font-bold text-gray-900">
                  {student.firstName} {student.lastName}
                </h3>
                <span className="px-3 py-1 bg-violet-100 text-violet-600 rounded-full text-sm font-medium">
                  {t("PurchaseSteps.Primary")}
                </span>
              </div>
              
              <div className="space-y-2">
                <div className="flex items-center gap-2 text-gray-600">
                  <Building className="w-4 h-4" />
                  <p>{address.street}</p>
                </div>
                <div className="flex items-center gap-2 text-gray-600">
                  <MapPin className="w-4 h-4" />
                  <p>{address.city}, {address.postalCode}</p>
                </div>
                <div className="flex items-center gap-2 text-gray-600">
                  <Globe className="w-4 h-4" />
                  <p>{address.country}</p>
                </div>
                {address.email && (
                  <div className="flex items-center gap-2 text-gray-600">
                    <Mail className="w-4 h-4" />
                    <p>{address.email}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        
        <button
          onClick={handleChangeAddress}
          className="w-full text-violet-600 hover:text-violet-700 py-3 px-4 rounded-xl border-2 
            border-violet-200 hover:border-violet-300 transition-all duration-300 font-medium 
            flex items-center justify-center gap-2"
        >
          <MapPin className="w-4 h-4" />
          {t("PurchaseSteps.ChangeBillingAddress")}
        </button>
      </div>
    );
  };

  const renderStep = () => {
    switch(currentStep) {
      case 0:
        return (
          <div className="space-y-8">
            <div className="flex items-center gap-4 mb-8">
              <div className="p-3 bg-violet-100 rounded-xl">
                <CreditCard className="w-8 h-8 text-violet-600" />
              </div>
              <h2 className="text-2xl font-bold text-gray-900">
                {t("PurchaseSteps.SelectLectures")}
              </h2>
            </div>
            
            <div className="space-y-6">
              {formatLectureOptions(pack?.lectures).map((lecture, index) => 
                renderLectureCard(lecture, index)
              )}
            </div>

            <button 
              onClick={handleNext}
              disabled={!selectedLecture}
              className={`w-full py-4 px-6 rounded-xl text-white transition-all duration-300 transform
                ${selectedLecture 
                  ? 'bg-gradient-to-r from-violet-600 to-purple-600 hover:from-violet-700 hover:to-purple-700 hover:-translate-y-1 shadow-lg hover:shadow-xl' 
                  : 'bg-gray-300 cursor-not-allowed'}`}
            >
              <div className="flex items-center justify-center gap-2">
                {t("PurchaseSteps.ContinueToBilling")}
                <ArrowRight className="w-5 h-5" />
              </div>
            </button>
          </div>
        );

      case 1:
        return (
          <div className="space-y-8">
            <div className="flex items-center gap-4 mb-8">
              <div className="p-3 bg-violet-100 rounded-xl">
                <MapPin className="w-8 h-8 text-violet-600" />
              </div>
              <h2 className="text-2xl font-bold text-gray-900">
                {t("PurchaseSteps.BillingAddress")}
              </h2>
            </div>
            
            {renderAddressStep()}
            
            <div className="flex gap-4">
              <button 
                onClick={handlePrev}
                className="flex-1 py-4 px-6 rounded-xl border-2 border-violet-200 text-violet-700 
                  hover:border-violet-300 hover:bg-violet-50 transition-all duration-300
                  flex items-center justify-center gap-2"
              >
                <ArrowRight className="w-5 h-5 rotate-180" />
                {t("PurchaseSteps.Back")}
              </button>
              <button 
                onClick={handleNext}
                disabled={!selectedBillingAddress}
                className={`flex-1 py-4 px-6 rounded-xl text-white transition-all duration-300 transform
                  ${selectedBillingAddress 
                    ? 'bg-gradient-to-r from-violet-600 to-purple-600 hover:from-violet-700 hover:to-purple-700 hover:-translate-y-1 shadow-lg hover:shadow-xl' 
                    : 'bg-gray-300 cursor-not-allowed'}`}
              >
                <div className="flex items-center justify-center gap-2">
                  {t("PurchaseSteps.ConfirmPurchase")}
                  <CheckCircle className="w-5 h-5" />
                </div>
              </button>
            </div>

            {/* Order Summary */}
            {selectedLecture && (
              <div className="mt-8 p-6 bg-gray-50 rounded-xl border border-gray-200">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">
                  {t("PurchaseSteps.OrderSummary")}
                </h3>
                <div className="space-y-3">
                  <div className="flex justify-between text-gray-600">
                    <span>{t("PurchaseSteps.LectureCount", { count: selectedLecture.count })}</span>
                    <span>{convertPrice(selectedLecture.basePrice)}</span>
                  </div>
                  {selectedLecture.discount > 0 && (
                    <div className="flex justify-between text-green-600">
                      <span>{t("PurchaseSteps.Discount")}</span>
                      <span>-{convertPrice(selectedLecture.basePrice - selectedLecture.finalPrice)}</span>
                    </div>
                  )}
                  <div className="pt-3 border-t border-gray-200">
                    <div className="flex justify-between text-lg font-semibold text-gray-900">
                      <span>{t("PurchaseSteps.Total")}</span>
                      <span>{convertPrice(selectedLecture.finalPrice)}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  const customModalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 1000,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1rem'
    },
    content: {
      position: 'relative',
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      maxWidth: '48rem',
      width: '100%',
      padding: '2rem',
      border: 'none',
      borderRadius: '1.5rem',
      background: '#fff',
      maxHeight: '90vh',
      overflow: 'auto'
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customModalStyles}
      ariaHideApp={false}
    >
      <div className="relative">
        <button 
          onClick={onClose}
          className="absolute -top-2 -right-2 w-10 h-10 flex items-center justify-center bg-gray-100 
            hover:bg-gray-200 rounded-full transition-colors duration-300 z-10"
          aria-label={t("PurchaseSteps.Close")}
        >
          <X className="w-5 h-5 text-gray-600" />
        </button>
        
        {/* Progress Steps */}
        <div className="flex justify-center mb-12">
          <div className="flex items-center">
            {[...Array(totalSteps)].map((_, index) => (
              <React.Fragment key={index}>
                <div className="relative">
                  <div className={`
                    w-14 h-14 rounded-full flex items-center justify-center text-lg font-semibold
                    transition-all duration-500 transform
                    ${currentStep === index 
                      ? 'bg-gradient-to-r from-violet-600 to-purple-600 text-white scale-110 shadow-lg' 
                      : currentStep > index 
                        ? 'bg-violet-200 text-violet-700' 
                        : 'bg-violet-100 text-violet-400'}`}
                  >
                    {index + 1}
                  </div>
                  {currentStep > index && (
                    <CheckCircle className="absolute -bottom-1 -right-1 w-6 h-6 text-violet-600 bg-white rounded-full" />
                  )}
                </div>
                {index < totalSteps - 1 && (
                  <div className="relative mx-4 w-24">
                    <div className={`h-1 rounded-full transition-all duration-500
                      ${currentStep > index ? 'bg-gradient-to-r from-violet-500 to-purple-500' : 'bg-violet-100'}`} />
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        
        {/* Content */}
        <div className="transform transition-all duration-500">
          {renderStep()}
        </div>
      </div>
    </Modal>
  );
};

export default PurchaseSteps;
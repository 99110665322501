import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPackage } from "../../store/actions/packagesActions";
import { useTranslation } from "react-i18next";
import Loader2 from "../Loader2";
import backgroundImg from './7492091.jpg';
import securePaymentIcon from './payu.jpeg';
import supportIcon from './blik.jpeg';

const CheckDetails = () => {
  const { Package_ID } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [initialLoading, setInitialLoading] = useState(true);
  const [paymentError, setPaymentError] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [nip, setNip] = useState('');
  const [agreements, setAgreements] = useState({
    rules: false,
    privacy: false
  });
  const { t } = useTranslation("global");

  const {
    packageName,
    hours,
    totalAmount,
    billingAddress,
    lectureDetails,
  } = location.state || {};

  const user = useSelector((state) => state.students.user);
  const paymentResponseUrl = useSelector((state) => state.payments.paymentResponseUrl);

  useEffect(() => {
    const fetchData = async () => {
      setInitialLoading(true);
      try {
        await dispatch(fetchPackage(Package_ID));
      } finally {
        setInitialLoading(false);
      }
    };
    fetchData();
  }, [Package_ID, dispatch]);

  useEffect(() => {
    if (paymentResponseUrl.length > 0) {
      window.location.href = paymentResponseUrl;
    }
  }, [paymentResponseUrl]);

  const openPdf = (url) => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    console.log("Location state in CheckDetails:", location.state);
    console.log("Billing address received:", location.state?.billingAddress);
  }, [location.state]);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!agreements.rules || !agreements.privacy) {
      setPaymentError(t("CheckDetails.AcceptTermsError"));
      return;
    }
    
    setLoading(true);
    setPaymentError(null);
  
    try {
      // Use the billing address from location state, with fallback default values
      const addressFromState = location.state?.billingAddress || {};
      console.log("Using address:", addressFromState);
      
      // Set default values for companyName and nip if empty
      const finalCompanyName = companyName.trim() || "INFAKT";
      const finalNip = nip.trim() || "000-000-00-00";
      
      const paymentData = {
        Package_ID,
        packageName,
        totalAmount,
        hours,
        billingAddress: {
          street: location.state?.billingAddress?.street || '',
          city: location.state?.billingAddress?.city || '',
          postalCode: location.state?.billingAddress?.postalCode || '',
          state: location.state?.billingAddress?.state || '',
          country: location.state?.billingAddress?.country || 'PL'
        },
        user,
        companyDetails: {
          companyName: finalCompanyName,
          nip: finalNip
        },
        agreements,
        lectureDetails: lectureDetails || null
      };
  
      console.log("Payment data being saved:", paymentData);
      localStorage.setItem('pendingPaymentData', JSON.stringify(paymentData));
      navigate('/Student-dashboard/PaymentsPage');
    } catch (error) {
      console.error("Navigation error:", error);
      setPaymentError(t("CheckDetails.PaymentPageError"));
    } finally {
      setLoading(false);
    }
  };

  if (initialLoading) {
    return <Loader2 loading={true} text={t("CheckDetails.LoadingPackage")} fullScreen={true} />;
  }

  
  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex flex-1 w-full">
        {/* Sidebar - hidden on mobile, visible from md breakpoint */}
        <div className="hidden md:block w-74 flex-shrink-0">
          {/* Sidebar space */}
        </div>

        <div className="flex-1 relative min-h-screen w-full">
          {/* Background container with responsive margin */}
          <div className="absolute inset-0">
            <div 
              className="absolute inset-0 w-full h-full ml-0 md:ml-74"
              style={{
                backgroundImage: `url(${backgroundImg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                imageRendering: 'crisp-edges',
                WebkitBackfaceVisibility: 'hidden',
                backfaceVisibility: 'hidden',
                transition: 'margin-left 0.3s ease-in-out'
              }}
            />
            <div className="absolute inset-0 bg-white/70 w-full h-full" />
          </div>

          <div className="relative z-10 py-4 md:py-12 px-3 md:px-8 lg:px-12 w-full">
            <div className="max-w-4xl mx-auto w-full">
              <div className="bg-white rounded-lg md:rounded-2xl shadow-lg overflow-hidden border border-gray-200">
                <div className="bg-blue-600 text-white px-4 md:px-6 py-3 md:py-4">
                  <h2 className="text-xl md:text-2xl font-bold">{t("CheckDetails.OrderDetails")}</h2>
                  <p className="text-sm md:text-base text-blue-100">{t("CheckDetails.OrderSummary")}</p>
                </div>

                <div className="p-4 md:p-8">
                  <div className="mb-6 md:mb-8">
                    <h3 className="text-base md:text-lg font-semibold text-gray-800 mb-3 md:mb-4">{t("CheckDetails.OrderSummary")}</h3>
                    <div className="space-y-2 md:space-y-3">
                      <div className="flex flex-col sm:flex-row sm:justify-between py-2">
                        <span className="text-sm md:text-base text-gray-600">{t("CheckDetails.PackageName")}</span>
                        <span className="font-medium text-sm md:text-base mt-1 sm:mt-0">{packageName}</span>
                      </div>
                      <div className="flex flex-col sm:flex-row sm:justify-between py-2">
                        <span className="text-sm md:text-base text-gray-600">{t("CheckDetails.HoursToPurchase")}</span>
                        <span className="font-medium text-sm md:text-base mt-1 sm:mt-0">{hours} {t("CheckDetails.Hours")}</span>
                      </div>
                      {lectureDetails?.discount > 0 && (
                        <div className="flex flex-col sm:flex-row sm:justify-between py-2 text-green-600">
                          <span className="text-sm md:text-base">{t("CheckDetails.Discount")}</span>
                          <span className="font-medium text-sm md:text-base mt-1 sm:mt-0">
                            {lectureDetails.discount}% {t("CheckDetails.Off")}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mb-6 md:mb-8">
                    <h3 className="text-base md:text-lg font-semibold text-gray-800 mb-3 md:mb-4">{t("CheckDetails.UserInfo")}</h3>
                    <div className="bg-gray-50 rounded-lg p-3 md:p-4">
                      <div className="space-y-2">
                        <div className="flex flex-col sm:flex-row sm:justify-between py-2">
                          <span className="text-sm md:text-base text-gray-600">{t("CheckDetails.Username")}</span>
                          <span className="text-sm md:text-base text-gray-700 mt-1 sm:mt-0">{user?.Username}</span>
                        </div>
                        <div className="flex flex-col sm:flex-row sm:justify-between py-2">
                          <span className="text-sm md:text-base text-gray-600">{t("CheckDetails.Email")}</span>
                          <span className="text-sm md:text-base text-gray-700 break-all mt-1 sm:mt-0">{user?.Email}</span>
                        </div>
                        <div className="flex flex-col sm:flex-row sm:justify-between py-2">
                          <span className="text-sm md:text-base text-gray-600">{t("CheckDetails.PhoneNo")}</span>
                          <span className="text-sm md:text-base text-gray-700 mt-1 sm:mt-0">{user?.Phone_Number}</span>
                        </div>
                        
                        {/* Updated address section with complete information */}
                        {location.state?.billingAddress && (
                          <div className="mt-3 pt-3 border-t border-gray-200">
                            <div className="space-y-2">
                              <div className="flex flex-col sm:flex-row sm:justify-between py-2">
                                <span className="text-sm md:text-base text-gray-600">{t("CheckDetails.Street")}</span>
                                <span className="text-sm md:text-base text-gray-700 mt-1 sm:mt-0">
                                  {location.state.billingAddress.street || "-"}
                                </span>
                              </div>
                              <div className="flex flex-col sm:flex-row sm:justify-between py-2">
                                <span className="text-sm md:text-base text-gray-600">{t("CheckDetails.City")}</span>
                                <span className="text-sm md:text-base text-gray-700 mt-1 sm:mt-0">
                                  {location.state.billingAddress.city || "-"}
                                </span>
                              </div>
                              {/* Added PostalCode */}
                              <div className="flex flex-col sm:flex-row sm:justify-between py-2">
                                <span className="text-sm md:text-base text-gray-600">{t("CheckDetails.PostalCode")}</span>
                                <span className="text-sm md:text-base text-gray-700 mt-1 sm:mt-0">
                                  {location.state.billingAddress.postalCode || "-"}
                                </span>
                              </div>
                              <div className="flex flex-col sm:flex-row sm:justify-between py-2">
                                <span className="text-sm md:text-base text-gray-600">{t("CheckDetails.State")}</span>
                                <span className="text-sm md:text-base text-gray-700 mt-1 sm:mt-0">
                                  {location.state.billingAddress.state || "-"}
                                </span>
                              </div>
                              <div className="flex flex-col sm:flex-row sm:justify-between py-2">
                                <span className="text-sm md:text-base text-gray-600">{t("CheckDetails.Country")}</span>
                                <span className="text-sm md:text-base text-gray-700 mt-1 sm:mt-0">
                                  {location.state.billingAddress.country || "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mb-6 md:mb-8">
                    <h3 className="text-base md:text-lg font-semibold text-gray-800 mb-3 md:mb-4">{t("CheckDetails.CompanyInformation")}</h3>
                    <div className="space-y-3 md:space-y-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          {t("CheckDetails.CompanyInformation")}
                        </label>
                        <input
                          type="text"
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                          className="w-full px-3 py-2 text-sm md:text-base border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                          placeholder={t("CheckDetails.EnterCompanyName")}
                        />
                        <p className="mt-1 text-xs text-gray-500">
                          {/* {t("CheckDetails.DefaultWillBeUsed", )} */}
                        </p>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          {t("CheckDetails.NIP")}
                        </label>
                        <input
                          type="text"
                          value={nip}
                          onChange={(e) => setNip(e.target.value)}
                          className="w-full px-3 py-2 text-sm md:text-base border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                          placeholder={t("CheckDetails.EnterNIP")}
                        />
                        <p className="mt-1 text-xs text-gray-500">
                          {/* {t("CheckDetails.DefaultWillBeUsed", )} */}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="border-t border-gray-200 pt-4 mb-6 md:mb-8">
                    <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                      <span className="text-base md:text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
                        {t("CheckDetails.AmountPayable")}
                      </span>
                      <span className="text-xl md:text-2xl font-bold text-blue-600">{totalAmount}</span>
                    </div>
                  </div>

                  <div className="mb-6 md:mb-8">
                    <div className="space-y-3 md:space-y-4">
                      <div className="flex items-start md:items-center">
                        <input
                          type="checkbox"
                          id="rules"
                          checked={agreements.rules}
                          onChange={(e) => setAgreements(prev => ({ ...prev, rules: e.target.checked }))}
                          className="h-4 w-4 mt-1 md:mt-0 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                        />
                        <label htmlFor="rules" className="ml-2 text-xs md:text-sm text-gray-600">
                          {t("CheckDetails.IReadandacceptRules")}{' '}
                          <button
                            type="button"
                            onClick={() => openPdf('https://speakable.online/api/pdf/rulesandcondition.pdf')}
                            className="text-blue-600 hover:text-blue-700 underline"
                          >
                            {t("CheckDetails.Rules")}
                          </button>
                        </label>
                      </div>
                      <div className="flex items-start md:items-center">
                        <input
                          type="checkbox"
                          id="privacy"
                          checked={agreements.privacy}
                          onChange={(e) => setAgreements(prev => ({ ...prev, privacy: e.target.checked }))}
                          className="h-4 w-4 mt-1 md:mt-0 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                        />
                        <label htmlFor="privacy" className="ml-2 text-xs md:text-sm text-gray-600">
                          {t("CheckDetails.Iread")}{' '}
                          <button
                            type="button"
                            onClick={() => openPdf('https://speakable.online/api/pdf/privacy.pdf')}
                            className="text-blue-600 hover:text-blue-700 underline"
                          >
                            {t("CheckDetails.PrivacyPolicy")}
                          </button>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="bg-gray-50 rounded-lg p-3 md:p-4 mb-6 md:mb-8">
                    <div className="flex flex-col sm:flex-row items-center gap-3 md:gap-4">
                      <div className="flex items-center text-xs md:text-sm text-gray-600 w-full sm:w-auto justify-center">
                        <img 
                          src={securePaymentIcon} 
                          alt={t("CheckDetails.SecurePayment")}
                          className="w-4 md:w-5 h-4 md:h-5 mr-2 object-contain"
                        />
                        {t("CheckDetails.SecurePayment")}
                      </div>
                      <div className="flex items-center text-xs md:text-sm text-gray-600 w-full sm:w-auto justify-center">
                        <img 
                          src={supportIcon} 
                          alt={t("CheckDetails.ZeroCommission")}
                          className="w-4 md:w-5 h-4 md:h-5 mr-2 object-contain"
                        />
                        {t("CheckDetails.ZeroCommission")}
                      </div>
                    </div>
                  </div>

                  {paymentError && (
                    <div className="mb-4 p-3 md:p-4 bg-red-50 text-red-600 rounded-lg text-xs md:text-sm">
                      {paymentError}
                    </div>
                  )}

                  <button
                    onClick={handleSubmit}
                    disabled={loading || !agreements.rules || !agreements.privacy}
                    className="w-full bg-blue-600 text-white py-3 md:py-4 px-4 md:px-6 rounded-lg text-sm md:text-base font-semibold hover:bg-blue-700 transition-colors disabled:bg-blue-300"
                  >
                    {loading ? t("CheckDetails.ProcessingPayment") : t("CheckDetails.ConfirmAndPay")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <Loader2 
            loading={true}
            text={t("CheckDetails.ProcessingPayment")}
            size={200}
            customStyles={{
              backgroundColor: 'transparent'
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CheckDetails;
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetBookingsByStudentID } from "../../store/actions/bookingActions";
import { studentJoinSession, getTeacherSessionStatus } from "../../store/actions/teacherSessionActions";
import AdminNav from "../admin-dashboard-components/AdminNav";
import { Calendar, Clock, Edit2, Info, Users, Package } from "lucide-react";
import moment from 'moment-timezone';
import { useTranslation } from "react-i18next";
import { Table, Button, Card, Tag, message, List, Typography, Space, Modal, Spin, Progress, Tooltip, Popover } from 'antd';
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Loader2 from "../Loader2";
import { useMediaQuery } from 'react-responsive';
import BookingFilters from './../utils/BookingFilters';
import axios from 'axios';
import { t } from "i18next";
import instance from "../../helpers/axiosconfig";


const { Text } = Typography;

const BookingCard = ({ booking, onJoin, onEdit }) => {
  const timeFormat = localStorage.getItem('timeFormat') || '24';
  const isButtonDisabled = ['Cancelled', 'Completed'].includes(booking.status);


  const [t] = useTranslation("global");

  const getStatusColor = (status) => {
    switch (status) {
      case 'Completed': return '#4ade80';
      case 'In Progress': return '#fb923c';
      case 'Scheduled': return '#60a5fa';
      case 'Cancelled': return '#f87171';
      default: return '#9ca3af';
    }
  };

  const getGradientByStatus = (status) => {
    switch (status) {
      case 'Completed': return 'from-green-50 to-emerald-50';
      case 'In Progress': return 'from-orange-50 to-amber-50';
      case 'Scheduled': return 'from-blue-50 to-indigo-50';
      case 'Cancelled': return 'from-red-50 to-rose-50';
      default: return 'from-gray-50 to-slate-50';
    }
  };

  // Format the time based on user preference
  const formatTime = (timeStr) => {
    const timeMoment = moment(timeStr, 'HH:mm');
    return timeFormat === '12' ? 
      timeMoment.format('hh:mm A') : 
      timeMoment.format('HH:mm');
  };

  const getTranslatedStatus = (status) => {
    return t(`status.${status}`); // Assuming translations are structured as status.Completed, status.InProgress, etc.
  };

  return (
    <div className={`relative overflow-hidden bg-gradient-to-br ${getGradientByStatus(booking.status)} 
                    rounded-lg shadow-sm border border-gray-100 transition-all duration-300
                    hover:shadow-lg hover:scale-[1.01]`}>
      <div className="absolute top-0 right-0 w-20 h-20 transform translate-x-10 -translate-y-10">
        <div className={`w-full h-full rounded-full opacity-10`}
             style={{ background: getStatusColor(booking.status) }} />
      </div>

      <div className="p-4 relative">
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center space-x-2">
            <div className={`p-1.5 rounded-md bg-white shadow-sm border border-gray-100`}>
              <Package className="w-4 h-4" style={{ color: getStatusColor(booking.status) }} />
            </div>
            <div>
              <h3 className="font-medium text-sm text-gray-900 leading-tight">
                {booking.Package_ID?.Package_Name}
              </h3>
              <span 
                className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium mt-0.5"
                style={{ 
                  backgroundColor: `${getStatusColor(booking.status)}15`,
                  color: getStatusColor(booking.status)
                }}
              >
                {getTranslatedStatus(booking.status)}
              </span>
            </div>
          </div>
        </div>

        <div className="space-y-2">
          <div className="flex items-center space-x-2 bg-white/60 p-2 rounded-md backdrop-blur-sm">
            <Users className="w-4 h-4 text-gray-500" />
            <div>
              <p className="text-xs text-gray-500">{t("AdminDashboard.subheading2")}</p>
              <p className="text-sm text-gray-900">{booking.Teacher_ID?.map(t => t?.Username).join(', ')}</p>
            </div>
          </div>

          <div className="flex justify-between space-x-2">
            <div className="flex-1 flex items-center space-x-2 bg-white/60 p-2 rounded-md backdrop-blur-sm">
              <Calendar className="w-4 h-4 text-gray-500" />
              <div>
                <p className="text-xs text-gray-500">{t("TeacherBookings.Date")}</p>
                <p className="text-sm text-gray-900">{booking.localDateFormatted}</p>
              </div>
            </div>

            <div className="flex-1 flex items-center space-x-2 bg-white/60 p-2 rounded-md backdrop-blur-sm">
              <Clock className="w-4 h-4 text-gray-500" />
              <div>
                <p className="text-xs text-gray-500">{t("TeacherBookings.Time")}</p>
                <p className="text-sm text-gray-900">
                  {`${formatTime(booking.localStart)} - ${formatTime(booking.localEnd)}`}
                </p>
              </div>
            </div>
          </div>
        </div>

        {booking.status !== 'Cancelled' && (
          <div className="flex space-x-2 mt-3">
            <button
              onClick={() => onJoin(booking._id, booking.Teacher_ID?.[0]?._id)}
              className="flex-1 flex items-center justify-center space-x-1 bg-gradient-to-r 
                        from-emerald-500 to-green-500 text-white py-1.5 px-3 rounded-md text-sm
                        hover:from-emerald-600 hover:to-green-600 transition-all duration-300
                        shadow-sm hover:shadow disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={booking.status === 'Completed'}
            >
              <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                      d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
              </svg>
              <span>{t("status.JoinRoom")}</span>
            </button>
            {/* <button
  onClick={() => onJoin(booking._id, booking.Teacher_ID?.[0]?._id)}
  disabled={isButtonDisabled}
  className={`flex-1 flex items-center justify-center space-x-1 bg-gradient-to-r 
              ${isButtonDisabled 
                ? 'from-gray-400 to-gray-500 cursor-not-allowed' 
                : 'from-emerald-500 to-green-500 hover:from-emerald-600 hover:to-green-600'} 
              text-white py-1.5 px-3 rounded-md text-sm
              transition-all duration-300 shadow-sm hover:shadow`}
>
  <span>{t("StudentBooking.JoinRoom")}</span>
</button> */}
            <button
              onClick={() => onEdit(booking._id, booking.date, booking.start, booking.end, booking.Teacher_ID?.[0]?._id)}
              className="flex-1 flex items-center justify-center space-x-1 bg-white border border-gray-200
                        text-gray-700 py-1.5 px-3 rounded-md text-sm hover:bg-gray-50 transition-all duration-300
                        shadow-sm hover:shadow disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={booking.status !== 'Scheduled'}
            >
              <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
              </svg>
              <span>{t("AdminEditTeacher.Edit")}</span>
            </button>
          </div>
        )}
      </div>

      <div className="absolute bottom-0 left-0 w-16 h-16 transform -translate-x-8 translate-y-8">
        <div className={`w-full h-full rounded-full opacity-10`}
             style={{ background: getStatusColor(booking.status) }} />
      </div>
    </div>
  );
};


const Bookings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const student = useSelector((state) => state.students.user);
  const Bookings = useSelector((state) => state.bookings.StudentID_Booking);
  const selectedTimezone = useSelector((state) => state.students.user.Timezone);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [hourFormat, setHourFormat] = useState(localStorage.getItem('hourFormat') || '24');
  const [isLoading, setIsLoading] = useState(true);
  const [isJoiningRoom, setIsJoiningRoom] = useState(false);
  const [currentBookingId, setCurrentBookingId] = useState(null);
  const [currentTeacherId, setCurrentTeacherId] = useState(null);
  const [teacherJoined, setTeacherJoined] = useState(false);
  const [waitTimeLeft, setWaitTimeLeft] = useState(10);
  const [utcTime, setUtcTime] = useState(null);
  const [teacherWaitTimer, setTeacherWaitTimer] = useState(null);
  const [hasOpenedTab, setHasOpenedTab] = useState(false);
  let intervalId;
  
  


  const checkUpcomingMeetings = useCallback(() => {
    if (!utcTime || !Bookings) return;
  
    const processedBookings = flattenBookings(Bookings);
    processedBookings.forEach(booking => {
      const meetingStartTime = moment.utc(`${booking.date} ${booking.start}`, 'YYYY-MM-DD HH:mm');
      const tenMinutesBefore = moment(meetingStartTime).subtract(10, 'minutes');
      
      const isWithinNotificationWindow = 
        Math.abs(moment.utc().diff(tenMinutesBefore, 'minutes')) < 1 && 
        booking.status === 'Scheduled';
  
      if (isWithinNotificationWindow) {
        triggerReminderAPI(booking);
      }
    });
  }, [utcTime, Bookings]);

  const sendTeacherWaitingReminder = async () => {
    try {
      const booking = flattenBookings(Bookings).find(b => b._id === currentBookingId);
      if (!booking) return;
  
      await instance.post('/send-teacher-reminder', {
        studentEmail: student.Email,
        teacherName: booking.Teacher_ID[0].Username,
        studentName: student.Username,
        teacherTimezone: booking.Teacher_ID[0].Timezone,
        date: booking.date,
        time: booking.start,
        bookingId: currentBookingId
      });
    } catch (error) {
      console.error('Error sending teacher waiting reminder:', error);
    }
  };

  const triggerReminderAPI = async (booking) => {
    try {
      await instance.post('/send-lesson-reminder', {
        studentEmail: student.Email,
        teacherName: booking.Teacher_ID[0].Username,
        studentName: student.Username,
        teacherTimezone: booking.Teacher_ID[0].Timezone,
        date: booking.date,
        time: booking.start,
        packageName: booking.Package_ID?.Package_Name || ''  // Add packageName, default to empty string if not available
      });
    } catch (error) {
      console.error('Error sending reminder:', error);
    }
  };
  
  useEffect(() => {
    const intervalId = setInterval(checkUpcomingMeetings, 60000); // Check every minute
    return () => clearInterval(intervalId);
  }, [checkUpcomingMeetings]);

  const bookingRules = {
    reschedule: "Meetings can't be rescheduled within 24 hours of the start time.",
    oneTimeReschedule: "Once rescheduled, a meeting can't be rescheduled again.",
    cancelled: "Cancelled meetings can't be joined, edited, or rescheduled.",
    consecutive: "Consecutive slots must be rescheduled individually as they are made up of multiple meetings."
  };

  const RenderBookingInfo = ({ isConsecutive }) => (
    <div>
      <p>{bookingRules.reschedule}</p>
      <p>{bookingRules.oneTimeReschedule}</p>
      <p>{bookingRules.cancelled}</p>
      {isConsecutive && <p>{bookingRules.consecutive}</p>}
    </div>
  );

  useEffect(() => {
    const updateTime = () => {
      setUtcTime(moment.utc());
    };

    // Update immediately and then every second
    updateTime();
    const intervalId = setInterval(updateTime, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const openMeetingTab = useCallback((bookingId) => {
    if (hasOpenedTab) return; // Prevent multiple tabs from opening
    
    const roleInfo = encodeURIComponent(JSON.stringify({ 
      role: 'student', 
      studentId: student._id 
    }));
    
    window.open(`/Student/room/meeting/${bookingId}?roleInfo=${roleInfo}`, '_blank', 'noopener,noreferrer');
    setHasOpenedTab(true);
    message.success(t("status.JoiningRoom"));
    setIsJoiningRoom(false);
  }, [hasOpenedTab, student?._id, t]);

  useEffect(() => {
    const fetchBookings = async () => {
      setIsLoading(true);
      try {
        if (student?._id) {
          const result = await dispatch(GetBookingsByStudentID(student._id));
          console.log("Raw bookings data:", result); 
          console.log("Processed bookings data:", flattenBookings(result)); 
        }
      } catch (error) {
        console.error("Error fetching bookings:", error);
        message.error("Failed to fetch bookings. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchBookings();
  }, [student?._id, dispatch]);

  const checkTeacherStatus = useCallback(async () => {
    if (!currentBookingId || !currentTeacherId) return;
  
    try {
      const result = await dispatch(getTeacherSessionStatus({ 
        bookingId: currentBookingId, 
        teacherId: currentTeacherId 
      }));
  
      console.log('Teacher status check result:', result); // Add logging
  
      if (result.payload && result.payload.isActive) {
        setTeacherJoined(true);
        clearInterval(intervalId); // Clear the interval once teacher joins
      }
    } catch (error) {
      console.error("Error checking teacher status:", error);
    }
  }, [currentBookingId, currentTeacherId, dispatch]);

  useEffect(() => {
    let intervalId;
    
    if (isJoiningRoom && !teacherJoined) {
      // Check teacher status every 5 seconds
      intervalId = setInterval(async () => {
        try {
          if (!currentBookingId || !currentTeacherId) return;
          
          const result = await dispatch(getTeacherSessionStatus({ 
            bookingId: currentBookingId, 
            teacherId: currentTeacherId 
          }));
          
          console.log('Teacher status check result:', result);
          
          // Check both payload patterns to be safe
          const isActive = 
            (result.payload && result.payload.isActive) || 
            (result.payload && result.payload.data && result.payload.data.isActive);
            
          if (isActive) {
            console.log('Teacher joined! Setting teacherJoined to true');
            setTeacherJoined(true);
            clearInterval(intervalId);
          }
        } catch (error) {
          console.error("Error checking teacher status:", error);
        }
      }, 5000);
    }
    
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isJoiningRoom, teacherJoined, currentBookingId, currentTeacherId, dispatch]);

  useEffect(() => {
    let timerId;
    
    if (teacherJoined && !hasOpenedTab) {
      console.log('Teacher has joined, starting countdown:', waitTimeLeft);
      
      // Immediate navigation option for faster experience
      if (waitTimeLeft <= 0) {
        openMeetingTab(currentBookingId);
        return;
      }
      
      // Start countdown timer
      timerId = setInterval(() => {
        setWaitTimeLeft((prevTime) => {
          console.log('Countdown:', prevTime);
          if (prevTime <= 1) {
            clearInterval(timerId);
            openMeetingTab(currentBookingId);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    
    return () => {
      if (timerId) clearInterval(timerId);
    };
  }, [teacherJoined, currentBookingId, waitTimeLeft, hasOpenedTab, openMeetingTab]);

  const joinRoom = async (id, teacherId) => {
    try {
      // Validate booking
      const booking = flattenBookings(Bookings).find(b => b._id === id);
      if (!booking || ['Cancelled', 'Completed'].includes(booking.status)) {
        throw new Error('Invalid booking status');
      }
  
      // Set current booking and teacher IDs for status checking
      setCurrentBookingId(id);
      setCurrentTeacherId(teacherId);
      setIsJoiningRoom(true);
      setTeacherJoined(false);
      setWaitTimeLeft(10);
      
      // Check teacher status immediately
      const result = await dispatch(getTeacherSessionStatus({ 
        bookingId: id, 
        teacherId: teacherId 
      }));
      
      console.log('Initial teacher status check:', result);
      
      // Check both possible payload patterns
      const isActive = 
        (result.payload && result.payload.isActive) || 
        (result.payload && result.payload.data && result.payload.data.isActive);
      
        if (isActive) {
          // Teacher is already in the room
          setTeacherJoined(true);
          
          // Use a timeout just to show the joined state briefly
          setTimeout(() => {
            openMeetingTab(id);
          }, 3000);
        }
    } catch (error) {
      console.error('Error joining room:', error);
      message.error(`Failed to join: ${error.message}`);
      setIsJoiningRoom(false);
    }
  };

  const directJoinRoom = (bookingId) => {
    if (!student || !student._id) {
      message.error("Student information not available");
      return;
    }
    
    openMeetingTab(bookingId);
  };
  

  const handleCancelJoining = () => {
    setIsJoiningRoom(false);
    setCurrentBookingId(null);
    setCurrentTeacherId(null);
    setTeacherJoined(false);
    setWaitTimeLeft(10);
    setHasOpenedTab(false); // Reset the flag when canceling
    if (teacherWaitTimer) clearTimeout(teacherWaitTimer);
  };

  const EditBooking = (id, date, start, end, teacherId) => {
    navigate(`/Student-dashboard/Bookings/edit-Booking/${id}`, {
      state: { 
        selectedDate: date, 
        currentStart: start, 
        currentEnd: end,
        teacherId: teacherId
      }
    });
  };

  const convertUTCToLocal = (date, time) => {
    return moment.tz(`${date} ${time}`, 'YYYY-MM-DD HH:mm', 'UTC').tz(selectedTimezone);
  };

  const renderTime = (time, date) => {
    if (!time || !date) return '';
    const localDateTime = convertUTCToLocal(date, time);
    return hourFormat === '12' 
      ? localDateTime.format('hh:mm A')
      : localDateTime.format('HH:mm');
  };

  const renderDate = (date, start) => {
    if (!date || !start) return 'N/A';
    const localDateTime = convertUTCToLocal(date, start);
    return localDateTime.format('MMMM Do, YYYY');
  };

  const isEditableBooking = (date, startTime) => {
    if (!utcTime) return false;
    const bookingStartUTC = moment.utc(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm');
    return utcTime.isBefore(bookingStartUTC.subtract(24, 'hours'));
  };

  const getBookingStatus = (date, start, end, status) => {
    if (!utcTime) return 'N/A';
    const bookingStartUTC = moment.utc(`${date} ${start}`, 'YYYY-MM-DD HH:mm');
    const bookingEndUTC = moment.utc(`${date} ${end}`, 'YYYY-MM-DD HH:mm');
  
    if (status === "Cancelled") return "Cancelled";
    if (utcTime.isAfter(bookingEndUTC)) return "Completed";
    if (utcTime.isSameOrAfter(bookingStartUTC) && utcTime.isBefore(bookingEndUTC)) return "In Progress";
    if (status === "Rescheduled") return "Rescheduled";
    return "Scheduled";
  };

  const convertToLocalTime = (utcDate, utcTime, timezone) => {
    if (!utcDate || !utcTime || !timezone) return null;
    return moment.tz(`${utcDate} ${utcTime}`, 'YYYY-MM-DD HH:mm', 'UTC')
      .tz(timezone);
  };

  const getLocalTimeInfo = (booking, timezone, hourFormat) => {
    const startLocal = convertToLocalTime(booking.date, booking.start, timezone);
    const endLocal = convertToLocalTime(booking.date, booking.end, timezone);
    
    if (!startLocal || !endLocal) return null;
  
    // Format time according to selected format
    const timeFormat = localStorage.getItem('timeFormat') || '24';

    const formatTime = (timeStr) => {
      const timeMoment = moment(timeStr, 'HH:mm');
      return timeFormat === '12' ? 
        timeMoment.format('hh:mm A') : 
        timeMoment.format('HH:mm');
    };

    return {
      localDate: startLocal.format('YYYY-MM-DD'),
      localStart: startLocal.format('HH:mm'),
      localEnd: endLocal.format('HH:mm'),
      localDateFormatted: startLocal.format('MMMM Do, YYYY'),
      localStartFormatted: startLocal.format(timeFormat),
      localEndFormatted: endLocal.format(timeFormat)
    };
  };

  const flattenBookings = (bookings) => {
    if (!Array.isArray(bookings)) {
      console.error('Bookings is not an array:', bookings);
      return [];
    }
    
    const flattened = bookings.flatMap(booking => {
      // Special handling for cancelled and completed bookings with missing data
      if (booking.Status === 'Cancelled' || (booking.Status === 'Completed' && (!booking.Teacher_ID || booking.Teacher_ID.length === 0))) {
        return [{
          ...booking,
          date: booking.Scheduled_Dates[0]?.date || booking.Scheduled_Dates[0]?.[Object.keys(booking.Scheduled_Dates[0])[0]],
          start: '00:00',
          end: '00:00',
          status: booking.Status,
          sortableTimestamp: 0,
          localDateFormatted: booking.Status,
          localStartFormatted: '-',
          localEndFormatted: '-',
          Teacher_ID: booking.Teacher_ID || []
        }];
      }
      
      if (!booking || !booking._id || !booking.Scheduled_Dates) {
        console.warn('Invalid booking object:', booking);
        return [];
      }
      
      return booking.Scheduled_Dates.flatMap(dateObj => {
        if (!dateObj || typeof dateObj !== 'object') {
          console.warn('Invalid dateObj:', dateObj);
          return [];
        }
  
        const date = dateObj.date || Object.keys(dateObj)[0];
        if (!date) {
          console.warn('No date found in dateObj:', dateObj);
          return [];
        }
  
        const timeSlots = dateObj.slots || dateObj[date];
        if (!timeSlots) {
          console.warn('No timeSlots found for date:', date);
          return [];
        }
  
        const slots = Array.isArray(timeSlots) ? timeSlots : [timeSlots];
        
        return slots
      .filter(slot => {
        if (!slot || typeof slot !== 'object' || !slot.start || !slot.end) {
          console.warn('Invalid slot object:', slot);
          return false;
        }
        return true;
      })
      .map(slot => {
        try {
          const localTimeInfo = getLocalTimeInfo({
            date,
            start: slot.start,
            end: slot.end
          }, selectedTimezone, hourFormat);  // Pass selectedTimezone and hourFormat here

          if (!localTimeInfo) return null;

          const startDateTime = moment.utc(`${date} ${slot.start}`, 'YYYY-MM-DD HH:mm');

          return {
            ...booking,
            date,
            start: slot.start,
            end: slot.end,
            ...localTimeInfo,
            slotId: `${booking._id}-${date}-${slot.start}-${slot.end}`,
            sortableTimestamp: startDateTime.valueOf(),
            status: getBookingStatus(date, slot.start, slot.end, booking.Status)
          };
        } catch (error) {
          console.error('Error processing slot:', error, slot);
          return null;
        }
      })
      .filter(Boolean);
  });

    });
  
    flattened.sort((a, b) => a.sortableTimestamp - b.sortableTimestamp);
  
    const merged = [];
    let currentMerge = null;
  
    for (let i = 0; i < flattened.length; i++) {
      const current = flattened[i];
      // Update validation to handle both cancelled and completed statuses
      if (!current || 
          (!current.Package_ID && !['Cancelled', 'Completed'].includes(current.status)) || 
          (!current.Teacher_ID?.[0] && !['Cancelled', 'Completed'].includes(current.status))) {
        console.warn('Invalid flattened booking:', current);
        continue;
      }
  
      const currentDateTime = moment.utc(current.sortableTimestamp);
      
      if (currentMerge) {
        const mergeEndDateTime = moment.utc(`${currentMerge.date} ${currentMerge.end}`, 'YYYY-MM-DD HH:mm');
        
        if (currentDateTime.isSame(mergeEndDateTime) &&
    current.Package_ID?._id === currentMerge.Package_ID?._id &&
    current.Teacher_ID?.[0]?._id === currentMerge.Teacher_ID?.[0]?._id) {
          currentMerge.end = current.end;
          currentMerge.localEnd = current.localEnd;
          currentMerge.slotId += `-${current.slotId}`;
          currentMerge.isConsecutive = true;
          currentMerge.status = getBookingStatus(currentMerge.date, currentMerge.start, current.end, current.Status);
        } else {
          merged.push(currentMerge);
          currentMerge = {...current, isConsecutive: false};
        }
      } else {
        currentMerge = {...current, isConsecutive: false};
      }
    }
  
    if (currentMerge) {
      merged.push(currentMerge);
    }
  
    return merged;
  };

  const filterBookings = (bookings) => {
    if (selectedFilters.length === 0) return bookings;
  
    return bookings.filter(booking => {
      const bookingStartUTC = moment.utc(`${booking.date} ${booking.start}`, 'YYYY-MM-DD HH:mm');
      const bookingEndUTC = moment.utc(`${booking.date} ${booking.end}`, 'YYYY-MM-DD HH:mm');
      const isPast = utcTime?.isAfter(bookingEndUTC);
      const isUpcoming = utcTime?.isBefore(bookingStartUTC);
  
      return selectedFilters.some(filter => {
        switch (filter) {
          case 'upcoming':
            return isUpcoming;
          case 'past':
            return isPast;
          case 'completed':
            return booking.status === 'BookingStatus.Completed';
          case 'inProgress':
            return booking.status === 'In Progress';
          case 'scheduled':
            return booking.status === 'Scheduled';
          case 'cancelled':
            return booking.status === 'Cancelled';
          default:
            return true;
        }
      });
    });
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Completed':
        return 'green';
      case 'In Progress':
        return 'orange';
      case 'Scheduled':
        return 'blue';
      case 'Cancelled':
        return 'red';
      default:
        return 'default';
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: t("StudentBooking.PackageName"),
      dataIndex: ['Package_ID', 'Package_Name'],
      key: 'packageName',
    },
    {
      title: t("StudentBooking.TeacherName"),
      dataIndex: 'Teacher_ID',
      key: 'teacherName',
      render: (teachers) => teachers?.map(teacher => teacher?.Username).join(", "),
    },
    {
      title: t("StudentBooking.Date"),
      dataIndex: 'localDateFormatted',
      key: 'date',
      sorter: (a, b) => a.sortableTimestamp - b.sortableTimestamp,
    },
    {
      title: t("StudentBooking.Time"),
      key: 'time',
      render: (text, record) => (
        <span>
          {`${record.localStartFormatted} - ${record.localEndFormatted}`}
          {record.isConsecutive && (
            <Tag color="blue" style={{ marginLeft: 8 }}>
              {t('Calendar.ConsecutiveBooking')}
            </Tag>
          )}
        </span>
      ),
      sorter: (a, b) => a.sortableTimestamp - b.sortableTimestamp,
    },
    {
      title: t("StudentBooking.Status"),
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={getStatusColor(status)}>
          {status || 'N/A'}
        </Tag>
      ),
    },
    {
      title: t("StudentBooking.Actions"),
      key: 'actions',
      render: (text, record) => {
        if (!utcTime) return null;
        
        const bookingStartUTC = moment.utc(`${record.date} ${record.start}`, 'YYYY-MM-DD HH:mm');
        const bookingEndUTC = moment.utc(`${record.date} ${record.end}`, 'YYYY-MM-DD HH:mm');
        
        const isBookingPast = utcTime.isAfter(bookingEndUTC);
        const isJoinable = (record.status === "Scheduled" || record.status === "Rescheduled" || record.status === "In Progress") && !isBookingPast;
        const isEditable = record.status === "Scheduled" && !isBookingPast && isEditableBooking(record.date, record.start);
    
        return (
          <span>
            {!isBookingPast && (
              <>
                <Tooltip title={bookingRules.cancelled}>
                  <Button 
                    type="primary"
                    onClick={() => joinRoom(record._id, record.Teacher_ID?.[0]?._id)}
                    style={{ marginRight: '10px', backgroundColor: '#74be57', borderColor: '#44643c' }}
                    disabled={!isJoinable}
                  >
                    {t("StudentBooking.JoinRoom")}
                  </Button>
                </Tooltip>
                <Tooltip title={
                  isEditable ? 
                    (record.isConsecutive ? bookingRules.consecutive : bookingRules.reschedule) : 
                    (record.status === "Rescheduled" ? bookingRules.oneTimeReschedule : bookingRules.reschedule)
                }>
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => EditBooking(record._id, record.date, record.start, record.end, record.Teacher_ID?.[0]?._id)}
                    disabled={!isEditable}
                  >
                    Edit
                  </Button>
                </Tooltip>
              </>
            )}
            <Popover 
              content={<RenderBookingInfo isConsecutive={record.isConsecutive} />} 
              title="Booking Rules"
              trigger="click"
            >
              <Button icon={<InfoCircleOutlined />} type="text" style={{ marginLeft: '10px' }} />
            </Popover>
          </span>
        );
      },
    }
  ];

  const renderMobileCard = (item) => {
    if (!utcTime) return null;

    const bookingStartUTC = moment.utc(`${item.date} ${item.start}`, 'YYYY-MM-DD HH:mm');
    const bookingEndUTC = moment.utc(`${item.date} ${item.end}`, 'YYYY-MM-DD HH:mm');
    
    const isBookingPast = utcTime.isAfter(bookingEndUTC);
    const isJoinable = (item.status === "Scheduled" || item.status === "Rescheduled" || item.status === "In Progress") && !isBookingPast;
    const isEditable = item.status === "Scheduled" && !isBookingPast && isEditableBooking(item.date, item.start);

    return (
      <Card 
        style={{ marginBottom: 16 }}
        extra={
          <Popover 
            content={<RenderBookingInfo isConsecutive={item.isConsecutive} />} 
            title="Booking Rules"
            trigger="click"
          >
            <Button icon={<InfoCircleOutlined />} type="text" />
          </Popover>
        }
      >
        <Space direction="vertical" size="small">
          <Text strong>{t("StudentBooking.PackageName")}: {item.Package_ID?.Package_Name || 'N/A'}</Text>
          <Text>{t("StudentBooking.TeacherName")}: {item.Teacher_ID?.map(teacher => teacher?.Username).join(", ") || 'N/A'}</Text>
          <Text>{t("StudentBooking.Date")}: {renderDate(item.date, item.start)}</Text>
          <Text>{t("StudentBooking.Time")}: {renderTime(item.start, item.date)} - {renderTime(item.end, item.date)}
            {item.isConsecutive && (
              <Tag color="blue" style={{ marginLeft: 8 }}>
                {t('Calendar.ConsecutiveBooking')}
              </Tag>
            )}
          </Text>
          <Text>{t("StudentBooking.Status")}: 
            <Tag color={getStatusColor(item.status)} style={{ marginLeft: 8 }}>
              {item.status || 'N/A'}
            </Tag>
          </Text>
          {!isBookingPast && (
            <>
              <Tooltip title={bookingRules.cancelled}>
                <Button 
                  type="primary"
                  onClick={() => joinRoom(item._id, item.Teacher_ID?.[0]?._id)}
                  style={{ width: '100%', marginBottom: '8px', backgroundColor: '#74be57', borderColor: '#44643c' }}
                  disabled={!isJoinable}
                >
                  {t("StudentBooking.JoinRoom")}
                </Button>
              </Tooltip>
              <Tooltip title={
                isEditable ? 
                  (item.isConsecutive ? bookingRules.consecutive : bookingRules.reschedule) : 
                  (item.status === "Rescheduled" ? bookingRules.oneTimeReschedule : bookingRules.reschedule)
              }>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => EditBooking(item._id, item.date, item.start, item.end, item.Teacher_ID?.[0]?._id)}
                  style={{ width: '100%' }}
                  disabled={!isEditable}
                >
                  Edit
                </Button>
              </Tooltip>
            </>
          )}
        </Space>
      </Card>
    );
  };

  // In the Bookings component
const flattenedBookings = flattenBookings(Bookings, selectedTimezone, hourFormat);

  if (isLoading) {
    return (
      <Loader2 
        loading={true}
        text={t("common.Loading")}
        fullScreen={true}
        size={300}
        customStyles={{
          backgroundColor: 'rgba(15, 15, 15, 0.5)'
        }}
      />
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pb-16 md:pb-0">
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 mb-6">
          <h1 className="text-2xl font-semibold text-gray-900">{t("StudentBooking.YourBookings")}</h1>
          <p className="text-gray-500 mt-1">{t("StudentBooking.Manage")}</p>
          
          <BookingFilters 
            selectedFilters={selectedFilters}
            onFilterChange={setSelectedFilters}
          />
        </div>

        {isLoading ? (
          <div className="flex items-center justify-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-4 border-emerald-500 border-t-transparent"></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filterBookings(flattenBookings(Bookings)).map((booking) => (
              <BookingCard
                key={booking.slotId}
                booking={booking}
                onJoin={joinRoom}
                onEdit={EditBooking}
                rules={bookingRules}
              />
            ))}
          </div>
        )}
      </div>

      {/* Join Room Modal */}
      {isJoiningRoom && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4">
      <div className="text-center">
        {!teacherJoined ? (
          <>
            <div className="animate-spin rounded-full h-12 w-12 border-4 border-emerald-500 border-t-transparent mx-auto"></div>
            <p className="mt-4 text-gray-600">{t("status.WaitingPeriod")}</p>
            <p className="mt-2 text-sm text-gray-500">
              {t("status.teacherWillJoin")}
            </p>
            <div className="mt-4 text-xs text-gray-400">
              {/* {t("status.teacherNotificationSent")} */}
            </div>
          </>
        ) : (
          <>
            <div className="relative h-20 w-20 mx-auto">
              <svg className="transform -rotate-90" viewBox="0 0 36 36">
                <path
                  d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                  fill="none"
                  stroke="#E5E7EB"
                  strokeWidth="3"
                />
                <path
                  d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                  fill="none"
                  stroke="#10B981"
                  strokeWidth="3"
                  strokeDasharray={`${(100 - (waitTimeLeft / 10 * 100)) * 1}, 100`}
                />
              </svg>
            </div>
            <p className="mt-4 text-gray-600">
              {t("status.teacherSettingUp")} {waitTimeLeft} {t("status.seconds")}
            </p>
          </>
        )}

        <div className="mt-6 flex space-x-3 justify-center">
          <button
            onClick={handleCancelJoining}
            className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors"
          >
            {t("status.Cancel")}
          </button>
          
          {/* <button
            onClick={() => directJoinRoom(currentBookingId)}
            className="px-4 py-2 bg-emerald-500 text-white rounded-lg hover:bg-emerald-600 transition-colors"
          >
            {t("status.JoinAnyway")}
          </button> */}
        </div>
      </div>
    </div>
  </div>
)}
    </div>
  );
};

export default Bookings;
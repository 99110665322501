// src/store/store.js
import { configureStore } from "@reduxjs/toolkit";
import studentsSlice from './slices/studentsSlice'
import teacherSlice from './slices/teacherSlice'
import coursesSlice from "./slices/coursesSlice";
import enquirySlice from "./slices/enquirySlice";
import packagesSlice from "./slices/packagesSlice";
import paymentSlice from "./slices/paymentSlice";
import bookingSlice from "./slices/bookingSlice";
import notificationSlice from "./slices/notificationSlice";
import walletSlice from "./slices/walletSlice"; 
import meetingReducer from './slices/meetingSlice';
import evaluationSlice from './slices/evaluationSlice';
import trialBookingReducer from './slices/trialPreferencesSlice';
import { teacherSessionReducer, studentSessionReducer } from './slices/teacherSessionSlice';
import teacherEarningsReducer from './slices/teacherWalletSlice';
import teacherNotesReducer from './slices/teacherNotesSlice'; // Add this import

export const store = configureStore({
  reducer: {
    students: studentsSlice,
    teachers: teacherSlice,
    courses: coursesSlice,
    enquirys: enquirySlice,
    packages: packagesSlice,
    payments: paymentSlice,
    bookings: bookingSlice,
    trialBooking: trialBookingReducer,
    notifications: notificationSlice,
    wallet: walletSlice, 
    meeting: meetingReducer,
    teacherSession: teacherSessionReducer,
    studentSession: studentSessionReducer,
    evaluations: evaluationSlice,
    teacherEarnings: teacherEarningsReducer,
    teacherNotes: teacherNotesReducer  // Add this line
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          // Existing ignored actions
          'bookings/getbookings',
          'bookings/getbookings/fulfilled',
          'bookings/Add_booking/fulfilled',
          'bookings/Updatebooking/fulfilled',
          'bookings/GetBookingsByStudentID/fulfilled',
          'bookings/GetBookingsByTeacherID/fulfilled',
          'evaluations/getStudentEvaluations/fulfilled',
          'evaluations/getEvaluationStats/fulfilled',
          'teacherSession/joinSession/fulfilled',
          'teacherSession/getStatus/fulfilled',
          'studentSession/joinSession/fulfilled',
          'studentSession/getStatus/fulfilled',
          
          // Teacher earnings ignored actions
          'teacherEarnings/setRate/fulfilled',
          'teacherEarnings/creditLesson/fulfilled',
          'teacherEarnings/getBalance/fulfilled',
          'teacherEarnings/getHistory/fulfilled',
          
          // Teacher notes ignored actions
          'teacherNotes/createNote/fulfilled',
          'teacherNotes/getStudentNotes/fulfilled',
          'teacherNotes/getTeacherNotes/fulfilled',
          'teacherNotes/getNoteById/fulfilled',
          'teacherNotes/updateNote/fulfilled',
          'teacherNotes/toggleArchiveStatus/fulfilled',
          'teacherNotes/getNotesByBooking/fulfilled',
          'teacherNotes/getRecentNotes/fulfilled'
        ],
        ignoredPaths: [
          // Existing ignored paths
          'bookings.Allbookinglist',
          'bookings.Teacher_Bookings',
          'bookings.StudentID_Booking',
          'bookings.existingBookings',
          'evaluations.evaluations',
          'evaluations.stats',
          'teacherSession.sessionDetails',
          'studentSession.sessionDetails',
          
          // Teacher earnings ignored paths
          'teacherEarnings.earningsHistory',
          'teacherEarnings.stats',
          'teacherEarnings.transactions',
          
          // Teacher notes ignored paths
          'teacherNotes.allNotes',
          'teacherNotes.studentNotes',
          'teacherNotes.bookingNotes',
          'teacherNotes.currentNote',
          'teacherNotes.recentNotes',
          'teacherNotes.statistics'
        ],
        warnAfter: 800
      },
      immutableCheck: { warnAfter: 800 }
    })
});

export default store;
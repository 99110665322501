import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
  Card, Row, Col, Statistic, List, Typography, Tag, 
  Button, Select, DatePicker, Divider, Modal, Form, Input, message 
} from 'antd';
import { 
  DollarOutlined, WalletOutlined, TransactionOutlined,
  ArrowUpOutlined, ArrowDownOutlined, LineChartOutlined 
} from '@ant-design/icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import {
  getTeacherEarningsBalance,
  getTeacherEarningsHistory,
  setTeacherEarningRate,
  fetchTeacherWalletData
} from '../../store/actions/teacherWallet';
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const TeacherEarnings = () => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch();
  const teacher = useSelector((state) => state.students.user);
  console.log('Current Teacher:', teacher);
  const { 
    balance, 
    earningRate,
    currency,
    earningsHistory,
    stats,
    loading 
  } = useSelector((state) => state.teacherEarnings);

  const [timeFilter, setTimeFilter] = useState('month');
  const [withdrawModalVisible, setWithdrawModalVisible] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [dateRange, setDateRange] = useState([
    moment().subtract(1, 'month'),
    moment()
  ]);
  const [walletData, setWalletData] = useState(null);

  // Get current teacher data
  useEffect(() => {
    console.log('Fetching current teacher');
    // Add your action to fetch current teacher here
    // For example:
    // dispatch(getCurrentTeacher());
  }, []);

  // Initial data load
  useEffect(() => {
    console.log('Current Teacher Object:', teacher);
    console.log('Teacher ID in useEffect:', teacher?._id);
    if (teacher?._id) {
      console.log('Dispatching fetchTeacherWalletData');
      const promise = dispatch(fetchTeacherWalletData(teacher._id));
      console.log('Dispatch result:', promise);
      
      promise.then((response) => {
        console.log('API Response:', response);
        console.log('Response Payload:', response.payload);
        if (response.payload) {
          setWalletData(response.payload);
        } else {
          console.log('No payload in response');
        }
      })
      .catch((error) => {
        console.error('API Error:', error);
        message.error(t('teacherEarnings.failedToFetch'));
      });

      dispatch(getTeacherEarningsBalance(teacher._id));
      dispatch(getTeacherEarningsHistory({
        teacherId: teacher._id,
        params: {
          startDate: dateRange[0].format('YYYY-MM-DD'),
          endDate: dateRange[1].format('YYYY-MM-DD'),
          page: 1,
          limit: 10
        }
      }));
    }
  }, [dispatch, teacher?._id, t]);

  // Debug wallet data changes
  useEffect(() => {
    console.log('Current Wallet Data:', walletData);
  }, [walletData]);

  // Format chart data
  const formatChartData = () => {
    if (walletData?.bookings) {
      return walletData.bookings
        .filter(booking => booking.Status === 'Completed')
        .map(booking => ({
          date: moment(booking.Scheduled_Dates?.[0] || new Date()).format('MMM D'),
          earnings: booking.earnings?.amount || 0
        }));
    }
    return [];
  };

  const handleWithdrawRequest = () => {
    if (withdrawAmount <= 0 || withdrawAmount > (walletData?.walletBalance || 0)) {
      message.error(t('teacherEarnings.invalidAmount'));
      return;
    }

    // Here you would implement the withdrawal API call
    message.success(t('teacherEarnings.withdrawalSubmitted'));
    setWithdrawModalVisible(false);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
    if (teacher?._id && dates) {
      dispatch(getTeacherEarningsHistory({
        teacherId: teacher._id,
        params: {
          startDate: dates[0].format('YYYY-MM-DD'),
          endDate: dates[1].format('YYYY-MM-DD'),
          page: 1,
          limit: 10
        }
      }));
    }
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase() || '') {
      case 'completed': return 'green';
      case 'processed': return 'blue';
      case 'scheduled': return 'orange';
      default: return 'gray';
    }
  };

  // Helper function to safely get the username from potentially null Student_ID
  const getStudentUsername = (booking) => {
    return booking?.Student_ID?.Username || t('teacherEarnings.unknownStudent');
  };

  // Helper function to safely get the package name
  const getPackageName = (booking) => {
    return booking?.Package_ID?.Package_Name || t('teacherEarnings.noPackage');
  };

  // Helper to safely get purchase price
  const getTeacherRate = () => {
    if (walletData?.bookings?.[0]?.Teacher_ID?.[0]?.Purchase_Price) {
      return walletData.bookings[0].Teacher_ID[0].Purchase_Price;
    }
    return 0;
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      {/* Header Section */}
      <Card bordered={false} className="mb-6" style={{ background: '#f6f9ff' }}>
        <Row align="middle" gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Title level={2} style={{ marginBottom: 0 }}>
              <WalletOutlined style={{ marginRight: 12 }} />
              {t('teacherEarnings.title')}
            </Title>
            <Text type="secondary">
              {t('teacherEarnings.currentRate')}: {getTeacherRate()} PLN {t('teacherEarnings.perLesson')}
            </Text>
          </Col>
          <Col xs={24} md={12} style={{ textAlign: 'right' }}>
            <Button 
              type="primary" 
              size="large"
              onClick={() => setWithdrawModalVisible(true)}
              icon={<ArrowUpOutlined />}
              disabled={!walletData?.walletBalance}
            >
              {t('teacherEarnings.requestWithdrawal')}
            </Button>
          </Col>
        </Row>
      </Card>

      {/* Stats Cards */}
      <Row gutter={[16, 16]} className="mb-6">
        <Col xs={24} sm={12} lg={6}>
          <Card bordered={false}>
            <Statistic
              title={t('teacherEarnings.availableBalance')}
              value={walletData?.walletBalance || 0}
              precision={2}
              prefix={<DollarOutlined />}
              valueStyle={{ color: '#52c41a' }}
              suffix="PLN"
              loading={loading}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card bordered={false}>
            <Statistic
              title={t('teacherEarnings.totalEarned')}
              value={walletData?.summary?.totalEarnings || 0}
              precision={2}
              prefix={<DollarOutlined />}
              valueStyle={{ color: '#1890ff' }}
              suffix="PLN"
              loading={loading}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card bordered={false}>
            <Statistic
              title={t('teacherEarnings.totalBookings')}
              value={walletData?.summary?.totalBookings || 0}
              prefix={<LineChartOutlined />}
              valueStyle={{ color: '#ff4d4f' }}
              loading={loading}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card bordered={false}>
            <Statistic
              title={t('teacherEarnings.completedBookings')}
              value={walletData?.summary?.completedBookings || 0}
              prefix={<LineChartOutlined />}
              valueStyle={{ color: '#722ed1' }}
              loading={loading}
            />
          </Card>
        </Col>
      </Row>

      {/* Earnings Chart */}
      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <LineChartOutlined style={{ marginRight: 8 }} />
            {t('teacherEarnings.earningsOverview')}
            <Select
              defaultValue="month"
              style={{ width: 120, marginLeft: 16 }}
              onChange={setTimeFilter}
            >
              <Select.Option value="week">{t('teacherEarnings.weekly')}</Select.Option>
              <Select.Option value="month">{t('teacherEarnings.monthly')}</Select.Option>
              <Select.Option value="year">{t('teacherEarnings.yearly')}</Select.Option>
            </Select>
          </div>
        }
        bordered={false}
        className="mb-6"
        loading={loading}
      >
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={formatChartData()}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Line 
              type="monotone" 
              dataKey="earnings" 
              stroke="#1890ff" 
              strokeWidth={2}
              dot={{ fill: '#1890ff' }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Card>

      {/* Bookings History */}
      <Card
        title={
          <>
            <TransactionOutlined style={{ marginRight: 8 }} />
            {t('teacherEarnings.bookingsHistory')}
            <RangePicker 
              style={{ marginLeft: 16, width: 250 }}
              value={dateRange}
              onChange={handleDateRangeChange}
            />
          </>
        }
        bordered={false}
        loading={loading}
      >
        <List
          itemLayout="vertical"
          dataSource={walletData?.bookings || []}
          renderItem={booking => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <div style={{
                    padding: 12,
                    borderRadius: '50%',
                    background: booking?.Status === 'Completed' ? '#e6f7ff' : '#fff1f0'
                  }}>
                    {booking?.Status === 'Completed' ? (
                      <ArrowDownOutlined style={{ color: '#52c41a', fontSize: 20 }} />
                    ) : (
                      <ArrowUpOutlined style={{ color: '#ff4d4f', fontSize: 20 }} />
                    )}
                  </div>
                }
                title={
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Text strong>{getStudentUsername(booking)}</Text>
                    <Text style={{ color: booking?.Status === 'Completed' ? '#52c41a' : '#ff4d4f' }}>
                      {booking?.earnings ? `${booking.earnings.amount} PLN` : '-'}
                    </Text>
                  </div>
                }
                description={
                  <>
                    <Tag color={getStatusColor(booking?.Status)}>
                      {booking?.Status === 'Completed' 
                        ? t('status.Completed') 
                        : booking?.Status === 'Scheduled' 
                        ? t('status.Scheduled') 
                        : booking?.Status === 'Cancelled' 
                        ? t('status.Cancelled') 
                        : booking?.Status || t('common.loading')}
                    </Tag>
                    <Text type="secondary">
                      {getPackageName(booking)}
                    </Text>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </Card>

      {/* Withdrawal Modal */}
      <Modal
        title={t('teacherEarnings.withdrawalTitle')}
        visible={withdrawModalVisible}
        onOk={handleWithdrawRequest}
        onCancel={() => setWithdrawModalVisible(false)}
        confirmLoading={loading}
      >
        <Form layout="vertical">
          <Form.Item
            label={t('teacherEarnings.withdrawalAmount')}
            help={`${t('teacherEarnings.availableBalanceHelp')}: ${walletData?.walletBalance || 0} PLN`}
          >
            <Input
              type="number"
              prefix={<DollarOutlined />}
              suffix="PLN"
              max={walletData?.walletBalance || 0}
              min={0}
              value={withdrawAmount}
              onChange={(e) => setWithdrawAmount(parseFloat(e.target.value))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TeacherEarnings;
// components/TeacherNotes/TeacherNotesModal.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { 
  Modal, Button, Form, Input, Select, Tag, List, Space, 
  Typography, Divider, Card, Skeleton, Empty, Tooltip, Badge, 
  message, Tabs, Popconfirm
} from 'antd';
import { 
  PlusOutlined, TagOutlined, FileTextOutlined, 
  DeleteOutlined, EditOutlined, LockOutlined,
  UserOutlined, EyeOutlined, InboxOutlined,
  ClockCircleOutlined, CheckCircleOutlined,
  BookOutlined,
  HistoryOutlined,
  ExpandOutlined
} from '@ant-design/icons';

import moment from 'moment-timezone';

import { 
  createNote, 
  getStudentNotes, 
  getNotesByBooking, 
  updateNote, 
  deleteNote, 
  toggleArchiveStatus 
} from '../../store/actions/teacherNotesActions';

// Import the NoteDetailModal component
import NoteDetailModal from './NoteDetailModal';

const { TextArea } = Input;
const { Option } = Select;
const { Text, Title } = Typography;
const { TabPane } = Tabs;

const TeacherNotesModal = ({ 
  isVisible, 
  onClose, 
  bookingId, 
  studentId, 
  studentName 
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('global');
  
  const { 
    studentNotes, 
    bookingNotes, 
    loading, 
    error 
  } = useSelector(state => state.teacherNotes);
  
  const [form] = Form.useForm();
  const [editingNote, setEditingNote] = useState(null);
  const [tagInput, setTagInput] = useState('');
  const [tags, setTags] = useState([]);
  const [activeTab, setActiveTab] = useState('student');
  // Add state for the detail modal
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);

  // Fetch notes when modal opens
  useEffect(() => {
    if (isVisible) {
      if (studentId) {
        dispatch(getStudentNotes({ 
          studentId,
          params: { archived: false }
        }));
      }
      
      if (bookingId) {
        dispatch(getNotesByBooking(bookingId));
      }
    }
  }, [dispatch, isVisible, studentId, bookingId]);

  // Reset form when modal closes or when editing note changes
  useEffect(() => {
    if (isVisible) {
      if (editingNote) {
        form.setFieldsValue({
          content: editingNote.content,
          category: editingNote.category,
          visibility: editingNote.visibility
        });
        setTags(editingNote.tags || []);
      } else {
        form.resetFields();
        setTags([]);
      }
    }
  }, [form, isVisible, editingNote]);

  const handleSubmit = async (values) => {
    try {
      if (editingNote) {
        await dispatch(updateNote({
          noteId: editingNote._id,
          updateData: {
            ...values,
            tags
          }
        })).unwrap();
        message.success(t('teacherNotes.updateSuccess'));
      } else {
        await dispatch(createNote({
          studentId,
          bookingId,
          ...values,
          tags
        })).unwrap();
        message.success(t('teacherNotes.createSuccess'));
      }
      
      form.resetFields();
      setTags([]);
      setEditingNote(null);
      
      // Refresh notes
      if (studentId) {
        dispatch(getStudentNotes({ 
          studentId,
          params: { archived: false }
        }));
      }
      
      if (bookingId) {
        dispatch(getNotesByBooking(bookingId));
      }
    } catch (err) {
      message.error(err.message || t('teacherNotes.error'));
    }
  };

  const handleTagInputChange = (e) => {
    setTagInput(e.target.value);
  };

  const handleTagInputConfirm = () => {
    if (tagInput && !tags.includes(tagInput)) {
      setTags([...tags, tagInput]);
    }
    setTagInput('');
  };

  const handleTagClose = (removedTag) => {
    const newTags = tags.filter(tag => tag !== removedTag);
    setTags(newTags);
  };

  const handleEditNote = (note) => {
    setEditingNote(note);
  };

  const handleDeleteNote = async (noteId) => {
    try {
      await dispatch(deleteNote(noteId)).unwrap();
      message.success(t('teacherNotes.deleteSuccess'));
      
      // Refresh notes
      if (studentId) {
        dispatch(getStudentNotes({ 
          studentId,
          params: { archived: false }
        }));
      }
      
      if (bookingId) {
        dispatch(getNotesByBooking(bookingId));
      }
    } catch (err) {
      message.error(err.message || t('teacherNotes.deleteError'));
    }
  };

  const handleArchiveToggle = async (noteId) => {
    try {
      await dispatch(toggleArchiveStatus(noteId)).unwrap();
      
      // Refresh notes
      if (studentId) {
        dispatch(getStudentNotes({ 
          studentId,
          params: { archived: false }
        }));
      }
      
      if (bookingId) {
        dispatch(getNotesByBooking(bookingId));
      }
    } catch (err) {
      message.error(err.message || t('teacherNotes.archiveError'));
    }
  };

  // New handler for opening the detail modal
  const handleOpenDetailModal = (note) => {
    setSelectedNote(note);
    setDetailModalVisible(true);
  };

  // Handler for closing the detail modal
  const handleCloseDetailModal = () => {
    setDetailModalVisible(false);
    setSelectedNote(null);
  };

  const renderVisibilityIcon = (visibility) => {
    switch (visibility) {
      case 'private':
        return <Tooltip title={t('teacherNotes.private')}><LockOutlined /></Tooltip>;
      case 'student':
        return <Tooltip title={t('teacherNotes.visibleToStudent')}><UserOutlined /></Tooltip>;
      case 'admin':
        return <Tooltip title={t('teacherNotes.visibleToAdmin')}><EyeOutlined /></Tooltip>;
      case 'all':
        return <Tooltip title={t('teacherNotes.visibleToAll')}><EyeOutlined /></Tooltip>;
      default:
        return <LockOutlined />;
    }
  };

  const getCategoryColor = (category) => {
    switch (category) {
      case 'lesson': return 'blue';
      case 'progress': return 'green';
      case 'behavior': return 'orange';
      default: return 'default';
    }
  };

  const renderNotesList = () => {
    let notesToShow = [];
    
    if (activeTab === 'student' && studentId && studentNotes[studentId]) {
      notesToShow = studentNotes[studentId].notes;
    } else if (activeTab === 'booking' && bookingId && bookingNotes[bookingId]) {
      notesToShow = bookingNotes[bookingId].notes;
    }
    
    if (loading && !notesToShow.length) {
      return <Skeleton active paragraph={{ rows: 3 }} />;
    }
    
    if (!notesToShow.length) {
      return (
        <Empty
          image={<InboxOutlined style={{ fontSize: 48 }} />}
          description={t('teacherNotes.noNotes')}
        />
      );
    }
    
    return (
      <List
        itemLayout="vertical"
        dataSource={notesToShow}
        renderItem={(note) => (
          <List.Item
            key={note._id}
            className="note-list-item"
            style={{ cursor: 'pointer' }}
            onClick={() => handleOpenDetailModal(note)}
            actions={[
              <Button 
                type="text" 
                icon={<EditOutlined />} 
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditNote(note);
                }}
              >
                {t('teacherNotes.edit')}
              </Button>,
              <Popconfirm
                title={t('teacherNotes.confirmDelete')}
                onConfirm={(e) => {
                  e.stopPropagation();
                  handleDeleteNote(note._id);
                }}
                onCancel={(e) => e.stopPropagation()}
                okText={t('general.yes')}
                cancelText={t('general.no')}
              >
                <Button 
                  type="text" 
                  danger 
                  icon={<DeleteOutlined />}
                  onClick={(e) => e.stopPropagation()}
                >
                  {t('teacherNotes.delete')}
                </Button>
              </Popconfirm>,
              <Button
                type="text"
                icon={note.isArchived ? <CheckCircleOutlined /> : <HistoryOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  handleArchiveToggle(note._id);
                }}
              >
                {note.isArchived ? t('teacherNotes.unarchive') : t('teacherNotes.archive')}
              </Button>,
              <Button
                type="text"
                icon={<ExpandOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenDetailModal(note);
                }}
              >
                {t('teacherNotes.viewDetails')}
              </Button>
            ]}
          >
            <List.Item.Meta
              title={
                <Space>
                  <Badge 
                    dot 
                    color={getCategoryColor(note.category)} 
                    style={{ marginRight: 8 }}
                  />
                  <Text strong>{t(`teacherNotes.categories.${note.category}`)}</Text>
                  {renderVisibilityIcon(note.visibility)}
                  <Tag color={getCategoryColor(note.category)}>
                    {t(`teacherNotes.categories.${note.category}`)}
                  </Tag>
                </Space>
              }
              description={
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Text type="secondary">
                    <ClockCircleOutlined /> {moment(note.createdAt).fromNow()}
                  </Text>
                  {note.tags?.length > 0 && (
                    <Space wrap>
                      {note.tags.map(tag => (
                        <Tag key={tag} color="purple">
                          {tag}
                        </Tag>
                      ))}
                    </Space>
                  )}
                </Space>
              }
            />
            <div style={{ marginTop: 8 }}>
              <Text 
                ellipsis={{ 
                  rows: 2, 
                  expandable: false,
                  tooltip: t('teacherNotes.clickToViewFull') 
                }}
              >
                {note.content}
              </Text>
            </div>
          </List.Item>
        )}
      />
    );
  };

  return (
    <>
      <Modal
        title={
          <Space>
            <FileTextOutlined />
            {studentName 
              ? t('teacherNotes.modalTitleWithStudent', { name: studentName })
              : t('teacherNotes.modalTitle')
            }
          </Space>
        }
        open={isVisible}
        onCancel={onClose}
        footer={null}
        width={800}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            content: '',
            category: 'general',
            visibility: 'private'
          }}
        >
          <Form.Item
            name="content"
            label={t('teacherNotes.noteContent')}
            rules={[{ required: true, message: t('teacherNotes.contentRequired') }]}
          >
            <TextArea rows={4} placeholder={t('teacherNotes.contentPlaceholder')} />
          </Form.Item>

          <Space style={{ display: 'flex', marginBottom: 16 }}>
            <Form.Item
              name="category"
              label={t('teacherNotes.category')}
              style={{ marginBottom: 0, marginRight: 16 }}
            >
              <Select style={{ width: 160 }}>
                <Option value="general">{t('teacherNotes.categories.general')}</Option>
                <Option value="lesson">{t('teacherNotes.categories.lesson')}</Option>
                <Option value="progress">{t('teacherNotes.categories.progress')}</Option>
                <Option value="behavior">{t('teacherNotes.categories.behavior')}</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="visibility"
              label={t('teacherNotes.visibility')}
              style={{ marginBottom: 0 }}
            >
              <Select style={{ width: 160 }}>
                <Option value="private">{t('teacherNotes.private')}</Option>
                <Option value="student">{t('teacherNotes.visibleToStudent')}</Option>
                <Option value="admin">{t('teacherNotes.visibleToAdmin')}</Option>
                <Option value="all">{t('teacherNotes.visibleToAll')}</Option>
              </Select>
            </Form.Item>
          </Space>

          <Form.Item label={t('teacherNotes.tags')}>
            <Space wrap>
              {tags.map(tag => (
                <Tag 
                  key={tag} 
                  closable 
                  onClose={() => handleTagClose(tag)}
                >
                  {tag}
                </Tag>
              ))}
              <Input
                type="text"
                size="small"
                style={{ width: 100 }}
                value={tagInput}
                onChange={handleTagInputChange}
                onBlur={handleTagInputConfirm}
                onPressEnter={handleTagInputConfirm}
                placeholder={t('teacherNotes.addTag')}
                prefix={<TagOutlined />}
              />
            </Space>
          </Form.Item>

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" icon={<PlusOutlined />}>
                {editingNote ? t('teacherNotes.update') : t('teacherNotes.create')}
              </Button>
              {editingNote && (
                <Button
                  onClick={() => {
                    setEditingNote(null);
                    form.resetFields();
                    setTags([]);
                  }}
                >
                  {t('teacherNotes.cancel')}
                </Button>
              )}
            </Space>
          </Form.Item>
        </Form>

        <Divider />

        <Tabs 
          activeKey={activeTab} 
          onChange={setActiveTab}
        >
          <TabPane 
            tab={<><UserOutlined /> {t('teacherNotes.studentNotes')}</>} 
            key="student"
          >
            {renderNotesList()}
          </TabPane>
          <TabPane 
            tab={<><BookOutlined /> {t('teacherNotes.lessonNotes')}</>} 
            key="booking"
            disabled={!bookingId}
          >
            {renderNotesList()}
          </TabPane>
        </Tabs>
      </Modal>

      {/* Note Detail Modal */}
      <NoteDetailModal
        isVisible={detailModalVisible}
        onClose={handleCloseDetailModal}
        note={selectedNote}
      />
    </>
  );
};

export default TeacherNotesModal;
// slices/teacherNotesSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createNote,
  getStudentNotes,
  getTeacherNotes,
  getNoteById,
  updateNote,
  deleteNote,
  toggleArchiveStatus,
  getNotesByBooking,
  getRecentNotes,
  getNoteStatistics
} from "../actions/teacherNotesActions";

const initialState = {
  allNotes: [],                // All teacher's notes
  studentNotes: {},            // Notes organized by studentId
  bookingNotes: {},            // Notes organized by bookingId
  currentNote: null,           // Currently selected note
  recentNotes: [],             // Recent notes
  statistics: null,            // Note statistics
  pagination: {                // Pagination for notes list
    currentPage: 1,
    totalPages: 1,
    limit: 10,
    total: 0
  },
  filters: {                   // Active filters
    category: '',
    archived: false,
    search: '',
    sort: 'newest'
  },
  loading: false,              // Loading state
  error: null                  // Error state
};

const teacherNotesSlice = createSlice({
  name: "teacherNotes",
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload
      };
    },
    clearCurrentNote: (state) => {
      state.currentNote = null;
    },
    clearErrors: (state) => {
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // Create Note
      .addCase(createNote.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNote.fulfilled, (state, action) => {
        state.loading = false;
        // Add to all notes
        state.allNotes.unshift(action.payload);
        
        // Add to student's notes if we have that student's notes loaded
        const studentId = action.payload.studentId;
        if (state.studentNotes[studentId]) {
          state.studentNotes[studentId].notes.unshift(action.payload);
          state.studentNotes[studentId].count += 1;
        }
        
        // Add to booking's notes if we have that booking's notes loaded
        const bookingId = action.payload.bookingId;
        if (bookingId && state.bookingNotes[bookingId]) {
          state.bookingNotes[bookingId].notes.unshift(action.payload);
          state.bookingNotes[bookingId].count += 1;
        }
        
        // Update recent notes if applicable
        if (state.recentNotes.length > 0) {
          state.recentNotes.unshift(action.payload);
          if (state.recentNotes.length > 5) {
            state.recentNotes.pop();
          }
        }
        
        // Show success toast
        toast.success("Note created successfully", {
          position: "top-right",
          autoClose: 3000
        });
      })
      .addCase(createNote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Failed to create note";
        toast.error(state.error, {
          position: "top-right",
          autoClose: 5000
        });
      })
      
      // Get Student Notes
      .addCase(getStudentNotes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getStudentNotes.fulfilled, (state, action) => {
        state.loading = false;
        const { studentId, notes, count } = action.payload;
        state.studentNotes[studentId] = { notes, count };
      })
      .addCase(getStudentNotes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Failed to fetch student notes";
        toast.error(state.error, {
          position: "top-right",
          autoClose: 5000
        });
      })
      
      // Get All Teacher Notes
      .addCase(getTeacherNotes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTeacherNotes.fulfilled, (state, action) => {
        state.loading = false;
        state.allNotes = action.payload.notes;
        state.pagination = action.payload.pagination;
        
        // Update total notes count if available
        if (action.payload.total !== undefined) {
          state.pagination.total = action.payload.total;
        }
      })
      .addCase(getTeacherNotes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Failed to fetch teacher notes";
        toast.error(state.error, {
          position: "top-right",
          autoClose: 5000
        });
      })
      
      // Get Note By ID
      .addCase(getNoteById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNoteById.fulfilled, (state, action) => {
        state.loading = false;
        state.currentNote = action.payload;
      })
      .addCase(getNoteById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Failed to fetch note";
        toast.error(state.error, {
          position: "top-right",
          autoClose: 5000
        });
      })
      
      // Update Note
      .addCase(updateNote.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateNote.fulfilled, (state, action) => {
        state.loading = false;
        const updatedNote = action.payload;
        
        // Update in all notes
        const allNotesIndex = state.allNotes.findIndex(note => note._id === updatedNote._id);
        if (allNotesIndex !== -1) {
          state.allNotes[allNotesIndex] = updatedNote;
        }
        
        // Update in student notes
        const studentId = updatedNote.studentId._id || updatedNote.studentId;
        if (state.studentNotes[studentId]) {
          const studentNotesIndex = state.studentNotes[studentId].notes.findIndex(
            note => note._id === updatedNote._id
          );
          if (studentNotesIndex !== -1) {
            state.studentNotes[studentId].notes[studentNotesIndex] = updatedNote;
          }
        }
        
        // Update in booking notes
        const bookingId = updatedNote.bookingId?._id || updatedNote.bookingId;
        if (bookingId && state.bookingNotes[bookingId]) {
          const bookingNotesIndex = state.bookingNotes[bookingId].notes.findIndex(
            note => note._id === updatedNote._id
          );
          if (bookingNotesIndex !== -1) {
            state.bookingNotes[bookingId].notes[bookingNotesIndex] = updatedNote;
          }
        }
        
        // Update in recent notes
        const recentNotesIndex = state.recentNotes.findIndex(note => note._id === updatedNote._id);
        if (recentNotesIndex !== -1) {
          state.recentNotes[recentNotesIndex] = updatedNote;
        }
        
        // Update current note if it's the one being edited
        if (state.currentNote && state.currentNote._id === updatedNote._id) {
          state.currentNote = updatedNote;
        }
        
        toast.success("Note updated successfully", {
          position: "top-right",
          autoClose: 3000
        });
      })
      .addCase(updateNote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Failed to update note";
        toast.error(state.error, {
          position: "top-right",
          autoClose: 5000
        });
      })
      
      // Delete Note
      .addCase(deleteNote.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        state.loading = false;
        const deletedNoteId = action.payload;
        
        // Find the note to get its studentId and bookingId before removing it
        const deletedNote = state.allNotes.find(note => note._id === deletedNoteId);
        
        // Remove from all notes
        state.allNotes = state.allNotes.filter(note => note._id !== deletedNoteId);
        
        // Remove from student notes
        if (deletedNote) {
          const studentId = deletedNote.studentId._id || deletedNote.studentId;
          if (state.studentNotes[studentId]) {
            state.studentNotes[studentId].notes = state.studentNotes[studentId].notes.filter(
              note => note._id !== deletedNoteId
            );
            state.studentNotes[studentId].count -= 1;
          }
          
          // Remove from booking notes
          const bookingId = deletedNote.bookingId?._id || deletedNote.bookingId;
          if (bookingId && state.bookingNotes[bookingId]) {
            state.bookingNotes[bookingId].notes = state.bookingNotes[bookingId].notes.filter(
              note => note._id !== deletedNoteId
            );
            state.bookingNotes[bookingId].count -= 1;
          }
        }
        
        // Remove from recent notes
        state.recentNotes = state.recentNotes.filter(note => note._id !== deletedNoteId);
        
        // Clear current note if it was deleted
        if (state.currentNote && state.currentNote._id === deletedNoteId) {
          state.currentNote = null;
        }
        
        toast.success("Note deleted successfully", {
          position: "top-right",
          autoClose: 3000
        });
      })
      .addCase(deleteNote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Failed to delete note";
        toast.error(state.error, {
          position: "top-right",
          autoClose: 5000
        });
      })
      
      // Toggle Archive Status
      .addCase(toggleArchiveStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(toggleArchiveStatus.fulfilled, (state, action) => {
        state.loading = false;
        const updatedNote = action.payload;
        
        // Update in all notes
        const allNotesIndex = state.allNotes.findIndex(note => note._id === updatedNote._id);
        if (allNotesIndex !== -1) {
          state.allNotes[allNotesIndex] = updatedNote;
        }
        
        // Update in student notes
        const studentId = updatedNote.studentId._id || updatedNote.studentId;
        if (state.studentNotes[studentId]) {
          const studentNotesIndex = state.studentNotes[studentId].notes.findIndex(
            note => note._id === updatedNote._id
          );
          if (studentNotesIndex !== -1) {
            state.studentNotes[studentId].notes[studentNotesIndex] = updatedNote;
          }
        }
        
        // Update in booking notes
        const bookingId = updatedNote.bookingId?._id || updatedNote.bookingId;
        if (bookingId && state.bookingNotes[bookingId]) {
          const bookingNotesIndex = state.bookingNotes[bookingId].notes.findIndex(
            note => note._id === updatedNote._id
          );
          if (bookingNotesIndex !== -1) {
            state.bookingNotes[bookingId].notes[bookingNotesIndex] = updatedNote;
          }
        }
        
        // Update in recent notes
        const recentNotesIndex = state.recentNotes.findIndex(note => note._id === updatedNote._id);
        if (recentNotesIndex !== -1) {
          state.recentNotes[recentNotesIndex] = updatedNote;
        }
        
        // Update current note if it's the one being edited
        if (state.currentNote && state.currentNote._id === updatedNote._id) {
          state.currentNote = updatedNote;
        }
        
        toast.success(`Note ${updatedNote.isArchived ? 'archived' : 'unarchived'} successfully`, {
          position: "top-right",
          autoClose: 3000
        });
      })
      .addCase(toggleArchiveStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Failed to update archive status";
        toast.error(state.error, {
          position: "top-right",
          autoClose: 5000
        });
      })
      
      // Get Notes By Booking
      .addCase(getNotesByBooking.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNotesByBooking.fulfilled, (state, action) => {
        state.loading = false;
        const { bookingId, notes, count } = action.payload;
        state.bookingNotes[bookingId] = { notes, count };
      })
      .addCase(getNotesByBooking.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Failed to fetch booking notes";
        toast.error(state.error, {
          position: "top-right",
          autoClose: 5000
        });
      })
      
      // Get Recent Notes
      .addCase(getRecentNotes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRecentNotes.fulfilled, (state, action) => {
        state.loading = false;
        state.recentNotes = action.payload.notes;
      })
      .addCase(getRecentNotes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Failed to fetch recent notes";
        toast.error(state.error, {
          position: "top-right",
          autoClose: 5000
        });
      })
      
      // Get Note Statistics
      .addCase(getNoteStatistics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNoteStatistics.fulfilled, (state, action) => {
        state.loading = false;
        state.statistics = action.payload;
      })
      .addCase(getNoteStatistics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Failed to fetch note statistics";
        toast.error(state.error, {
          position: "top-right",
          autoClose: 5000
        });
      });
  }
});

export const { setFilters, clearCurrentNote, clearErrors } = teacherNotesSlice.actions;

export default teacherNotesSlice.reducer;
import React, { useEffect, useState } from "react";
import { Modal, Input, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Getcourses } from "../../../store/actions/coursesActions";
import {
  GetTeachers,
  imageUpload,
  updateTeacher,
  getVideo,
  videoUpload,
  updateTeacherDetails,
} from "../../../store/actions/teachersActions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate, useParams } from "react-router-dom";
import moment from 'moment-timezone';
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { 
  format, 
  addMonths, 
  startOfMonth, 
  endOfMonth, 
  startOfWeek, 
  endOfWeek, 
  isSameMonth, 
  isSameDay, 
  addDays,
} from 'date-fns';
import ReactPlayer from 'react-player';

dayjs.extend(utc);
dayjs.extend(timezone);

const AdminEditTeacher = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [t] = useTranslation("global");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const courses = useSelector((state) => state.courses.courseslist);
  const teachers = useSelector((state) => state.teachers.AllTeacherlist);
  const [videoUrl, setVideoUrl] = useState(null);
  const [currentTeacher, setCurrentTeacher] = useState(null);

  const [formData, setFormData] = useState({
    Username: "",
    Password: "",
    Phone_Number: "",
    Address: "",
    Description: "",
    Short_Title: "",
    Email: "",
    Profile_Image: [],
    Availability: {},
    SocialLinks: [
      { platform: "facebook", link: "" },
      { platform: "twitter", link: "" },
      { platform: "instagram", link: "" },
    ],
    Courses_assign: [],
    Meetings: [],
    Purchase_Price: "",
    UserType: "",
    selectedDays: {
      Sunday: false,
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
    },
    timeSlots: {
      Monday: { enabled: false, slots: [], isEditing: false },
      Tuesday: { enabled: false, slots: [], isEditing: false },
      Wednesday: { enabled: false, slots: [], isEditing: false },
      Thursday: { enabled: false, slots: [], isEditing: false },
      Friday: { enabled: false, slots: [], isEditing: false },
      Saturday: { enabled: false, slots: [], isEditing: false },
      Sunday: { enabled: false, slots: [], isEditing: false },
    },
    userTimezone: "",
  });

  const [videoFile, setVideoFile] = useState(null);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);
  const [editStartTime, setEditStartTime] = useState("");
  const [editEndTime, setEditEndTime] = useState("");
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editModalIndex, setEditModalIndex] = useState(null);
  const [editModalDate, setEditModalDate] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [editModalTimeIndex, setEditModalTimeIndex] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [hourFormat, setHourFormat] = useState('24');
  const [selectedCalendarDate, setSelectedCalendarDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDates, setSelectedDates] = useState({});

  const timezone = localStorage.getItem('AdminTimezone') || 'America/New_York';

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        await Promise.all([
          dispatch(Getcourses()),
          dispatch(GetTeachers())
        ]);
        
        const teachers = await dispatch(GetTeachers()).unwrap();
        const currentTeacher = teachers.find((teacher) => teacher._id === id);
        
        if (currentTeacher) {
          setCurrentTeacher(currentTeacher);
          const [first, ...last] = currentTeacher.Username.split(" ");
          setFirstName(first);
          setLastName(last.join(" "));
          
          // Transform the slots array into a date-based object with timezone conversion
          const availabilityByDate = {};
          if (currentTeacher.Availability?.slots) {
            currentTeacher.Availability.slots.forEach(slot => {
              // Convert UTC times to user's timezone
              const startMoment = dayjs.utc(slot.start).tz(timezone);
              const endMoment = dayjs.utc(slot.end).tz(timezone);
              
              const date = startMoment.format('YYYY-MM-DD');
              
              if (!availabilityByDate[date]) {
                availabilityByDate[date] = {
                  dayOfWeek: startMoment.format('dddd'),
                  slots: []
                };
              }
              
              availabilityByDate[date].slots.push({
                start: startMoment.format('HH:mm'),
                end: endMoment.format('HH:mm')
              });
            });
          }
  
          setFormData({ 
            ...currentTeacher,
            Username: `${first} ${last}`,
            Availability: availabilityByDate
          });
          
          // Set selected dates based on transformed availability
          const newSelectedDates = {};
          Object.keys(availabilityByDate).forEach(date => {
            newSelectedDates[date] = true;
          });
          setSelectedDates(newSelectedDates);
  
          if (currentTeacher.Video) {
            const videoAction = await dispatch(getVideo(currentTeacher.Video));
            if (getVideo.fulfilled.match(videoAction)) {
              setVideoUrl(videoAction.payload);
            }
          }
        }
      } catch (err) {
        setError("Failed to load data. Please try again.");
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [dispatch, id, timezone]);

  useEffect(() => {
    const storedHourFormat = localStorage.getItem('AdminHourFormat');
    if (storedHourFormat) {
      setHourFormat(storedHourFormat);
    }
  }, []);

  const handleEditTime = (date, timeIndex) => {
    const selectedTimeSlot = formData.Availability[date].slots[timeIndex];
    setEditStartTime(moment.tz(selectedTimeSlot.start, "HH:mm", timezone).format('HH:mm'));
    setEditEndTime(moment.tz(selectedTimeSlot.end, "HH:mm", timezone).format('HH:mm'));
    setIsEditModalVisible(true);
    setEditModalDate(date);
    setEditModalTimeIndex(timeIndex);
  };
  
  const handleDateToggle = (dateStr) => {
    setSelectedDate(dateStr);
    setSelectedCalendarDate(dateStr);
  };

  const handleEditModalOk = () => {
    setFormData(prevFormData => {
      const updatedAvailability = { ...prevFormData.Availability };
      updatedAvailability[editModalDate].slots[editModalTimeIndex] = {
        start: moment.tz(editStartTime, "HH:mm", timezone).utc().format('HH:mm'),
        end: moment.tz(editEndTime, "HH:mm", timezone).utc().format('HH:mm')
      };
      return { ...prevFormData, Availability: updatedAvailability };
    });
    setIsEditModalVisible(false);
  };

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (courseid) => {
    setFormData((prevData) => {
      const isSelected = prevData.Courses_assign.some(
        (course) => course._id === courseid
      );
      if (isSelected) {
        return {
          ...prevData,
          Courses_assign: prevData.Courses_assign.filter(
            (course) => course._id !== courseid
          ),
        };
      } else {
        return {
          ...prevData,
          Courses_assign: [...prevData.Courses_assign, { _id: courseid }],
        };
      }
    });
  };

  const handleFileUpload = async (event) => {
    const image = event.target.files[0];
    const uploadResult = await dispatch(imageUpload(image));
    setFormData(prevData => ({
      ...prevData,
      Profile_Image: prevData.Profile_Image?.length
        ? [...prevData.Profile_Image, uploadResult.payload]
        : [uploadResult.payload],
    }));
  };

  const handleVideoUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setVideoFile(file);
      setVideoPreviewUrl(URL.createObjectURL(file));
  
      try {
        const result = await dispatch(videoUpload(file));
        if (videoUpload.fulfilled.match(result)) {
          setFormData(prevData => ({
            ...prevData,
            Video: result.payload
          }));
          console.log("Video uploaded successfully:", result.payload);
        } else {
          throw new Error("Video upload failed");
        }
      } catch (error) {
        console.error("Error uploading video:", error);
      }
    }
  };

  const handleSocialLinkChange = (index, platform, value) => {
    setFormData((prevData) => {
      const updatedSocialLinks = [...prevData.SocialLinks];
      updatedSocialLinks[index] = { ...updatedSocialLinks[index], platform, link: value };
      return { ...prevData, SocialLinks: updatedSocialLinks };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const teacherId = id;
    const newUsername = `${firstName} ${lastName}`;
    
    const updatedData = {
      Username: newUsername,
      Phone_Number: formData.Phone_Number,
      Address: formData.Address,
      Description: formData.Description,
      Short_Title: formData.Short_Title,
      Purchase_Price: formData.Purchase_Price,
      Courses_assign: formData.Courses_assign,
      Email: formData.Email,
      Profile_Image: formData.Profile_Image,
      SocialLinks: formData.SocialLinks,
      selectedDays: formData.selectedDays,
      Video: formData.Video,
    };
    
    try {
      await dispatch(updateTeacherDetails({ teacherId, updatedData }));
      navigate("/Admin-Dashboard/Teachers");
    } catch (error) {
      console.error("Error editing teacher:", error);
    }
  };

  const handleImageRemoval = (val) => {
    setFormData(prevData => ({
      ...prevData,
      Profile_Image: prevData.Profile_Image.filter((img) => img !== val),
    }));
  };

  const handleAddAvailability = (id) => {
    const newUsername = `${firstName} ${lastName}`;
    const updatedData = { 
      ...formData, 
      Username: newUsername,
      Availability: formData.Availability || {},
      selectedDates: selectedDates,
      selectedDays: formData.selectedDays || {}
    };
    
    navigate(`/Admin-Dashboard/Teachers/edit-teacher/add-availability/${id}`, {
      state: { updatedData, selectedDate, timezone },
    });
  };

  const handleDeleteTime = (date, timeIndex) => {
    setFormData(prevFormData => {
      const updatedAvailability = { ...prevFormData.Availability };
      updatedAvailability[date].slots = updatedAvailability[date].slots.filter((_, idx) => idx !== timeIndex);
      if (updatedAvailability[date].slots.length === 0) {
        delete updatedAvailability[date];
      }
      return { ...prevFormData, Availability: updatedAvailability };
    });
  };

  const formatTime = (timeString) => {
    // Since the times are already in the correct timezone from the transformation above,
    // we just need to format them according to the hour format preference
    return hourFormat === '12' ? 
      moment(timeString, 'HH:mm').format('hh:mm A') : 
      timeString;
  };

  const MiniCalendar = ({ selectedDates, onDateToggle, currentMonth, setCurrentMonth, selectedCalendarDate }) => {
    const firstDayOfMonth = startOfMonth(currentMonth);
    const lastDayOfMonth = endOfMonth(currentMonth);
    const startDate = startOfWeek(firstDayOfMonth);
    const endDate = endOfWeek(lastDayOfMonth);

    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;

    const changeMonth = (amount) => {
      setCurrentMonth(addMonths(currentMonth, amount));
    };

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const cloneDay = day;
        const dateStr = format(cloneDay, 'yyyy-MM-dd');
        const isAvailable = selectedDates[dateStr];
        days.push(
          <div
            className={`calendar-day ${
              !isSameMonth(day, firstDayOfMonth) ? "disabled" :
              isSameDay(day, new Date()) ? "today" : ""
            } ${isAvailable ? "available" : ""} ${dateStr === selectedCalendarDate ? "selected" : ""}`}
            key={day}
            onClick={() => onDateToggle(dateStr)}
            style={{ 
              backgroundColor: isAvailable ? 'yellow' : '',
              cursor: 'pointer',
              ...(dateStr === selectedCalendarDate && { backgroundColor: 'green', color: 'white' })
            }}
          >
            <span>{format(day, dateFormat)}</span>
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="calendar-row" key={day}>
          {days}
        </div>
      );
      days = [];
    }

    return (
      <div className="mini-calendar-container">
        <div className="calendar-header">
          <button onClick={() => changeMonth(-1)}>&lt;</button>
          <h3>{format(currentMonth, 'MMMM yyyy')}</h3>
          <button onClick={() => changeMonth(1)}>&gt;</button>
        </div>
        <div className="calendar-days">
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
            <div className="calendar-day-header" key={day}>{day}</div>
          ))}
        </div>
        <div className="calendar-body">
          {rows}
        </div>
      </div>
    );
  };

  if (loading) {
    return <Spin size="large" />;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="Add_Teachers_main_div">
      <h5>{t("AdminEditTeacher.header")}</h5>
      <form onSubmit={handleSubmit}>
        {/* Profile Image Section */}
        <div className="image-upload-section mb-3">
          <h6>{t("AdminEditTeacher.ProfileImage")}</h6>
          <div className="Addteacherimage_box">
            {formData.Profile_Image?.map((md, index) => (
              <div key={index} className="image-container" style={{ position: 'relative', marginBottom: '10px' }}>
              <img
                src={"https://speakable.online/api/image/" + md}
                alt={`Profile ${index + 1}`}
                style={{ width: '100%', height: 'auto', maxHeight: '200px', objectFit: 'cover' }}
              />
              <button
                type="button"
                className="btn btn-danger btn-sm"
                style={{ position: 'absolute', top: '5px', right: '5px' }}
                onClick={() => handleImageRemoval(md)}
              >
                {t("AdminEditTeacher.Delete")}
              </button>
            </div>
          ))}
        </div>
        {formData.Profile_Image?.length < 10 && (
          <div className="mt-2" style={{ maxWidth: '300px' }}>
            <input
              type="file"
              className="form-control"
              onChange={handleFileUpload}
              accept="image/*"
              style={{ width: '100%' }}
            />
          </div>
        )}
      </div>

      {/* Video Upload Section */}
      <div className="video-section mb-3">
        <h6>{t("AdminEditTeacher.UploadVideo")}</h6>
        {(videoPreviewUrl || videoUrl) && (
          <div className="video-preview" style={{ width: '100%', height: '200px', marginBottom: '20px' }}>
            <ReactPlayer
              url={videoPreviewUrl || videoUrl}
              controls
              width="100%"
              height="100%"
            />
          </div>
        )}
        <div style={{ maxWidth: '300px' }}>
          <input
            type="file"
            className="form-control mb-2"
            onChange={handleVideoUpload}
            accept="video/*"
            style={{ width: '100%' }}
          />
        </div>
        {formData.Video && (
          <p>Current video: {formData.Video}</p>
        )}
      </div>

      {/* Personal Information */}
      <div className="row mb-3">
        <div className="col-md-4">
          <input
            type="text"
            className="form-control"
            placeholder={t("AdminEditTeacher.pl1")}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </div>
        <div className="col-md-4">
          <input
            type="text"
            className="form-control"
            placeholder={t("AdminEditTeacher.pl2")}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </div>
        <div className="col-md-4">
          <input
            type="email"
            className="form-control"
            placeholder={t("AdminEditTeacher.pl8")}
            name="Email"
            value={formData.Email}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      {/* Contact Information */}
      <div className="row mb-3">
        <div className="col-md-4">
          <PhoneInput
            country={"us"}
            value={formData.Phone_Number}
            onChange={(phone, country, e, formattedValue) => {
              setFormData((prevData) => ({
                ...prevData,
                Phone_Number: formattedValue,
              }));
            }}
            inputProps={{
              required: true,
              className: 'form-control'
            }}
          />
        </div>
        <div className="col-md-4">
          <input
            type="password"
            className="form-control"
            placeholder={t("AdminEditTeacher.pl4")}
            name="Password"
            value={formData.Password}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-4">
          <input
            type="text"
            className="form-control"
            placeholder={t("AdminEditTeacher.pl5")}
            name="Address"
            value={formData.Address}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      {/* Additional Information */}
      <div className="row mb-3">
        <div className="col-md-4">
          <input
            type="text"
            className="form-control"
            placeholder={t("AdminEditTeacher.pl3")}
            name="Description"
            value={formData.Description}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-4">
          <input
            type="text"
            className="form-control"
            placeholder={t("AdminEditTeacher.pl6")}
            name="Short_Title"
            value={formData.Short_Title}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-4">
          <input
            type="number"
            className="form-control"
            placeholder={t("AdminEditTeacher.pl7")}
            name="Purchase_Price"
            value={formData.Purchase_Price}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      {/* Social Links */}
      <div className="row mb-3">
        {formData.SocialLinks?.map((socialLink, index) => (
          <div key={index} className="col-md-4 mb-2">
            <input
              type="text"
              className="form-control"
              placeholder={socialLink.platform}
              value={socialLink.link}
              onChange={(e) => handleSocialLinkChange(index, socialLink.platform, e.target.value)}
            />
          </div>
        ))}
      </div>

      {/* Course Assignment */}
      <div className="form-group mb-3">
        <label>{t("AdminEditTeacher.SelectCourses")}</label>
        <div className="row">
          {courses?.map((course) => (
            <div key={course._id} className="col-md-4 mb-2">
              <div className="form-check">
                <input
                  type="checkbox"
                  id={course._id}
                  checked={formData.Courses_assign.some((c) => c._id === course._id)}
                  onChange={() => handleCheckboxChange(course._id)}
                  className="form-check-input"
                />
                <label htmlFor={course._id} className="form-check-label">
                  {course.Course_Name}
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Availability Section */}
<div className="availability-section mb-3">
  <h6>{t("AdminEditTeacher.Availability")}</h6>
  <div className="row">
    <div className="col-md-6">
      <MiniCalendar 
        selectedDates={selectedDates}
        onDateToggle={handleDateToggle}
        currentMonth={currentMonth}
        setCurrentMonth={setCurrentMonth}
        selectedCalendarDate={selectedCalendarDate}
      />
    </div>
    <div className="col-md-6">
      {selectedDate && formData.Availability && formData.Availability[selectedDate] && (
        <div className="availability-item p-2 mb-2">
          <h6>{selectedDate} ({formData.Availability[selectedDate].dayOfWeek})</h6>
          <div className="time-slots">
            {formData.Availability[selectedDate].slots.map((time, timeIndex) => (
              <div key={timeIndex} className="time-slot-item d-flex align-items-center mb-2">
                <span>{formatTime(time.start)} - {formatTime(time.end)}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  </div>
</div>

      {/* Add Availability Button */}
      <div className="mb-3">
        <button
          type="button"
          onClick={() => handleAddAvailability(id)}
          className="btn btn-outline-success"
        >
          {t("AdminEditTeacher.AddAvailability")}
        </button>
      </div>

      {/* Submit Button */}
      <button
        type="submit"
        className="btn btn-primary btn-block"
      >
        {t("AdminEditTeacher.Submit")}
      </button>
    </form>

    {/* Edit Time Slot Modal */}
    <Modal
      title={t("AdminEditTeacher.EditTimeSlot")}
      open={isEditModalVisible}
      onOk={handleEditModalOk}
      onCancel={handleEditModalCancel}
    >
      <div className="form-group">
        <label htmlFor="editStartTime">{t("AdminEditTeacher.StartTime")}:</label>
        <Input
          type="time"
          id="editStartTime"
          value={editStartTime}
          onChange={(e) => setEditStartTime(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="editEndTime">{t("AdminEditTeacher.EndTime")}:</label>
        <Input
          type="time"
          id="editEndTime"
          value={editEndTime}
          onChange={(e) => setEditEndTime(e.target.value)}
        />
      </div>
    </Modal>
  </div>
);
};

export default AdminEditTeacher;
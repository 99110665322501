import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ChatBotButton from './landingcomponents/ChatBotButton';
import Home from './landingcomponents/LandingNewPage';

const Layout = () => {
  console.log("Layout component rendering");
  const isAuthenticated = useSelector((state) => state.students.isAuthenticated);
  const userType = useSelector((state) => state.students.userType);
  const navigate = useNavigate();
  const location = useLocation();
  const [chatPopUp, setChatPopUp] = useState(false);
  
  // State for referral code handling
  const [referralCode, setReferralCode] = useState('');
  const [showSignup, setShowSignup] = useState(false);
  // Add a state to prevent redirect loops
  const [hasCheckedAuth, setHasCheckedAuth] = useState(false);

  // Check for referral code in URL
  useEffect(() => {
    console.log("Checking URL for referral code:", location.pathname, location.search);
    
    const searchParams = new URLSearchParams(location.search);
    const refCode = searchParams.get('ref');
    
    if (refCode) {
      console.log("Referral code found:", refCode);
      setReferralCode(refCode);
      setShowSignup(true);
    }
  }, [location]);

  // Handle authentication redirects
  useEffect(() => {
    console.log("Auth check: isAuthenticated =", isAuthenticated, "userType =", userType);
    
    // Only redirect if authenticated and we've confirmed auth status
    if (isAuthenticated > 0 && !hasCheckedAuth) {
      setHasCheckedAuth(true);
      
      console.log("User is authenticated, redirecting to dashboard");
      switch (userType) {
        case 'student':
          navigate('/Student-dashboard/dash');
          break;
        case 'teacher':
          navigate('/Teacher-dashboard/dash');
          break;
        case 'admin':
          navigate('/Admin-Dashboard/Dashboard');
          break;
        case 'accountant':
          navigate('/Accontant-Dashboard/dash');
          break;
        default:
          // If userType is not recognized, don't redirect
          console.log("Unknown user type, not redirecting");
          break;
      }
    } else if (isAuthenticated === 0 && !hasCheckedAuth) {
      // Mark that we've checked authentication to prevent loops
      setHasCheckedAuth(true);
      console.log("User is not authenticated, staying on home page");
    }
  }, [isAuthenticated, userType, navigate, hasCheckedAuth]);

  const onLetsChatClick = () => {
    setChatPopUp(!chatPopUp);
  };
  
  const handleCloseSignupPopup = () => {
    setShowSignup(false);
  };

  return (
    <>
      <div>
        <Home 
          referralCode={referralCode}
          showSignupPopup={showSignup}
          onCloseSignupPopup={handleCloseSignupPopup}
        />
        <ChatBotButton
          onLetsChatClick={onLetsChatClick}
          ChatPopUp={chatPopUp}
        />
        <ToastContainer
          style={{
            zIndex: 9999,
            position: "fixed",
            top: 0,
            right: 0,
          }}
        />
      </div>
    </>
  );
};

export default Layout;
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { GetTeachers } from "../../../store/actions/teachersActions";
import { Getcourses } from "../../../store/actions/coursesActions";
import { fetchPackage, updatePackage } from "../../../store/actions/packagesActions";
import { ToastContainer, toast } from "react-toastify";
import AdminNav from "../AdminNav";

const LectureConfigRow = ({ config, index, onUpdate, onRemove, selectedCourse }) => {
  const basePrice = selectedCourse ? selectedCourse.Purchase_Price * config.count : config.basePrice;
  const finalPrice = basePrice * (1 - (config.discount / 100));

  return (
    <div className="border rounded p-3 mb-3 bg-light">
      <div className="row">
        <div className="col-md-4">
          <label className="form-label">Number of Lectures</label>
          <input
            type="number"
            className="form-control"
            value={config.count}
            onChange={(e) => onUpdate(index, "count", parseInt(e.target.value) || 0)}
            min="1"
            required
          />
        </div>
        <div className="col-md-4">
          <label className="form-label">Discount (%)</label>
          <input
            type="number"
            className="form-control"
            value={config.discount}
            onChange={(e) => onUpdate(index, "discount", parseFloat(e.target.value) || 0)}
            min="0"
            max="100"
            step="0.1"
            required
          />
        </div>
        <div className="col-md-3">
          <label className="form-label">Price</label>
          <div className="form-control-plaintext">
            <div className="small">Base: ₹{basePrice?.toLocaleString()}</div>
            <div className="small text-success">Final: ₹{finalPrice?.toLocaleString()}</div>
          </div>
        </div>
        <div className="col-md-1 d-flex align-items-end">
          <button 
            type="button" 
            className="btn btn-outline-danger btn-sm"
            onClick={() => onRemove(index)}
            disabled={index === 0 && config.count === 1}
          >
            <i className="bi bi-trash"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

const AdminEditPackages = () => {
  const { PackageID } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const teachers = useSelector((state) => state.teachers.Teacherslist);
  const courses = useSelector((state) => state.courses.courseslist);
  const currentPackage = useSelector((state) => state.packages.currentPackage);

  const [formData, setFormData] = useState({
    Package_Name: "",
    Teacher_IDs: [],
    Course_IDs: [],
    Package_Amount: 0,
    lectures: [
      {
        count: 1,
        discount: 0,
        basePrice: 0,
        finalPrice: 0
      }
    ],
    Free_Package: false,
    Hide: false
  });

  useEffect(() => {
    dispatch(GetTeachers());
    dispatch(Getcourses());
    if (PackageID) {
      dispatch(fetchPackage(PackageID));
    }
  }, [dispatch, PackageID]);

  useEffect(() => {
    if (currentPackage) {
      const lectures = currentPackage.lectures.map(lecture => ({
        count: lecture.count,
        discount: lecture.discount,
        basePrice: lecture.basePrice,
        finalPrice: lecture.finalPrice
      }));

      setFormData({
        Package_Name: currentPackage.Package_Name || "",
        Teacher_IDs: Array.isArray(currentPackage.Teacher_IDs) 
          ? currentPackage.Teacher_IDs.map(teacher => teacher._id || teacher)
          : [],
        Course_IDs: Array.isArray(currentPackage.Course_IDs)
          ? currentPackage.Course_IDs.map(course => course._id || course)
          : [],
        Package_Amount: currentPackage.Package_Amount || 0,
        lectures: lectures,
        Free_Package: currentPackage.Free_Package || false,
        Hide: currentPackage.Hide || false
      });
    }
  }, [currentPackage]);

  const selectedCourse = courses?.find(course => 
    course._id === formData.Course_IDs[0]
  );

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleLectureUpdate = (index, field, value) => {
    setFormData(prev => {
      const newLectures = prev.lectures.map((lecture, i) => {
        if (i === index) {
          const updatedLecture = { ...lecture, [field]: value };
          if (selectedCourse) {
            const basePrice = selectedCourse.Purchase_Price * updatedLecture.count;
            const finalPrice = basePrice * (1 - (updatedLecture.discount / 100));
            return {
              ...updatedLecture,
              basePrice,
              finalPrice
            };
          }
          return updatedLecture;
        }
        return lecture;
      });

      const totalAmount = newLectures.reduce((sum, lecture) => sum + lecture.finalPrice, 0);

      return {
        ...prev,
        lectures: newLectures,
        Package_Amount: totalAmount
      };
    });
  };

  const addLectureConfig = () => {
    setFormData(prev => {
      const newLecture = {
        count: 1,
        discount: 0,
        basePrice: selectedCourse ? selectedCourse.Purchase_Price : 0,
        finalPrice: selectedCourse ? selectedCourse.Purchase_Price : 0
      };
      
      const newLectures = [...prev.lectures, newLecture];
      const totalAmount = newLectures.reduce((sum, lecture) => sum + lecture.finalPrice, 0);

      return {
        ...prev,
        lectures: newLectures,
        Package_Amount: totalAmount
      };
    });
  };

  const removeLectureConfig = (index) => {
    if (formData.lectures.length > 1) {
      setFormData(prev => {
        const newLectures = prev.lectures.filter((_, i) => i !== index);
        const totalAmount = newLectures.reduce((sum, lecture) => sum + lecture.finalPrice, 0);
        
        return {
          ...prev,
          lectures: newLectures,
          Package_Amount: totalAmount
        };
      });
    }
  };

  const handleTeacherChange = (teacherId) => {
    setFormData(prev => ({
      ...prev,
      Teacher_IDs: prev.Teacher_IDs.includes(teacherId)
        ? prev.Teacher_IDs.filter(id => id !== teacherId)
        : [...prev.Teacher_IDs, teacherId]
    }));
  };

  const handleCourseChange = (courseId) => {
    const course = courses.find(c => c._id === courseId);
    setFormData(prev => {
      const updatedLectures = prev.lectures.map(lecture => {
        const basePrice = course ? course.Purchase_Price * lecture.count : 0;
        const finalPrice = basePrice * (1 - (lecture.discount / 100));
        return {
          ...lecture,
          basePrice,
          finalPrice
        };
      });

      const totalAmount = updatedLectures.reduce((sum, lecture) => sum + lecture.finalPrice, 0);

      return {
        ...prev,
        Course_IDs: courseId ? [courseId] : [],
        lectures: updatedLectures,
        Package_Amount: totalAmount
      };
    });
  };

  const validateForm = () => {
    if (!formData.Package_Name.trim()) {
      toast.error("Please enter a package name");
      return false;
    }
    if (!formData.Teacher_IDs.length) {
      toast.error("Please select at least one teacher");
      return false;
    }
    if (!formData.Course_IDs.length) {
      toast.error("Please select a course");
      return false;
    }
    if (!formData.lectures.length) {
      toast.error("Please add at least one lecture configuration");
      return false;
    }

    const invalidLecture = formData.lectures.find(
      lecture => !lecture.count || lecture.count < 1 || lecture.discount < 0 || lecture.discount > 100
    );
    if (invalidLecture) {
      toast.error("Please check lecture configurations. Counts must be positive and discounts between 0-100%");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      await dispatch(updatePackage({ PackageID, formData }));
      toast.success("Package updated successfully");
      navigate("/Admin-Dashboard/Packages");
    } catch (error) {
      console.error("Error updating package:", error);
      toast.error(error.message || "Error updating package");
    }
  };

  return (
    <>
      <AdminNav />
      <div className="container py-4">
        <div className="card">
          <div className="card-header">
            <h5 className="mb-0">Edit Package</h5>
          </div>
          <div className="card-body">
            <ToastContainer position="top-right" autoClose={5000} />
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="Package_Name" className="form-label">Package Name *</label>
                <input
                  type="text"
                  className="form-control"
                  id="Package_Name"
                  name="Package_Name"
                  value={formData.Package_Name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Select Teachers *</label>
                <div className="row g-3">
                  {teachers?.map((teacher) => (
                    <div key={teacher._id} className="col-md-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id={`teacher-${teacher._id}`}
                          checked={formData.Teacher_IDs.includes(teacher._id)}
                          onChange={() => handleTeacherChange(teacher._id)}
                          className="form-check-input"
                        />
                        <label htmlFor={`teacher-${teacher._id}`} className="form-check-label">
                          {teacher.Username}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="mb-3">
                <label className="form-label">Select Course *</label>
                <select 
                  className="form-select"
                  value={formData.Course_IDs[0] || ""}
                  onChange={(e) => handleCourseChange(e.target.value)}
                  required
                >
                  <option value="">Choose a course...</option>
                  {courses?.map((course) => (
                    <option key={course._id} value={course._id}>
                      {course.Course_Name} - ₹{course.Purchase_Price?.toLocaleString()}/lecture
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-3">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <label className="form-label mb-0">Lecture Configurations *</label>
                  <button 
                    type="button" 
                    className="btn btn-outline-primary btn-sm"
                    onClick={addLectureConfig}
                  >
                    <i className="bi bi-plus-lg me-1"></i>
                    Add Configuration
                  </button>
                </div>
                
                {formData.lectures.map((config, index) => (
                  <LectureConfigRow
                    key={index}
                    config={config}
                    index={index}
                    onUpdate={handleLectureUpdate}
                    onRemove={removeLectureConfig}
                    selectedCourse={selectedCourse}
                  />
                ))}
              </div>

              <div className="mb-3">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Free_Package"
                    name="Free_Package"
                    checked={formData.Free_Package}
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="Free_Package">
                    Make This Package a Free Trial
                  </label>
                </div>
              </div>

              <div className="mb-3">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Hide"
                    name="Hide"
                    checked={formData.Hide}
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="Hide">
                    Hide This Package
                  </label>
                </div>
              </div>

              <div className="mt-4">
                <button type="submit" className="btn btn-primary me-2">
                  Update Package
                </button>
                <button 
                  type="button" 
                  className="btn btn-outline-secondary"
                  onClick={() => navigate("/Admin-Dashboard/Packages")}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminEditPackages;
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetch5Teachers } from '../../store/actions/teachersActions';
import { Getcourses } from '../../store/actions/coursesActions';
import { fetchPackagesWithCourses } from '../../store/actions/packagesActions';
import Speakableimg from "../../Speakableimg.jpg";
import SignupFormPopup from "../SignupFormPopup";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SigninFormPopup from "../SigninFormPopup";
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';
import { X } from 'lucide-react';
import { toast } from "react-toastify";
import { Create_Enquiry } from '../../store/actions/enquiryActions';
import { GetCourseByID } from '../../store/actions/coursesActions'; // Adjust the import path as needed
  
  // Language selector component
  
  const Home = ({ referralCode = '', showSignupPopup = false, onCloseSignupPopup = () => {} }) => {
    const [showBooking, setShowBooking] = useState(false);
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const [showTeacherModal, setShowTeacherModal] = useState(false);
    const [showSelectTeacherMessage, setShowSelectTeacherMessage] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [language, setLanguage] = useState(i18next.language);
    const [signupFormPopup, setSignupFormPopup] = useState(showSignupPopup);
    const [signinFormPopup, setSigninFormPopup] = useState(false);
    const { t } = useTranslation("global");
  
    useEffect(() => {
      console.log("showSignupPopup changed:", showSignupPopup);
      setSignupFormPopup(showSignupPopup);
    }, [showSignupPopup]);
  
    const handleCloseSignupPopup = () => {
      setSignupFormPopup(false);
      if (onCloseSignupPopup) {
        onCloseSignupPopup();
      }
    };

    // Function to detect user's country and set language
    useEffect(() => {
        const detectCountryAndSetLanguage = async () => {
            try {
                // Use a free IP geolocation API
                const response = await fetch('https://ipapi.co/json/');
                const data = await response.json();
                
                // If user is from Poland, set language to Polish, otherwise English
                const userLanguage = data.country_code === 'PL' ? 'pl' : 'en';
                i18next.changeLanguage(userLanguage);
                setLanguage(userLanguage);
            } catch (error) {
                console.error('Error detecting country:', error);
                // Fallback to English in case of error
                i18next.changeLanguage('en');
                setLanguage('en');
            }
        };

        detectCountryAndSetLanguage();
    }, []);

    const changeLanguage = (lng) => {
        i18next.changeLanguage(lng);
        setLanguage(lng);
    };

    // Language selector component defined within Home
    const LanguageSelector = () => (
        <div className="fixed bottom-20 right-4 z-50">
            <select 
                value={language} 
                onChange={(e) => changeLanguage(e.target.value)}
                className="bg-white border border-gray-300 rounded-lg px-4 py-2 shadow-lg hover:border-blue-500 transition-all duration-300"
            >
                <option value="en">English</option>
                <option value="pl">Polski</option>
            </select>
        </div>
    );

    const handleFreeLessonClick = () => {
        setShowSelectTeacherMessage(true);
        const teachersSection = document.getElementById('teachers');
        if (teachersSection) {
            teachersSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleChooseTeacher = () => {
        setShowTeacherModal(false);
        setShowBooking(true);
    };

    // Navigation Component
    const Navigation = ({ menuOpen, setMenuOpen, handleFreeLessonClick }) => {
        const { t } = useTranslation("global");
        const [signupFormPopup, setSignupFormPopup] = useState(false);
        const [signinFormPopup, setSigninFormPopup] = useState(false);
      
        const handleMenuClick = () => setMenuOpen(false);
        
    const closeSignupFormPopup = () => setSignupFormPopup(false);
    
    const closeSigninFormPopup = () => setSigninFormPopup(false);
    const openSignupFormPopup = () => setSignupFormPopup(true);
  const openSigninFormPopup = () => setSigninFormPopup(true);
      
        return (
          <nav className="bg-white shadow-md sticky top-0 z-50 animate-fade-in">
            <div className="container mx-auto px-4 py-2">
              <div className="flex items-center justify-between">
                {/* Logo */}
                <div className="flex items-center">
                  <img 
                    src={Speakableimg} 
                    alt={t("navigation.brand")} 
                    className="h-8 w-8 mr-2 transition-transform hover:scale-105"
                  />
                  <a href="#" className="text-blue-700 font-bold text-xl">
                    {t("navigation.brand")}
                  </a>
                </div>
      
                {/* Desktop Navigation */}
                <div className="hidden lg:flex space-x-4 animate-fade-in-down">
                  {['home', 'individual', 'business', 'courses', 'methodology', 
                    'pricing', 'teachers', 'faq', 'contact'].map(key => (
                    <a 
                      key={key}
                      href={`#${key}`}
                      onClick={handleMenuClick}
                      className="text-gray-700 hover:text-blue-700 transition duration-300 hover:scale-105"
                    >
                      {t(`navigation.${key}`)}
                    </a>
                  ))}
                </div>
      
                {/* Auth Buttons */}
                <div className="flex items-center space-x-2">
                  <button 
                    onClick={openSigninFormPopup}
                    className="bg-blue-700 text-white py-1 px-3 text-sm rounded hover:bg-blue-800 transition-all duration-300 hover:scale-105 animate-fade-in"
                  >
                    {t("navigation.signIn")}
                  </button>
                  <button 
                    onClick={openSignupFormPopup}
                    className="border border-blue-700 text-blue-700 py-1 px-3 text-sm rounded hover:bg-blue-50 transition-all duration-300 hover:scale-105 animate-fade-in"
                  >
                    {t("navigation.signUp")}
                  </button>
      
                  {/* Mobile Menu Button */}
                  <div className="lg:hidden ml-2">
                    <button
                      onClick={() => setMenuOpen(!menuOpen)}
                      className="text-gray-700 hover:text-blue-700 focus:outline-none"
                    >
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        {menuOpen ? (
                          <path d="M6 18L18 6M6 6l12 12" />
                        ) : (
                          <path d="M4 6h16M4 12h16M4 18h16" />
                        )}
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
      
              {/* Mobile Menu */}
              {menuOpen && (
                <div className="lg:hidden mt-4 animate-fade-in-down">
                  <div className="flex flex-col space-y-2">
                    {['home', 'individual', 'business', 'courses', 'methodology', 
                      'pricing', 'teachers', 'faq', 'contact'].map(key => (
                      <a
                        key={key}
                        href={`#${key}`}
                        onClick={handleMenuClick}
                        className="text-gray-700 hover:text-blue-700 transition duration-300 py-2 px-4 rounded hover:bg-gray-100"
                      >
                        {t(`navigation.${key}`)}
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </div>
      
            {/* Popups */}
            {signupFormPopup && (
        <SignupFormPopup 
          handleClose={handleCloseSignupPopup}
          referralCode={referralCode}
        />
      )}
      
      {signinFormPopup && (
        <SigninFormPopup 
          handleClose={() => setSigninFormPopup(false)}
        />
      )}
          </nav>
        );
      };

    // Home Section Component
    const HomeSection = ({ handleFreeLessonClick }) => {
        const { t } = useTranslation("global");
        
        return (
          <section id="home" className="bg-gradient-to-r from-purple-500 to-blue-700 text-white text-center py-20 px-4 animate-fade-in">
            <div className="container mx-auto">
              <h1 className="text-3xl md:text-6xl font-bold animate-fade-in-up">
                {t("navigation.heroTitle")}
              </h1>
              <p className="mt-4 text-lg md:text-xl animate-fade-in-up" style={{ animationDelay: '200ms' }}>
                {t("navigation.heroSubtitle")}
              </p>
              <button 
                onClick={handleFreeLessonClick} 
                className="mt-6 inline-block bg-orange-500 text-white font-semibold py-2 px-6 rounded-full shadow-lg hover:bg-orange-600 transition-all duration-300 hover:scale-110"
              >
                {t("navigation.freeTrial")}
              </button>
            </div>
            <LanguageSelector />

          </section>
        );
      };

    // Promotion Section Component
    const PromotionSection = () => (
        <section className="bg-white text-gray-800 py-10 px-4">
            <div className="container mx-auto text-center">
                <div className="flex flex-col md:flex-row justify-center items-center">
                    <span className="text-4xl md:text-5xl mr-0 md:mr-4">🎁💸</span>
                    <h2 className="text-2xl md:text-3xl font-bold">
                    {t("navigation.promotionTitle")}
                    </h2>
                </div>
            </div>
        </section>
    );

    // Platform Section Component
    const PlatformSection = () => (
        <section id="platform" className="bg-gray-100 text-gray-800 py-20 px-4 animate-fade-in">
            <div className="container mx-auto">
                <h2 className="text-3xl md:text-4xl font-bold text-blue-700 text-center animate-fade-in-up">
                {t("navigation.platformTitle")}
                </h2>
                <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div className="p-6 text-center animate-fade-in-up hover:scale-105 transition-all duration-300" style={{ animationDelay: '200ms' }}>
                        <span className="text-6xl animate-float">💻</span>
                        <h3 className="text-2xl font-bold mt-4">{t("navigation.reviewTitle")}</h3>
                        <p className="mt-2">{t("navigation.reviewDescription")}</p>
                    </div>
                    <div className="p-6 text-center animate-fade-in-up hover:scale-105 transition-all duration-300" style={{ animationDelay: '400ms' }}>
                        <span className="text-6xl animate-float">🎮</span>
                        <h3 className="text-2xl font-bold mt-4">{t("navigation.gamifiedTitle")}</h3>
                        <p className="mt-2">{t("navigation.gamifiedDescription")}</p>
                    </div>
                    <div className="p-6 text-center animate-fade-in-up hover:scale-105 transition-all duration-300" style={{ animationDelay: '600ms' }}>
                        <span className="text-6xl animate-float">📱</span>
                        <h3 className="text-2xl font-bold mt-4">{t("navigation.accessTitle")}</h3>
                        <p className="mt-2">{t("navigation.accessDescription")}</p>
                    </div>
                </div>
            </div>
        </section>
    );

    // Inspiration Section Component
    const InspirationSection = () => (
      <section className="bg-white text-gray-800 py-20 px-4">
            <div className="container mx-auto">
                {/* Stats Section */}
                <div className="text-center">
                    <h2 className="text-3xl md:text-4xl font-bold text-blue-700 animate-fade-in-up">
                        {t("navigation.inspirationTitle")}
                    </h2>
                </div>

                {/* Benefits Section */}
                <div className="mt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    <div className="p-6 text-center animate-fade-in-up" style={{ animationDelay: '200ms' }}>
                        <div className="text-4xl mb-4">📅</div>
                        <p className="text-lg">{t("navigation.individualLessons")}</p>
                    </div>
                    <div className="p-6 text-center animate-fade-in-up" style={{ animationDelay: '400ms' }}>
                        <div className="text-4xl mb-4">📚</div>
                        <p className="text-lg">{t("navigation.modernCourses")}</p>
                    </div>
                    <div className="p-6 text-center animate-fade-in-up" style={{ animationDelay: '600ms' }}>
                        <div className="text-4xl mb-4">🧑‍🏫</div>
                        <p className="text-lg">{t("navigation.professionalTeachers")}</p>
                    </div>
                    <div className="p-6 text-center animate-fade-in-up" style={{ animationDelay: '800ms' }}>
                        <div className="text-4xl mb-4">🌍</div>
                        <p className="text-lg">{t("navigation.learnAnywhere")}</p>
                    </div>
                </div>
            </div>
        </section>
  );

    // Methodology Section Component
    const MethodologySection = () => (
        <section id="methodology" className="bg-gray-100 text-gray-800 py-20 px-4 animate-fade-in">
            <div className="container mx-auto">
                <h2 className="text-3xl md:text-4xl font-bold text-blue-700 text-center animate-fade-in-up">
                {t("navigation.methodologyTitle")}
                </h2>
                <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className="p-6 flex animate-fade-in-left hover:scale-105 transition-all duration-300" style={{ animationDelay: '200ms' }}>
                        <span className="text-4xl mr-4">🤓</span>
                        <div>
                            <h3 className="text-2xl font-bold">{t("navigation.methodTitle")}</h3>
                            <p className="mt-2">
                            {t("navigation.methodDescription")}
                            </p>
                        </div>
                    </div>
                    <div className="p-6 flex animate-fade-in-right hover:scale-105 transition-all duration-300" style={{ animationDelay: '400ms' }}>
                        <span className="text-4xl mr-4">💁‍♀️</span>
                        <div>
                            <h3 className="text-2xl font-bold">{t("navigation.approachTitle")}</h3>
                            <p className="mt-2">{t("navigation.approachDescription")}</p>
                        </div>
                    </div>
                    <div className="p-6 flex animate-fade-in-left hover:scale-105 transition-all duration-300" style={{ animationDelay: '600ms' }}>
                        <span className="text-4xl mr-4">💻</span>
                        <div>
                            <h3 className="text-2xl font-bold">{t("navigation.appTitle")}</h3>
                            <p className="mt-2">
                            {t("navigation.appDescription")}
                            </p>
                        </div>
                    </div>
                    <div className="p-6 flex animate-fade-in-right hover:scale-105 transition-all duration-300" style={{ animationDelay: '800ms' }}>
                        <span className="text-4xl mr-4">🌎</span>
                        <div>
                            <h3 className="text-2xl font-bold">{t("navigation.globalTeachersTitle")}</h3>
                            <p className="mt-2">
                            {t("navigation.globalTeachersDescription")}.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

    // Pricing Section Component
   

  
    const CourseCard = ({ course, onCardClick, packageInfo }) => {
      const { t } = useTranslation("global");
      const [showFullDescription, setShowFullDescription] = useState(false);
    
      const truncateDescription = (text, sentences) => {
        if (!text) return '';
        const sentenceRegex = /[^\.!\?]+[\.!\?]+/g;
        const allSentences = text.match(sentenceRegex);
        if (allSentences && allSentences.length > sentences) {
          return allSentences.slice(0, sentences).join(' ');
        }
        return text;
      };
    
      const truncatedDescription = truncateDescription(course?.description, 3);
    
      // Safely finding the lecture with the lowest price to display
      const lowestPriceLecture = React.useMemo(() => {
        if (!packageInfo?.lectures || !packageInfo.lectures.length) {
          return { count: 0, finalPrice: 0 };
        }
        
        return packageInfo.lectures.reduce(
          (lowest, current) => current.finalPrice < lowest.finalPrice ? current : lowest, 
          packageInfo.lectures[0]
        );
      }, [packageInfo]);
    
      // Calculate price per lesson
      const pricePerLesson = lowestPriceLecture?.finalPrice && lowestPriceLecture.count ? 
  (lowestPriceLecture.finalPrice / lowestPriceLecture.count).toFixed(2) : 
  (course?.pricePerHour || 0);
    
      // Calculate discount percentage
      const hasDiscount = lowestPriceLecture?.discount > 0;
      
      return (
        <div className="course-card" onClick={() => onCardClick(course, packageInfo)}>
          <div className="course-card-inner">
            <div className="course-card-front">
              {hasDiscount && (
                <div className="discount-badge">
                  {lowestPriceLecture.discount}% {t("common.off")}
                </div>
              )}
              <div className="image-container">
                <img 
                  src={course?.images ? `https://speakable.online/api/image/${course.images[0]}` : "/api/placeholder/400/250"} 
                  alt={course?.name}
                  className="course-image"
                  loading="lazy"
                />
                <div className="package-label">{packageInfo?.Package_Name || 'Package'}</div>
              </div>
              <div className="card-content-front">
                <h3 className="course-title">{course?.name}</h3>
                <div className="card-meta">
                  <div className="lessons-info">
                    <span className="lessons-count">{lowestPriceLecture.count} {t("teacherEarnings.lessons")}</span>
                  </div>
                  <div className="teacher-count">{packageInfo?.teachers?.length || 0} {t("common.teachers")}</div>
                </div>
                <div className="price-section">
                  <p className="course-price">
                    PLN {pricePerLesson} <span className="per-lesson">/ {t("teacherEarnings.perLesson")}</span>
                  </p>
                  <div className="total-price">
                    {t("common.total")}: PLN {packageInfo?.Package_Amount || 0}
                  </div>
                </div>
                <div className="card-action">
                  <button className="view-details-btn">{t("common.viewDetails")}</button>
                </div>
              </div>
            </div>
            <div className="course-card-back">
              <div className="card-content-back">
                <h3 className="back-title">{course?.name}</h3>
                <div className="description-container">
                  <p className="course-description">
                    {truncatedDescription}
                    {course?.description && course.description.length > truncatedDescription.length && 
                      <span className="read-more">...</span>
                    }
                  </p>
                </div>
                {packageInfo?.teachers && packageInfo.teachers.length > 0 && (
                  <div className="teachers-preview">
                    <span className="teachers-label">{t("common.availableTeachers")}:</span>
                    <div className="teachers-list">
                      {packageInfo.teachers.slice(0, 2).map((teacher, idx) => (
                        <span key={idx} className="teacher-badge">
                          {teacher.Username || teacher.name}
                        </span>
                      ))}
                      {packageInfo.teachers.length > 2 && (
                        <span className="teacher-badge more">+{packageInfo.teachers.length - 2}</span>
                      )}
                    </div>
                  </div>
                )}
                <button 
                  className="enroll-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.location.href = '#contact';
                  }}
                >
                  {t("navigation.enrollNow")}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    };
    
    const PricingSection = () => {
      const dispatch = useDispatch();
      
      // FIXED: Use state.packages instead of state.package to match your store configuration
      const { packagesWithCourses = [], loading = false, error = null } = useSelector((state) => {
        console.log("Full Redux state:", state);
        return state.packages || {}; // Access packages, not package
      });
      
      // Debug log to verify data
      console.log("Packages with courses from Redux:", packagesWithCourses);
      
      const [selectedCourse, setSelectedCourse] = useState(null);
      const [selectedPackage, setSelectedPackage] = useState(null);
      const [isDialogOpen, setIsDialogOpen] = useState(false);
      const [isLoading, setIsLoading] = useState(true);
      const [fetchError, setFetchError] = useState(null);
      const { t } = useTranslation("global");
    
      useEffect(() => {
        const fetchData = async () => {
          setIsLoading(true);
          setFetchError(null);
          
          try {
            // Use our new action to fetch packages with courses in a single API call
            console.log("Dispatching fetchPackagesWithCourses action");
            await dispatch(fetchPackagesWithCourses());
            
            setIsLoading(false);
          } catch (err) {
            console.error("Error fetching packages with courses:", err);
            setFetchError(err.message || "Failed to load data");
            setIsLoading(false);
          }
        };
        
        fetchData();
      }, [dispatch]);
    
      const handleCardClick = (course, packageInfo) => {
        setSelectedCourse(course);
        setSelectedPackage(packageInfo);
        setIsDialogOpen(true);
      };
    
      // Check both component state and Redux state for loading
      if (isLoading || loading) {
        return (
          <div className="py-20 px-4 text-center">
            <p className="text-lg font-semibold text-blue-700">Loading packages...</p>
          </div>
        );
      }
      
      if (fetchError || error) {
        return (
          <div className="py-20 px-4 text-center">
            <p className="text-lg font-semibold text-red-600">Error: {fetchError || error}</p>
            <button 
              className="mt-4 bg-blue-700 text-white px-4 py-2 rounded-lg hover:bg-blue-800"
              onClick={() => window.location.reload()}
            >
              Retry
            </button>
          </div>
        );
      }
    
      return (
        <div className="relative">
          <section id="pricing" className="bg-white text-gray-800 py-20 px-4 animate-fade-in">
            <div className="container mx-auto">
              <h2 className="text-3xl md:text-4xl font-bold text-blue-700 text-center animate-fade-in-up">
                {t("navigation.pricingTitle")}
              </h2>
              <p className="mt-4 text-lg md:text-xl text-center animate-fade-in-up" 
                 style={{ animationDelay: '200ms' }}>
                {t("navigation.pricingSubtitle")}!
              </p>
              
              {/* Debug information */}
              <div className="text-xs text-gray-500 text-left mt-2 mb-4">
               
                
              </div>
              
              {(!packagesWithCourses || packagesWithCourses.length === 0) && !isLoading && (
                <div className="text-center mt-10 p-6 border border-gray-200 rounded-lg">
                  <p className="text-lg text-gray-600">No packages available at the moment.</p>
                  <p className="mt-2 text-sm text-gray-500">
                    Please check back later or contact us for more information.
                  </p>
                </div>
              )}
              
              <div className="courses-grid mt-10">
                {Array.isArray(packagesWithCourses) && packagesWithCourses.map((pkg, index) => (
                  <CourseCard 
                    key={pkg._id || index}
                    course={pkg.course}
                    packageInfo={pkg}
                    onCardClick={handleCardClick}
                  />
                ))}
              </div>
            </div>
          </section>
    
          {isDialogOpen && selectedCourse && (
            <div className="fixed inset-0 z-30">
              <div className="absolute inset-0 bg-black bg-opacity-50" onClick={() => setIsDialogOpen(false)}></div>
              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-screen items-center justify-center p-4">
                  <div className="relative bg-white rounded-lg shadow-xl max-w-2xl w-full">
                    <div className="p-6">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-2xl font-bold text-blue-700">{selectedCourse.name}</h2>
                        <button 
                          onClick={() => setIsDialogOpen(false)} 
                          className="text-gray-500 hover:text-gray-700 p-1"
                        >
                          <X size={24} />
                        </button>
                      </div>
                      
                      {selectedCourse.images && selectedCourse.images.length > 0 && (
                        <div className="mb-6">
                          <img 
                            src={`https://speakable.online/api/image/${selectedCourse.images[0]}`}
                            alt={selectedCourse.name}
                            className="w-full h-64 object-cover rounded-lg"
                          />
                        </div>
                      )}
                      
                      <div className="space-y-4">
                        <div>
                          <h3 className="text-lg font-semibold mb-2">Description</h3>
                          <p className="text-gray-600">{selectedCourse.description}</p>
                        </div>
                        
                        {selectedPackage && (
                          <div>
                            <h3 className="text-lg font-semibold mb-2">Package Details</h3>
                            <p className="text-gray-600">Package: {selectedPackage.Package_Name}</p>
                            <p className="text-gray-600">Price: PLN {selectedPackage.Package_Amount}</p>
                            
                            {selectedPackage.lectures && selectedPackage.lectures.length > 0 && (
                              <div className="mt-2">
                                <h4 className="text-md font-semibold">Available Options:</h4>
                                <ul className="list-disc pl-5 mt-1">
                                  {selectedPackage.lectures.map((lecture, idx) => (
                                    <li key={idx} className="text-gray-600">
                                      {lecture.count} lessons - PLN {lecture.finalPrice.toFixed(2)} 
                                      {lecture.discount > 0 && ` (${lecture.discount}% discount)`}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                            
                            {selectedPackage.teachers && selectedPackage.teachers.length > 0 && (
                              <div className="mt-4">
                                <h4 className="text-md font-semibold">Available Teachers:</h4>
                                <div className="flex flex-wrap gap-2 mt-2">
                                  {selectedPackage.teachers.map((teacher, idx) => (
                                    <span key={idx} className="inline-block bg-blue-100 text-blue-800 px-2 py-1 rounded text-sm">
                                      {teacher.Username || teacher.name}
                                    </span>
                                  ))}
                                </div>
                              </div>
                            )}
                            
                            {selectedPackage.summary && (
                              <div className="mt-4 bg-gray-50 p-3 rounded">
                                <h4 className="text-md font-semibold">Summary:</h4>
                                <p className="text-gray-600 text-sm">Total Hours: {selectedPackage.summary.totalHours}</p>
                                <p className="text-gray-600 text-sm">Average Discount: {selectedPackage.summary.averageDiscount}%</p>
                                <p className="text-gray-600 text-sm">Effective Hourly Rate: PLN {selectedPackage.summary.effectiveHourlyRate.toFixed(2)}</p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      
                      <div className="mt-6">
                        <a 
                          href="#contact"
                          className="block w-full text-center bg-blue-700 text-white font-semibold py-3 px-6 rounded-full hover:bg-blue-800 transition-all duration-300"
                          onClick={() => setIsDialogOpen(false)}
                        >
                          {t("navigation.enrollNow")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
    
          <style jsx>{`
            .courses-grid {
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
              gap: 30px;
              justify-items: center;
            }
    
            .course-card {
              width: 300px;
              height: 400px;
              perspective: 1000px;
            }
    
            .course-card-inner {
              position: relative;
              width: 100%;
              height: 100%;
              text-align: center;
              transition: transform 0.6s;
              transform-style: preserve-3d;
            }
    
            .course-card:hover .course-card-inner {
              transform: rotateY(180deg);
            }
    
            .course-card-front, .course-card-back {
              position: absolute;
              width: 100%;
              height: 100%;
              backface-visibility: hidden;
              border-radius: 20px;
              overflow: hidden;
              box-shadow: 0 10px 30px rgba(79, 70, 229, 0.15);
            }
    
            .course-card-front {
              background: linear-gradient(to bottom right, #ffffff, #f5f3ff);
              border: 1px solid #e9e7fd;
            }
    
            .course-card-back {
              background: linear-gradient(to bottom right, #f5f3ff, #ffffff);
              color: #1e1b4b;
              transform: rotateY(180deg);
              border: 1px solid #e9e7fd;
            }
    
            .card-content {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 100%;
              padding: 20px;
            }
    
            .image-container {
              width: 100%;
              height: 150px;
              overflow: hidden;
              border-radius: 8px;
              box-shadow: 0 4px 6px rgba(79, 70, 229, 0.1);
            }
    
            .course-image {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
    
            .course-name {
              margin: 15px 0 5px;
              font-size: 24px;
              color: #4338ca;
              font-weight: 600;
              text-shadow: 0 1px 2px rgba(79, 70, 229, 0.1);
            }
    
            .course-title {
              font-size: 16px;
              color: #4c1d95;
              margin-bottom: 10px;
            }
    
            .lessons-count {
              font-size: 14px;
              color: #5b21b6;
            }
    
            .course-price {
              font-size: 20px;
              color: #4338ca;
              font-weight: 600;
              margin: 10px 0;
            }
    
            .course-description {
              font-size: 14px;
              color: #4c1d95;
              margin-bottom: 20px;
              max-height: 200px;
              overflow-y: auto;
              line-height: 1.6;
            }
    
            .course-description::-webkit-scrollbar {
              width: 4px;
            }
    
            .course-description::-webkit-scrollbar-track {
              background: #e9e7fd;
              border-radius: 2px;
            }
    
            .course-description::-webkit-scrollbar-thumb {
              background: #4338ca;
              border-radius: 2px;
            }
    
            .enroll-btn {
              background: linear-gradient(135deg, #4338ca, #5b21b6);
              color: white;
              padding: 12px 20px;
              border-radius: 8px;
              text-decoration: none;
              transition: all 0.3s ease;
              font-size: 16px;
              font-weight: 600;
              width: 100%;
              border: none;
              cursor: pointer;
              box-shadow: 0 4px 6px rgba(79, 70, 229, 0.2);
            }
    
            .enroll-btn:hover {
              background: linear-gradient(135deg, #4c1d95, #4338ca);
              transform: translateY(-2px);
              box-shadow: 0 6px 8px rgba(79, 70, 229, 0.3);
            }
          `}</style>
        </div>
      );
    };
    

    

    const TeachersSection = ({ setSelectedTeacher, setShowTeacherModal }) => {
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const teachersData = useSelector((state) => state.teachers.Teacherslist);
        const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    
        useEffect(() => {
            dispatch(fetch5Teachers());
            
            const handleResize = () => {
                setIsMobile(window.innerWidth < 768);
            };
    
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, [dispatch]);
    
        if (!teachersData || teachersData.length === 0) {
            return null;
        }
    
        const handleTeacherClick = (teacher) => {
            navigate(`/TeacherDetails/${teacher._id}`);
        };
    
        // Carousel settings with mobile-specific adjustments
        const settings = {
            showArrows: true,
            showStatus: false,
            showIndicators: true,
            infiniteLoop: true,
            centerMode: true,
            centerSlidePercentage: isMobile ? 100 : 33.33,
            emulateTouch: true,
            swipeable: true,
            dynamicHeight: isMobile,
            showThumbs: false,
            useKeyboardArrows: true,
            autoPlay: false,
            interval: 5000,
            transitionTime: 500,
            // Add these settings for better mobile touch handling
            swipeScrollTolerance: 5,
            preventMovementUntilSwipeScrollTolerance: true,
            stopOnHover: true
        };
    
        return (
            <section id="teachers" className="bg-gray-100 py-16">
                <div className="container mx-auto px-4">
                    <div className="text-center mb-12">
                        <h2 className="text-3xl md:text-4xl font-bold text-blue-700 mb-4">
                        {t("navigation.ourTeachers")}
                        </h2>
                        <p className="text-lg text-gray-600">
                        {t("navigation.meetTeam2")}
                        </p>
                    </div>
    
                    <div className="max-w-7xl mx-auto">
                        <Carousel {...settings} className="teachers-carousel">
                            {teachersData.map((teacher) => (
                                <div key={teacher._id} className="px-2 pb-6">
                                    <div className="bg-white rounded-lg shadow-md overflow-hidden mx-2 h-full hover:shadow-xl transition-shadow duration-300">
                                        <div className="relative group" onClick={() => handleTeacherClick(teacher)}>
                                            <img
                                                src={teacher.Profile_Image?.[0]
                                                    ? `https://speakable.online/api/image/${teacher.Profile_Image[0]}`
                                                    : '/api/placeholder/300/400'
                                                }
                                                alt={teacher.Username}
                                                className="w-full h-64 object-cover"
                                            />
                                            <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setSelectedTeacher(teacher);
                                                        setShowTeacherModal(true);
                                                    }}
                                                    className="bg-white text-blue-700 px-6 py-2 rounded-full hover:bg-blue-50 transition-colors"
                                                >
                                                   {t("navigation.viewProfile")} 
                                                </button>
                                            </div>
                                        </div>
                                        <div className="p-6 text-center">
                                            <h3 className="text-xl font-bold mb-2">
                                                {teacher.Username}
                                            </h3>
                                            <p className="text-gray-600">
                                                {teacher.Short_Title}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
    
                <style jsx>{`
                    .teachers-carousel .carousel.carousel-slider {
                        touch-action: pan-y pinch-zoom;
                    }
                    
                    .teachers-carousel .carousel .slide {
                        touch-action: pan-y pinch-zoom;
                    }
                `}</style>
            </section>
        );
    };

    // FAQ Section Component
    const FAQSection = () => (
        <section id="faq" className="bg-white text-gray-800 py-20 px-4 animate-fade-in">
            <div className="container mx-auto">
                <h2 className="text-3xl md:text-4xl font-bold text-blue-700 text-center animate-fade-in-up">
                {t("navigation.faqTitle")}
                </h2>
                <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className="bg-gray-100 p-6 rounded-lg animate-fade-in-left hover:shadow-lg transition-all duration-300 hover:scale-105">
                        <h3 className="text-2xl font-bold">
                        {t("navigation.signupQuestion")}
                        </h3>
                        <p className="mt-2">
                        {t("navigation.signupAnswer")}.
                        </p>
                    </div>
                    <div className="bg-gray-100 p-6 rounded-lg animate-fade-in-right hover:shadow-lg transition-all duration-300 hover:scale-105">
                        <h3 className="text-2xl font-bold">
                        {t("navigation.changeTeacherQuestion")}
                        </h3>
                        <p className="mt-2">
                        {t("navigation.changeTeacherAnswer")}.
                        </p>
                    </div>
                    <div className="bg-gray-100 p-6 rounded-lg animate-fade-in-left hover:shadow-lg transition-all duration-300 hover:scale-105">
                        <h3 className="text-2xl font-bold"> {t("navigation.childrenQuestion")}</h3>
                        <p className="mt-2">
                        {t("navigation.childrenAnswer")}.
                        </p>
                    </div>
                    <div className="bg-gray-100 p-6 rounded-lg animate-fade-in-right hover:shadow-lg transition-all duration-300 hover:scale-105">
                        <h3 className="text-2xl font-bold">{t("navigation.paymentQuestion")}</h3>
                        <p className="mt-2">
                        {t("navigation.paymentAnswer")}.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
  

    // Contact Section Component
    const ContactSection = () => {
      const dispatch = useDispatch();
      const { t } = useTranslation("global");
      const [formData, setFormData] = useState({
          Name: '',
          Email: '',
          Message: ''
      });
  
      const handleChange = (e) => {
          setFormData({
              ...formData,
              [e.target.name]: e.target.value
          });
      };
  
      const handleSubmit = async (e) => {
          e.preventDefault();
          try {
              await dispatch(Create_Enquiry(formData));
              
              toast.success(t("Contact.successMessage") || "Enquiry submitted successfully!", {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
              });
  
              setFormData({
                  Name: '',
                  Email: '',
                  Message: ''
              });
          } catch (error) {
              console.error("Error submitting enquiry:", error);
              toast.error(t("Contact.errorMessage") || "Error submitting enquiry. Please try again later.", {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
              });
          }
      };
  
      return (
          <section id="contact" className="bg-gray-100 text-gray-800 py-20 px-4 animate-fade-in">
              <div className="container mx-auto text-center">
                  <h2 className="text-3xl md:text-4xl font-bold text-blue-700 animate-fade-in-up">
                      {t("navigation.contactTitle")}
                  </h2>
                  <p className="mt-4 text-lg md:text-xl animate-fade-in-up" style={{ animationDelay: '200ms' }}>
                      {t("navigation.contactSubtitle")}
                  </p>
                  <form onSubmit={handleSubmit} className="mt-10 max-w-xl mx-auto animate-fade-in-up" style={{ animationDelay: '400ms' }}>
                      <input
                          type="text"
                          name="Name"
                          value={formData.Name}
                          onChange={handleChange}
                          placeholder={t("navigation.yourName")}
                          className="w-full p-3 rounded mb-4 border hover:border-blue-500 focus:border-blue-700 transition-all duration-300"
                          required
                      />
                      <input
                          type="email"
                          name="Email"
                          value={formData.Email}
                          onChange={handleChange}
                          placeholder={t("navigation.yourEmail")}
                          className="w-full p-3 rounded mb-4 border hover:border-blue-500 focus:border-blue-700 transition-all duration-300"
                          required
                      />
                      <textarea
                          name="Message"
                          value={formData.Message}
                          onChange={handleChange}
                          placeholder={t("navigation.yourMessage")}
                          className="w-full p-3 rounded mb-4 border hover:border-blue-500 focus:border-blue-700 transition-all duration-300"
                          rows="4"
                          required
                      />
                      <button
                          type="submit"
                          className="bg-orange-500 text-white font-bold py-2 px-6 rounded-full hover:bg-orange-600 transition-all duration-300 hover:scale-110 animate-pulse-slow"
                      >
                          {t("navigation.send")}
                      </button>
                  </form>
                  <div className="mt-10 text-left md:text-center">
                      <div className="space-y-4 animate-fade-in-up" style={{ animationDelay: '600ms' }}>
                          <p className="text-lg hover:text-blue-700 transition-all duration-300">
                              <span className="inline-block">📞</span> +48727710433
                          </p>
                          <p className="text-lg hover:text-blue-700 transition-all duration-300">
                              <span className="inline-block">✉️</span> {t("navigation.email")}
                          </p>
                          <p className="text-lg hover:text-blue-700 transition-all duration-300">
                              <span className="inline-block">🕒</span> {t("navigation.hours")}
                          </p>
                          <p className="text-lg hover:text-blue-700 transition-all duration-300">
                              <span className="inline-block">📍</span> {t("navigation.address")}
                          </p>
                      </div>
                  </div>
              </div>
          </section>
      );
  };

    // Footer Component
    const Footer = () => (
        <footer className="bg-gray-800 text-white py-6 px-4 animate-fade-in">
            <div className="container mx-auto text-center">
                <p className="animate-fade-in-up">
                    &copy; {t("navigation.copyright")}.
                </p>
                <div className="flex justify-center space-x-4 mt-4 animate-fade-in-up" style={{ animationDelay: '200ms' }}>
                    <a href="#" className="hover:text-gray-400 transition-all duration-300 hover:scale-110">{t("navigation.facebook")}</a>
                    <a href="#" className="hover:text-gray-400 transition-all duration-300 hover:scale-110">{t("navigation.instagram")}</a>
                </div>
                <div className="mt-4">
    
    <a href="https://speakable.online/api/pdf/rulesandcondition.pdf" className="text-gray-400 hover:text-white mx-2 transition-all duration-300 hover:scale-110">
        {t("navigation.termsLink")}
    </a>
    <a href="https://speakable.online/api/pdf/privacy.pdf" className="text-gray-400 hover:text-white mx-2 transition-all duration-300 hover:scale-110">
        {t("navigation.privacyLink")}
    </a>
    <a href="https://speakable.online/api/pdf/rulesandcondition.pdf" className="text-gray-400 hover:text-white mx-2 transition-all duration-300 hover:scale-110">
        {t("navigation.cookiesLink")}
    </a>
</div>
            </div>
        </footer>
    );

    // TeacherModal Component with enhanced animations
    const TeacherModal = ({ teacher, onClose, handleChooseTeacher }) => (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 animate-fade-in">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-lg mx-4 overflow-auto max-h-screen p-6 animate-zoom-in">
                <button 
                    onClick={onClose} 
                    className="text-gray-500 hover:text-gray-700 float-right transition-colors duration-300 hover:scale-110"
                >
                    {t("navigation.close")}
                </button>
                <div className="mt-4">
                    <h2 className="text-2xl font-bold animate-fade-in-up">{teacher.name}</h2>
                    <p className="mt-2 animate-fade-in-up" style={{ animationDelay: '200ms' }}>{teacher.specialization}</p>
                    <p className="mt-4 animate-fade-in-up" style={{ animationDelay: '400ms' }}>{teacher.description}</p>
                    {teacher.video && (
                        <div className="mt-4 animate-fade-in-up" style={{ animationDelay: '600ms' }}>
                            <video controls className="w-full h-auto rounded-lg shadow-lg">
                                <source src={teacher.video} type="video/mp4" />
                                {t("navigation.Yourbrowserdoesntsupportvideoplayback")}.
                            </video>
                        </div>
                    )}
                    <button 
                        onClick={handleChooseTeacher} 
                        className="mt-6 bg-blue-700 text-white py-2 px-4 rounded hover:bg-blue-800 transition-all duration-300 hover:scale-110 animate-fade-in-up"
                        style={{ animationDelay: '800ms' }}
                    >
                        {t("navigation.choose")}
                    </button>
                </div>
            </div>
        </div>
    );

    // FreeLessonBooking Component with enhanced animations
    const FreeLessonBooking = ({ setShowBooking, selectedTeacher, setSelectedTeacher }) => {
        const [step, setStep] = useState(selectedTeacher ? 2 : 1);
        const [selectedDate, setSelectedDate] = useState(null);
        const [selectedTime, setSelectedTime] = useState(null);
        const [formData, setFormData] = useState({
            firstName: '',
            lastName: '',
            email: '',
            phone: ''
        });

        const teachers = [
            { id: 1, name: 'Anna', specialization: 'Business English' },
            { id: 2, name: 'John', specialization: 'Native Speaker from USA' },
            { id: 3, name: 'Eva', specialization: 'English for Kids' },
        ];

        const availableTimes = ['10:00', '11:00', '14:00', '16:00'];

        const handleTeacherSelect = (teacher) => {
            setSelectedTeacher(teacher);
            setStep(2);
        };

        const handleDateSelect = (event) => {
            setSelectedDate(event.target.value);
            setStep(3);
        };

        const handleTimeSelect = (time) => {
            setSelectedTime(time);
            setStep(4);
        };

        const handleFormChange = (e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        };

        const handleFormSubmit = (e) => {
            e.preventDefault();
            setStep(5);
        };

        return (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 animate-fade-in">
                <div className="bg-white rounded-lg shadow-lg w-full max-w-md mx-4 overflow-auto max-h-screen animate-zoom-in">
                    <div className="p-6">
                        <button 
                            onClick={() => setShowBooking(false)} 
                            className="text-gray-500 hover:text-gray-700 float-right transition-colors duration-300 hover:scale-110"
                        >
                             {t("navigation.close")}
                        </button>
                        
                        {step === 1 && (
                            <div className="animate-fade-in">
                                <h2 className="text-2xl font-bold mb-4 animate-fade-in-up"> {t("navigation.chooseTeacher")}</h2>
                                {teachers.map((teacher, index) => (
                                    <div
                                        key={teacher.id}
                                        className="p-4 border rounded mb-2 cursor-pointer hover:bg-gray-100 transition-all duration-300 animate-fade-in-up hover:scale-105"
                                        style={{ animationDelay: `${index * 200}ms` }}
                                        onClick={() => handleTeacherSelect(teacher)}
                                    >
                                        <h3 className="text-xl font-semibold">{teacher.name}</h3>
                                        <p>{teacher.specialization}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                        {step === 2 && (
                            <div className="animate-fade-in">
                                <h2 className="text-2xl font-bold mb-4 animate-fade-in-up"> {t("navigation.selectDate")}</h2>
                                {selectedTeacher && (
                                    <p className="mb-4 animate-fade-in-up"> {t("navigation.selectedTeacher")}: <strong>{selectedTeacher.name}</strong></p>
                                )}
                                <label className="block mb-2 animate-fade-in-up" style={{ animationDelay: '200ms' }}> {t("navigation.timezone")}:</label>
                                <select className="w-full p-2 border rounded mb-4 hover:border-blue-500 transition-colors duration-300 animate-fade-in-up" style={{ animationDelay: '400ms' }}>
                                    <option>UTC+1 - Warsaw</option>
                                    <option>UTC+2 - Kiev</option>
                                    <option>UTC+0 - London</option>
                                </select>
                                <input 
                                    type="date" 
                                    className="w-full p-2 border rounded mb-4 hover:border-blue-500 transition-colors duration-300 animate-fade-in-up" 
                                    onChange={handleDateSelect}
                                    style={{ animationDelay: '600ms' }}
                                />
                            </div>
                        )}

                        {step === 3 && (
                            <div className="animate-fade-in">
                                <h2 className="text-2xl font-bold mb-4 animate-fade-in-up"> {t("navigation.selectTime")}</h2>
                                {availableTimes.map((time, index) => (
                                    <button
                                        key={index}
                                        className="w-full p-2 border rounded mb-2 hover:bg-gray-100 transition-all duration-300 animate-fade-in-up hover:scale-105"
                                        style={{ animationDelay: `${index * 200}ms` }}
                                        onClick={() => handleTimeSelect(time)}
                                    >
                                        {time}
                                    </button>
                                ))}
                            </div>
                        )}

                        {step === 4 && (
                            <div className="animate-fade-in">
                                <h2 className="text-2xl font-bold mb-4 animate-fade-in-up"> {t("navigation.yourDetails")}</h2>
                                <form onSubmit={handleFormSubmit}>
                                    <div className="space-y-4">
                                        {[
                                            { label: 'First Name*', name: 'firstName', type: 'text', required: true },
                                            { label: 'Last Name', name: 'lastName', type: 'text', required: false },
                                            { label: 'Email*', name: 'email', type: 'email', required: true },
                                            { label: 'Phone Number', name: 'phone', type: 'tel', required: false }
                                        ].map((field, index) => (
                                            <div 
                                                key={field.name}
                                                className="animate-fade-in-up"
                                                style={{ animationDelay: `${index * 200}ms` }}
                                            >
                                                <label className="block mb-2">{field.label}</label>
                                                <input
                                                    type={field.type}
                                                    name={field.name}
                                                    required={field.required}
                                                    className="w-full p-2 border rounded mb-4 hover:border-blue-500 focus:border-blue-700 transition-all duration-300"
                                                    onChange={handleFormChange}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <button 
                                        type="submit" 
                                        className="w-full bg-blue-700 text-white py-2 px-4 rounded hover:bg-blue-800 transition-all duration-300 hover:scale-105 animate-fade-in-up"
                                        style={{ animationDelay: '800ms' }}
                                    >
                                         {t("navigation.bookNow")}
                                    </button>
                                </form>
                            </div>
                        )}

                        {step === 5 && (
                            <div className="text-center animate-zoom-in">
                                <h2 className="text-2xl font-bold mb-4"> {t("navigation.thankYou")}!</h2>
                                <p className="animate-fade-in-up" style={{ animationDelay: '200ms' }}>
                                {t("navigation.bookingConfirmed")}
                                </p>
                                <button
                                    onClick={() => setShowBooking(false)}
                                    className="mt-6 bg-blue-700 text-white py-2 px-4 rounded hover:bg-blue-800 transition-all duration-300 hover:scale-110 animate-fade-in-up"
                                    style={{ animationDelay: '400ms' }}
                                >
                                     {t("navigation.close")}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    return (
      <div className="animate-fade-in">
          <Navigation
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              handleFreeLessonClick={handleFreeLessonClick}
          />
          <HomeSection handleFreeLessonClick={handleFreeLessonClick} />
          <PromotionSection />
          <PlatformSection />
          <InspirationSection />
          <MethodologySection />
          <PricingSection />
          <TeachersSection
              selectedTeacher={selectedTeacher}
              setSelectedTeacher={setSelectedTeacher}
              setShowTeacherModal={setShowTeacherModal}
              showSelectTeacherMessage={showSelectTeacherMessage}
          />
          <FAQSection />
          <ContactSection />
          <Footer />
          
          {/* Render popups */}
          {signupFormPopup && (
              <SignupFormPopup 
                  handleClose={handleCloseSignupPopup}
                  referralCode={referralCode}
              />
          )}
          
          {signinFormPopup && (
              <SigninFormPopup 
                  handleClose={() => setSigninFormPopup(false)}
              />
          )}
          
          {/* Other modals */}
          {showTeacherModal && (
              <TeacherModal
                  teacher={selectedTeacher}
                  onClose={() => setShowTeacherModal(false)}
                  handleChooseTeacher={handleChooseTeacher}
              />
          )}
          {showBooking && (
              <FreeLessonBooking
                  setShowBooking={setShowBooking}
                  selectedTeacher={selectedTeacher}
                  setSelectedTeacher={setSelectedTeacher}
              />
          )}
      </div>
  );
};

export default Home;
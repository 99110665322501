import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { async_removeuser } from "../../store/actions/studentsActions";
import ChatPopup from './ChatPopup';
import { useTranslation } from "react-i18next";

const AdminDashboard = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [menuInactive, setMenuInactive] = useState(false);
  const [currentLanguage, setcurrentLanguage] = useState("Language");
  const [t, i18n] = useTranslation("global");
  const currentUser = {
    id: "667fd808df2795ef057c93b5", 
    name: "admin",
    type: "admin"
};
  const students = useSelector((state) => state.students.studentslist);
  const teachers = useSelector((state) => state.teachers.Teacherslist); 

  const logoutHandler = () => {
    dispatch(async_removeuser());
    // console.log("logout ----------")
  };

  const chatUsers = React.useMemo(() => {
    const allUsers = [
      ...students.map(student => ({
        id: student._id,
        name: student.Username,
        email: student.Email,
        type: 'student',
        avatar: student.Profile_Image?.[0] ? 
          `https://speakable.online/api/image/${student.Profile_Image[0]}` : 
          null
      })),
      ...teachers.map(teacher => ({
        id: teacher._id,
        name: teacher.Username,
        email: teacher.Email,
        type: 'teacher',
        avatar: teacher.Profile_Image?.[0] ? 
          `https://speakable.online/api/image/${teacher.Profile_Image[0]}` : 
          null
      }))
    ];

    return allUsers;
  }, [students, teachers]);

  const menuHandler = () => {
    // Toggle menu's inactive/active status
    setMenuInactive(!menuInactive);
  };

  const closeMenuHandler = (e) => {
    e.preventDefault(); // Prevent default behavior of NavLink
    setMenuInactive(!menuInactive); // Close the menu
  };

  const handleChangeLanguage = (lang) => {
    if (lang === "pl") {
      setcurrentLanguage("Polsku");
    }
    if (lang === "en") {
      setcurrentLanguage("English");
    }
    i18n.changeLanguage(lang);
  };

  return (
    <div className="col-md-12  Admin-Dashboard_main_div">
       <ChatPopup 
    currentUser={currentUser}
    availableUsers={chatUsers}
/>
      <div
        className={`col-md-12 Admin-Dashboard_main_div ${
          menuInactive ? "inactive" : ""
        }`}
      >
        <div className="Admin-Dashboard_main_left_div">
          <NavLink
            to="/Admin-Dashboard/Dashboard"
            className="Admin-Dashboard_main_left_header_div"
          >
            <h5 style={{textAlign:"center"}}> {t("AdminDashboard.heading")}</h5>
            <span>{t("AdminDashboard.panel")}</span>
          </NavLink>
          
          {!menuInactive ? (
            <div className="Admin-Dashboard_main_left_router_div">
              
              <NavLink
                to="/Admin-Dashboard/Dashboard"
                className={`Admin-Dashboard_router_col_ ${
                  location.pathname === "/Admin-Dashboard/Dashboard"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i class="bi bi-speedometer2"></i>
                </span>
                <span>{t("AdminDashboard.subheading1")}</span>
              </NavLink>
              <NavLink
                to="/Admin-Dashboard/Online-Users"
                className={`Admin-Dashboard_router_col_3 ${
                  location.pathname === "/Admin-Dashboard/Online-Users"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i className="bi bi-people-fill"></i>
                </span>
                <span>Online Users</span>
              </NavLink>
              <NavLink
                to="/Admin-Dashboard/Teachers"
                className={`Admin-Dashboard_router_col_ ${
                  location.pathname === "/Admin-Dashboard/Teachers" ||
                  location.pathname === "/Admin-Dashboard/Teachers/add-teacher"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i class="bi bi-person-fill-add"></i>
                </span>
                <span>{t("AdminDashboard.subheading2")}</span>
              </NavLink>
              <NavLink
  to="/Admin-Dashboard/Bug-Reports"
  className={`Admin-Dashboard_router_col_3 ${
    location.pathname === "/Admin-Dashboard/Bug-Reports"
      ? "active"
      : "inactive"
  }`}
>
  <span>
    <i className="bi bi-bug-fill"></i>
  </span>
  <span>Bug Reports</span>
</NavLink>
              <NavLink
                to="/Admin-Dashboard/Students"
                className={`Admin-Dashboard_router_col_ ${
                  location.pathname === "/Admin-Dashboard/Students"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i class="bi bi-person-fill-gear"></i>
                </span>
                <span>{t("AdminDashboard.subheading3")}</span>
              </NavLink>
              <NavLink
                to="/Admin-Dashboard/Courses"
                className={`Admin-Dashboard_router_col_ ${
                  location.pathname === "/Admin-Dashboard/Courses"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i class="bi bi-box-fill"></i>
                </span>
                <span>{t("AdminDashboard.subheading4")}</span>
              </NavLink>

              <NavLink
                to="/Admin-Dashboard/Bookings"
                className={`Admin-Dashboard_router_col_ ${
                  location.pathname === "/Admin-Dashboard/Bookings"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i class="bi bi-bookmark-dash-fill"></i>
                </span>
                <span>{t("AdminDashboard.subheading5")}</span>
              </NavLink>
              <NavLink
                to="/Admin-Dashboard/Enquirys"
                className={`Admin-Dashboard_router_col_ ${
                  location.pathname === "/Admin-Dashboard/Enquirys"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i class="bi bi-chat-right-quote-fill"></i>
                </span>
                <span>{t("AdminDashboard.subheading6")}</span>
              </NavLink>
              <NavLink
                to="/Admin-Dashboard/Payments"
                className={`Admin-Dashboard_router_col_ ${
                  location.pathname === "/Admin-Dashboard/Payments"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i class="bi bi-currency-dollar"></i>
                </span>
                <span>{t("AdminDashboard.subheading7")}</span>
              </NavLink>
              <NavLink
                to="/Admin-Dashboard/Packages"
                className={`Admin-Dashboard_router_col_ ${
                  location.pathname === "/Admin-Dashboard/Packages"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i class="bi bi-basket3-fill"></i>
                </span>
                <span>{t("AdminDashboard.subheading8")}</span>
              </NavLink>
              <NavLink
  to={`/Admin-Dashboard/Settings/Admin-Settings/${"admin@email.com"}`}
  className={`Admin-Dashboard_router_col_3 ${
    location.pathname === "/Admin-Dashboard/Settings/Admin-Settings"
      ? "active"
      : "inactive"
  }`}
>
  <span>
    <i className="bi bi-gear-fill"></i>
  </span>
  <span>Admin Settings</span>
</NavLink>
<NavLink
                onClick={logoutHandler}
                className={`Admin-Dashboard_router_col_ ${
                  location.pathname === "/Admin-Dashboard/Logout"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i class="bi bi-box-arrow-right"></i>
                </span>
                <span>{t("AdminDashboard.subheading9")}</span>
              </NavLink>
              <NavLink
                className="Admin-Dashboard_router_col_2"
                onClick={menuHandler}
              >
                <span>
                  <i class="bi bi-list"></i>
                </span>
                <span>{t("AdminDashboard.subheading10")}</span>
              </NavLink>
            </div>
          ) : (
            
            <div className="Admin-Dashboard_main_left_router_div_2">
              <NavLink
  to="/Admin-Dashboard/Bug-Reports"
  className={`Admin-Dashboard_router_col_ ${
    location.pathname === "/Admin-Dashboard/Bug-Reports"
      ? "active"
      : "inactive"
  }`}
>
  <span>
    <i className="bi bi-bug-fill"></i>
  </span>
  <span>Bug Reports</span>
</NavLink>
              <NavLink
                to="/Admin-Dashboard/Dashboard"
                className={`Admin-Dashboard_router_col_3 ${
                  location.pathname === "/Admin-Dashboard/Dashboard"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i class="bi bi-speedometer2"></i>
                </span>
                <span>{t("AdminDashboard.subheading1")}</span>
              </NavLink>
              <NavLink
                to="/Admin-Dashboard/Teachers"
                className={`Admin-Dashboard_router_col_3 ${
                  location.pathname === "/Admin-Dashboard/Teachers" ||
                  location.pathname === "/Admin-Dashboard/Teachers/add-teacher"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i class="bi bi-person-fill-add"></i>
                </span>
                <span>{t("AdminDashboard.subheading2")}</span>
              </NavLink>
              <NavLink
                to="/Admin-Dashboard/Students"
                className={`Admin-Dashboard_router_col_3 ${
                  location.pathname === "/Admin-Dashboard/Students"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i class="bi bi-person-fill-gear"></i>
                </span>
                <span>{t("AdminDashboard.subheading3")}</span>
              </NavLink>
              <NavLink
                to="/Admin-Dashboard/Courses"
                className={`Admin-Dashboard_router_col_3 ${
                  location.pathname === "/Admin-Dashboard/Courses"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i class="bi bi-box-fill"></i>
                </span>
                <span>{t("AdminDashboard.subheading4")}</span>
              </NavLink>
              {/* <NavLink
                to="/Admin-Dashboard/Meetings"
                className={`Admin-Dashboard_router_col_3 ${
                  location.pathname === "/Admin-Dashboard/Meetings"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i class="bi bi-clipboard-check-fill"></i>
                </span>
                <span>Meetings</span>
              </NavLink> */}
              <NavLink
                to="/Admin-Dashboard/Bookings"
                className={`Admin-Dashboard_router_col_3 ${
                  location.pathname === "/Admin-Dashboard/Bookings"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i class="bi bi-bookmark-dash-fill"></i>
                </span>
                <span>{t("AdminDashboard.subheading5")}</span>
              </NavLink>
              <NavLink
                to="/Admin-Dashboard/Enquirys"
                className={`Admin-Dashboard_router_col_3 ${
                  location.pathname === "/Admin-Dashboard/Enquirys"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i class="bi bi-chat-right-quote-fill"></i>
                </span>
                <span>{t("AdminDashboard.subheading6")}</span>
              </NavLink>
              <NavLink
                to="/Admin-Dashboard/Payments"
                className={`Admin-Dashboard_router_col_3 ${
                  location.pathname === "/Admin-Dashboard/Payments"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i class="bi bi-currency-dollar"></i>
                </span>
                <span>{t("AdminDashboard.subheading7")}</span>
              </NavLink>
              <NavLink
                to="/Admin-Dashboard/Packages"
                className={`Admin-Dashboard_router_col_3 ${
                  location.pathname === "/Admin-Dashboard/Packages"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i class="bi bi-basket3-fill"></i>
                </span>
                <span>{t("AdminDashboard.subheading8")}</span>
              </NavLink>
              <NavLink
                to="/Admin-Dashboard//Admin-Dashboard/Settings/Admin-Settings/"
                className={`Admin-Dashboard_router_col_3 ${
                  location.pathname === "/Admin-Dashboard/Settings/Admin-Settings"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i class="bi bi-basket3-fill"></i>
                </span>
                <span>Admin Settings</span>
              </NavLink>
              <NavLink
                onClick={logoutHandler}
                className={`Admin-Dashboard_router_col_3 ${
                  location.pathname === "/Admin-Dashboard/Logout"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i class="bi bi-box-arrow-right"></i>
                </span>
                <span>{t("AdminDashboard.subheading9")}</span>
              </NavLink>
              <NavLink
                className="Admin-Dashboard_router_col_2"
                onClick={closeMenuHandler} // Handle close button click
              >
                <span>
                  <i class="bi bi-x-square"></i>
                </span>
                <span>{t("AdminDashboard.subheading11")}</span>
              </NavLink>
            </div>
          )}
        </div>
        <div className="Admin-Dashboard_main_right_div">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;

// PaymentSuccess.jsx
import React, { useEffect, useState } from 'react';
import { Check, ShoppingCart, FileText, CalendarCheck, Home } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import axios from '../../helpers/axiosconfig';

const PaymentSuccess = () => {
  const { t } = useTranslation("global");
  const navigate = useNavigate();
  const [paymentData, setPaymentData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Try to retrieve payment details from localStorage
    const storedPaymentDetails = localStorage.getItem('pendingPaymentDetails');
    
    if (storedPaymentDetails) {
      try {
        const parsedDetails = JSON.parse(storedPaymentDetails);
        setPaymentData(parsedDetails);
      } catch (error) {
        console.error('Error parsing payment details:', error);
      }
    }
    
    // Mark as loaded even if no data is found
    setLoading(false);
    
    // Clean up localStorage
    localStorage.removeItem('pendingPaymentDetails');
    
    // Check if user came from payment flow, if not redirect to dashboard
    const referrer = document.referrer;
    if (!referrer.includes('Payment') && !referrer.includes('payment')) {
      navigate('/Student-dashboard/dash');
    }
  }, [navigate]);

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg overflow-hidden">
        {/* Header */}
        <div className="bg-green-500 p-6 text-center">
          <div className="flex justify-center">
            <div className="rounded-full bg-white p-3 inline-flex">
              <Check className="h-10 w-10 text-green-500" />
            </div>
          </div>
          <h1 className="mt-4 text-2xl font-bold text-white">
            {t("PaymentSuccess.Title") || "Payment Successful!"}
          </h1>
          <p className="mt-2 text-white opacity-90">
            {t("PaymentSuccess.Subtitle") || "Your transaction has been completed successfully"}
          </p>
        </div>
        
        {/* Content */}
        <div className="p-6">
          {loading ? (
            <div className="flex justify-center py-4">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-500"></div>
            </div>
          ) : (
            <>
              {paymentData && (
                <div className="mb-6 space-y-3 border-b pb-4">
                  <h2 className="font-semibold text-xl">
                    {t("PaymentSuccess.OrderDetails") || "Order Details"}
                  </h2>
                  {paymentData.packageName && (
                    <p className="text-gray-700">
                      <span className="font-medium">{t("PaymentSuccess.Package") || "Package"}:</span> {paymentData.packageName}
                    </p>
                  )}
                  {paymentData.Purchase_Amount && (
                    <p className="text-gray-700">
                      <span className="font-medium">{t("PaymentSuccess.Amount") || "Amount"}:</span> {paymentData.Purchase_Amount} PLN
                    </p>
                  )}
                  {paymentData.OrderID && (
                    <p className="text-gray-700">
                      <span className="font-medium">{t("PaymentSuccess.OrderID") || "Order ID"}:</span> {paymentData.OrderID}
                    </p>
                  )}
                </div>
              )}
              
              <p className="text-gray-600 mb-6">
                {t("PaymentSuccess.ConfirmationMsg") || 
                 "Your payment has been processed successfully. You can now access your purchased content in your dashboard."}
              </p>
              
              <div className="space-y-3">
                <Link 
                  to="/Student-dashboard/dash" 
                  className="block w-full py-3 bg-green-500 hover:bg-green-600 text-white font-medium rounded-lg text-center transition-colors flex items-center justify-center"
                >
                  <Home className="w-5 h-5 mr-2" />
                  {t("PaymentSuccess.GoToDashboard") || "Go to Dashboard"}
                </Link>
                
                <Link 
                  to="/Student-dashboard/Bookings" 
                  className="block w-full py-3 bg-blue-500 hover:bg-blue-600 text-white font-medium rounded-lg text-center transition-colors flex items-center justify-center"
                >
                  <CalendarCheck className="w-5 h-5 mr-2" />
                  {t("PaymentSuccess.ViewBookings") || "View My Bookings"}
                </Link>
                
                <Link 
                  to="/Student-dashboard/my-packages" 
                  className="block w-full py-3 border border-gray-300 hover:bg-gray-50 font-medium rounded-lg text-center transition-colors flex items-center justify-center"
                >
                  <ShoppingCart className="w-5 h-5 mr-2" />
                  {t("PaymentSuccess.ViewPackages") || "View My Packages"}
                </Link>
                
                {paymentData?.invoice?.orderId && (
                  <Link 
                    to={`/Student-dashboard/Payments/Invoice/${paymentData.invoice.orderId}`}
                    className="block w-full py-3 border border-gray-300 hover:bg-gray-50 font-medium rounded-lg text-center transition-colors flex items-center justify-center"
                  >
                    <FileText className="w-5 h-5 mr-2" />
                    {t("PaymentSuccess.ViewInvoice") || "View Invoice"}
                  </Link>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetBookingsByTeacherID } from "../../store/actions/bookingActions";
import AdminNav from "../admin-dashboard-components/AdminNav";
import moment from 'moment-timezone';
import { useTranslation } from "react-i18next";
import { joinWindowsApp } from './frontendWindowsJitsiLauncher';
import { Clock, Calendar, User, Package } from 'lucide-react';
import { Table, Button, Card, Tag, message, List, Typography, Space, Select, Tooltip, Popover, Modal, Input, Progress, Spin } from 'antd';
import { connectWebSocket, sendWebSocketMessage, joinRoom, leaveRoom } from "../../store/actions/teachersActions";
import { teacherJoinSession, getStudentSessionStatus } from "../../store/actions/teacherSessionActions";
import { useMediaQuery } from 'react-responsive';
import { InfoCircleOutlined, CopyOutlined, DownloadOutlined, GlobalOutlined, WindowsOutlined, CheckCircleOutlined, FileTextOutlined } from '@ant-design/icons';
import { Get_Trial_Bookings_By_Package } from "../../store/actions/trialPreferencesActions";
import { FaBookOpen } from 'react-icons/fa';
import axios from 'axios';
import BookingFilters from '../utils/BookingFilters';
import TeacherNotesModal from './TeacherNotesModal';


const { Text } = Typography;

const BookingCard = ({ 
  booking, 
  onJoin, 
  onViewPreferences, 
  joiningRoom, 
  t,
  bookingRules,
  renderTime,
  renderDate,
  formatStudentName,
  onOpenNotes  // Add this new prop
}) => {
  const getStatusColor = (status) => {
    switch (status) {
      case 'Completed': return '#4ade80';
      case 'In Progress': return '#fb923c';
      case 'Scheduled': return '#60a5fa';
      case 'Cancelled': return '#f87171';
      case 'Trial': return '#06b6d4';
      default: return '#9ca3af';
    }
  };

  const getGradientByStatus = (status) => {
    switch (status) {
      case 'Completed': return 'from-green-50 to-emerald-50';
      case 'In Progress': return 'from-orange-50 to-amber-50';
      case 'Scheduled': return 'from-blue-50 to-indigo-50';
      case 'Cancelled': return 'from-red-50 to-rose-50';
      case 'Trial': return 'from-cyan-50 to-sky-50';
      default: return 'from-gray-50 to-slate-50';
    }
  };

  const isButtonDisabled = ['Cancelled', 'Completed'].includes(booking.status);

  return (
    <div className={`relative overflow-hidden bg-gradient-to-br ${getGradientByStatus(booking.status)} 
                    rounded-lg shadow-sm border border-gray-100 transition-all duration-300
                    hover:shadow-lg hover:scale-[1.01]`}>
      <div className="absolute top-0 right-0 w-20 h-20 transform translate-x-10 -translate-y-10">
        <div className="w-full h-full rounded-full opacity-10"
             style={{ background: getStatusColor(booking.status) }} />
      </div>

      <div className="p-4 relative">
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center space-x-2">
            <div className="p-1.5 rounded-md bg-white shadow-sm border border-gray-100">
              <Package className="w-4 h-4" style={{ color: getStatusColor(booking.status) }} />
            </div>
            <div>
              <h3 className="font-medium text-sm text-gray-900 leading-tight">
                {booking.Package_ID?.Package_Name || 'N/A'}
              </h3>
              <span 
                className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium mt-0.5"
                style={{ 
                  backgroundColor: `${getStatusColor(booking.status)}15`,
                  color: getStatusColor(booking.status)
                }}
              >
                {booking.status}
                {booking.isConsecutive && (
                  <span className="ml-1">(Consecutive)</span>
                )}
              </span>
            </div>
          </div>
        </div>

        <div className="space-y-2">
          <div className="flex items-center space-x-2 bg-white/60 p-2 rounded-md backdrop-blur-sm">
            <User className="w-4 h-4 text-gray-500" />
            <div>
              <p className="text-xs text-gray-500">{t("TeacherBookings.StudentName")}</p>
              <p className="text-sm text-gray-900">{formatStudentName(booking.Student_ID, booking)}</p>
            </div>
          </div>

          <div className="flex justify-between space-x-2">
            <div className="flex-1 flex items-center space-x-2 bg-white/60 p-2 rounded-md backdrop-blur-sm">
              <Calendar className="w-4 h-4 text-gray-500" />
              <div>
                <p className="text-xs text-gray-500">{t("TeacherBookings.Date")}</p>
                <p className="text-sm text-gray-900">{renderDate(booking.date, booking.start)}</p>
              </div>
            </div>

            <div className="flex-1 flex items-center space-x-2 bg-white/60 p-2 rounded-md backdrop-blur-sm">
              <Clock className="w-4 h-4 text-gray-500" />
              <div>
                <p className="text-xs text-gray-500">{t("TeacherBookings.Time")}</p>
                <p className="text-sm text-gray-900">
                  {`${renderTime(booking.start, booking.date)} - ${renderTime(booking.end, booking.date)}`}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex space-x-2 mt-3">
          <Tooltip title={isButtonDisabled ? bookingRules.cancelled : ''}>
            <button
              onClick={() => onJoin(booking._id)}
              disabled={isButtonDisabled || joiningRoom}
              className={`flex-1 flex items-center justify-center space-x-1 bg-gradient-to-r 
                      ${isButtonDisabled || joiningRoom
                        ? 'from-gray-400 to-gray-500 cursor-not-allowed' 
                        : 'from-emerald-500 to-green-500 hover:from-emerald-600 hover:to-green-600'} 
                      text-white py-1.5 px-3 rounded-md text-sm
                      transition-all duration-300 shadow-sm hover:shadow`}
            >
              <span>
                {joiningRoom 
                  ? t("TeacherBookings.JoiningRoom") 
                  : t("TeacherBookings.JoinRoom")
                }
              </span>
            </button>
          </Tooltip>
          
          {booking.isTrial && (
            <button
              onClick={() => onViewPreferences(booking._id)}
              className="flex-1 flex items-center justify-center space-x-1 bg-white border border-gray-200
                        text-gray-700 py-1.5 px-3 rounded-md text-sm hover:bg-gray-50 transition-all duration-300
                        shadow-sm hover:shadow"
            >
              <FaBookOpen className="mr-1" />
              <span>View Preferences</span>
            </button>
          )}
        {/* Add Notes button */}
        <button
          onClick={() => onOpenNotes(booking)}
          className="flex-1 flex items-center justify-center space-x-1 bg-white border border-gray-200
                    text-gray-700 py-1.5 px-3 rounded-md text-sm hover:bg-gray-50 transition-all duration-300
                    shadow-sm hover:shadow"
        >
          <FileTextOutlined className="mr-1" />
          <span>{t("TeacherDash.Notes")}</span>
        </button>
      </div>
      </div>

      <div className="absolute bottom-0 left-0 w-16 h-16 transform -translate-x-8 translate-y-8">
        <div className="w-full h-full rounded-full opacity-10"
             style={{ background: getStatusColor(booking.status) }} />
      </div>
    </div>
  );
};

const TeacherBookings = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const teacher = useSelector((state) => state.students.user);
  const Bookings = useSelector((state) => state.bookings.Teacher_Bookings);
  const teacherState = useSelector((state) => state.teacher);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [joiningRoom, setJoiningRoom] = useState(false);
  const [isAppDownloaded, setIsAppDownloaded] = useState(localStorage.getItem('isAppDownloaded') === 'true');
  const [currentBookingId, setCurrentBookingId] = useState(null);
  const [hourFormat, setHourFormat] = useState(localStorage.getItem('hourFormat') || '24');
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTimezone, setSelectedTimezone] = useState(localStorage.getItem('selectedTimezone') || moment.tz.guess());
  const [utcTime, setUtcTime] = useState(null);
  const [filteredBookingList, setFilteredBookingList] = useState([]);
  const { Option } = Select;
  const [trialPreferencesData, setTrialPreferencesData] = useState(null);
  const [trialPreferencesMap, setTrialPreferencesMap] = useState({});
  const [isTrialPreferencesModalVisible, setIsTrialPreferencesModalVisible] = useState(false);
  const [currentStudentId, setCurrentStudentId] = useState(null);
const [studentJoined, setStudentJoined] = useState(false);
const [flattenedBookings, setFlattenedBookings] = useState([]);
const [waitTimeLeft, setWaitTimeLeft] = useState(300); // 5 minutes in seconds
const [postJoinWaitTime, setPostJoinWaitTime] = useState(10);
const [selectedFilters, setSelectedFilters] = useState([]); 
const [isNotesModalVisible, setIsNotesModalVisible] = useState(false);
const [selectedStudent, setSelectedStudent] = useState(null);






  const isConnected = teacherState?.isConnected || false;
  const lastMessage = teacherState?.lastMessage || null;

  const checkStudentStatus = useCallback(async () => {
    if (!currentBookingId) return;
    const booking = flattenedBookings.find(b => b._id === currentBookingId);
    const studentId = booking?.Student_ID?._id;
  
    if (!studentId) return;
  
    try {
      const result = await dispatch(getStudentSessionStatus({ 
        bookingId: currentBookingId, 
        studentId: studentId
      }));
  
      if (result.payload && result.payload.isActive) {
        setStudentJoined(true);
      }
    } catch (error) {
      console.error("Error checking student status:", error);
    }
  }, [currentBookingId, dispatch, flattenedBookings]);

  

  const handleCancelJoining = () => {
    setJoiningRoom(false);
    setStudentJoined(false);
    setWaitTimeLeft(300);
    setPostJoinWaitTime(10);
    setIsModalVisible(false);
  };

  const bookingRules = {
    cancelled: "Cancelled meetings can't be joined.",
    completed: "Completed meetings can't be joined.",
    consecutive: "Consecutive slots are made up of multiple meetings."
  };

  const showJoinModal = (id) => {
    setSelectedBookingId(id);
    setIsModalVisible(true);
  };

  const handleJoinChoice = (choice) => {
    setIsModalVisible(false);
    if (choice === 'browser') {
      joinBrowserRoom(selectedBookingId);
    } else if (choice === 'windows') {
      joinWindowsApp(selectedBookingId);
    }
  };

  const copyBookingId = () => {
    navigator.clipboard.writeText(selectedBookingId).then(() => {
      message.success('Booking ID copied to clipboard');
    }, (err) => {
      message.error('Failed to copy booking ID');
      console.error('Could not copy text: ', err);
    });
  };

  const handleOpenNotes = (booking) => {
    setSelectedBookingId(booking._id);
    setSelectedStudent({
      id: booking.Student_ID?._id,
      name: formatStudentName(booking.Student_ID, booking)
    });
    setIsNotesModalVisible(true);
  };

  const filterBookings = (bookings) => {
    if (selectedFilters.length === 0) return bookings;
  
    return bookings.filter(booking => {
      const bookingStartUTC = moment.utc(`${booking.date} ${booking.start}`, 'YYYY-MM-DD HH:mm');
      const bookingEndUTC = moment.utc(`${booking.date} ${booking.end}`, 'YYYY-MM-DD HH:mm');
      const isPast = utcTime?.isAfter(bookingEndUTC);
      const isUpcoming = utcTime?.isBefore(bookingStartUTC);
  
      return selectedFilters.some(filter => {
        switch (filter) {
          case 'upcoming':
            return isUpcoming;
          case 'past':
            return isPast;
          case 'completed':
            return booking.status === 'Completed';
          case 'inProgress':
            return booking.status === 'In Progress';
          case 'scheduled':
            return booking.status === 'Scheduled';
          case 'cancelled':
            return booking.status === 'Cancelled';
          case 'trial':
            return booking.isTrial;
          default:
            return true;
        }
      });
    });
  };

  useEffect(() => {
    if (flattenedBookings.length) {
      setFilteredBookingList(filterBookings(flattenedBookings));
    }
  }, [selectedFilters, flattenedBookings]);

  const joinBrowserRoom = async (id) => {
    try {
      const booking = flattenedBookings.find(b => b._id === id);
      if (!booking?.Student_ID?._id) {
        throw new Error('Student information not found');
      }
      
      if (isConnected) {
        dispatch(sendWebSocketMessage({ type: 'teacherJoined', bookingId: id }));
      }
  
      console.log('Calling teacherJoinSession with ID:', id, 'Teacher ID:', teacher._id);
      
      const joinSessionResult = await dispatch(teacherJoinSession({ 
        bookingId: id, 
        teacherId: teacher._id 
      }));
      
      console.log('Join session result:', joinSessionResult);
      
      if (joinSessionResult.error) {
        throw new Error(joinSessionResult.error.message);
      }
  
      await dispatch(joinRoom(id));
      
      // Wait a moment to ensure all actions complete
      await new Promise(resolve => setTimeout(resolve, 500));
      
      // Now open the room
      const roleInfo = encodeURIComponent(JSON.stringify({ 
        role: 'teacher', 
        teacherId: teacher._id 
      }));
      window.open(`/room/meeting/${id}?roleInfo=${roleInfo}`, '_blank', 'noopener,noreferrer');
      setIsModalVisible(false);
  
    } catch (error) {
      console.error('Error joining room:', error);
      message.error(`Failed to join: ${error.message}`);
    }
  };
  

  const checkAppInstallation = () => {
    return new Promise((resolve) => {
      const protocolCheck = 'speakable-online://';
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
  
      let isInstalled = false;
      const checkFocus = () => {
        if (document.hasFocus()) {
          isInstalled = true;
          cleanup();
        }
      };
  
      const interval = setInterval(checkFocus, 100);
  
      const cleanup = () => {
        clearInterval(interval);
        document.body.removeChild(iframe);
        window.removeEventListener('blur', onBlur);
        resolve(isInstalled);
      };
  
      const onBlur = () => {
        isInstalled = true;
        cleanup();
      };
  
      window.addEventListener('blur', onBlur);
  
      setTimeout(() => {
        if (!isInstalled) {
          cleanup();
        }
      }, 1000);
  
      iframe.src = protocolCheck;
    });
  };

  const handleViewTrialPreferences = async (bookingId) => {
    try {
      const result = await dispatch(Get_Trial_Bookings_By_Package(bookingId));
      if (result.payload?.data?.[0]) {
        setTrialPreferencesData(result.payload.data[0]);
        setIsTrialPreferencesModalVisible(true);
      } else {
        message.info('No trial preferences found for this booking');
      }
    } catch (error) {
      message.error('Failed to fetch trial preferences');
      console.error('Error fetching trial preferences:', error);
    }
  };

  const downloadApp = () => {
    const downloadUrl = 'https://speakable.online/api/Speakable-Online-Setup-2024.10.0.exe';
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'Speakable-Online-Setup-2024.10.0.exe';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setIsAppDownloaded(true);
    localStorage.setItem('isAppDownloaded', 'true');
    message.success('Downloading Speakable-Online application. Please install it after downloading.');
  };

  useEffect(() => {
    const isDownloaded = localStorage.getItem('isAppDownloaded') === 'true';
    setIsAppDownloaded(isDownloaded);
  }, []);

  const joinWindowsApp = async (id) => {
    setJoiningRoom(true);
    try {
      if (isConnected) {
        dispatch(sendWebSocketMessage({ type: 'teacherJoined', bookingId: id }));
      } else {
        console.warn('WebSocket is not connected');
      }
  
      const joinSessionResult = await dispatch(teacherJoinSession({ bookingId: id, teacherId: teacher._id }));
      if (joinSessionResult.error) {
        throw new Error(joinSessionResult.error.message);
      }
  
      await dispatch(joinRoom(id));
      message.success('Successfully joined the room.');
      setCurrentBookingId(id);
  
      const isAppInstalled = await checkAppInstallation();
  
      const jitsiDomain = "meet.speakable.online";
      const roomName = id;
      const userInfo = encodeURIComponent(JSON.stringify({
        displayName: `${teacher.Username} (Teacher)`,
        email: teacher.email,
        role: 'teacher'
      }));
  
      const jitsiAppUrl = `speakable-online://${jitsiDomain}/${roomName}?jwt=${teacher._id}&userInfo=${userInfo}#config.prejoinPageEnabled=false`;
      
      if (isAppInstalled) {
        window.location.href = jitsiAppUrl;
      } else if (!isAppDownloaded) {
        // If not installed and not downloaded, show a modal to ask if the user wants to download
        Modal.confirm({
          title: 'Speakable-Online App Not Detected',
          content: 'The Speakable-Online app does not appear to be installed. Would you like to download it now?',
          onOk() {
            downloadApp();
          },
          onCancel() {
            message.info('You can join the meeting via browser instead.');
          },
        });
      } else {
        // If downloaded but not installed, show a message to install
        message.info('Please install the Speakable-Online application and try again.');
      }
  
    } catch (error) {
      console.error('Error joining the room:', error);
      message.error(`Failed to join the room: ${error.message}`);
    } finally {
      setJoiningRoom(false);
    }
  };

  const RenderBookingInfo = ({ isConsecutive }) => (
    <div>
      <p>{bookingRules.cancelled}</p>
      <p>{bookingRules.completed}</p>
      {isConsecutive && <p>{bookingRules.consecutive}</p>}
    </div>
  );

  useEffect(() => {
    dispatch(connectWebSocket());
  }, [dispatch]);

  useEffect(() => {
    const fetchUTCTime = async () => {
      try {
        const response = await axios.get('http://worldtimeapi.org/api/timezone/Etc/UTC');
        setUtcTime(moment(response.data.utc_datetime));
      } catch (error) {
        console.error("Error fetching UTC time:", error);
        setUtcTime(moment.utc());
      }
    };

    fetchUTCTime();
    const intervalId = setInterval(fetchUTCTime, 60000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (teacher?._id) {
      setIsLoading(true);
      dispatch(GetBookingsByTeacherID(teacher._id))
        .then(() => setIsLoading(false))
        .catch(error => {
          console.error('Error fetching bookings:', error);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [dispatch, teacher?._id]);

  useEffect(() => {
    if (lastMessage && lastMessage.type === 'teacherJoined') {
      console.log('Teacher joined response:', lastMessage);
    }
  }, [lastMessage]);

  const formatStudentName = useCallback((student, booking) => {
    if (!booking) return 'N/A';
    
    // Check if it's a trial booking
    const isTrial = booking.Status === 'Trail' || booking.Status === 'Trial';
    
    if (isTrial) {
      const trialData = trialPreferencesMap[booking._id];
      if (trialData?.studentDetails?.name) {
        return trialData.studentDetails.name;
      }
    }
    
    // Regular name formatting
    if (!student?.Username) return 'N/A';
    const [firstName, ...lastNameParts] = student.Username.split(' ');
    const lastInitial = lastNameParts.length > 0 ? lastNameParts[0][0] : '';
    return `${firstName} ${lastInitial}${lastInitial ? '.' : ''}`;
}, [trialPreferencesMap]);

useEffect(() => {
  if (Bookings?.length) {
    const processed = flattenBookings(Bookings);
    setFlattenedBookings(processed);
  }
}, [Bookings, selectedTimezone, hourFormat]);

  const roomHandler = useCallback(async (id) => {
    setJoiningRoom(true);
    try {
      if (isConnected) {
        dispatch(sendWebSocketMessage({ type: 'teacherJoined', bookingId: id }));
      } else {
        console.warn('WebSocket is not connected');
      }

      const joinSessionResult = await dispatch(teacherJoinSession({ bookingId: id, teacherId: teacher._id }));
      if (joinSessionResult.error) {
        throw new Error(joinSessionResult.error.message);
      }

      await dispatch(joinRoom(id));
      message.success('Successfully joined the room.');
      setCurrentBookingId(id);

      const roleInfo = encodeURIComponent(JSON.stringify({ role: 'teacher', teacherId: teacher._id }));
      window.open(`/room/meeting/${id}?roleInfo=${roleInfo}`, '_blank', 'noopener,noreferrer');

    } catch (error) {
      console.error('Error joining the room:', error);
      message.error(`Failed to join the room: ${error.message}`);
    } finally {
      setJoiningRoom(false);
    }
  }, [isConnected, dispatch, teacher._id]);

  useEffect(() => {
    const fetchAllTrialPreferences = async () => {
      const trialBookings = flattenedBookings?.filter(booking => booking.isTrial) || [];
      
      for (const booking of trialBookings) {
        try {
          const result = await dispatch(Get_Trial_Bookings_By_Package(booking._id));
          if (result.payload?.data?.[0]) {
            setTrialPreferencesMap(prev => ({
              ...prev,
              [booking._id]: result.payload.data[0]
            }));
          }
        } catch (error) {
          console.error('Error fetching trial preferences:', error);
        }
      }
    };

    if (Bookings?.length) {
      fetchAllTrialPreferences();
    }
}, [Bookings, dispatch]);

  useEffect(() => {
    return () => {
      if (currentBookingId) {
        dispatch(leaveRoom(currentBookingId)).catch(error => {
          console.error('Error notifying teacher left:', error);
        });
      }
    };
  }, [currentBookingId, dispatch]);

  const convertUTCToLocal = (date, time) => {
    return moment.tz(`${date} ${time}`, 'YYYY-MM-DD HH:mm', 'UTC').tz(selectedTimezone);
  };

  const renderTime = (time, date) => {
    if (!time || !date) return '';
    const localDateTime = convertUTCToLocal(date, time);
    return hourFormat === '12' 
      ? localDateTime.format('hh:mm A')
      : localDateTime.format('HH:mm');
  };

  const renderDate = (date, start) => {
    if (!date || !start) return 'N/A';
    const localDateTime = convertUTCToLocal(date, start);
    return localDateTime.format('MMMM Do, YYYY');
  };

  const getBookingStatus = (date, start, end, status) => {
    if (!utcTime) return 'N/A';
    const bookingStartUTC = moment.utc(`${date} ${start}`, 'YYYY-MM-DD HH:mm');
    const bookingEndUTC = moment.utc(`${date} ${end}`, 'YYYY-MM-DD HH:mm');
  
    if (status === "Cancelled") return "Cancelled";
    if (utcTime.isAfter(bookingEndUTC)) return "Completed";
    if (utcTime.isSameOrAfter(bookingStartUTC) && utcTime.isBefore(bookingEndUTC)) return "In Progress";
    if (status === "Rescheduled") return "Rescheduled";
    return "Scheduled";
  };

  const flattenBookings = (bookings) => {
    if (!Array.isArray(bookings)) {
      console.error('Bookings is not an array:', bookings);
      return [];
    }
    
    const flattened = bookings.flatMap(booking => 
      (booking.Scheduled_Dates || []).flatMap(dateObj => {
        if (!dateObj || typeof dateObj !== 'object') {
          console.error('Invalid dateObj:', dateObj);
          return [];
        }
        const date = Object.keys(dateObj)[0];
        const timeSlots = dateObj[date];
        if (!Array.isArray(timeSlots) && typeof timeSlots !== 'object') {
          console.error('Invalid timeSlots:', timeSlots);
          return [];
        }
        return (Array.isArray(timeSlots) ? timeSlots : Object.values(timeSlots))
          .filter(slot => slot && typeof slot === 'object' && slot.start && slot.end)
          .map(slot => {
            const startDateTime = moment.utc(`${date} ${slot.start}`).tz(selectedTimezone);
            const endDateTime = moment.utc(`${date} ${slot.end}`).tz(selectedTimezone);
            
            // Determine if this is a trial booking
            const isTrial = booking.Status === 'Trail' || booking.Status === 'Trial';
            
            return {
              ...booking,
              date,
              start: slot.start,
              end: slot.end,
              localDate: startDateTime.format('YYYY-MM-DD'),
              localStart: startDateTime.format('HH:mm'),
              localEnd: endDateTime.format('HH:mm'),
              slotId: `${booking._id}-${date}-${slot.start}-${slot.end}`,
              sortableTimestamp: startDateTime.valueOf(),
              status: isTrial ? 'Trial' : getBookingStatus(date, slot.start, slot.end, booking.Status),
              isTrial // Add this flag
            };
          });
      })
    );
  
    flattened.sort((a, b) => a.sortableTimestamp - b.sortableTimestamp);
  
    const merged = [];
    let currentMerge = null;
  
    for (let i = 0; i < flattened.length; i++) {
      const current = flattened[i];
      
      if (currentMerge &&
          current.date === currentMerge.date && 
          moment(current.start, 'HH:mm').isSame(moment(currentMerge.end, 'HH:mm')) &&
          current.Package_ID?._id === currentMerge.Package_ID?._id &&
          current.Student_ID?._id === currentMerge.Student_ID?._id) {
        currentMerge.end = current.end;
        currentMerge.localEnd = current.localEnd;
        currentMerge.slotId += `-${current.slotId}`;
        currentMerge.isConsecutive = true;
        currentMerge.status = getBookingStatus(currentMerge.date, currentMerge.start, current.end, current.Status);
      } else {
        if (currentMerge) {
          merged.push(currentMerge);
        }
        currentMerge = {...current, isConsecutive: false};
      }
    }
  
    if (currentMerge) {
      merged.push(currentMerge);
    }
  
    return merged;
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Completed':
        return 'green';
      case 'In Progress':
        return 'orange';
      case 'Scheduled':
        return 'blue';
      case 'Cancelled':
        return 'red';
      case 'Rescheduled':
        return 'purple';
      case 'Trial':
        return 'cyan';  // Add this case for Trial meetings
      default:
        return 'default';
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: t("TeacherBookings.StudentName"),
      dataIndex: ['Student_ID', 'Username'],
      key: 'studentName',
      render: (text, record) => formatStudentName(record.Student_ID, record),
    },
    {
      title: t("TeacherBookings.PackageName"),
      dataIndex: ['Package_ID', 'Package_Name'],
      key: 'packageName',
      render: (text, record) => record.Package_ID?.Package_Name || 'N/A',
    },
    {
      title: t("TeacherBookings.Date"),
      dataIndex: 'date',
      key: 'date',
      render: (date, record) => renderDate(date, record.start),
      sorter: (a, b) => a.sortableTimestamp - b.sortableTimestamp,
    },
    {
      title: t("TeacherBookings.Time"),
      key: 'time',
      render: (text, record) => (
        <span>
          {`${renderTime(record.start, record.date)} - ${renderTime(record.end, record.date)}`}
          {record.isConsecutive && (
            <Tag color="blue" style={{ marginLeft: 8 }}>
              {t("TeacherBookings.ConsecutiveBooking")}
            </Tag>
          )}
        </span>
      ),
      sorter: (a, b) => a.sortableTimestamp - b.sortableTimestamp,
    },
    {
      title: t("TeacherBookings.Status"),
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <Space>
          <Tag color={getStatusColor(status)}>
            {status || 'N/A'}
          </Tag>
          {record.isTrial && (
            <Tag color="cyan">Trial</Tag>
          )}
        </Space>
      ),
    },
    {
      title: t("TeacherBookings.Actions"),
      key: 'actions',
      render: (text, record) => {
        const isButtonDisabled = ['Cancelled', 'Completed'].includes(record.status);

        return (
          <Space>
            <Tooltip title={bookingRules.cancelled}>
              <Button 
                type="primary"
                onClick={() => showJoinModal(record._id)}
                disabled={isButtonDisabled || joiningRoom}
                loading={joiningRoom}
              >
                {joiningRoom 
                  ? t("TeacherBookings.JoiningRoom") 
                  : t("TeacherBookings.JoinRoom")
                }
              </Button>
            </Tooltip>
            {record.isTrial && (
              <Button
                type="primary"
                icon={<FaBookOpen />}
                onClick={() => handleViewTrialPreferences(record._id)}
              >
                View Preferences
              </Button>
            )}
            <Popover 
              content={<RenderBookingInfo isConsecutive={record.isConsecutive} />} 
              title="Booking Rules"
              trigger="click"
            >
              <Button icon={<InfoCircleOutlined />} type="text" />
            </Popover>
          </Space>
        );
      },
    }
  ];

  const renderMobileCard = (item) => {
    const isButtonDisabled = ['Cancelled', 'Completed'].includes(item.status);

    return (
      <Card style={{ marginBottom: 16 }}>
        <Space direction="vertical" size="small">
          <Text strong>{t("TeacherBookings.StudentName")}: {formatStudentName(item.Student_ID)}</Text>
          <Text>{t("TeacherBookings.PackageName")}: {item.Package_ID?.Package_Name || 'N/A'}</Text>
          <Text>{t("TeacherBookings.Date")}: {renderDate(item.date, item.start)}</Text>
          <Text>
            {t("TeacherBookings.Time")}: {renderTime(item.start, item.date)} - {renderTime(item.end, item.date)}
            {item.isConsecutive && (
              <Tag color="blue" style={{ marginLeft: 8 }}>
                {t("TeacherBookings.ConsecutiveBooking")}
              </Tag>
            )}
          </Text>
          <Text>{t("TeacherBookings.Status")}: 
          <Space style={{ marginLeft: 8 }}>
            <Tag color={getStatusColor(item.status)}>
              {item.status || 'N/A'}
            </Tag>
            {item.isTrial && (
              <Tag color="cyan">Trial</Tag>
            )}
          </Space>
        </Text>
          <Tooltip title={bookingRules.cancelled}>
          <Button 
            type="primary"
            onClick={() => showJoinModal(item._id)}
            disabled={isButtonDisabled || joiningRoom}
            loading={joiningRoom}
            style={{ width: '100%' }}
          >
            {joiningRoom ? t("TeacherBookings.JoiningRoom") : t("TeacherBookings.JoinRoom")}
          </Button>
          {item.isTrial && (  // Add trial preferences button
            <Button
              type="primary"
              icon={<FaBookOpen />}
              onClick={() => handleViewTrialPreferences(item._id)}
              style={{ width: '100%' }}
            >
              View Preferences
            </Button>
          )}
          {/* Add Notes button */}
          <Button
            type="default"
            icon={<FileTextOutlined />}
            onClick={() => handleOpenNotes(item)}
            style={{ width: '100%' }}
          >
            {t("TeacherBookings.Notes")}
          </Button>
        
        </Tooltip>
          <Popover 
            content={<RenderBookingInfo isConsecutive={item.isConsecutive} />} 
            title="Booking Rules"
            trigger="click"
          >
            <Button icon={<InfoCircleOutlined />} type="text" style={{ width: '100%' }} />
          </Popover>
        </Space>
      </Card>
    );
  };

  const handleTimezoneChange = (value) => {
    setSelectedTimezone(value);
    localStorage.setItem('selectedTimezone', value);
  };

  const handleHourFormatChange = (value) => {
    setHourFormat(value);
    localStorage.setItem('hourFormat', value);
  };


const TrialPreferencesModal = ({ visible, onClose, data }) => {
  if (!data) return null;
  
  const { studentDetails, learningPreferences, timeSlot, scheduledDate } = data;
  
  return (
    <Modal
  title={t("trialPreferences.title")}
  open={visible}
  onCancel={onClose}
  footer={[
    <Button key="close" onClick={onClose}>
      {t("common.close")}
    </Button>
  ]}
  width={700}
>
      <div className="space-y-4">
      <Card className="mb-4">
  <h3 className="text-lg font-semibold mb-2">{t("trialPreferences.studentInfo")}</h3>
  <p><strong>{t("trialPreferences.name")}:</strong> {studentDetails.name.split(' ')[0]}</p>
  <p><strong>{t("trialPreferences.age")}:</strong> {learningPreferences.sessionPreferences.age}</p>
</Card>


        <Card className="mb-4">
          <h3 className="text-lg font-semibold mb-2">{t("trialPreferences.learningObjectives")}</h3>
          {learningPreferences.learningObjectives.map((objective, index) => (
            <Tag key={index} color="blue" className="m-1">
              {objective.skillType} ({objective.proficiencyLevel}) - {objective.priority} {t("trialPreferences.priority")}
            </Tag>
          ))}
        </Card>

        <Card className="mb-4">
        <h3 className="text-lg font-semibold mb-2">{t("trialPreferences.sessionPreferences")}</h3>
          <div className="mb-2">
          <strong>{t("trialPreferences.focusAreas")}:</strong>
            <div>
              {learningPreferences.sessionPreferences.focusAreas.map((area, index) => (
                <Tag key={index} color="green" className="m-1">
                  {area.replace(/_/g, ' ')}
                </Tag>
              ))}
            </div>
          </div>
        </Card>
      </div>
    </Modal>
  );
};
  
  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="min-h-screen bg-gray-50 pb-16 md:pb-0">
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 mb-6">
          <h1 className="text-2xl font-semibold text-gray-900">{t("TeacherBookings.head")}</h1>
          <p className="text-gray-500 mt-1">{t("TeacherBookings.manageClasses")}</p>

          <BookingFilters 
            selectedFilters={selectedFilters}
            onFilterChange={setSelectedFilters}
          />
          
          <div className="mt-4 flex flex-wrap gap-2">
            <Select
              style={{ width: 120 }}
              defaultValue="all"
              onChange={(value) => {
                const filtered = value === 'trial' 
                  ? flattenedBookings.filter(b => b.isTrial)
                  : value === 'regular'
                    ? flattenedBookings.filter(b => !b.isTrial)
                    : flattenedBookings;
                setFilteredBookingList(filtered);
              }}
            >
              <Select.Option value="all">{t("TeacherBookings.allBookings")}</Select.Option>
<Select.Option value="trial">{t("TeacherBookings.trialOnly")}</Select.Option>
<Select.Option value="regular">{t("TeacherBookings.regularOnly")}</Select.Option>
            </Select>

            <Button 
  onClick={() => dispatch(GetBookingsByTeacherID(teacher._id))}
  className="ml-auto"
>
  {t("TeacherBookings.refreshBookings")}
</Button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
  {filteredBookingList.map((booking) => (
    <BookingCard
      key={booking.slotId}
      booking={booking}
      onJoin={showJoinModal}
      onViewPreferences={handleViewTrialPreferences}
      onOpenNotes={handleOpenNotes} // Add this prop
      joiningRoom={joiningRoom}
      t={t}
      bookingRules={bookingRules}
      renderTime={renderTime}
      renderDate={renderDate}
      formatStudentName={formatStudentName}
    />
  ))}
</div>
      </div>

      <Modal
  title={t("TeacherBookings.joinMeeting")}
  open={isModalVisible}
  onCancel={() => setIsModalVisible(false)}
  footer={null}
>
        <div>
          <p className="mb-4">{t("TeacherBookings.joinPrompt")}</p>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Input.Group compact>
            <Input
  style={{ width: 'calc(100% - 32px)' }}
  value={selectedBookingId}
  readOnly
  placeholder={t("TeacherBookings.bookingIdPlaceholder")}
/>
<Tooltip title={t("TeacherBookings.copyBookingId")}>
                <Button icon={<CopyOutlined />} onClick={copyBookingId} />
              </Tooltip>
            </Input.Group>
            <Space align="center" style={{ width: '100%', justifyContent: 'space-between' }}>
            <Button 
  type="primary"
  onClick={() => handleJoinChoice('browser')}
  icon={<GlobalOutlined />}
>
  {t("TeacherBookings.joinBrowser")}
</Button>
              <Space>
                <Button 
                  onClick={() => handleJoinChoice('windows')}
                  icon={<WindowsOutlined />}
                >
                  {t("TeacherBookings.joinWindows")}
                </Button>
                {!isAppDownloaded && (
                  <Tooltip title={t("TeacherBookings.downloadApp")}>
                    <Button 
                      icon={<DownloadOutlined />} 
                      onClick={downloadApp}
                      type="text"
                    />
                  </Tooltip>
                )}
              </Space>
            </Space>
          </Space>
        </div>
        
      </Modal>
      <TeacherNotesModal
  isVisible={isNotesModalVisible}
  onClose={() => setIsNotesModalVisible(false)}
  bookingId={selectedBookingId}
  studentId={selectedStudent?.id}
  studentName={selectedStudent?.name}
/>
    </div>
  );
};

export default TeacherBookings;
import React, { useState, useMemo, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { useTranslation } from "react-i18next";
import { async_removeuser } from "../store/actions/studentsActions";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { useWebSocket } from './hooks/useWebSocket';
import { createBugReport, imageUpload } from "../store/actions/teachersActions";
import ChatPopup from './admin-dashboard-components/ChatPopup';
// Import framer-motion for animations
import { motion, AnimatePresence } from "framer-motion";

const StudentDashboard = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [isPinned, setPinned] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("global");
  const student = useSelector((state) => state.students.user);
  const teachers = useSelector((state) => state.teachers.Teacherslist);
  const Bookings = useSelector((state) => state.bookings.StudentID_Booking);
  const [showBetaNotice, setShowBetaNotice] = useState(false);
  const [showBugModal, setShowBugModal] = useState(false);
  const [formData, setFormData] = useState({ title: '', description: '' });
  const [files, setFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const { loading = false, error = null } = useSelector((state) => state.teacher || {});
  const [previewImage, setPreviewImage] = useState(null);

  // Animation variants
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5 } }
  };

  const slideUp = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 0.4 } }
  };

  const staggerChildren = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const popIn = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: { 
      scale: 1, 
      opacity: 1, 
      transition: { 
        type: "spring", 
        stiffness: 260, 
        damping: 20 
      } 
    }
  };

  const handleBugReportClose = () => {
    setShowBugModal(false);
    setFormData({ title: '', description: '' });
    setFiles([]);
    setSubmitError('');
    setSubmitSuccess(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles.slice(0, 3)); // Limit to 3 files
  };

  const handleSubmitBug = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitError('');

    try {
      let imageName = null;
      if (files.length > 0) {
        const imageResponse = await dispatch(imageUpload(files[0]));
        if (imageResponse.payload) {
          imageName = imageResponse.payload;
        }
      }

      const bugData = {
        title: formData.title,
        description: formData.description,
        imageName: imageName
      };

      await dispatch(createBugReport(bugData)).unwrap();
      
      setSubmitSuccess(true);
      setTimeout(() => {
        handleBugReportClose();
      }, 2000);

    } catch (error) {
      setSubmitError(error.message || t('bugReport.submitError'));
    } finally {
      setIsSubmitting(false);
    }
  };
  
  const chatUsers = useMemo(() => {
    const teacherIdsFromBookings = new Set();
    
    Bookings?.forEach(booking => {
      booking.Teacher_ID?.forEach(teacher => {
        if (teacher._id) {
          teacherIdsFromBookings.add(teacher._id);
        }
      });
    });

    return [
      ...teachers.filter(teacher => teacherIdsFromBookings.has(teacher._id))
        .map(teacher => ({
          id: teacher._id,
          name: teacher.Username,
          email: teacher.Email,
          type: 'teacher',
          avatar: teacher.Profile_Image?.[0] ? 
            `https://speakable.online/api/image/${teacher.Profile_Image[0]}` : 
            null
        })),
      {
        id: '667fd808df2795ef057c93b5',
        name: 'admin',
        email: 'admin@speakable.com',
        type: 'admin',
        avatar: null
      }
    ];
  }, [teachers, Bookings]);

  const themeColors = {
    dashboard: { bg: 'bg-blue-100', text: 'text-blue-700', hover: 'hover:bg-blue-50' },
    profile: { bg: 'bg-purple-100', text: 'text-purple-700', hover: 'hover:bg-purple-50' },
    bookings: { bg: 'bg-emerald-100', text: 'text-emerald-700', hover: 'hover:bg-emerald-50' },
    payments: { bg: 'bg-amber-100', text: 'text-amber-700', hover: 'hover:bg-amber-50' },
    // enquiries: { bg: 'bg-rose-100', text: 'text-rose-700', hover: 'hover:bg-rose-50' }
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('i18nextLng');
    if (savedLanguage && savedLanguage !== i18n.language) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const languages = [
    { value: 'en', label: t('languages.English'), flag: '🇬🇧' },
    { value: 'pl', label: t('languages.Polish'), flag: '🇵🇱' },
  ];

  const handleLogout = async () => {
    try {
      await dispatch(async_removeuser());
      dispatch({ type: 'LOGOUT_USER' });
      localStorage.removeItem('token');
      sessionStorage.clear();
      navigate('/');
    } catch (error) {
      console.error('Error during logout:', error);
      dispatch({ type: 'LOGOUT_USER' });
      localStorage.removeItem('token');
      sessionStorage.clear();
      navigate('/');
    }
  };

  // Add this function to handle image clicks
  const handleImageClick = (file) => {
    setPreviewImage(URL.createObjectURL(file));
  };

  // Add this function to close the preview
  const closePreview = () => {
    setPreviewImage(null);
  };

  const handleLanguageChange = (selectedOption) => {
    const newLang = selectedOption.value;
    i18n.changeLanguage(newLang);
    localStorage.setItem('i18nextLng', newLang);
    setShowMobileMenu(false);
  };

  const getCurrentLanguage = () => {
    const currentLang = i18n.language || localStorage.getItem('i18nextLng') || 'en';
    return languages.find(lang => lang.value === currentLang) || languages[0];
  };

  const getTheme = (path) => {
    if (path.includes("dash")) return themeColors.dashboard;
    if (path.includes("profile")) return themeColors.profile;
    if (path.includes("Bookings")) return themeColors.bookings;
    if (path.includes("Payments") || path.includes("Wallet")) return themeColors.payments;
    return themeColors.dashboard; // Default
  };

  const handleSidebarMouseEnter = () => {
    if (!isPinned) setCollapsed(false);
  };

  const handleSidebarMouseLeave = () => {
    if (!isPinned) setCollapsed(true);
  };

  const togglePin = () => {
    setPinned(!isPinned);
    if (!isPinned) setCollapsed(false);
  };

  const selectStyles = {
    control: (base) => ({
      ...base,
      background: 'transparent',
      border: '1px solid rgb(229 231 235)',
      borderRadius: '1rem',
      padding: '2px',
      boxShadow: 'none',
      minHeight: '2rem',
      '&:hover': {
        borderColor: getTheme(location.pathname).text
      }
    }),
    option: (base, { isSelected }) => ({
      ...base,
      backgroundColor: isSelected ? getTheme(location.pathname).bg : 'white',
      color: isSelected ? getTheme(location.pathname).text : 'gray',
      fontSize: '0.875rem',
      '&:hover': {
        backgroundColor: getTheme(location.pathname).hover
      }
    }),
    singleValue: (base) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      fontSize: '0.875rem'
    }),
    menu: (base) => ({
      ...base,
      fontSize: '0.875rem'
    })
  };

  const menuItems = [
    { label: t('StudentDash.panelheading1'), icon: "bar-chart-fill", path: "/Student-dashboard/dash" },
    { label: t('StudentDash.panelheading2'), icon: "person-fill", path: "/Student-dashboard/profile" },
    { label: t('StudentDash.panelheading4'), icon: "calendar-check", path: "/Student-dashboard/Bookings" },
    { label: t('StudentDash.panelheading5'), icon: "credit-card", path: "/Student-dashboard/Payments" },
    // { label: t('StudentDash.panelheading8'), icon: "wallet2", path: "/Student-dashboard/Wallet" },
    { label: t("StudentDash.PurchasePackages"), icon: "bag-plus", path: "/Student-dashboard/purchase-packages" },
    { label: t("StudentDash.MyPackages"), icon: "box-seam", path: "/Student-dashboard/my-packages" },
    { label: t("StudentDash.WalletHistory"), icon: "clock-history", path: "/Student-dashboard/wallet-history" },
    { 
      label: t('StudentDash.referrals') || 'Referrals', 
      icon: "people-fill", 
      path: "/Student-dashboard/referrals" 
    },
    // { label: t('StudentDash.panelheading6'), icon: "chat-dots", path: "/Student-dashboard/Enquirys" },
    { label: t('StudentDash.panelheading7'), icon: "box-arrow-right", path: null, onClick: handleLogout }
  ];

  const mobileMenuItems = [
    { label: t('StudentDash.panelheading1'), icon: "bar-chart-fill", path: "/Student-dashboard/dash" },
    { label: t('StudentDash.panelheading2'), icon: "person-fill", path: "/Student-dashboard/profile" },
    { label: t('StudentDash.panelheading4'), icon: "calendar-check", path: "/Student-dashboard/Bookings" },
    // New items shown on main bar
    { label: t("StudentDash.PurchasePackages"), icon: "bag-plus", path: "/Student-dashboard/purchase-packages" },
    { label: t("StudentDash.MyPackages"), icon: "box-seam", path: "/Student-dashboard/my-packages" },
    { label: t('common.more'), icon: "three-dots", onClick: () => setShowMobileMenu(!showMobileMenu) }
  ];
  
  // Define the dropdown menu items
  const dropdownMenuItems = [
    { label: t('StudentDash.panelheading5'), icon: "credit-card", path: "/Student-dashboard/Payments" },
    { 
      label: t('StudentDash.referrals') || 'Referrals', 
      icon: "people-fill", 
      path: "/Student-dashboard/referrals" 
    },
    { label: t("StudentDash.WalletHistory"), icon: "clock-history", path: "/Student-dashboard/wallet-history" },
    { label: t('StudentDash.panelheading7'), icon: "box-arrow-right", path: null, onClick: handleLogout }
  ];
  
  const currentLanguage = getCurrentLanguage();

  return (
    <div className="flex min-h-screen bg-gray-50/95 relative">
      {/* Beta Testing Notice */}
      <div className="fixed top-4 right-4 z-50">
        <div className="relative">
          <motion.button
            onClick={() => setShowBetaNotice(!showBetaNotice)}
            className="bg-red-500 text-white px-3 py-1 rounded-full text-sm font-medium shadow-lg hover:bg-red-600 transition-colors flex items-center gap-1"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", stiffness: 300, damping: 15 }}
          >
            <i className="bi bi-bug-fill text-xs" />
            <span>Beta</span>
          </motion.button>
          
          <AnimatePresence>
            {showBetaNotice && (
              <motion.div 
                className="absolute right-0 top-full mt-2 w-64 bg-white rounded-lg shadow-lg p-4 border border-gray-200"
                initial={{ opacity: 0, y: -10, scale: 0.95 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{ opacity: 0, y: -10, scale: 0.95 }}
                transition={{ duration: 0.2 }}
              >
                <button
                  onClick={() => setShowBetaNotice(false)}
                  className="absolute top-1 right-1 text-gray-400 hover:text-gray-600"
                >
                  <i className="bi bi-x-lg text-sm" />
                </button>
                <p className="text-sm text-gray-700 mb-2">
                  {t('beta.notice')}
                </p>
                <motion.button
                  onClick={() => {
                    setShowBetaNotice(false);
                    setShowBugModal(true);
                  }}
                  className="text-blue-600 hover:text-blue-800 text-sm underline"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {t('beta.report')}
                </motion.button>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      
      {/* Desktop Sidebar - FIXED VERSION */}
      <div className="hidden md:block relative">
        <div 
          className="transition-all duration-500 ease-in-out transform sticky top-0"
          onMouseEnter={handleSidebarMouseEnter}
          onMouseLeave={handleSidebarMouseLeave}
        >
          <Sidebar
            collapsed={collapsed}
            width="min(300px, 30vw)"
            collapsedWidth="min(80px, 10vw)"
            className="h-screen"
            backgroundColor="transparent"
            rootStyles={{
              border: 'none',
              minHeight: '100vh',
              position: 'relative'
            }}
          >
            <motion.div 
              className="h-full bg-white/90 backdrop-blur-sm shadow-xl rounded-r-3xl relative overflow-y-auto border-r border-gray-100"
              initial={{ x: -30, opacity: 0.5 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, ease: "easeOut" }}
            >
              <div className={`absolute top-0 left-0 w-full h-40 ${getTheme(location.pathname).bg} transition-colors duration-300`} />
              
              <div className="relative pt-4 md:pt-8 pb-3 md:pb-6 px-3 md:px-6">
                <div className="flex items-center justify-between">
                  <motion.h3 
                    className={`text-xl md:text-2xl font-bold ${getTheme(location.pathname).text} transition-all duration-300 flex items-center gap-2 md:gap-3 ${collapsed ? 'justify-center' : ''}`}
                    initial={false}
                    animate={collapsed ? { scale: 1.2 } : { scale: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    <motion.span 
                      className="text-2xl md:text-3xl"
                      animate={{ rotate: [0, 10, 0, -10, 0] }}
                      transition={{ duration: 1, repeat: 0, repeatDelay: 5 }}
                    >
                      🎓
                    </motion.span>
                    <span className={`transition-all duration-300 ${collapsed ? 'opacity-0 scale-0 w-0' : 'opacity-100 scale-100 w-auto'}`}>
                      {t('StudentDash.heading')}
                    </span>
                  </motion.h3>
                  {!collapsed && (
                    <motion.button
                      onClick={togglePin}
                      className={`p-2 rounded-lg transition-colors duration-200 ${
                        isPinned 
                          ? `${getTheme(location.pathname).bg} ${getTheme(location.pathname).text}`
                          : 'text-gray-400 hover:bg-gray-100'
                      }`}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <i className={`bi bi-pin${isPinned ? '-fill' : ''} text-lg`} />
                    </motion.button>
                  )}
                </div>

                {!collapsed && (
                  <motion.div 
                    className="mt-2 md:mt-4"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 }}
                  >
                    <Select
                      options={languages}
                      defaultValue={currentLanguage}
                      onChange={handleLanguageChange}
                      styles={selectStyles}
                      formatOptionLabel={({ label, flag }) => (
                        <div className="flex items-center gap-2">
                          <span>{flag}</span>
                          <span>{label}</span>
                        </div>
                      )}
                    />
                  </motion.div>
                )}
              </div>

              <Menu
                menuItemStyles={{
                  button: ({ active }) => {
                    const theme = getTheme(location.pathname);
                    return {
                      backgroundColor: active ? theme.bg : 'transparent',
                      backdropFilter: 'blur(8px)',
                      borderRadius: '1rem',
                      margin: '0.25rem 0.75rem',
                      padding: '0.75rem',
                      color: active ? theme.text : 'rgb(107 114 128)',
                      fontWeight: '500',
                      fontSize: '0.875rem',
                      transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                      transform: hoveredItem === active ? 'scale(1.05)' : 'scale(1)',
                      height: '44px',       /* Fixed height for menu items */
                      display: 'flex',      /* Ensures proper alignment */
                      alignItems: 'center', /* Vertically centers content */
                      '&:hover': {
                        backgroundColor: theme.hover,
                        color: theme.text,
                        transform: 'translateX(0.5rem)',
                        boxShadow: '0 8px 16px -4px rgb(0 0 0 / 0.1)',
                      },
                    };
                  },
                  icon: {
                    fontSize: '1.125rem',
                    transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                    minWidth: '24px',    /* Fixed width for icons */
                    display: 'flex',     /* Ensures proper alignment */
                    justifyContent: 'center', /* Centers icon horizontally */
                    alignItems: 'center', /* Centers icon vertically */
                    marginRight: '12px',  /* Consistent spacing */
                  }
                }}
              >
                {!collapsed && (
                  <motion.div 
                    className="px-6 py-4 text-xs font-bold text-gray-400 uppercase tracking-widest"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    {t('StudentDash.panel')}
                  </motion.div>
                )}
                
                <motion.div
                  variants={staggerChildren}
                  initial="hidden"
                  animate="visible"
                >
                  {menuItems.map((item, index) => (
                    <motion.div key={item.label} variants={slideUp}>
                      <MenuItem
                        icon={<i className={`bi bi-${item.icon}`} style={{ fontSize: '1.25rem' }} />}
                        active={location.pathname === item.path}
                        onClick={() => item.onClick ? item.onClick() : item.path && navigate(item.path)}
                        onMouseEnter={() => setHoveredItem(item.label)}
                        onMouseLeave={() => setHoveredItem(null)}
                      >
                        <span className="whitespace-nowrap overflow-hidden text-ellipsis">{item.label}</span>
                      </MenuItem>
                    </motion.div>
                  ))}
                </motion.div>
              </Menu>
            </motion.div>
          </Sidebar>
        </div>
      </div>

      {/* Main Content */}
      <main className="flex-1 min-h-screen overflow-y-auto relative">
        <motion.div 
          className="p-4 md:p-8 pb-24 md:pb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          key={location.pathname} // Animate when route changes
        >
          <Outlet />
        </motion.div>
      </main>

      {/* ChatPopup */}
      <div className="fixed bottom-20 md:bottom-6 right-4 md:right-6 z-50 max-w-[calc(100vw-2rem)]">
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.5, type: "spring", stiffness: 200 }}
        >
          <ChatPopup 
            currentUser={{
              id: student._id,
              name: `${student.firstName} ${student.lastName}`,
              type: 'student'
            }}
            availableUsers={chatUsers}
          />
        </motion.div>
      </div>

      {/* Mobile Navigation */}
      <div className="md:hidden fixed bottom-0 left-0 right-0 z-40">
        <AnimatePresence>
          {showMobileMenu && (
            <motion.div 
              className="absolute bottom-full w-full mb-2 px-4 max-h-[80vh] overflow-y-auto"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ type: "spring", stiffness: 300, damping: 25 }}
            >
              <motion.div 
                className="bg-white rounded-lg shadow-lg border border-gray-100"
                variants={staggerChildren}
                initial="hidden"
                animate="visible"
              >
                {/* Additional Menu Items */}
                <div className="py-2">
                  {dropdownMenuItems.map((item) => (
                    <motion.button
                      key={item.label}
                      onClick={item.onClick || (() => {
                        navigate(item.path);
                        setShowMobileMenu(false);
                      })}
                      className="w-full px-4 py-3 flex items-center gap-3 text-gray-600 hover:bg-gray-50 transition-colors duration-200"
                      variants={slideUp}
                      whileHover={{ x: 5 }}
                    >
                      <i className={`bi bi-${item.icon} text-lg`} />
                      <span>{item.label}</span>
                    </motion.button>
                  ))}
                </div>
                
                {/* Language Selection */}
                <motion.div 
                  className="px-4 py-3 border-t border-gray-100"
                  variants={fadeIn}
                >
                  <div className="text-sm font-medium text-gray-500 mb-2">{t('common.Language')}</div>
                  <div className="space-y-2">
                    {languages.map((lang) => (
                      <motion.button
                        key={lang.value}
                        onClick={() => handleLanguageChange(lang)}
                        className={`w-full px-3 py-2 rounded-lg flex items-center gap-3 transition-colors duration-200 ${
                          lang.value === currentLanguage.value
                            ? `${getTheme(location.pathname).bg} ${getTheme(location.pathname).text}`
                            : 'hover:bg-gray-50'
                        }`}
                        whileHover={{ x: 5 }}
                      >
                        <span className="text-lg">{lang.flag}</span>
                        <span>{lang.label}</span>
                        {lang.value === currentLanguage.value && (
                          <i className="bi bi-check2 ml-auto" />
                        )}
                      </motion.button>
                    ))}
                  </div>
                </motion.div>

                {/* Cancel Button */}
                <motion.button
                  onClick={() => setShowMobileMenu(false)}
                  className="w-full px-4 py-3 flex items-center gap-3 text-gray-600 hover:bg-gray-50 border-t border-gray-100 transition-colors duration-200"
                  variants={fadeIn}
                >
                  <i className="bi bi-x-lg text-lg" />
                  <span>{t('common.cancel')}</span>
                </motion.button>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Mobile Navigation Bar */}
        <motion.div 
          className="bg-white border-t border-gray-100 shadow-lg"
          initial={{ y: 80 }}
          animate={{ y: 0 }}
          transition={{ type: "spring", stiffness: 300, damping: 25 }}
        >
          <div className="flex justify-around items-center h-16">
            {mobileMenuItems.map((item, index) => {
              const isActive = item.path ? location.pathname === item.path : false;
              const theme = getTheme(location.pathname);
              
              return (
                <motion.button
                  key={item.label}
                  onClick={item.onClick || (() => navigate(item.path))}
                  className={`flex flex-col items-center justify-center w-full h-full px-2 ${
                    isActive ? `${theme.text} ${theme.bg}` : 'text-gray-500'
                  } transition-colors duration-200`}
                  whileHover={{ y: -3 }}
                  whileTap={{ scale: 0.95 }}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <i className={`bi bi-${item.icon} text-base md:text-lg`} />
                  <span className="text-xs mt-1">{item.label}</span>
                </motion.button>
              );
            })}
          </div>
        </motion.div>
      </div>

      {/* Bug Report Modal */}
      <AnimatePresence>
        {showBugModal && (
          <motion.div 
            className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div 
              className="bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto"
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              transition={{ type: "spring", damping: 25, stiffness: 300 }}
            >
              <div className="p-6">
                <div className="flex justify-between items-center mb-4">
                  <motion.h3 
                    className="text-xl font-bold"
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.2 }}
                  >
                    {t('bugReport.title')}
                  </motion.h3>
                  <motion.button
                    onClick={handleBugReportClose}
                    className="text-gray-500 hover:text-gray-700"
                    whileHover={{ rotate: 90 }}
                    transition={{ duration: 0.2 }}
                  >
                    <i className="bi bi-x-lg text-xl" />
                  </motion.button>
                </div>

                <AnimatePresence>
                  {submitSuccess && (
                    <motion.div 
                      className="mb-4 p-4 bg-green-100 text-green-700 rounded-lg"
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0 }}
                    >
                      {t('bugReport.success')}
                    </motion.div>
                  )}
                </AnimatePresence>

                <AnimatePresence>
                  {submitError && (
                    <motion.div 
                      className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg"
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0 }}
                    >
                      {submitError}
                    </motion.div>
                  )}
                </AnimatePresence>

                <motion.form 
                  onSubmit={handleSubmitBug} 
                  className="space-y-4"
                  variants={staggerChildren}
                  initial="hidden"
                  animate="visible"
                >
                  <motion.div variants={slideUp}>
                    <label className="block text-sm font-medium mb-1">
                      {t('bugReport.titleLabel')}
                    </label>
                    <input
                      type="text"
                      name="title"
                      value={formData.title}
                      onChange={handleInputChange}
                      className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-300 focus:border-blue-500 transition-all duration-200"
                      required
                    />
                  </motion.div>

                  <motion.div variants={slideUp}>
                    <label className="block text-sm font-medium mb-1">
                      {t('bugReport.descriptionLabel')}
                    </label>
                    <textarea
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                      rows="4"
                      className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-300 focus:border-blue-500 transition-all duration-200"
                      required
                    />
                  </motion.div>

                  <motion.div variants={slideUp}>
                    <label className="block text-sm font-medium mb-1">
                      {t('bugReport.attachmentsLabel')} ({t('bugReport.maxFiles')})
                    </label>
                    <div className="relative border-2 border-dashed border-gray-300 rounded-md p-4 transition-all duration-200 hover:border-blue-400">
                      <input
                        type="file"
                        onChange={handleFileChange}
                        multiple
                        accept="image/*"
                        className="hidden"
                        id="file-upload"
                      />
                      <label
                        htmlFor="file-upload"
                        className="inline-flex items-center px-4 py-2 bg-white border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 cursor-pointer transition-all duration-200 hover:border-blue-400 hover:text-blue-600"
                      >
                        <i className="bi bi-upload me-2" />
                        {t('bugReport.uploadLabel')}
                      </label>
                      
                      <AnimatePresence>
                        {files.length > 0 && (
                          <motion.div 
                            className="mt-4 grid grid-cols-3 gap-2"
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3 }}
                          >
                            {files.map((file, index) => (
                              <motion.div 
                                key={index} 
                                className="relative"
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0, scale: 0.8 }}
                                transition={{ delay: index * 0.1 }}
                                whileHover={{ scale: 1.05, zIndex: 1 }}
                              >
                                <img
                                  src={URL.createObjectURL(file)}
                                  alt={`Preview ${index + 1}`}
                                  className="h-24 w-full object-cover rounded-md"
                                  onClick={() => handleImageClick(file)}
                                />
                                <motion.button
                                  type="button"
                                  onClick={() => setFiles(files.filter((_, i) => i !== index))}
                                  className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                                  whileHover={{ scale: 1.2 }}
                                  whileTap={{ scale: 0.9 }}
                                >
                                  <i className="bi bi-x text-sm" />
                                </motion.button>
                              </motion.div>
                            ))}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  </motion.div>

                  <motion.button
                    type="submit"
                    disabled={loading || isSubmitting}
                    className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 disabled:bg-gray-400 transition-all duration-200"
                    variants={popIn}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    {loading || isSubmitting ? (
                      <div className="flex items-center justify-center">
                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        {t('bugReport.submitting')}
                      </div>
                    ) : t('bugReport.submitButton')}
                  </motion.button>
                </motion.form>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Image Preview Modal */}
      <AnimatePresence>
        {previewImage && (
          <motion.div 
            className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center p-4"
            onClick={closePreview}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div 
              onClick={e => e.stopPropagation()} 
              className="relative max-w-4xl max-h-[90vh]"
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.5, opacity: 0 }}
              transition={{ type: "spring", damping: 25 }}
            >
              <motion.button
                onClick={closePreview}
                className="absolute top-2 right-2 bg-white rounded-full p-2 text-gray-800 hover:bg-gray-200"
                whileHover={{ scale: 1.1, rotate: 90 }}
                whileTap={{ scale: 0.9 }}
              >
                <i className="bi bi-x-lg" />
              </motion.button>
              <motion.img 
                src={previewImage} 
                alt="Preview" 
                className="max-w-full max-h-[85vh] object-contain rounded-lg"
                initial={{ y: 20 }}
                animate={{ y: 0 }}
              />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default StudentDashboard;
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  Card, 
  Button, 
  Modal, 
  Form, 
  Input, 
  Rate, 
  Space, 
  List, 
  Tag, 
  Typography, 
  message, 
  Row, 
  Col, 
  Avatar,
  Select
} from 'antd';
import { 
  StarOutlined, 
  UserOutlined, 
  BookOutlined, 
  EditOutlined, 
  DeleteOutlined,
  PlusOutlined 
} from '@ant-design/icons';
import moment from 'moment';
import { 
  createEvaluation,
  getStudentEvaluations,
  updateEvaluation,
  deleteEvaluation,
  getEvaluationStats
} from '../../store/actions/evaluationActions';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;
const { Title, Text } = Typography;
const { Option } = Select;

const TeacherEvaluation = ({ booking, teacher }) => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [currentEvaluationId, setCurrentEvaluationId] = useState(null);
  const { t } = useTranslation('global'); 

  // Get evaluations from Redux store
  const evaluations = useSelector((state) => state.evaluations?.studentEvaluations);
  const evaluationStats = useSelector((state) => state.evaluations?.evaluationStats);
  const evaluationLoading = useSelector((state) => state.evaluations?.loading);

  // Fetch evaluations when booking is selected
  useEffect(() => {
    if (selectedBooking?.Student_ID?._id) {
      dispatch(getStudentEvaluations(selectedBooking.Student_ID._id));
      dispatch(getEvaluationStats(selectedBooking.Student_ID._id));
    }
  }, [dispatch, selectedBooking]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Completed': return '#722ed1';
      case 'In Progress': return '#faad14';
      case 'Scheduled': return '#52c41a';
      case 'Cancelled': return '#ff4d4f';
      case 'Rescheduled': return '#1890ff';
      case 'Trial': return '#13c2c2';
      default: return '#d9d9d9';
    }
  };

  const renderBookingDateTime = (dateObj) => {
    if (!dateObj) return null;
    const date = Object.keys(dateObj)[0];
    const slot = dateObj[date][0];
    
    return (
      <div>
        <Text type="secondary">
          {moment(date).format('ddd MMM DD YYYY')}
        </Text>
        <br />
        <Text>
          {slot.start} - {slot.end}
        </Text>
      </div>
    );
  };

  const handleEdit = (evaluation) => {
    setIsEditing(true);
    setCurrentEvaluationId(evaluation._id);
    form.setFieldsValue({
      overallScore: evaluation.overallScore,
      speaking: evaluation.skillAssessments?.speaking,
      listening: evaluation.skillAssessments?.listening,
      grammar: evaluation.skillAssessments?.grammar,
      vocabulary: evaluation.skillAssessments?.vocabulary,
      pronunciation: evaluation.skillAssessments?.pronunciation,
      strengths: evaluation.strengths,
      areasToImprove: evaluation.areasToImprove,
      recommendations: evaluation.recommendations,
      learningObjectivesAchieved: evaluation.learningObjectivesAchieved,
      nextLessonObjectives: evaluation.nextLessonObjectives
    });
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      const resultAction = await dispatch(deleteEvaluation(id));
      if (!resultAction.error) {
        message.success('Evaluation deleted successfully');
        if (selectedBooking?.Student_ID?._id) {
          dispatch(getStudentEvaluations(selectedBooking.Student_ID._id));
          dispatch(getEvaluationStats(selectedBooking.Student_ID._id));
        }
      } else {
        throw new Error(resultAction.error);
      }
    } catch (error) {
      console.error('Error deleting evaluation:', error);
      message.error('Failed to delete evaluation');
    }
  };

  const EvaluationForm = () => (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={{ overallScore: 0 }}
    >
      <Form.Item
  name="overallScore"
  label={t('evaluations.overallScore')}
  rules={[{ required: true, message: t('evaluations.pleaseProvideOverallScore') }]}
>
  <Rate count={5} />
</Form.Item>

<Form.Item
  name="generalComments"
  label={t('evaluations.generalComments')}
  rules={[{ required: true, message: t('evaluations.pleaseProvideGeneralComments') }]}
>
  <TextArea rows={3} placeholder={t('evaluations.overallFeedbackPlaceholder')} />
</Form.Item>

      {/* Criteria Assessments */}
      <Title level={5}>{t('evaluations.assessmentCriteria')}</Title>
      <Form.List name="criteria">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                <Form.Item
  {...restField}
  name={[name, 'name']}
  rules={[{ required: true, message: t('evaluations.missingCriterionName') }]}
>
  <Input placeholder={t('evaluations.criterionNamePlaceholder')} />
</Form.Item>
<Form.Item
  {...restField}
  name={[name, 'score']}
  rules={[{ required: true, message: t('evaluations.missingScore') }]}
>
  <Rate count={5} />
</Form.Item>
<Form.Item
  {...restField}
  name={[name, 'comments']}
  rules={[{ required: true, message: t('evaluations.missingComments') }]}
>
  <Input placeholder={t('evaluations.commentsPlaceholder')} />
</Form.Item>
<Button onClick={() => remove(name)} icon={<DeleteOutlined />} />
              </Space>
            ))}
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
  {t('evaluations.addAssessmentCriterion')}
</Button>
          </>
        )}
      </Form.List>

      {/* Strengths and Improvements */}
      <Row gutter={16}>
        <Col span={12}>
          <Form.List name={['areas', 'strengths']}>
            {(fields, { add, remove }) => (
              <div style={{ marginBottom: 16 }}>
                <Title level={5}>{t('evaluations.strengths')}</Title>
                {fields.map((field, index) => (
                  <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }}>
                    <Form.Item
  {...field}
  validateTrigger={['onChange', 'onBlur']}
  rules={[{ required: true, message: t('evaluations.pleaseInputStrength') }]}
>
  <Input placeholder={t('evaluations.studentsStrengthPlaceholder')} />
</Form.Item>
                    <Button onClick={() => remove(field.name)} icon={<DeleteOutlined />} />
                  </Space>
                ))}
                <Button type="dashed" onClick={() => add()} block>
  {t('evaluations.addStrength')}
</Button>
              </div>
            )}
          </Form.List>
        </Col>
        <Col span={12}>
          <Form.List name={['areas', 'improvements']}>
            {(fields, { add, remove }) => (
              <div style={{ marginBottom: 16 }}>
                <Title level={5}>{t('evaluations.areasForImprovement')}</Title>
                {fields.map((field, index) => (
                  <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }}>
                    <Form.Item
  {...field}
  validateTrigger={['onChange', 'onBlur']}
  rules={[{ required: true, message: t('evaluations.pleaseInputImprovement') }]}
>
  <Input placeholder={t('evaluations.areaToImprovePlaceholder')} />
</Form.Item>
                    <Button onClick={() => remove(field.name)} icon={<DeleteOutlined />} />
                  </Space>
                ))}
                <Button type="dashed" onClick={() => add()} block>
  {t('evaluations.addImprovementArea')}
</Button>
              </div>
            )}
          </Form.List>
        </Col>
      </Row>

      {/* Goals */}
      <Title level={5}>{t('evaluations.learningGoals')}</Title>
      <Form.List name="goals">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                <Form.Item
  {...restField}
  name={[name, 'description']}
  rules={[{ required: true, message: t('evaluations.missingGoalDescription') }]}
>
  <Input placeholder={t('evaluations.goalDescriptionPlaceholder')} />
</Form.Item>
<Form.Item
  {...restField}
  name={[name, 'timeframe']}
  rules={[{ required: true, message: t('evaluations.missingTimeframe') }]}
>
  <Input placeholder={t('evaluations.timeframePlaceholder')} />
</Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'status']}
                  initialValue="pending"
                >
                  <Select style={{ width: 120 }}>
                  <Option value="pending">{t('evaluations.pending')}</Option>
  <Option value="in-progress">{t('evaluations.inProgress')}</Option>
  <Option value="completed">{t('evaluations.completed')}</Option>
                  </Select>
                </Form.Item>
                <Button onClick={() => remove(name)} icon={<DeleteOutlined />} />
              </Space>
            ))}
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
  {t('evaluations.addGoal')}
</Button>
          </>
        )}
      </Form.List>

      {/* Next Steps */}
      <Title level={5}>{t('evaluations.nextSteps')}</Title>
      <Form.List name="nextSteps">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space key={key} style={{ display: 'flex', marginBottom: 8 }}>
                <Form.Item
  {...restField}
  name={name}
  rules={[{ required: true, message: t('evaluations.pleaseInputNextStep') }]}
>
  <Input placeholder={t('evaluations.nextStepPlaceholder')} />
</Form.Item>
                <Button onClick={() => remove(name)} icon={<DeleteOutlined />} />
              </Space>
            ))}
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
  {t('evaluations.addNextStep')}
</Button>
          </>
        )}
      </Form.List>
    </Form>
  );

  const handleSubmit = async (values) => {
    if (!selectedBooking) {
      message.error(t('evaluations.pleaseSelectBooking'));
      return;
    }

    setLoading(true);
    try {
      // Validate required fields
      if (!teacher?._id || !selectedBooking?.Student_ID?._id || !selectedBooking?._id) {
        throw new Error(t('evaluations.missingRequiredIds'));
      }

      // Format the evaluation data to match API requirements
      const evaluationData = {
        teacherId: teacher._id,
        studentId: selectedBooking.Student_ID._id,
        lessonId: selectedBooking._id,
        overallScore: values.overallScore || 0,
        generalComments: values.generalComments,
        criteria: values.criteria || [],
        areas: {
          strengths: values.areas?.strengths || [],
          improvements: values.areas?.improvements || []
        },
        goals: values.goals || [],
        nextSteps: values.nextSteps || []
      };

      console.log('Submitting evaluation data:', evaluationData);

      let resultAction;
      if (isEditing) {
        resultAction = await dispatch(updateEvaluation({
          id: currentEvaluationId,
          updatedData: evaluationData
        }));
      } else {
        resultAction = await dispatch(createEvaluation(evaluationData));
      }

      if (resultAction.error) {
        throw new Error(resultAction.error);
      }

      message.success(t(isEditing ? 'evaluations.evaluationUpdated' : 'evaluations.evaluationAdded'));
      setIsModalVisible(false);
      form.resetFields();
      setSelectedBooking(null);
      
      // Refresh evaluations
      if (selectedBooking?.Student_ID?._id) {
        dispatch(getStudentEvaluations(selectedBooking.Student_ID._id));
        dispatch(getEvaluationStats(selectedBooking.Student_ID._id));
      }
    } catch (error) {
      console.error('Error saving evaluation:', error);
      console.error('Error details:', error.response?.data || error.message);
      message.error(
        t(isEditing ? 'evaluations.failedToUpdate' : 'evaluations.failedToSave', 
          { error: error.response?.data?.message || error.message })
      );
    } finally {
      setLoading(false);
    }
  };

  const renderEvaluationList = (studentId) => {
    const studentEvaluations = evaluations?.filter(evaluation => evaluation.studentId === studentId);
    if (!studentEvaluations?.length) return null;

    return (
      <List
        size="small"
        dataSource={studentEvaluations}
        renderItem={evaluation => (
          <List.Item
            actions={[
              <Button 
  icon={<EditOutlined />} 
  type="link"
  onClick={() => handleEdit(evaluation)}
>
  {t('common.edit')}
</Button>,
              <Button 
              icon={<DeleteOutlined />} 
              type="link" 
              danger
              onClick={() => handleDelete(evaluation._id)}
            >
              {t('common.delete')}
            </Button>
            ]}
          >
            <List.Item.Meta
              avatar={<Rate disabled value={evaluation.overallScore} />}
              title={t('evaluations.evaluationFromDate', { date: moment(evaluation.createdAt).format('MMM DD, YYYY') })}
              description={evaluation.strengths}
            />
          </List.Item>
        )}
      />
    );
  };

  return (
    <Card title={<Title level={4}>{t('evaluations.meetingsAndEvaluations')}</Title>}>
      <List
        loading={evaluationLoading}
        itemLayout="horizontal"
        dataSource={booking}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
  type="primary"
  onClick={() => {
    setSelectedBooking(item);
    setIsModalVisible(true);
    setIsEditing(false);
    form.resetFields();
  }}
  icon={<StarOutlined />}
>
  {t('evaluations.addEvaluation')}
</Button>
            ]}
          >
            <List.Item.Meta
              avatar={
                <Avatar 
                  size="large"
                  icon={<UserOutlined />}
                  style={{ backgroundColor: getStatusColor(item.Status) }}
                />
              }
              title={
                <Space>
                  {item.Student_ID?.Username || t('evaluations.unknownStudent')}
                  <Tag color={getStatusColor(item.Status)}>
  {t(`status.${item.Status}`)}
</Tag>
                </Space>
              }
              description={
                <div>
                  {item.Scheduled_Dates?.map((dateObj, index) => (
                    <div key={index}>
                      {renderBookingDateTime(dateObj)}
                    </div>
                  ))}
                  {renderEvaluationList(item.Student_ID?._id)}
                </div>
              }
            />
          </List.Item>
        )}
        locale={{
          emptyText: (
            <div className="text-center p-6">
              <BookOutlined className="text-2xl mb-2" />
              <Text>{t('evaluations.noMeetingsAvailable')}</Text>
            </div>
          )
        }}
      />

      <Modal
       title={isEditing ? t('evaluations.editStudentEvaluation') : t('evaluations.addStudentEvaluation')}
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setIsEditing(false);
          form.resetFields();
          setSelectedBooking(null);
        }}
        footer={[
          <Button 
  key="cancel" 
  onClick={() => {
    setIsModalVisible(false);
    setIsEditing(false);
    form.resetFields();
    setSelectedBooking(null);
  }}
>
{t('common.cancel')}
          </Button>,
          <Button 
          key="submit" 
          type="primary"
          loading={loading}
          onClick={() => form.submit()}
        >
          {isEditing ? t('common.update') : t('common.submit')} {t('evaluations.evaluation')}
        </Button>
        ]}
        width={800}
      >
        <div className="mb-4">
        <Text strong>{t('evaluations.student')}: </Text>
<Text>{selectedBooking?.Student_ID?.Username}</Text>
<br />
<Text strong>{t('evaluations.date')}: </Text>
          <Text>
            {selectedBooking?.Scheduled_Dates?.[0] && 
             renderBookingDateTime(selectedBooking.Scheduled_Dates[0])}
          </Text>
        </div>
        <EvaluationForm />
      </Modal>
    </Card>
  );
};

export default TeacherEvaluation;
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { purchaseHours } from "../../store/actions/walletActions";
import { fetchAllpackages, addFreeHour, getStudentFreeHourUsage } from "../../store/actions/packagesActions";
import { FaSearch, FaStar, FaCheck, FaGraduationCap, FaLightbulb, FaRocket, FaUsers, FaChalkboardTeacher, FaBookOpen, FaClock, FaChevronDown, FaChevronUp  } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useCurrencyConversion } from "../currencyConversionUtil";
import Loader2 from "../Loader2";
import AdminNav from "../admin-dashboard-components/AdminNav";
import PurchaseSteps from './PurchaseSteps';
import { useTranslation } from "react-i18next";
import { BookOpen, Clock, GraduationCap, ChevronDown, ChevronUp, CheckCircle } from 'lucide-react';


const PackageCard = ({ pack, onPurchaseClick, convertPrice, calculatePackagePricing }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { lectureOptions = [] } = calculatePackagePricing(pack) || {};
  const { t } = useTranslation("global");

  
  // Safety check for empty lecture options
  const hasValidLectureOptions = lectureOptions && lectureOptions.length > 0;
  const firstOption = hasValidLectureOptions ? lectureOptions[0] : null;

  // Helper function to safely calculate per lecture price
  const calculatePerLecturePrice = () => {
    if (!firstOption || !firstOption.count || !firstOption.finalPrice) return null;
    return convertPrice(firstOption.finalPrice / firstOption.count);
  };

  return (
    <div className="group relative bg-white rounded-2xl shadow-lg hover:shadow-2xl transition-all duration-300">
      {/* Top gradient bar */}
      <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-violet-600 to-blue-600 rounded-t-2xl"></div>

      {/* Free badge */}
      {pack.Free_Package && (
        <div className="absolute -top-3 right-4 bg-gradient-to-r from-violet-600 to-blue-600 text-white px-6 py-1 rounded-full text-sm font-semibold shadow-lg">
          {t("StudentWallet.FreeHours")}
        </div>
      )}

      <div className="p-6 space-y-6">
        {/* Header */}
        <div className="space-y-2">
          <h2 className="text-2xl font-bold text-gray-900">{pack.Package_Name}</h2>
          {!pack.Free_Package && hasValidLectureOptions && (
            <div className="space-y-1">
              <div className="text-3xl font-bold bg-gradient-to-r from-violet-600 to-blue-600 bg-clip-text text-transparent">
                {lectureOptions.length === 1 
                  ? convertPrice(firstOption.finalPrice)
                  : t("StudentWallet.Price") + ' ' + convertPrice(firstOption.finalPrice)}
              </div>
              {calculatePerLecturePrice() && (
                <div className="flex items-center gap-2">
                  <div className="text-lg font-semibold text-gray-600">
                    {calculatePerLecturePrice()} {t("StudentWallet.PerSession")}
                  </div>
                  {firstOption.discount > 0 && (
                    <span className="bg-green-100 text-green-700 text-sm font-medium px-2 py-0.5 rounded-full">
                      {firstOption.discount}% {t("StudentWallet.Off")}
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        {/* Features */}
        <div className="space-y-4">
          {/* Lecture Options */}
          {hasValidLectureOptions && (
            <div className="relative">
              <button 
                onClick={() => setIsExpanded(!isExpanded)}
                className="flex items-center gap-3 w-full p-3 rounded-xl hover:bg-gray-50 transition-colors text-gray-700 group"
              >
                <div className="w-8 h-8 rounded-lg bg-violet-100 flex items-center justify-center">
                  <GraduationCap className="text-violet-600 w-5 h-5" />
                </div>
                <span className="flex-grow text-left font-medium">{t("StudentWallet.ChooseHours")}</span>
                {isExpanded ? (
                  <ChevronUp className="w-5 h-5 text-gray-400" />
                ) : (
                  <ChevronDown className="w-5 h-5 text-gray-400" />
                )}
              </button>

              {isExpanded && (
                <div className="absolute top-full left-0 right-0 z-10 mt-2 bg-white rounded-xl shadow-xl border border-gray-100">
                  {lectureOptions.map((option, index) => (
                    <div 
                      key={index}
                      className="p-4 flex justify-between items-center hover:bg-gray-50 transition-colors"
                    >
                      <span className="font-medium text-gray-700">{option.count} {t("StudentWallet.Hours")}</span>
                      <div className="text-right">
                        {option.discount > 0 && (
                          <div className="text-sm line-through text-gray-400">
                            {convertPrice(option.basePrice)}
                          </div>
                        )}
                        <div className="font-bold text-violet-600">
                          {convertPrice(option.finalPrice)}
                        </div>
                        {option.discount > 0 && (
                          <div className="text-xs text-green-600 font-medium">
                            {t("StudentWallet.Discount")} {option.discount}%
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          {/* Courses */}
          <div className="flex items-center gap-3 p-3 rounded-xl hover:bg-gray-50 transition-colors">
            <div className="w-8 h-8 rounded-lg bg-blue-100 flex items-center justify-center">
              <BookOpen className="text-blue-600 w-5 h-5" />
            </div>
            <div>
              <div className="font-medium text-gray-700">
                {pack.Course_IDs?.length || 0} {t("StudentWallet.PurchasedPackages")}
              </div>
              <div className="text-sm text-gray-500">
                {t("StudentWallet.ChooseThePerfect")}
              </div>
            </div>
          </div>

          {/* Lifetime Access */}
          <div className="flex items-center gap-3 p-3 rounded-xl hover:bg-gray-50 transition-colors">
            <div className="w-8 h-8 rounded-lg bg-violet-100 flex items-center justify-center">
              <Clock className="text-violet-600 w-5 h-5" />
            </div>
            <div>
              <div className="font-medium text-gray-700">
                {t("StudentWallet.FullPackageOnly")}
              </div>
              <div className="text-sm text-gray-500">
                {t("StudentWallet.ChooseThePerfect")}
              </div>
            </div>
          </div>
        </div>

        {/* Action Button */}
        <div className="space-y-3 pt-2">
          <button
            onClick={() => onPurchaseClick(pack)}
            className="w-full bg-gradient-to-r from-violet-600 to-blue-600 text-white px-6 py-3 rounded-xl font-semibold text-lg hover:from-violet-700 hover:to-blue-700 transition-all duration-300 shadow-md hover:shadow-lg flex items-center justify-center gap-2"
          >
            {pack.Free_Package ? (
              <>
                <CheckCircle className="w-5 h-5" />
                {t("StudentWallet.GetFreeHours")}
              </>
            ) : (
              <>
                <GraduationCap className="w-5 h-5" />
                {t("StudentWallet.PurchaseHours")}
              </>
            )}
          </button>

          {!pack.Free_Package && (
            <div className="text-center text-sm text-gray-600 font-medium">
              {t("StudentWallet.FullPackagePrice")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};


const PurchasePackages = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const student = useSelector((state) => state.students.user);
  const packages = useSelector((state) => state.packages?.packageslist?.packages ?? []);
  const studentFreeHourUsage = useSelector((state) => state.packages.studentFreeHourUsage);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showFreePackageModal, setShowFreePackageModal] = useState(false);
  const [showPurchaseSteps, setShowPurchaseSteps] = useState(false);
const [selectedLectureOption, setSelectedLectureOption] = useState(null);
const [showFreeHoursUsedModal, setShowFreeHoursUsedModal] = useState(false);
  const { convertPrice, loading: currencyLoading } = useCurrencyConversion(student?.Timezone || 'Europe/Warsaw');
  const { t } = useTranslation("global");

  useEffect(() => {
    Modal.setAppElement('#root'); // Change '#root' to match your app's root element
  }, []);


  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setIsLoading(true);
        await dispatch(fetchAllpackages());
        if (student?._id) {
          await dispatch(getStudentFreeHourUsage(student._id));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error('Failed to load packages');
      } finally {
        setIsLoading(false);
      }
    };
    fetchInitialData();
    // Added studentFreeHourUsage as a dependency to ensure UI updates when it changes
  }, [dispatch, student, studentFreeHourUsage?.length]);

  const filteredPackages = packages.filter(pack => {
    if (!pack?.Package_Name) return false;
    if (pack.Hide === true) return false;
    return pack.Package_Name.toLowerCase().includes(searchTerm.toLowerCase());
  });


  const calculatePackagePricing = useCallback((pack) => {
    if (!pack?.lectures || !Array.isArray(pack.lectures)) {
      return {
        minPrice: pack?.Package_Amount || 0,
        maxDiscount: 0,
        lectureOptions: [{
          count: pack?.Number_of_Lectures || 1,
          basePrice: pack?.Package_Amount || 0,
          discount: 0,
          finalPrice: pack?.Package_Amount || 0
        }]
      };
    }
  
    const lectureOptions = pack.lectures.map(lecture => {
      const lectureData = lecture._doc || lecture;
      return {
        count: lectureData.count || 0,
        basePrice: lectureData.basePrice || 0,
        discount: lectureData.discount || 0,
        finalPrice: lectureData.finalPrice || lectureData.basePrice || 0
      };
    });
  
    const minPrice = Math.min(...lectureOptions.map(opt => opt.finalPrice));
    const maxDiscount = Math.max(...lectureOptions.map(opt => opt.discount));
  
    return {
      minPrice,
      maxDiscount,
      lectureOptions
    };
  }, []);

  const checkFreeHourUsage = useCallback((packageId) => {
    // If still loading or data is not an array, assume not used yet
    if (!Array.isArray(studentFreeHourUsage)) return false;
    
    // Check if this specific package has been used
    return studentFreeHourUsage.some(usage => 
      usage.packageId === packageId && usage.hourUsed === true
    );
  }, [studentFreeHourUsage]);

  const handlePurchaseClick = useCallback((pack, lectureOption = null) => {
    if (!pack) return;
    
    setSelectedPackage(pack);
    setSelectedLectureOption(lectureOption);
    
    if (pack.Free_Package) {
      if (checkFreeHourUsage(pack._id)) {
        setShowFreeHoursUsedModal(true);
      } else {
        setShowFreePackageModal(true);
      }
    } else {
      setShowPurchaseSteps(true);
    }
  }, [checkFreeHourUsage]);

  const handleFreePackagePurchase = async () => {
    if (!selectedPackage || !student?._id) {
      toast.error('Invalid package or student data');
      return;
    }
  
    const lectureCount = selectedPackage.lectures?.[0]?._doc?.count;
  
    if (!lectureCount || isNaN(Number(lectureCount))) {
      toast.error('Invalid number of hours in package');
      return;
    }
  
    // Save the selected package ID before clearing state
    const packageId = selectedPackage._id;
    
    // Close the modal immediately to prevent UI issues
    setShowFreePackageModal(false);
    setSelectedPackage(null);
    
    try {
      setIsLoading(true);
      
      // First add the hours to the wallet
      const result = await dispatch(purchaseHours({
        studentId: student._id,
        packageId: packageId,
        hours: Number(lectureCount),
        amount: 0
      }));
        
      if (purchaseHours.fulfilled.match(result)) {
        // Show success toast
        toast.success('Free package added successfully!');
        
        // Navigate to my-packages page
        navigate('/Student-dashboard/my-packages');
        
        // After navigation, try to mark the free hour as used
        // This happens asynchronously after the user is already redirected
        setTimeout(async () => {
          try {
            await dispatch(addFreeHour({
              StudentID: student._id,
              PackageID: packageId,
              Free_hour_used: true
            }));
            
            await dispatch(getStudentFreeHourUsage(student._id));
          } catch (innerError) {
            console.error("Error marking free hour as used:", innerError);
            // This happens in the background, user is already on my-packages page
          }
        }, 500);
      } else {
        throw new Error(result.error || 'Failed to add free package');
      }
    } catch (error) {
      console.error("Error adding free package:", error);
      toast.error(error.message || 'Error adding free package');
      setIsLoading(false);
    }
  };

  const handlePurchaseConfirm = (purchaseDetails) => {
    // Handle the purchase confirmation
    console.log('Purchase confirmed:', purchaseDetails);
    // Add your purchase logic here
    setShowPurchaseModal(false);
    toast.success('Purchase initiated successfully!');
  };

  if (isLoading || currencyLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen w-full">
      <Loader2 
        loading={true}
        text={t("common.Loading")}
        fullScreen={false}  // Changed to false
        size={400}          // Reduced size for better mobile experience
        customStyles={{
          backgroundColor: 'transparent' // Changed from dark overlay to transparent
        }}
      />
    </div>
  );
}
  

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-50 via-white to-blue-50">
      <AdminNav />
      
      {/* Hero Section */}
      <div className="relative overflow-hidden bg-gradient-to-r from-purple-600 to-blue-600 py-16">
        <div className="absolute inset-0 bg-grid-white/10 bg-grid-16 mix-blend-overlay"></div>
        <div className="max-w-7xl mx-auto px-4 text-center relative z-10">
          <h1 className="text-5xl font-extrabold text-white mb-6 tracking-tight">
            {t("StudentWallet.ChooseThePerfect")}
          </h1>
          <p className="text-2xl text-purple-100 max-w-3xl mx-auto mb-8">
            {t("StudentWallet.AddFreeHoursMessage")}
          </p>
          
          <div className="flex flex-wrap justify-center gap-3 md:gap-8 mb-12">
      <div className="flex items-center gap-2 text-purple-100 px-2 py-1">
        <FaUsers className="text-xl md:text-2xl" />
        <span className="text-sm md:text-xl whitespace-nowrap">10K+ {t("StudentWallet.Hours")}</span>
      </div>
      <div className="flex items-center gap-2 text-purple-100 px-2 py-1">
        <FaGraduationCap className="text-xl md:text-2xl" />
        <span className="text-sm md:text-xl whitespace-nowrap">95% {t("StudentWallet.AvailableHours")}</span>
      </div>
      <div className="flex items-center gap-2 text-purple-100 px-2 py-1">
        <FaStar className="text-xl md:text-2xl" />
        <span className="text-sm md:text-xl whitespace-nowrap">4.9/5 {t("StudentWallet.Price")}</span>
      </div>
    </div>

          <div className="relative max-w-xl mx-auto">
            <FaSearch className="absolute left-4 top-1/2 transform -translate-y-1/2 text-purple-400 text-xl" />
            <input
              type="text"
              className="w-full pl-12 pr-4 py-4 rounded-full text-lg focus:ring-4 focus:ring-purple-300 focus:ring-opacity-50 transition-all border-none shadow-xl"
              placeholder={t("StudentWallet.SearchPackages")}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-16">
        {/* Packages Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredPackages.map((pack) => (
            <PackageCard
              key={pack._id}
              pack={pack}
              onPurchaseClick={handlePurchaseClick}
              convertPrice={convertPrice}
              calculatePackagePricing={calculatePackagePricing}
            />
          ))}
        </div>

        {/* Features Section */}
        <div className="mt-24">
          <h3 className="text-3xl font-bold text-center text-gray-900 mb-12">
            {t("StudentWallet.WalletAndPackages")}
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all">
              <div className="w-16 h-16 bg-gradient-to-br from-purple-500 to-blue-500 rounded-2xl flex items-center justify-center mx-auto mb-6 transform -rotate-6">
                <FaChalkboardTeacher className="text-white text-2xl" />
              </div>
              <h4 className="text-xl font-bold mb-4 text-center">{t("StudentWallet.TotalPackageHours")}</h4>
              <p className="text-gray-600 text-center">{t("StudentWallet.ChooseThePerfect")}</p>
            </div>
            
            <div className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all">
              <div className="w-16 h-16 bg-gradient-to-br from-purple-500 to-blue-500 rounded-2xl flex items-center justify-center mx-auto mb-6 transform rotate-6">
                <FaLightbulb className="text-white text-2xl" />
              </div>
              <h4 className="text-xl font-bold mb-4 text-center">{t("StudentWallet.PurchasedPackages")}</h4>
              <p className="text-gray-600 text-center">{t("StudentWallet.ChooseThePerfect")}</p>
            </div>
            
            <div className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all">
              <div className="w-16 h-16 bg-gradient-to-br from-purple-500 to-blue-500 rounded-2xl flex items-center justify-center mx-auto mb-6 transform -rotate-6">
                <FaRocket className="text-white text-2xl" />
              </div>
              <h4 className="text-xl font-bold mb-4 text-center">{t("StudentWallet.FullPackagePrice")}</h4>
              <p className="text-gray-600 text-center">{t("StudentWallet.ChooseThePerfect")}</p>
            </div>
            
            <div className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all">
              <div className="w-16 h-16 bg-gradient-to-br from-purple-500 to-blue-500 rounded-2xl flex items-center justify-center mx-auto mb-6 transform rotate-6">
                <FaUsers className="text-white text-2xl" />
              </div>
              <h4 className="text-xl font-bold mb-4 text-center">{t("StudentWallet.TotalBalance")}</h4>
              <p className="text-gray-600 text-center">{t("StudentWallet.ChooseThePerfect")}</p>
            </div>
          </div>
        </div>

        {/* Testimonial Banner */}
        <div className="mt-24 bg-gradient-to-r from-purple-600 to-blue-600 rounded-3xl p-12 text-center text-white">
          <h3 className="text-3xl font-bold mb-6">{t("StudentWallet.ChooseThePerfect")}</h3>
          <p className="text-xl text-purple-100 max-w-3xl mx-auto mb-8">
            {t("StudentWallet.AddFreeHoursMessage")}
          </p>
          <div className="flex items-center justify-center gap-2">
            <div className="w-12 h-12 rounded-full bg-white"></div>
            <div className="text-left">
              <p className="font-bold">{t("StudentWallet.FullPackageOnly")}</p>
              <p className="text-purple-200">{t("StudentWallet.FreePackage")}</p>
            </div>
          </div>
        </div>
      </div>

      <PurchaseSteps
  isOpen={showPurchaseSteps}
  onClose={() => setShowPurchaseSteps(false)}
  pack={selectedPackage}
  lectureOption={selectedLectureOption}
  student={student}
  convertPrice={convertPrice}
  onConfirm={({ lectureDetails, billingAddress }) => {
    const { minPrice } = calculatePackagePricing(selectedPackage);
    
    // Log what's being passed
    console.log("Billing address being passed to CheckDetails:", billingAddress);
    
    navigate(`/Student-dashboard/CheckDetails/${selectedPackage?._id}`, {
      state: {
        packageName: selectedPackage?.Package_Name,
        hours: lectureDetails?.count || selectedPackage?.Number_of_Lectures,
        totalAmount: lectureDetails ? 
          convertPrice(lectureDetails.finalPrice) : 
          convertPrice(minPrice),
        lectureDetails,
        billingAddress // Make sure this is included here
      }
    });
  }}
/>

<Modal
  isOpen={showFreePackageModal}
  onRequestClose={() => setShowFreePackageModal(false)}
  contentLabel={t("StudentWallet.FreePackageConfirmation")}
  className="relative bg-white rounded-lg max-w-md mx-auto mt-24 p-6 shadow-xl"
  overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center"
  style={{
    overlay: {
      zIndex: 1000,
    }
  }}
>
<div className="space-y-4">
    <h2 className="text-2xl font-bold text-gray-900">{t("StudentWallet.FreePackageConfirmation")}</h2>
    <p className="text-gray-600">
      {t("StudentWallet.ConfirmAddFreeHours")}
    </p>
    <div className="flex space-x-3 pt-4">
      <button 
        className="flex-1 bg-purple-600 text-white px-4 py-2 rounded-lg font-medium hover:bg-purple-700 transition-colors duration-200"
        onClick={handleFreePackagePurchase}
        disabled={isLoading} // Disable button when loading
      >
        {isLoading ? t("StudentWallet.Loading") : t("StudentWallet.ConfirmAddFreeHours")}
      </button>
      <button 
        className="flex-1 bg-white text-gray-700 px-4 py-2 rounded-lg font-medium border border-gray-300 hover:bg-gray-50 transition-colors duration-200"
        onClick={() => setShowFreePackageModal(false)}
        disabled={isLoading} // Disable button when loading
      >
        {t("StudentWallet.Cancel")}
      </button>
    </div>
  </div>
</Modal>

      <Modal
        isOpen={showFreeHoursUsedModal}
        onRequestClose={() => setShowFreeHoursUsedModal(false)}
        contentLabel={t("StudentWallet.FreeHoursUsed")}
        className="relative bg-white rounded-lg max-w-md p-6 shadow-xl"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        <div className="space-y-4">
          <h2 className="text-2xl font-bold text-gray-900">
            {t("StudentWallet.FreeHoursUsed")}
          </h2>
          <p className="text-gray-600">
            {t("StudentWallet.FreeHoursUsedMessage")}
          </p>
          <div className="flex justify-end pt-4">
            <button
              className="bg-white text-gray-700 px-6 py-2 rounded-lg font-medium border border-gray-300 hover:bg-gray-50 transition-colors duration-200"
              onClick={() => setShowFreeHoursUsedModal(false)}
            >
              {t("StudentWallet.Cancel")}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PurchasePackages;
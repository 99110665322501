import React, { useState, useEffect } from 'react';
import { Card, Progress, Tag, Modal, Space, Button } from 'antd';
import { 
  StarFilled,
  StarOutlined,
  RocketFilled,
  TrophyFilled,
  CrownFilled,
  ThunderboltFilled,
  BulbFilled,
  SmileFilled,
  HeartFilled,
  ArrowLeftOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  getStudentEvaluations,
  getEvaluationStats
} from '../../store/actions/evaluationActions';

const KidFriendlyEvaluations = () => {
  const { t } = useTranslation("global");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // Check if we're on the dedicated evaluations page
  const isEvaluationsPage = location.pathname === '/student/evaluations';

  // Redux states
  const student = useSelector(state => state.students?.user);
  const evaluations = useSelector(state => state.evaluations?.evaluations ?? []);
  const stats = useSelector(state => state.evaluations?.stats ?? {
    averageScore: 0,
    totalEvaluations: 0,
    minScore: 0,
    maxScore: 0
  });
  const loading = useSelector(state => state.evaluations?.loading ?? false);
  const error = useSelector(state => state.evaluations?.error ?? null);

  // Local state
  const [selectedEval, setSelectedEval] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Custom star rating component
  const StarRating = ({ score }) => {
    return (
      <Space>
        {[...Array(5)].map((_, index) => (
          <span key={index}>
            {index < score ? (
              <StarFilled style={{ color: '#FBBF24', fontSize: '16px' }} />
            ) : (
              <StarOutlined style={{ color: '#D1D5DB', fontSize: '16px' }} />
            )}
          </span>
        ))}
      </Space>
    );
  };

  // Fetch evaluations data
  useEffect(() => {
    const fetchData = async () => {
      if (student?._id) {
        try {
          await Promise.all([
            dispatch(getStudentEvaluations(student._id)),
            dispatch(getEvaluationStats(student._id))
          ]);
        } catch (error) {
          console.error('Error fetching evaluation data:', error);
        }
      }
    };

    fetchData();
  }, [student?._id, dispatch]);

  const getScoreEmoji = (score) => {
    const percentage = score * 20;
    if (percentage >= 90) return '🌟';
    if (percentage >= 80) return '🎉';
    if (percentage >= 70) return '😊';
    if (percentage >= 60) return '👍';
    return '💪';
  };

  const getScoreColor = (score) => {
    const percentage = score * 20;
    if (percentage >= 90) return '#7C3AED'; // Purple
    if (percentage >= 80) return '#3B82F6'; // Blue
    if (percentage >= 70) return '#10B981'; // Green
    if (percentage >= 60) return '#F59E0B'; // Yellow
    return '#EF4444'; // Red
  };

  const renderEvaluationCard = (evaluation) => {
    const scoreColor = getScoreColor(evaluation.overallScore);
    const scorePercentage = Math.round(evaluation.overallScore * 20);
    const lessonDate = evaluation.lessonId?.Scheduled_Dates?.[0] 
      ? Object.keys(evaluation.lessonId.Scheduled_Dates[0])[0]
      : evaluation.createdAt;
    
    return (
      <Card 
        key={evaluation._id}
        className="evaluation-card mb-6"
        bodyStyle={{ 
          background: 'linear-gradient(135deg, #fff6f6 0%, #f0f7ff 100%)',
          borderRadius: '16px',
          padding: '16px'
        }}
      >
        <div className="mb-6">
          <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
            <Space className="flex-wrap justify-center sm:justify-start">
              <TrophyFilled style={{ fontSize: '28px', color: scoreColor }} />
              <div className="text-center sm:text-left">
                <h3 className="text-lg sm:text-xl font-bold mb-2" style={{ color: scoreColor }}>
                  {t("evaluations.lessonTitle")} {getScoreEmoji(evaluation.overallScore)}
                </h3>
                <Tag color="blue">{moment(lessonDate).format('LL')}</Tag>
              </div>
            </Space>
            <Progress
              type="circle"
              percent={scorePercentage}
              format={(percent) => (
                <span style={{ color: scoreColor, fontSize: '16px', fontWeight: 'bold' }}>
                  {percent}%
                </span>
              )}
              strokeColor={scoreColor}
              width={60}
            />
          </div>
        </div>

        <div className="flex flex-col gap-4">
          {/* Super Powers Section */}
          <Card
            className="super-powers w-full"
            style={{ background: '#F0FDF4', border: 'none' }}
            bodyStyle={{ padding: '12px' }}
          >
            <div className="flex items-center gap-2 mb-4">
              <StarFilled style={{ color: '#22C55E', fontSize: '18px' }} />
              <h4 className="text-base sm:text-lg font-bold text-green-700">
                {t("evaluations.strengths")}
              </h4>
            </div>
            <Space direction="vertical" className="w-full">
              {evaluation.areas?.strengths?.map((strength, idx) => (
                <Tag
                  key={idx}
                  color="success"
                  icon={<SmileFilled />}
                  className="w-full text-sm"
                  style={{ padding: '6px 10px', borderRadius: '8px' }}
                >
                  {strength}
                </Tag>
              ))}
            </Space>
          </Card>

          {/* Level Up Goals Section */}
          <Card
            className="level-up-goals w-full"
            style={{ background: '#EFF6FF', border: 'none' }}
            bodyStyle={{ padding: '12px' }}
          >
            <div className="flex items-center gap-2 mb-4">
              <RocketFilled style={{ color: '#3B82F6', fontSize: '18px' }} />
              <h4 className="text-base sm:text-lg font-bold text-blue-700">
                {t("evaluations.improvements")}
              </h4>
            </div>
            <Space direction="vertical" className="w-full">
              {evaluation.areas?.improvements?.map((improvement, idx) => (
                <Tag
                  key={idx}
                  color="processing"
                  icon={<ThunderboltFilled />}
                  className="w-full text-sm"
                  style={{ padding: '6px 10px', borderRadius: '8px' }}
                >
                  {improvement}
                </Tag>
              ))}
            </Space>
          </Card>
        </div>

        {/* Skills Progress Section */}
        {evaluation.criteria?.length > 0 && (
          <Card
            className="skills-progress mt-4"
            style={{ background: '#F5F3FF', border: 'none' }}
            bodyStyle={{ padding: '12px' }}
          >
            <div className="flex items-center gap-2 mb-4">
              <BulbFilled style={{ color: '#7C3AED', fontSize: '18px' }} />
              <h4 className="text-base sm:text-lg font-bold text-purple-700">
                {t("evaluations.criteria")}
              </h4>
            </div>
            <Space direction="vertical" className="w-full">
              {evaluation.criteria.map((criterion, idx) => (
                <div key={idx} className="flex flex-col sm:flex-row justify-between items-start sm:items-center w-full gap-2">
                  <span className="text-purple-700 text-sm">{criterion.name}</span>
                  <StarRating score={criterion.score} />
                </div>
              ))}
            </Space>
          </Card>
        )}

        <Button
          type="primary"
          size="large"
          icon={<CrownFilled />}
          onClick={() => {
            setSelectedEval(evaluation);
            setIsModalVisible(true);
          }}
          className="mt-4 w-full"
          style={{
            height: '44px',
            borderRadius: '12px',
            background: `linear-gradient(135deg, ${scoreColor} 0%, ${scoreColor}dd 100%)`,
            border: 'none',
            fontSize: '14px',
            fontWeight: 'bold'
          }}
        >
          {t("evaluations.viewDetails")} ✨
        </Button>
      </Card>
    );
  };

  const renderEvaluationModal = () => (
    <Modal
      title={
        <div className="flex items-center gap-2">
          <CrownFilled style={{ color: '#7C3AED', fontSize: '20px' }} />
          <span className="text-lg sm:text-xl font-bold">{t("evaluations.detailsTitle")}</span>
        </div>
      }
      open={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      footer={null}
      width="100%"
      style={{ maxWidth: '700px', margin: '16px' }}
    >
      {selectedEval && (
        <div className="space-y-4 sm:space-y-6">
          <Card style={{ background: '#F5F3FF' }}>
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
              <Space direction="vertical">
                <h4 className="text-base sm:text-lg font-bold text-purple-700">
                  {t("evaluations.lessonInfo")}
                </h4>
                <Space direction="vertical" className="text-sm">
                  <span>
                    <strong>{t("evaluations.teacher")}:</strong> {selectedEval.teacherId?.Username}
                  </span>
                  <span>
                    <strong>{t("evaluations.date")}:</strong> {moment(selectedEval.createdAt).format('LLLL')}
                  </span>
                </Space>
              </Space>
              <Progress
                type="circle"
                percent={Math.round(selectedEval.overallScore * 20)}
                strokeColor={getScoreColor(selectedEval.overallScore)}
                width={60}
              />
            </div>
            
            {selectedEval.generalComments && (
              <div className="mt-4">
                <h4 className="font-bold mb-2 text-base">{t("evaluations.feedback")}</h4>
                <p className="text-sm">{selectedEval.generalComments}</p>
              </div>
            )}
          </Card>

          {/* Criteria Details */}
          {selectedEval.criteria?.length > 0 && (
            <Card title={t("evaluations.criteria")} style={{ background: '#F5F3FF' }}>
              {selectedEval.criteria.map((criterion, idx) => (
                <div key={idx} className="mb-4">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2">
                    <span className="font-bold text-purple-700 text-sm">{criterion.name}</span>
                    <StarRating score={criterion.score} />
                  </div>
                  {criterion.comments && (
                    <p className="text-gray-600 mt-1 text-sm">{criterion.comments}</p>
                  )}
                </div>
              ))}
            </Card>
          )}

          {/* Strengths Section */}
          {selectedEval.areas?.strengths?.length > 0 && (
            <Card 
              title={
                <span className="text-base sm:text-lg">{t("evaluations.strengths")}</span>
              } 
              style={{ background: '#F0FDF4' }}
            >
              <Space direction="vertical" className="w-full">
                {selectedEval.areas.strengths.map((strength, idx) => (
                  <Tag
                    key={idx}
                    color="success"
                    icon={<SmileFilled />}
                    className="w-full text-sm"
                    style={{ padding: '6px 10px', borderRadius: '8px' }}
                  >
                    {strength}
                  </Tag>
                ))}
              </Space>
            </Card>
          )}

          {/* Improvements Section */}
          {selectedEval.areas?.improvements?.length > 0 && (
            <Card 
              title={
                <span className="text-base sm:text-lg">{t("evaluations.improvements")}</span>
              } 
              style={{ background: '#EFF6FF' }}
            >
              <Space direction="vertical" className="w-full">
                {selectedEval.areas.improvements.map((improvement, idx) => (
                  <Tag
                    key={idx}
                    color="processing"
                    icon={<ThunderboltFilled />}
                    className="w-full text-sm"
                    style={{ padding: '6px 10px', borderRadius: '8px' }}
                  >
                    {improvement}
                  </Tag>
                ))}
              </Space>
            </Card>
          )}

          {/* Next Steps */}
          {selectedEval.nextSteps?.length > 0 && (
            <Card 
              title={
                <span className="text-base sm:text-lg">{t("evaluations.nextSteps")}</span>
              } 
              style={{ background: '#F0FDF4' }}
            >
              <ul className="list-disc list-inside space-y-2 text-sm">
                {selectedEval.nextSteps.map((step, index) => (
                  <li key={index} className="text-green-700">{step}</li>
                ))}
              </ul>
            </Card>
          )}

          {/* Goals */}
          {selectedEval.goals?.length > 0 && (
            <Card 
              title={
                <span className="text-base sm:text-lg">{t("evaluations.goals")}</span>
              } 
              style={{ background: '#EFF6FF' }}
            >
              {selectedEval.goals.map((goal, index) => (
                <div key={index} className="mb-3">
                  <p className="font-bold text-blue-700 text-sm">{goal.description}</p>
                  <p className="text-xs text-blue-600">
                    {t("evaluations.timeframe")}: {goal.timeframe} | {t("evaluations.status")}: {goal.status}
                  </p>
                </div>
              ))}
            </Card>
          )}
        </div>
      )}
    </Modal>
  );

  if (loading) {
    return (
      <Card>
        <div className="flex justify-center items-center h-64">
          <Space>
            <RocketFilled style={{ fontSize: '24px', color: '#7C3AED' }} />
            <span className="text-purple-700">{t("common.Loading")}</span>
          </Space>
        </div>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        <div className="text-center py-8">
          <Space direction="vertical">
            <SmileFilled style={{ fontSize: '48px', color: '#EF4444' }} />
            <h3 className="text-xl font-bold text-red-600">{t("common.Error")}</h3>
            <p className="text-red-500">{error}</p>
          </Space>
        </div>
      </Card>
    );
  }

  return (
    <div className="p-3 sm:p-6" style={{ background: 'linear-gradient(135deg, #FDF2F8 0%, #F5F3FF 100%)' }}>
      {/* Header */}
      <div className="flex flex-col sm:flex-row justify-between items-center gap-4 mb-6">
        <Space className="text-center sm:text-left">
          <TrophyFilled style={{ fontSize: '28px', color: '#7C3AED' }} />
          <h2 className="text-xl sm:text-2xl font-bold" style={{ 
            background: 'linear-gradient(135deg, #7C3AED 0%, #EC4899 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
          }}>
            {t("evaluations.recentEvaluations")}
          </h2>
        </Space>
        
        <Space className="flex-wrap justify-center">
          {stats && (
            <Tag 
              color="purple" 
              icon={<HeartFilled />} 
              className="flex items-center gap-2"
              style={{ padding: '6px 10px', borderRadius: '8px' }}
            >
              <span className="text-sm">
                {t("evaluations.averageScore")}: {Math.round((stats.averageScore || 0) * 20)}%
              </span>
              <span className="text-base">🌟</span>
            </Tag>
          )}

          {/* Conditionally render Back button or View All button based on current page */}
          {isEvaluationsPage ? (
            <Tag 
              color="blue"
              className="flex items-center gap-2"
              style={{ padding: '6px 10px', borderRadius: '8px' }}
              onClick={() => navigate('/Student-dashboard/dash')}
            >
              <ArrowLeftOutlined />
              <span className="text-sm">{t("common.Back")}</span>
            </Tag>
          ) : (
            <Tag 
              color="blue"
              className="flex items-center gap-2"
              style={{ padding: '6px 10px', borderRadius: '8px' }}
              onClick={() => navigate('/student/evaluations')}
            >
              <CrownFilled />
              <span className="text-sm">{t("common.viewAll")}</span>
            </Tag>
          )}
        </Space>
      </div>

      {/* Evaluation Cards */}
      <div className="space-y-6">
        {/* Show all evaluations on the dedicated page, or just 3 on the dashboard */}
        {evaluations?.slice(0, isEvaluationsPage ? undefined : 3).map(renderEvaluationCard)}
      </div>

      {/* Empty State */}
      {(!evaluations || evaluations.length === 0) && (
        <Card 
          style={{ 
            textAlign: 'center', 
            padding: '32px', 
            background: 'transparent', 
            border: 'none' 
          }}
        >
          <div className="space-y-4">
            <RocketFilled 
              style={{ 
                fontSize: '48px', 
                color: '#7C3AED', 
                marginBottom: '16px' 
              }}
            />
            <h3 className="text-lg sm:text-xl font-bold text-purple-600">
              {t("evaluations.noEvaluations")}
            </h3>
            <p className="text-sm sm:text-base text-purple-500">
              {t("evaluations.startJourney")} 🚀
            </p>
          </div>
        </Card>
      )}

      {/* View All Button - Only show on dashboard and if there are more than 3 evaluations */}
      {!isEvaluationsPage && evaluations?.length > 3 && (
        <div className="mt-6 text-center">
          <Button
            type="primary"
            size="large"
            icon={<CrownFilled />}
            onClick={() => navigate('/student/evaluations')}
            style={{
              height: '44px',
              borderRadius: '12px',
              background: 'linear-gradient(135deg, #7C3AED 0%, #6D28D9 100%)',
              border: 'none',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '0 24px'
            }}
          >
            {t("common.viewAll")} ✨
          </Button>
        </div>
      )}

      {/* Back to Dashboard Button - Only show on evaluations page */}
      {isEvaluationsPage && (
        <div className="mt-6 text-center">
          <Button
            type="primary"
            size="large"
            icon={<ArrowLeftOutlined />}
            onClick={() => navigate('/Student-dashboard/dash')}
            style={{
              height: '44px',
              borderRadius: '12px',
              background: 'linear-gradient(135deg, #7C3AED 0%, #6D28D9 100%)',
              border: 'none',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '0 24px'
            }}
          >
            {t("common.Back")} 🏠
          </Button>
        </div>
      )}

      {/* Modal */}
      {renderEvaluationModal()}
    </div>
  );
};

export default KidFriendlyEvaluations;
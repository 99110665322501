import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Calendar from "react-calendar";
import Lottie from 'react-lottie-player';
import clockAnimation from './Animations/StudentAnimation.json';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaRegQuestionCircle } from "react-icons/fa";
import { Tooltip, Modal, Button, List, Table, Spin, Card, Progress, Tag, message } from "antd";
import { DownloadOutlined, MessageOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import OwncloudSignupFormPopup from "./OwncloudSignupFormPopup";
import { studentJoinSession, getTeacherSessionStatus } from "../../store/actions/teacherSessionActions";
import moment from 'moment-timezone';
import GuidePopup from './GuidePopup'; 
import { useTranslation } from "react-i18next";
import ChatPopup from '../../components/admin-dashboard-components/ChatPopup';
import axios from 'axios';
import Loader2 from "../Loader2";
import {
  GetBookingsByStudentID,
  UpdatetheBookingStatus,
} from "../../store/actions/bookingActions";
import { fetchTeacherDetailsWithoutSlot } from "../../store/actions/teachersActions";
import { listDriveFiles, downloadDriveFile } from '../../store/actions/studentsActions';
import { useWebSocket } from '../hooks/useWebSocket';
import YourMaterialModal2 from "./YourMaterialModel2";

import { 
  Calendar as CalendarIcon, 
  Clock, 
  BookOpen, 
  Award,
  Check,
  AlertCircle,
  X,
  Clock4,
  Flame,
  FolderOpen,
  Search
} from 'lucide-react';
import StudentEvaluations from './StudentEvaluation';

const StudentDashboard = () => {
  const { t, i18n } = useTranslation("global");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Redux states
  const student = useSelector(state => state.students.user);
  const bookings = useSelector(state => state.bookings.StudentID_Booking);
  const wallet = useSelector(state => state.wallet);

  // Local states
  const [currentTime, setCurrentTime] = useState(() => {
    const timezone = student?.Timezone || moment.tz.guess();
    return moment.tz(timezone);
  });
  const [initialTimezone, setInitialTimezone] = useState('');
  const [teacherWaitTimer, setTeacherWaitTimer] = useState(null);
  const [utcTime, setUtcTime] = useState(moment.utc());
  const [showPrompt, setShowPrompt] = useState(false);
  const [isGuideOpen, setIsGuideOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [isMaterialModalVisible, setIsMaterialModalVisible] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [showAllBookings, setShowAllBookings] = useState(false);
  const [timeFormat, setTimeFormat] = useState(() => {
    return localStorage.getItem('timeFormat') || '24';
  });

  //join room
const [isJoiningRoom, setIsJoiningRoom] = useState(false);
const [currentBookingId, setCurrentBookingId] = useState(null);
const [currentTeacherId, setCurrentTeacherId] = useState(null);
const [teacherJoined, setTeacherJoined] = useState(false);
const [waitTimeLeft, setWaitTimeLeft] = useState(10);

const joinRoom = async (bookingId, teacherId) => {
  try {
    setIsJoiningRoom(true);
    setCurrentBookingId(bookingId);
    setCurrentTeacherId(teacherId);
    
    // Call the student join session action from Redux
    await dispatch(studentJoinSession({
      bookingId,
      studentId: student._id
    }));
    
    // Check teacher status immediately
    const result = await dispatch(getTeacherSessionStatus({ 
      bookingId, 
      teacherId 
    }));
    
    console.log('Initial teacher status check:', result);
    
    // Check both possible payload patterns
    const isActive = 
      (result.payload && result.payload.isActive) || 
      (result.payload && result.payload.data && result.payload.data.isActive);
    
    if (isActive) {
      // Teacher is already in the room
      setTeacherJoined(true);
    } else {
      // Start checking if teacher has joined
      checkTeacherStatus();
      
      // Set a timer to send a notification to the teacher after 3 minutes
      const waitTimer = setTimeout(() => {
        sendTeacherWaitingReminder();
        
        // Display a notification to the student that we've sent a reminder
        message.info(t("status.teacherReminderSent"));
        
        // After 2 more minutes, if the teacher hasn't joined, show a notification
        setTimeout(() => {
          if (!teacherJoined) {
            message.warning(t("status.teacherNotJoined"));
            // Give the student the option to continue waiting or join directly
            Modal.confirm({
              title: t("status.teacherNotAvailable"),
              content: t("status.continueWaiting"),
              okText: t("status.joinAnyway"),
              cancelText: t("status.continueWait"),
              onOk() {
                directJoinRoom(bookingId);
              }
            });
          }
        }, 120000); // 2 more minutes after the reminder
      }, 180000); // 3 minutes
      
      setTeacherWaitTimer(waitTimer);
    }
    
  } catch (error) {
    console.error('Error joining room:', error);
    message.error(t("common.JoinError"));
    handleCancelJoining();
  }
};

const sendTeacherWaitingReminder = async () => {
  try {
    const booking = upcomingMeetings.find(meeting => meeting.id === currentBookingId);
    if (!booking) return;

    await axios.post('/api/send-teacher-reminder', {
      studentEmail: student.Email,
      teacherName: booking.teacher.name,
      studentName: student.Username,
      teacherTimezone: booking.teacherTimezone || 'UTC',
      date: booking.date.format('YYYY-MM-DD'),
      time: booking.startTime,
      bookingId: currentBookingId
    });
  } catch (error) {
    console.error('Error sending teacher waiting reminder:', error);
  }
};

const directJoinRoom = (bookingId) => {
  setIsJoiningRoom(false);
  navigate(`/Student/room/meeting/${bookingId}`);
  message.success(t("status.JoiningRoom"));
};


const handleCancelJoining = () => {
  setIsJoiningRoom(false);
  setCurrentBookingId(null);
  setCurrentTeacherId(null);
  setTeacherJoined(false);
  setWaitTimeLeft(10);
  
  // Clear the teacher reminder timer if it exists
  if (teacherWaitTimer) {
    clearTimeout(teacherWaitTimer);
    setTeacherWaitTimer(null);
  }
};

const checkTeacherStatus = useCallback(async () => {
  if (!currentBookingId || !currentTeacherId) return;

  try {
    const result = await dispatch(getTeacherSessionStatus({ 
      bookingId: currentBookingId, 
      teacherId: currentTeacherId 
    }));

    if (result.payload && result.payload.isActive) {
      setTeacherJoined(true);
    }
  } catch (error) {
    console.error("Error checking teacher status:", error);
  }
}, [currentBookingId, currentTeacherId, dispatch]);

useEffect(() => {
  let intervalId;
  if (isJoiningRoom && !teacherJoined) {
    intervalId = setInterval(checkTeacherStatus, 5000);
  }
  return () => {
    if (intervalId) clearInterval(intervalId);
  };
}, [isJoiningRoom, teacherJoined, checkTeacherStatus]);

useEffect(() => {
  let timerId;
  if (teacherJoined) {
    timerId = setInterval(() => {
      setWaitTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerId);
          setIsJoiningRoom(false);
          navigate(`/Student/room/meeting/${currentBookingId}`);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  }
  return () => {
    if (timerId) clearInterval(timerId);
  };
}, [teacherJoined, currentBookingId, navigate]);

  // Timezone detection
  useEffect(() => {
    const fetchTimezone = async () => {
      setIsLoading(true);
      try {
        const ipResponse = await axios.get('https://api.ipify.org?format=json');
        const ipAddress = ipResponse.data.ip;

        const timezoneResponse = await axios.get(`https://ipinfo.io/${ipAddress}?token=132a6bee31081e`);
        const timezone = timezoneResponse.data.timezone;
        setInitialTimezone(timezone);

        const hasResponded = sessionStorage.getItem('timezonePromptResponded') || 
                          localStorage.getItem('timezonePromptResponded');
        
        if (!hasResponded && timezone !== student?.Timezone) {
          setShowPrompt(true);
        }
      } catch (error) {
        console.error('Error fetching timezone:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTimezone();
  }, [student?.Timezone]);

  // Time conversion utility
  const convertToLocalTime = useCallback((date, time) => {
    if (!date || !time) return moment();
    const timezone = student?.Timezone || moment.tz.guess();
    return moment.utc(`${date} ${time}`).tz(timezone);
  }, [student?.Timezone]);

  // Update current time
  useEffect(() => {
    const timezone = student?.Timezone || moment.tz.guess();
    
    const updateTime = () => {
      const now = moment().tz(timezone);
      setCurrentTime(now);
      setUtcTime(moment.utc());
    };
    
    updateTime();
    const timer = setInterval(updateTime, 1000);
    
    return () => clearInterval(timer);
  }, [student?.Timezone]);

  // Format booking date with timezone
  const formatBookingDate = useCallback((scheduledDates) => {
    if (!scheduledDates?.[0]) return t("StudentDash.NoDateAvailable");
    const date = Object.keys(scheduledDates[0])[0];
    return convertToLocalTime(date, "00:00").format('LL');
  }, [convertToLocalTime, t]);

  // Timezone prompt handler
  const handleTimezonePromptResponse = (response) => {
    if (response === 'yes') {
      navigate(`/Student-dashboard/profile`);
    } else {
      localStorage.setItem('userTimezone', initialTimezone);
    }
    sessionStorage.setItem('timezonePromptResponded', 'true');
    localStorage.setItem('timezonePromptResponded', 'true');
    setShowPrompt(false);
  };




  useEffect(() => {
    if (!bookings) return;
    
    const filtered = bookings.filter(booking => {
      const teacherName = booking.Teacher_ID?.[0]?.Username?.toLowerCase() || '';
      const status = booking.Status?.toLowerCase() || '';
      const search = searchTerm.toLowerCase();
      
      return teacherName.includes(search) || status.includes(search);
    });
    
    setFilteredBookings(filtered);
  }, [bookings, searchTerm]);

  // Function to handle opening the materials modal
  const handleOpenMaterialsModal = (bookingId) => {
    console.log('Opening modal for booking:', bookingId); // Debug log
    setSelectedBookingId(bookingId);
    setIsMaterialModalVisible(true);
  };

  // Function to handle closing the materials modal
  const handleCloseMaterialsModal = () => {
    setIsMaterialModalVisible(false);
    setSelectedBookingId(null);
  };


  const getStatusColor = (status, language) => {
    // Create a reverse mapping for Polish statuses
    const plToEnStatus = {
      'Zakończony': 'Completed',
      'Zaplanowany': 'Scheduled',
      'Anulowany': 'Cancelled',
      'Przełożony': 'Rescheduled'
    };
  
    // Get the English status for consistent color mapping
    const englishStatus = language === 'pl' ? plToEnStatus[status] : status;
    
    const colors = {
      Completed: 'bg-green-100 text-green-800 border-green-200',
      Scheduled: 'bg-blue-100 text-blue-800 border-blue-200',
      Cancelled: 'bg-red-100 text-red-800 border-red-200',
      Rescheduled: 'bg-yellow-100 text-yellow-800 border-yellow-200',
    };
    
    return colors[englishStatus] || 'bg-gray-100 text-gray-800 border-gray-200';
  };

  const getTranslatedStatus = (status, language) => {
    const translations = statusTranslations[language] || statusTranslations['en'];
    return translations[status] || status;
  };





  // Data fetching
  useEffect(() => {
    console.log('Student data:', student);
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const result = await dispatch(GetBookingsByStudentID(student._id));
        if (result.error) {
          throw new Error(result.error);
        }
      } catch (err) {
        setError(err.message);
        console.error("Error fetching bookings:", err);
      } finally {
        setIsLoading(false);
      }
    };

    if (student?._id) {
      fetchData();
    }
  }, [dispatch, student?._id]);

  useEffect(() => {
    if (!isJoiningRoom) {
      setTeacherJoined(false);
      setWaitTimeLeft(10);
      setCurrentBookingId(null);
      setCurrentTeacherId(null);
    }
  }, [isJoiningRoom]);

  // Update booking status
  useEffect(() => {
    const updateBookingStatuses = async () => {
      if (!Array.isArray(bookings)) return;

      const scheduledBookings = bookings.filter(booking => booking.Status === "Scheduled");
      for (const booking of scheduledBookings) {
        if (!booking?.Scheduled_Dates?.[0]) continue;

        const date = Object.keys(booking.Scheduled_Dates[0])[0];
        const scheduleDate = moment(date);
        if (moment().isAfter(scheduleDate)) {
          await dispatch(UpdatetheBookingStatus(booking._id));
        }
      }
    };

    updateBookingStatuses();
  }, [bookings, dispatch]);

  const statusTranslations = {
    en: {
      Completed: 'Completed',
      Scheduled: 'Scheduled',
      Cancelled: 'Cancelled',
      Rescheduled: 'Rescheduled'
    },
    pl: {
      Completed: 'Zakończony',
      Scheduled: 'Zaplanowany',
      Cancelled: 'Anulowany',
      Rescheduled: 'Przełożony'
    }
  };

  // Update current time
  // Update the time update effect
  useEffect(() => {
    const timezone = student?.Timezone || moment.tz.guess();
    
    const updateTime = () => {
      const now = moment().tz(timezone);
      setCurrentTime(now);
    };
    
    updateTime();
    const timer = setInterval(updateTime, 1000);
    
    return () => clearInterval(timer);
  }, [student?.Timezone]);

  // Calculate streak
  const calculateStreak = useCallback(() => {
    if (!bookings?.length) return { currentStreak: 0, longestStreak: 0 };

    const completedBookings = bookings
      .filter(booking => booking.Status === "Completed" || "Zakończony")
      .map(booking => {
        const date = booking.Scheduled_Dates?.[0];
        return date ? moment(Object.keys(date)[0]).startOf('day') : null;
      })
      .filter(Boolean)
      .sort((a, b) => b.valueOf() - a.valueOf());

    if (!completedBookings.length) return { currentStreak: 0, longestStreak: 0 };

    let currentStreak = 1;
    let longestStreak = 1;
    let tempStreak = 1;

    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'day').startOf('day');
    const mostRecent = completedBookings[0];

    if (!mostRecent.isSame(today) && !mostRecent.isSame(yesterday)) {
      currentStreak = 0;
    } else {
      for (let i = 1; i < completedBookings.length; i++) {
        const current = completedBookings[i];
        const prev = completedBookings[i - 1];
        if (prev.diff(current, 'days') === 1) {
          currentStreak++;
        } else {
          break;
        }
      }
    }

    for (let i = 1; i < completedBookings.length; i++) {
      const current = completedBookings[i];
      const prev = completedBookings[i - 1];
      if (prev.diff(current, 'days') === 1) {
        tempStreak++;
        longestStreak = Math.max(longestStreak, tempStreak);
      } else {
        tempStreak = 1;
      }
    }

    return { currentStreak, longestStreak };
  }, [bookings]);

  const handleTimezonePrompt = () => {
    setShowPrompt(true);
  };

  // Stats calculations
  const stats = useCallback(() => ({
    totalLectures: bookings?.length || 0,
    completedSessions: bookings?.filter(booking => booking?.Status === "Completed").length || 0,
    pendingSessions: bookings?.filter(booking => 
      booking?.Status === "Rescheduled" || booking?.Status === "Scheduled"
    ).length || 0,
    cancelledSessions: bookings?.filter(booking => booking?.Status === "Cancelled").length || 0
  }), [bookings]);

  // Get upcoming meetings
  const getUpcomingMeetings = useCallback(async () => {
    if (!bookings) return [];
    
    // Get current date (at the beginning of the day) in student's timezone
    const now = moment().tz(student?.Timezone || moment.tz.guess()).startOf('day');
    console.log('Current date for comparison:', now.format('YYYY-MM-DD'));
    
    const scheduledBookings = bookings.filter(booking => booking.Status === "Scheduled");
    console.log('Total scheduled bookings:', scheduledBookings.length);
    
    const meetings = await Promise.all(scheduledBookings.map(async booking => {
      const nextSession = booking.Scheduled_Dates?.[0];
      if (!nextSession) return null;
      
      const date = Object.keys(nextSession)[0];
      console.log('Booking date from data:', date);
      
      const timeSlot = nextSession[date][0];
      const teacherId = booking.Teacher_ID?.[0]?._id;
      
      try {
        // Create a moment object for the booking date in student's timezone
        const meetingDate = moment.tz(date, 'YYYY-MM-DD', 'UTC').tz(student?.Timezone || moment.tz.guess());
        console.log('Parsed meeting date:', meetingDate.format('YYYY-MM-DD'), 'for booking ID:', booking._id);
        
        // Skip past meetings
        if (meetingDate.isBefore(now)) {
          console.log('Skipping past meeting:', date);
          return null;
        }
        
        const teacherDetails = (await dispatch(fetchTeacherDetailsWithoutSlot(teacherId))).payload;
        
        return {
          id: booking._id,
          date: meetingDate,
          rawDate: date, // Store original date string for debugging
          startTime: timeSlot.start,
          endTime: timeSlot.end,
          status: booking.Status,
          teacherId,
          teacher: {
            name: teacherDetails?.Username,
            profilePic: teacherDetails?.Profile_Image?.[0],
            subject: teacherDetails?.Courses_assign?.[0]?.Course_Name,
            specialization: teacherDetails?.Short_Title,
            language: teacherDetails?.Description
          }
        };
      } catch (error) {
        console.error("Error fetching teacher details:", error);
        return null;
      }
    }));
  
    const validMeetings = meetings.filter(Boolean);
    console.log('Valid upcoming meetings before sorting:', 
      validMeetings.map(m => ({id: m.id, date: m.date.format('YYYY-MM-DD')}))
    );
    
    // Sort by date and get the first 3
    const sortedMeetings = validMeetings
      .sort((a, b) => a.date.valueOf() - b.date.valueOf())
      .slice(0, 3);
      
    console.log('Sorted upcoming meetings:', 
      sortedMeetings.map(m => ({id: m.id, date: m.date.format('YYYY-MM-DD')}))
    );
    
    return sortedMeetings;
  }, [bookings, dispatch, student?.Timezone]);

  

  const { totalLectures, completedSessions, pendingSessions } = stats();
  const { currentStreak, longestStreak } = calculateStreak();

  useEffect(() => {
    const fetchMeetings = async () => {
      const meetings = await getUpcomingMeetings();
      setUpcomingMeetings(meetings);
    };
    fetchMeetings();
  }, [getUpcomingMeetings]);

  

  // Stats cards data
  const cardColors = {
    totalLectures: { bg: 'bg-blue-100', text: 'text-blue-700', icon: 'bg-blue-500' },
    completed: { bg: 'bg-green-100', text: 'text-green-700', icon: 'bg-green-500' },
    pending: { bg: 'bg-amber-100', text: 'text-amber-700', icon: 'bg-amber-500' },
    hours: { bg: 'bg-purple-100', text: 'text-purple-700', icon: 'bg-purple-500' },
    streak: { bg: 'bg-rose-100', text: 'text-rose-700', icon: 'bg-rose-500' }
  };

  const statsCards = [
    {
      icon: BookOpen,
      label: t("StudentDash.upperhead2"),
      value: totalLectures,
      colors: cardColors.totalLectures,
      percent: (totalLectures / (totalLectures || 1)) * 100
    },
    {
      icon: Check,
      label: t("StudentDash.upperhead3"),
      value: completedSessions,
      colors: cardColors.completed,
      percent: (completedSessions / totalLectures) * 100
    },
    {
      icon: AlertCircle,
      label: t("StudentDash.upperhead4"),
      value: pendingSessions,
      colors: cardColors.pending,
      percent: (pendingSessions / totalLectures) * 100
    },
    
    {
      icon: Flame,
      label: t("StudentDash.CurrentStreak"),
      value: `${currentStreak} days`,
      colors: cardColors.streak,
      percent: (currentStreak / (longestStreak || 1)) * 100,
      tooltip: `${t("StudentDash.LongestStreak")} ${longestStreak}`
    }
  ];

  if (isLoading) {
    return (
      <Loader2 
        loading={true}
        text={t("common.Loading")}
        fullScreen={true}
        size={300}
        customStyles={{
          backgroundColor: 'rgba(15, 15, 15, 0.5)'
        }}
      />
    );
  }

  if (error) {
    return (
      <div className="p-6 text-center">
        <h2 className="text-red-500">{t("common.Error")}</h2>
        <p>{error}</p>
      </div>
    );
  }

  const renderTimeDisplay = () => (
    <div className="text-center">
      <div className="text-xl sm:text-2xl font-bold text-gray-800">
        {currentTime.format(timeFormat === '24' ? 'HH:mm:ss' : 'hh:mm:ss A')}
      </div>
      <div className="text-sm sm:text-base text-gray-600">
        {student?.Timezone || moment.tz.guess()}
        {' '}
        ({currentTime.format('z')})
      </div>
      <Button 
        onClick={() => {
          const newFormat = timeFormat === '24' ? '12' : '24';
          setTimeFormat(newFormat);
          localStorage.setItem('timeFormat', newFormat);
        }}
        size="small"
        type="link"
      >
        {timeFormat === '24' ? '12h' : '24h'}
      </Button>
    </div>
  );


  const TimezoneModal = () => (
    <Modal
      visible={showPrompt}
      onCancel={() => setShowPrompt(false)}
      title={t("StudentDash.TimezonePrompt")}
      footer={[
        <Button key="no" onClick={() => handleTimezonePromptResponse('no')}>
          {t("common.KeepCurrent")}
        </Button>,
        <Button key="yes" type="primary" onClick={() => handleTimezonePromptResponse('yes')}>
          {t("common.UpdateTimezone")}
        </Button>
      ]}
    >
      <p>{t("StudentDash.TimezonePromptMessage", { 
        detected: initialTimezone,
        current: student?.Timezone 
      })}</p>
    </Modal>
  );

  // Add this new component for all bookings section
  const renderAllBookings = () => {
    const displayedBookings = showAllBookings ? filteredBookings : filteredBookings.slice(0, 3);
    const currentLanguage = i18n.language.split('-')[0];
  
    return (
    <Card 
      title={
        <div className="flex flex-col sm:flex-row justify-between items-center w-full gap-4">
          <h2 className="text-xl sm:text-2xl font-bold text-indigo-700">
            {t("StudentDash.MaterialsBookings")}
          </h2>
          <div className="w-full sm:w-auto">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              <input
                type="text"
                placeholder={t("StudentDash.SearchBookings")}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full sm:w-auto pl-10 pr-4 py-2 border-2 border-gray-200 rounded-lg focus:outline-none focus:border-indigo-500 transition-colors"
              />
            </div>
          </div>
        </div>
      }
      className="mb-4 sm:mb-6 bg-white rounded-lg sm:rounded-xl shadow-xl"
    >
      <div className="overflow-hidden">
        <div className="grid gap-4 sm:gap-6 p-4 sm:p-6">
          {displayedBookings.map((booking) => {
            const teacher = booking.Teacher_ID?.[0];
            const translatedStatus = getTranslatedStatus(booking.Status, currentLanguage);
            
            return (
              <div 
                key={booking._id}
                className="bg-white rounded-xl border-2 border-gray-100 p-4 sm:p-6 hover:border-indigo-200 transition-all"
              >
                <div className="flex flex-col gap-4">
                  <div className="flex items-start gap-4">
                    <img
                      src={"https://speakable.online/api/image/" + teacher?.Profile_Image?.[0] || "/api/placeholder/64/64"}
                      alt={teacher?.Username || t("StudentDash.TeacherNotAvailable")}
                      className="w-12 h-12 sm:w-16 sm:h-16 rounded-full object-cover border-4 border-indigo-100 flex-shrink-0"
                    />
                    <div className="flex-1 min-w-0">
                      <h3 className="text-lg font-semibold text-gray-900 break-words">
                        {teacher?.Username || t("StudentDash.TeacherNotAvailable")}
                      </h3>
                      <p className="text-sm text-gray-500 mt-1">
                        {teacher?.Courses_assign?.[0]?.Course_Name || t("StudentDash.SubjectNotAvailable")}
                      </p>
                      <div className="flex flex-wrap items-center gap-2 mt-2">
                        <span className={`px-3 py-1 rounded-full text-sm border ${getStatusColor(booking.Status, currentLanguage)}`}>
                          {translatedStatus}
                        </span>
                        <span className="text-sm text-gray-500">
                          {formatBookingDate(booking.Scheduled_Dates)}
                        </span>
                      </div>
                    </div>
                  </div>
                  
                  <div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-3 mt-2">
                    <button
                      onClick={() => handleOpenMaterialsModal(booking._id)}
                      className="w-full sm:w-auto px-4 py-2 bg-emerald-500 hover:bg-emerald-600 text-white rounded-lg shadow-lg flex items-center justify-center gap-2 transform hover:-translate-y-0.5 transition-all"
                    >
                      <FolderOpen className="w-5 h-5" />
                      <span>{t("StudentDash.Materials")}</span>
                    </button>
                    
                  </div>
                </div>
              </div>
            );
          })}
          
          {filteredBookings.length > 3 && !showAllBookings && (
            <button
              onClick={() => setShowAllBookings(true)}
              className="w-full py-3 text-indigo-600 bg-indigo-50 hover:bg-indigo-100 rounded-lg font-medium transition-colors"
            >
              {t("common.ShowAll")}
            </button>
          )}

          {filteredBookings.length === 0 && (
            <div className="text-center py-8 bg-gray-50 rounded-xl border-2 border-gray-100">
              <div className="text-xl font-semibold text-gray-600">
                {searchTerm 
                  ? t("StudentDash.NoMatchingBookings")
                  : t("StudentDash.NoBookingsAvailable")
                }
              </div>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

  
  return (
    <div className="p-3 pb-24 sm:p-4 md:p-6 bg-gray-50 min-h-screen">


      {/* Header Card */}
      <div className="mb-4 sm:mb-6 bg-white rounded-xl sm:rounded-2xl shadow-lg overflow-hidden">
        <div className="p-4 sm:p-6 bg-gradient-to-r from-cyan-50 to-blue-50">
          <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
            <div className="space-y-2 text-center sm:text-left">
              <h1 className="text-2xl sm:text-3xl font-bold text-gray-800">
                {t("StudentDash.panel", { name: student?.Username })}
              </h1>
              <h2 className="text-lg sm:text-xl text-gray-600">{student?.Username}</h2>
            </div>
            <div className="hidden sm:block">
              <Lottie
                loop
                animationData={clockAnimation}
                play
                style={{ width: 120, height: 120 }}
              />
            </div>
            <div className="text-center">
           {/* Change this line to use the timeFormat state */}
            <div className="text-xl sm:text-2xl font-bold text-gray-800">
         {currentTime.format(timeFormat === '24' ? 'HH:mm:ss' : 'hh:mm:ss A')}
  </div>
  <div className="text-sm sm:text-base text-gray-600">
    {student?.Timezone || moment.tz.guess()}
    {' '}
    ({currentTime.format('z')})
  </div>
</div>
          </div>
        </div>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-3 sm:gap-4 md:gap-6 mb-4 sm:mb-6">
        {statsCards.map((stat, index) => (
          <div key={index} className={`${stat.colors.bg} rounded-xl sm:rounded-2xl p-4 sm:p-6 shadow-lg transition-transform hover:scale-105`}>
            <div className="flex justify-between items-start">
              <div className={`${stat.colors.icon} p-2 sm:p-3 rounded-lg sm:rounded-xl text-white`}>
                <stat.icon size={20} className="sm:w-6 sm:h-6" />
              </div>
              <Progress 
                type="circle" 
                percent={Math.round(stat.percent)} 
                width={40} 
                strokeColor={stat.colors.icon.replace('bg-', 'rgb(')} 
                className="scale-75 sm:scale-90"
              />
            </div>
            <h3 className={`${stat.colors.text} mt-3 sm:mt-4 text-sm sm:text-base font-medium`}>{stat.label}</h3>
            <p className="text-xl sm:text-2xl font-bold text-gray-800 mt-1 sm:mt-2">{stat.value}</p>
            {stat.tooltip && (
              <p className="text-xs sm:text-sm text-gray-600 mt-1">{stat.tooltip}</p>
            )}
          </div>
        ))}
      </div>

      {/* Upcoming Classes */}
      <Card 
  title={<h2 className="text-xl sm:text-2xl font-bold text-indigo-700">{t("StudentDash.UpcomingMeetings")}</h2>}
  className="mb-4 sm:mb-6 bg-white rounded-lg sm:rounded-xl shadow-xl"
>
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 p-3 sm:p-6">
  {upcomingMeetings.map((meeting, index) => {
  // Convert the meeting times to local timezone
  const startTime = moment.tz(
    `${meeting.date.format('YYYY-MM-DD')} ${meeting.startTime}`,
    'YYYY-MM-DD HH:mm',
    'UTC'
  ).tz(student?.Timezone || moment.tz.guess());

  const endTime = moment.tz(
    `${meeting.date.format('YYYY-MM-DD')} ${meeting.endTime}`,
    'YYYY-MM-DD HH:mm',
    'UTC'
  ).tz(student?.Timezone || moment.tz.guess());

  return (
    <Card key={index} className="bg-white rounded-xl sm:rounded-2xl p-4 sm:p-6 shadow-lg border-2 border-indigo-100 hover:border-indigo-300 transition-all">
      {/* Teacher info section */}
      <div className="flex items-center gap-2 sm:gap-4 mb-3 sm:mb-6">
        <div className="relative flex-shrink-0">
          <img
            src={"https://speakable.online/api/image/" + meeting.teacher?.profilePic || "/api/placeholder/64/64"} 
            alt={meeting.teacher.name}
            className="w-10 h-10 xs:w-12 xs:h-12 sm:w-14 sm:h-14 md:w-16 md:h-16 rounded-full object-cover border-2 sm:border-3 md:border-4 border-pink-400 shadow-md sm:shadow-lg"
          />
          <div className="absolute bottom-0 right-0 w-2.5 h-2.5 xs:w-3 xs:h-3 sm:w-3.5 sm:h-3.5 md:w-4 md:h-4 rounded-full bg-green-400 border-2 border-white shadow-sm"></div>
        </div>
        <div className="min-w-0">
          <h3 className="text-base xs:text-lg sm:text-xl font-bold text-indigo-700 truncate">{meeting.teacher.name}</h3>
          <p className="text-xs xs:text-sm sm:text-base text-gray-600 truncate">{meeting.teacher?.subject || t("StudentDash.Subject")}</p>
        </div>
      </div>
      
      <div className="space-y-3 sm:space-y-4 mb-4 sm:mb-6">
        <div className="flex items-center gap-2 sm:gap-3 bg-orange-100 p-2 sm:p-3 rounded-lg text-sm sm:text-base">
          <CalendarIcon className="w-4 h-4 sm:w-5 sm:h-5 text-orange-500" />
          <span className="text-orange-700 font-medium">
            {startTime.format('LL')}
          </span>
        </div>
        <div className="flex items-center gap-2 sm:gap-3 bg-cyan-100 p-2 sm:p-3 rounded-lg text-sm sm:text-base">
          <Clock className="w-4 h-4 sm:w-5 sm:h-5 text-cyan-500" />
          <span className="text-cyan-700 font-medium">
            {startTime.format(timeFormat === '24' ? 'HH:mm' : 'hh:mm A')} - 
            {endTime.format(timeFormat === '24' ? 'HH:mm' : 'hh:mm A')}
          </span>
        </div>
        <div className="flex items-center gap-2 sm:gap-3 bg-purple-100 p-2 sm:p-3 rounded-lg text-sm sm:text-base">
          <BookOpen className="w-4 h-4 sm:w-5 sm:h-5 text-purple-500" />
          <span className="text-purple-700 font-medium">{meeting.lessonTitle || t("StudentDash.UpcomingLesson")}</span>
        </div>
      </div>

      <button
        onClick={() => joinRoom(meeting.id, meeting.teacherId)}
        disabled={isJoiningRoom}
        className={`w-full py-2 sm:py-3 text-sm sm:text-base ${
          isJoiningRoom 
            ? 'bg-gray-400 cursor-not-allowed' 
            : 'bg-indigo-500 hover:bg-indigo-600'
        } text-white rounded-lg sm:rounded-xl shadow-lg flex items-center justify-center gap-2 transform hover:-translate-y-0.5 transition-all`}
      >
        {isJoiningRoom && currentBookingId === meeting.id ? (
          <>
            <div className="animate-spin h-4 w-4 border-2 border-white border-t-transparent rounded-full mr-2"></div>
            {t("StudentDash.Joining")}
          </>
        ) : (
          <>
            <MessageOutlined />
            {t("StudentDash.JoinRoom")}
          </>
        )}
      </button>
    </Card>
  );
})}
    {upcomingMeetings.length === 0 && (
      <div className="col-span-full text-center py-8 sm:py-12 bg-indigo-50 rounded-xl border-2 border-indigo-100">
        <div className="text-xl sm:text-2xl font-semibold text-indigo-700">
          {t("StudentDash.footer")}
        </div>
      </div>
    )}
  </div>
</Card>

      {/* Student Evaluations */}
<div className="mb-4 sm:mb-6">
  <StudentEvaluations />
</div>

      {/* Achievement Card */}
      <div className="mb-4 sm:mb-6 bg-gradient-to-r from-violet-100 to-fuchsia-100 rounded-xl sm:rounded-2xl p-4 sm:p-6 shadow-lg">
        <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
          <div className="space-y-2 text-center sm:text-left">
            <h3 className="text-xl sm:text-2xl font-bold text-gray-800">{t("StudentDash.Progress")}</h3>
            <p className="text-base sm:text-lg text-gray-600">
              {t("StudentDash.CompletionStatus", { 
                completed: completedSessions,
                total: totalLectures 
              })}
            </p>
          </div>
          <div className="flex items-center gap-4 sm:gap-6">
            <Award size={40} className="text-violet-500 sm:w-12 sm:h-12" />
            <div className="text-center">
              <div className="text-2xl sm:text-3xl font-bold text-gray-800">
                {Math.round((completedSessions / totalLectures) * 100)}%
              </div>
              <div className="text-sm sm:text-base text-gray-600">{t("StudentDash.CompletionStatus")}</div>
            </div>
          </div>
        </div>
      </div>

      {renderAllBookings()}

      {/* Modals and Popups */}
      {/* {showPrompt && (
        <Modal
          visible={showPrompt}
          onCancel={() => setShowPrompt(false)}
          footer={null}
          title={t("StudentDash.TimezonePrompt")}
        >
          <p>{t("StudentDash.TimezonePromptMessage")}</p>
          <div className="flex justify-end gap-2 mt-4">
            <Button onClick={() => setShowPrompt(false)}>{t("common.Cancel")}</Button>
            <Button type="primary" onClick={handleTimezonePrompt}>{t("common.Update")}</Button>
          </div>
        </Modal>
      )} */}

      <GuidePopup
        isOpen={isGuideOpen}
        onClose={() => setIsGuideOpen(false)}
        currentTimezone={student?.Timezone}
        onTimezoneChange={handleTimezonePrompt}
      />

      {error && (
        <Modal
          visible={!!error}
          onOk={() => setError(null)}
          onCancel={() => setError(null)}
          title={t("common.Error")}
        >
          <p className="text-red-500">{error}</p>
        </Modal>
      )}

      {/* Join Room Modal */}
      {isJoiningRoom && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4">
      <div className="text-center">
        {!teacherJoined ? (
          <>
            <div className="animate-spin rounded-full h-12 w-12 border-4 border-emerald-500 border-t-transparent mx-auto"></div>
            <p className="mt-4 text-gray-600">{t("status.WaitingPeriod")}</p>
            <p className="mt-2 text-sm text-gray-500">
              {t("status.teacherWillJoin")}
            </p>
            <div className="mt-4 text-xs text-gray-400">
              {/* {t("status.teacherNotificationSent")} */}
            </div>
          </>
        ) : (
          <>
            <div className="relative h-20 w-20 mx-auto">
              <svg className="transform -rotate-90" viewBox="0 0 36 36">
                <path
                  d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                  fill="none"
                  stroke="#E5E7EB"
                  strokeWidth="3"
                />
                <path
                  d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                  fill="none"
                  stroke="#10B981"
                  strokeWidth="3"
                  strokeDasharray={`${(100 - (waitTimeLeft / 10 * 100)) * 1}, 100`}
                />
              </svg>
            </div>
            <p className="mt-4 text-gray-600">
              {t("status.teacherSettingUp", { seconds: waitTimeLeft })}
            </p>
          </>
        )}

        <div className="mt-6 flex space-x-3 justify-center">
          <button
            onClick={handleCancelJoining}
            className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors"
          >
            {t("common.Cancel")}
          </button>
          
          {/* <button
            onClick={() => directJoinRoom(currentBookingId)}
            className="px-4 py-2 bg-emerald-500 text-white rounded-lg hover:bg-emerald-600 transition-colors"
          >
            {t("status.JoinAnyway")}
          </button> */}
        </div>
      </div>
    </div>
  </div>
)}

<YourMaterialModal2
        open={isMaterialModalVisible}
        isVisible={isMaterialModalVisible}
        onClose={handleCloseMaterialsModal}
        bookingId={selectedBookingId}
      />

      {/* Debug information */}
      <div style={{ display: 'none' }}>
        Modal visible: {isMaterialModalVisible ? 'true' : 'false'}
        Selected booking: {selectedBookingId}
      </div>
    </div>
    
  );
};

export default StudentDashboard;
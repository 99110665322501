// components/TeacherNotes/NoteDetailModal.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { 
  Modal, Typography, Space, Tag, Badge, 
  Tooltip, Divider, Row, Col, Card
} from 'antd';
import { 
  LockOutlined, UserOutlined, EyeOutlined,
  ClockCircleOutlined, BookOutlined,
  TagOutlined, EditOutlined
} from '@ant-design/icons';

import moment from 'moment-timezone';

const { Text, Paragraph, Title } = Typography;

const NoteDetailModal = ({ 
  isVisible, 
  onClose, 
  note
}) => {
  const { t } = useTranslation('global');

  if (!note) return null;

  const renderVisibilityIcon = (visibility) => {
    switch (visibility) {
      case 'private':
        return <Tooltip title={t('teacherNotes.private')}><LockOutlined /></Tooltip>;
      case 'student':
        return <Tooltip title={t('teacherNotes.visibleToStudent')}><UserOutlined /></Tooltip>;
      case 'admin':
        return <Tooltip title={t('teacherNotes.visibleToAdmin')}><EyeOutlined /></Tooltip>;
      case 'all':
        return <Tooltip title={t('teacherNotes.visibleToAll')}><EyeOutlined /></Tooltip>;
      default:
        return <LockOutlined />;
    }
  };

  const getCategoryColor = (category) => {
    switch (category) {
      case 'lesson': return 'blue';
      case 'progress': return 'green';
      case 'behavior': return 'orange';
      default: return 'default';
    }
  };

  // Custom style for book appearance
  const bookStyle = {
    container: {
      background: '#f8f5e6', // Soft paper-like background
      borderRadius: '0',
      boxShadow: '0 6px 12px rgba(0,0,0,0.15), 5px 0 15px rgba(0,0,0,0.1), -5px 0 15px rgba(0,0,0,0.1)',
      padding: 0,
      overflow: 'hidden',
      maxHeight: '80vh',
    },
    header: {
      background: getCategoryColor(note.category) === 'blue' ? '#e6f7ff' :
                 getCategoryColor(note.category) === 'green' ? '#f6ffed' :
                 getCategoryColor(note.category) === 'orange' ? '#fff7e6' : '#f5f5f5',
      borderBottom: `2px solid ${getCategoryColor(note.category) === 'blue' ? '#91d5ff' :
                                  getCategoryColor(note.category) === 'green' ? '#b7eb8f' :
                                  getCategoryColor(note.category) === 'orange' ? '#ffd591' : '#d9d9d9'}`,
      padding: '12px 24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    pageLeft: {
      background: '#f8f5e6',
      padding: '24px',
      minHeight: '400px',
      borderRight: '1px solid rgba(0,0,0,0.05)',
      boxShadow: 'inset -7px 0 10px -7px rgba(0,0,0,0.1)',
      display: 'flex',
      flexDirection: 'column'
    },
    pageRight: {
      background: '#f8f5e6', 
      backgroundImage: 'linear-gradient(to right, rgba(0,0,0,0.05) 1px, transparent 1px)',
      backgroundSize: '24px 100%',
      padding: '24px 24px 24px 48px',
      minHeight: '400px',
      position: 'relative',
      boxShadow: 'inset 7px 0 10px -7px rgba(0,0,0,0.1)'
    },
    binding: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '24px',
      height: '100%',
      background: 'repeating-linear-gradient(to bottom, transparent, transparent 15px, rgba(0,0,0,0.1) 15px, rgba(0,0,0,0.1) 18px)',
      boxShadow: 'inset -2px 0 3px rgba(0,0,0,0.1)'
    },
    noteContent: {
      fontSize: '16px',
      lineHeight: '28px',
      color: '#333',
      whiteSpace: 'pre-wrap',
      fontFamily: '"Noto Serif", Georgia, serif',
      textAlign: 'justify',
      letterSpacing: '0.01em'
    },
    pageNumber: {
      position: 'absolute',
      bottom: '12px',
      right: '20px',
      fontFamily: '"Noto Serif", Georgia, serif',
      fontSize: '12px',
      fontStyle: 'italic',
      color: '#888'
    }
  };

  return (
    <Modal
      title={null}
      open={isVisible}
      onCancel={onClose}
      footer={null}
      width={900}
      centered
      className="teacher-notes-book-modal"
      bodyStyle={bookStyle.container}
      closeIcon={<span style={{ color: '#555', fontSize: '18px', padding: '5px' }}>×</span>}
    >
      <div style={bookStyle.header}>
        <Space>
          <BookOutlined style={{ fontSize: '18px', color: getCategoryColor(note.category) === 'default' ? '#555' : bookStyle.header.borderBottom.split(' ')[2] }} />
          <Title level={4} style={{ margin: 0 }}>
            {t(`teacherNotes.categories.${note.category}`)} {t('teacherNotes.note')}
          </Title>
          <Tag color={getCategoryColor(note.category)}>
            {t(`teacherNotes.categories.${note.category}`)}
          </Tag>
          {renderVisibilityIcon(note.visibility)}
        </Space>
        {note.updatedAt && note.updatedAt !== note.createdAt && (
          <EditOutlined style={{ fontSize: '16px', color: '#888' }} />
        )}
      </div>
      <Row>
        <Col span={8}>
          <div style={bookStyle.pageLeft}>
            <Card 
              bordered={false} 
              style={{ background: 'rgba(255,255,255,0.6)', marginBottom: '16px' }}
              size="small"
            >
              <Space direction="vertical" size={2}>
                <Text type="secondary">
                  <ClockCircleOutlined /> {t('teacherNotes.createdAt')}:
                </Text>
                <Text>{moment(note.createdAt).format('MMMM Do YYYY')}</Text>
                <Text>{moment(note.createdAt).format('h:mm a')}</Text>
              </Space>
            </Card>
            
            {note.updatedAt && note.updatedAt !== note.createdAt && (
              <Card 
                bordered={false} 
                style={{ background: 'rgba(255,255,255,0.6)', marginBottom: '16px' }}
                size="small"
              >
                <Space direction="vertical" size={2}>
                  <Text type="secondary">
                    <EditOutlined /> {t('teacherNotes.updatedAt')}:
                  </Text>
                  <Text>{moment(note.updatedAt).format('MMMM Do YYYY')}</Text>
                  <Text>{moment(note.updatedAt).format('h:mm a')}</Text>
                </Space>
              </Card>
            )}

            {note.tags?.length > 0 && (
              <Card 
                bordered={false} 
                style={{ background: 'rgba(255,255,255,0.6)', marginBottom: '16px' }}
                size="small"
                title={<><TagOutlined /> {t('teacherNotes.tags')}</>}
              >
                <Space wrap>
                  {note.tags.map(tag => (
                    <Tag key={tag} color="purple">
                      {tag}
                    </Tag>
                  ))}
                </Space>
              </Card>
            )}
          </div>
        </Col>
        <Col span={16}>
          <div style={bookStyle.pageRight}>
            <div style={bookStyle.binding}></div>
            <Paragraph style={bookStyle.noteContent}>
              {note.content}
            </Paragraph>
            <div style={bookStyle.pageNumber}>1</div>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default NoteDetailModal;
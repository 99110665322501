import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axiosconfig"; // Update path

export const teacherJoinSession = createAsyncThunk(
  "teacherSession/joinSession",
  async ({ bookingId, teacherId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`teacher-join-session/${bookingId}/${teacherId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTeacherSessionStatus = createAsyncThunk(
  'teacherSession/getStatus',
  async ({ bookingId, teacherId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`teacher-session-status/${bookingId}/${teacherId}`);
      return response.data;
    } catch (error) {
      console.error('Teacher session status error:', error);
      return rejectWithValue(error.response?.data || 'Failed to get teacher status');
    }
  }
);

export const studentJoinSession = createAsyncThunk(
  "studentSession/joinSession",
  async ({ bookingId, studentId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`student-join-session/${bookingId}/${studentId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStudentSessionStatus = createAsyncThunk(
  "studentSession/getStatus", 
  async ({ bookingId, studentId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`student-session-status/${bookingId}/${studentId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
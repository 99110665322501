import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchWalletBalance, fetchWalletTransactions } from "../../store/actions/walletActions";
import { 
  Search, Clock, Calendar, ChevronRight, AlertCircle, Package, 
  TrendingUp, Clock4, LayoutGrid, List, Sparkles, Gift, 
  ArrowUpRight, BarChart3, Wallet
} from 'lucide-react';
import AdminNav from "../admin-dashboard-components/AdminNav";
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from "react-i18next";


// Stat Card Component
const StatCard = ({ icon: Icon, label, value, trend, color }) => (
  <motion.div 
    whileHover={{ y: -4 }}
    className={`bg-white rounded-2xl p-6 shadow-sm hover:shadow-xl transition-all duration-300`}
  >
    <div className="flex items-center gap-4">
      <div className={`p-3 rounded-xl ${color.light}`}>
        <Icon className={`h-6 w-6 ${color.text}`} />
      </div>
      <div>
        <p className="text-sm font-medium text-gray-600">{label}</p>
        <div className="flex items-center gap-2">
          <h3 className="text-2xl font-bold text-gray-900">{value}</h3>
          {trend && (
            <span className={`text-sm font-medium ${trend > 0 ? 'text-green-600' : 'text-red-600'}`}>
              {trend > 0 ? '+' : ''}{trend}%
            </span>
          )}
        </div>
      </div>
    </div>
  </motion.div>
);

// Package Card Component
const PackageCard = ({ balance, packageId, onBookNow }) => {
  const totalHours = balance.packageDetails?.lectures?.[0]?.count || 100;
  const progress = totalHours > 0 ? (balance.hours / totalHours) * 100 : 0;
  const isLowHours = balance.hours < (totalHours * 0.2);
  const { t } = useTranslation("global");

  const packageName = balance.name !== "Package Name" 
    ? balance.name 
    : balance.packageDetails?.Package_Name || 
      balance.packageName || 
      t("StudentWallet.UnnamedPackage");

  return (
    <motion.div 
      whileHover={{ y: -8 }}
      className="bg-white rounded-2xl shadow-sm overflow-hidden"
    >
      <div className="p-6">
        <div className="flex justify-between items-start mb-6">
          <div className="flex-1">
            <h3 className="text-xl font-bold text-gray-900 break-words">
              {packageName}
            </h3>
            {balance.packageDetails?.courseName && (
              <p className="text-sm text-purple-600 mt-1 break-words">
                {balance.packageDetails.courseName}
              </p>
            )}
            <p className="text-xs text-gray-500 mt-1">{t("StudentWallet.PackageID")} {packageId.slice(0, 8)}...</p>
          </div>
          {isLowHours && balance.hours > 0 && (
            <span className="ml-2 px-3 py-1 bg-red-100 text-red-700 rounded-full text-sm font-medium whitespace-nowrap">
              {t("StudentWallet.LowHours")}
            </span>
          )}
        </div>

        <div className="space-y-6">
          {/* Hours Progress */}
          <div>
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm font-medium text-gray-600">{t("StudentWallet.RemainingHours")}</span>
              <span className="text-sm font-bold text-gray-900">
                {balance.hours} / {totalHours}
              </span>
            </div>
            <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
              <motion.div 
                initial={{ width: 0 }}
                animate={{ width: `${progress}%` }}
                transition={{ duration: 1, ease: "easeOut" }}
                className={`h-full rounded-full ${
                  isLowHours && balance.hours > 0
                    ? 'bg-gradient-to-r from-red-500 to-red-600'
                    : 'bg-gradient-to-r from-purple-500 to-purple-600'
                }`}
              />
            </div>
          </div>

          {/* Package Details */}
          {(balance.packageDetails || balance.packageName !== "Unknown Package") && (
            <div className="space-y-2">
              <div className="flex justify-between items-center">
                <h4 className="text-sm font-semibold text-gray-900">{t("StudentWallet.PackageDetails")}</h4>
              </div>
              <div className="grid grid-cols-2 gap-4">
                {balance.packageDetails?.Package_Amount > 0 && (
                  <div className="p-3 bg-gray-50 rounded-xl">
                    <p className="text-xs text-gray-500">{t("StudentWallet.Amount")}</p>
                    <p className="text-sm font-medium text-gray-900">
                      {balance.packageDetails.Package_Amount.toLocaleString()} PLN
                    </p>
                  </div>
                )}
                {totalHours > 0 && (
                  <div className="p-3 bg-gray-50 rounded-xl">
                    <p className="text-xs text-gray-500">{t("StudentWallet.TotalSessions")}</p>
                    <p className="text-sm font-medium text-gray-900">
                      {totalHours}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Action Footer */}
      <div className="p-6 bg-gray-50 border-t border-gray-100">
        <button
          onClick={() => onBookNow(packageId)}
          className="w-full bg-gradient-to-r from-purple-600 to-purple-700 text-white px-4 py-3 rounded-xl font-medium hover:from-purple-700 hover:to-purple-800 transition-all duration-300 flex items-center justify-center gap-2"
        >
          {t("StudentWallet.BookLesson")}
          <ArrowUpRight className="h-4 w-4" />
        </button>
      </div>
    </motion.div>
  );
};

const MyPackages = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const student = useSelector((state) => state.students.user);
  const wallet = useSelector((state) => state.wallet);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [sortBy, setSortBy] = useState('hoursRemaining');
  const [showExpiring, setShowExpiring] = useState(false);
  const { t } = useTranslation("global");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        if (student?._id) {
          await Promise.all([
            dispatch(fetchWalletBalance(student._id)),
            dispatch(fetchWalletTransactions(student._id))
          ]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchData();
    const intervalId = setInterval(fetchData, 30000); // Only refreshes every 30 seconds
    return () => clearInterval(intervalId);
  }, [dispatch, student]);

  const handleBookNow = (packageId) => {
    const hoursAvailable = wallet.packageBalances[packageId]?.hours || 0;
    if (hoursAvailable > 0) {
      navigate(`/Student-dashboard/EditPackageTeacher/${packageId}`);
    } else {
      setSelectedPackage(packageId);
      setShowBookingModal(true);
    }
  };

  const activePackages = Object.entries(wallet.packageBalances || {})
    .filter(([_, balance]) => balance.hours > 0)
    .filter(([packageId, balance]) => {
      const packageName = (balance.packageDetails?.Package_Name || 
                         balance.packageName || 
                         balance.name || 
                         '').toLowerCase();
      
      const matchesSearch = packageName.includes(searchTerm.toLowerCase()) ||
                          packageId.toLowerCase().includes(searchTerm.toLowerCase());

      const totalHours = balance.packageDetails?.lectures?.[0]?.count || 100;
      const isExpiring = showExpiring ? balance.hours < (totalHours * 0.2) : true;
      
      return matchesSearch && isExpiring;
    })
    .sort((a, b) => {
      const [, balanceA] = a;
      const [, balanceB] = b;
      
      switch(sortBy) {
        case 'hoursRemaining':
          return balanceB.hours - balanceA.hours;
        case 'name':
          const nameA = (balanceA.packageDetails?.Package_Name || 
                        balanceA.packageName || 
                        balanceA.name || 
                        '').toLowerCase();
          const nameB = (balanceB.packageDetails?.Package_Name || 
                        balanceB.packageName || 
                        balanceB.name || 
                        '').toLowerCase();
          return nameA.localeCompare(nameB);
        case 'expiration':
          const hoursA = balanceA.hours;
          const hoursB = balanceB.hours;
          const totalHoursA = balanceA.packageDetails?.lectures?.[0]?.count || 100;
          const totalHoursB = balanceB.packageDetails?.lectures?.[0]?.count || 100;
          return (hoursA / totalHoursA) - (hoursB / totalHoursB);
        default:
          return 0;
      }
    });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500" />
      </div>
    );
  }

  const totalRemainingHours = Object.values(wallet.packageBalances || {})
    .reduce((sum, balance) => sum + balance.hours, 0);

    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
        <AdminNav />
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          {/* Header */}
          <div className="mb-8">
            <h1 className="text-3xl font-bold text-gray-900">{t("StudentWallet.MyLearningPackages")}</h1>
            <p className="mt-2 text-gray-600">{t("StudentWallet.ManageLearning")}</p>
          </div>
  
          {/* Stats Overview */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
            <StatCard 
              icon={Package}
              label={t("StudentWallet.ActivePackages")}
              value={activePackages.length}
              color={{ light: 'bg-purple-50', text: 'text-purple-600' }}
            />
            <StatCard 
              icon={Clock4}
              label={t("StudentWallet.TotalHoursLeft")}
              value={totalRemainingHours}
              trend={5}
              color={{ light: 'bg-blue-50', text: 'text-blue-600' }}
            />
            <StatCard 
              icon={BarChart3}
              label={t("StudentWallet.MonthlyUsage")}
              value={`${wallet.monthlyUsage || 0}h`}
              trend={-2}
              color={{ light: 'bg-green-50', text: 'text-green-600' }}
            />
          </div>
  
          {/* Search and Filters */}
          <div className="bg-white rounded-2xl shadow-sm p-6 mb-8">
            <div className="flex flex-col md:flex-row gap-6 items-center justify-between">
              <div className="relative w-full md:w-96">
                <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                <input
                  type="text"
                  className="w-full pl-12 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all"
                  placeholder={t("StudentWallet.SearchPackages")}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              
              <div className="flex flex-wrap gap-4 w-full md:w-auto">
                <select
                  className="px-4 py-2 border border-gray-200 rounded-xl bg-white focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all"
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                >
                  <option value="hoursRemaining">{t("StudentWallet.SortByHours")}</option>
                  <option value="name">{t("StudentWallet.SortByName")}</option>
                  <option value="expiration">{t("StudentWallet.SortByExpiry")}</option>
                </select>
                
                <button
                  className={`flex items-center px-4 py-2 rounded-xl font-medium transition-all ${
                    showExpiring
                      ? 'bg-red-100 text-red-700 hover:bg-red-200'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                  onClick={() => setShowExpiring(!showExpiring)}
                >
                  <AlertCircle className="h-4 w-4 mr-2" />
                  {t("StudentWallet.ExpiringSoon")}
                </button>
              </div>
            </div>
          </div>
  
          {/* Packages Grid */}
          <AnimatePresence>
            {activePackages.length > 0 ? (
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
              >
                {activePackages.map(([packageId, balance]) => (
                  <PackageCard
                    key={packageId}
                    balance={balance}
                    packageId={packageId}
                    onBookNow={handleBookNow}
                  />
                ))}
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="text-center py-16 bg-white rounded-2xl shadow-sm"
              >
                <div className="bg-gradient-to-br from-purple-100 to-purple-200 rounded-full h-20 w-20 flex items-center justify-center mx-auto mb-6">
                  <Gift className="h-10 w-10 text-purple-600" />
                </div>
                <h3 className="text-2xl font-bold text-gray-900 mb-3">{t("StudentWallet.NoActivePackages")}</h3>
                <p className="text-gray-600 mb-8">{t("StudentWallet.StartLearningJourney")}</p>
                <button
                  onClick={() => navigate('/purchase-packages')}
                  className="inline-flex items-center px-8 py-4 text-lg font-medium rounded-xl text-white bg-gradient-to-r from-purple-600 to-purple-700 hover:from-purple-700 hover:to-purple-800 transition-all duration-300 transform hover:-translate-y-1 shadow-md hover:shadow-lg"
                >
                  {t("StudentWallet.ViewAvailablePackages")}
                  <ChevronRight className="ml-2 h-6 w-6" />
                </button>
              </motion.div>
            )}
          </AnimatePresence>
  
          {/* Error Message */}
          {wallet.error && (
            <div className="mt-4 p-4 bg-red-50 rounded-xl border border-red-200">
              <p className="text-red-700 text-sm">
                {wallet.error}
              </p>
            </div>
          )}
  
          {/* Info Box for First-Time Users */}
          {!isLoading && activePackages.length === 0 && (
            <div className="mt-8 p-6 bg-blue-50 rounded-2xl border border-blue-100">
              <h4 className="text-lg font-semibold text-blue-900 mb-2">
                {t("StudentWallet.GettingStarted")}
              </h4>
              <p className="text-blue-700 mb-4">
                {t("StudentWallet.WelcomeMessage")}
              </p>
              <ul className="list-disc list-inside text-blue-700 space-y-2">
                <li>{t("StudentWallet.Feature1")}</li>
                <li>{t("StudentWallet.Feature2")}</li>
                <li>{t("StudentWallet.Feature3")}</li>
                <li>{t("StudentWallet.Feature4")}</li>
              </ul>
            </div>
          )}
  
          {/* Booking Confirmation Modal */}
          {showBookingModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <motion.div 
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                className="bg-white rounded-2xl p-6 max-w-md w-full mx-4"
              >
                <h3 className="text-xl font-bold text-gray-900 mb-4">
                  {t("StudentWallet.InsufficientHours")}
                </h3>
                <p className="text-gray-600 mb-6">
                  {t("StudentWallet.InsufficientHoursMessage")}
                </p>
                <div className="flex gap-4">
                  <button
                    onClick={() => {
                      setShowBookingModal(false);
                      navigate('/purchase-packages');
                    }}
                    className="flex-1 bg-purple-600 text-white px-4 py-2 rounded-xl hover:bg-purple-700 transition-colors"
                  >
                    {t("StudentWallet.PurchaseHours")}
                  </button>
                  <button
                    onClick={() => setShowBookingModal(false)}
                    className="flex-1 bg-gray-100 text-gray-700 px-4 py-2 rounded-xl hover:bg-gray-200 transition-colors"
                  >
                    {t("StudentWallet.Cancel")}
                  </button>
                </div>
              </motion.div>
            </div>
          )}
        </div>
      </div>
    );
};

export default MyPackages;
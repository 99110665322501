import React, { useRef, useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Loader from "../Loader";

const Room = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.students.user);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isModerator, setIsModerator] = useState(false);
  const jitsiContainerRef = useRef(null);
  const [jitsiAPI, setJitsiAPI] = useState(null);
  const isInitialized = useRef(false);

  // Simple role check from URL params
  const roleInfo = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    const roleInfoParam = searchParams.get('roleInfo');
    return roleInfoParam ? JSON.parse(decodeURIComponent(roleInfoParam)) : null;
  }, [location.search]);

  // Set moderator status
  useEffect(() => {
    const role = roleInfo();
    setIsModerator(role && role.role === 'teacher');
  }, [roleInfo]);

  // Simple loading timer
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const formatStudentName = useCallback((student) => {
    if (!student || !student.Username) return 'N/A';
    const [firstName, ...lastNameParts] = student.Username.split(' ');
    const lastInitial = lastNameParts.length > 0 ? lastNameParts[0][0] : '';
    return `${firstName} ${lastInitial}${lastInitial ? '.' : ''}`;
  }, []);

  const initJitsi = useCallback(() => {
    if (jitsiContainerRef.current && jitsiContainerRef.current.childNodes.length === 0 && !isInitialized.current) {
      console.log("Initializing Jitsi");
      isInitialized.current = true;
      const domain = "meet.speakable.online";
      const options = {
        roomName: id,
        width: "100%",
        height: "100%",
        parentNode: jitsiContainerRef.current,
        userInfo: {
          displayName: formatStudentName(user),
          email: user.email,
          role: roleInfo()?.role || 'participant'
        },
        interfaceConfigOverwrite: {
          TOOLBAR_BUTTONS: [
            "microphone", "camera", "closedcaptions", "desktop", "fullscreen",
            "fodeviceselection", "hangup", "profile", "chat", "recording",
            "livestreaming", "etherpad", "sharedvideo", "settings", "raisehand",
            "videoquality", "filmstrip", "invite", "feedback", "stats",
            "shortcuts", "tileview", "videobackgroundblur", "download", "help",
            "mute-everyone", "security"
          ],
          TOOLBAR_BUTTONS_ONLY_MODERATOR: [
            "mute-everyone",
            "security"
          ],
          DISPLAY_WELCOME_PAGE_CONTENT: false,
          SHOW_JITSI_WATERMARK: false,
        },
        configOverwrite: {
          disableDeepLinking: true,
          enableUserRolesBasedOnToken: false,
          useHostPageLocalStorage: true,
          prejoinPageEnabled: false,
        }
      };

      try {
        console.log("Creating Jitsi API with options:", options);
        const api = new window.JitsiMeetExternalAPI(domain, options);
        setJitsiAPI(api);

        api.on("videoConferenceJoined", () => {
          console.log("Conference joined");
          setIsLoading(false);
          if (isModerator) {
            api.executeCommand('setRole', 'moderator');
          } else {
            api.executeCommand('setRole', 'participant');
          }
          api.executeCommand('displayName', formatStudentName(user));
        });

        api.on("participantJoined", (participant) => {
          console.log("Participant joined:", participant);
        });

        api.on("videoConferenceLeft", () => {
          console.log("Conference left");
          navigate('/');
        });

        api.addListener('readyToClose', () => {
          console.log("Ready to close");
          navigate('/');
        });

        api.on('displayNameChange', (event) => {
          console.log('Name changed to:', event.displayname);
        });

        api.on("error", (error) => {
          console.error("Jitsi error:", error);
          setError(error);
          setIsLoading(false);
        });

      } catch (error) {
        console.error("Error initializing Jitsi:", error);
        setError(error.message);
        setIsLoading(false);
      }
    }
  }, [id, user, navigate, isModerator, roleInfo, formatStudentName]);

  useEffect(() => {
    if (!window.JitsiMeetExternalAPI) {
      console.log("Loading Jitsi script");
      const script = document.createElement("script");
      script.src = "https://meet.speakable.online/external_api.js";
      script.async = true;
      script.onload = () => {
        console.log("Jitsi script loaded");
        if (id && user) {
          initJitsi();
        }
      };
      script.onerror = (err) => {
        console.error("Error loading Jitsi script:", err);
        setError("Failed to load meeting resources. Please check your connection.");
        setIsLoading(false);
      };
      document.body.appendChild(script);
    } else if (id && user) {
      initJitsi();
    }
  }, [id, user, initJitsi]);

  useEffect(() => {
    return () => {
      if (jitsiAPI) {
        console.log("Disposing Jitsi API");
        jitsiAPI.dispose();
        setJitsiAPI(null);
        isInitialized.current = false;
      }
    };
  }, [jitsiAPI]);

  const joinMeeting = () => {
    console.log("Join meeting clicked");
    setIsLoading(true);
    isInitialized.current = false;
    if (jitsiAPI) {
      jitsiAPI.dispose();
      setJitsiAPI(null);
    }
    initJitsi();
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!id || !user) {
    return <Loader loading={true} text="Loading... Please wait while we set up your meeting." fullScreen={true} />;
  }

  return (
    <div style={{ width: '100vw', height: '100vh', position: 'absolute', top: 0, left: 0 }}>
      {isLoading && (
        <div style={{ 
          position: 'absolute', 
          top: 0, 
          left: 0, 
          width: '100%', 
          height: '100%', 
          zIndex: 1000,
          backgroundColor: '#ffffff',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Loader 
            loading={true} 
            text="Joining the meeting... Please wait." 
            fullScreen={false} 
            size={1000}
            customStyles={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
        </div>
      )}
      <div ref={jitsiContainerRef} style={{ width: '100%', height: '100%' }} />
      
      {/* <button 
        onClick={joinMeeting} 
        style={{ 
          position: 'absolute', 
          top: 10, 
          left: 10,
          padding: '8px 16px',
          backgroundColor: '#2196F3',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          zIndex: 1001
        }}
      >
        Join Meeting
      </button> */}

      <div style={{ 
        position: 'absolute', 
        top: 10, 
        right: 10, 
        background: 'rgba(0,0,0,0.5)', 
        color: 'white', 
        padding: '5px 10px', 
        borderRadius: '5px',
        zIndex: 1001
      }}>
        {isModerator ? 'Teacher (Moderator)' : 'Student (Participant)'}
      </div>
    </div>
  );
};

export default Room;
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { 
  Card, Button, Row, Col, Statistic, Calendar, Badge, 
  Avatar, List, Typography, Space, Tag, Input, Select, 
  Modal, Tooltip, Spin, Progress, message
} from 'antd';
import { 
  UserOutlined, ClockCircleOutlined, SearchOutlined,
  CheckCircleOutlined, DollarOutlined, BookOutlined,
  WarningOutlined, CloseCircleOutlined, SwapOutlined,
  ExperimentOutlined, MessageOutlined, QuestionCircleOutlined
} from '@ant-design/icons';
import { 
  LineChart, Line, BarChart, Bar, PieChart, Pie,
  XAxis, YAxis, CartesianGrid, Cell,
  Tooltip as RechartsTooltip, ResponsiveContainer 
} from 'recharts';
import moment from 'moment-timezone';
import axios from 'axios';
import Cookies from 'js-cookie';

import { GetBookingsByTeacherID } from '../../store/actions/bookingActions';
import { Get_Trial_Bookings_By_Package } from '../../store/actions/trialPreferencesActions';
import { teacherJoinSession, getTeacherSessionStatus } from "../../store/actions/teacherSessionActions";
import YourMaterialModal from './YourMaterialModal';
import TeacherEvaluation from './TeacherEvaluation';
import { FileTextOutlined } from '@ant-design/icons';
import TeacherNotesModal from './TeacherNotesModal';
import { fetchTeacherWalletData } from '../../store/actions/teacherWallet';


const { Title, Text } = Typography;
const { Option } = Select;

const TeacherDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('global');

  // Redux state
  const teacher = useSelector((state) => state.students.user);
  const bookings = useSelector((state) => state.bookings.Teacher_Bookings);
  const trailBookings = useSelector((state) => state.bookings.Trail_Bookings);
  const { 
    balance, 
    earningRate,
    currency,
    walletBalance,
    summary,
    loading: walletLoading 
  } = useSelector((state) => state.teacherEarnings);
  
  // Local state
  const [selectedTimezone, setSelectedTimezone] = useState(
    localStorage.getItem('selectedTimezone') || 'America/New_York'
  );
  const [date, setDate] = useState(moment().tz(selectedTimezone));
  const [stats, setStats] = useState({
    income: 0,
    totalLectures: 0,
    completedSessions: 0,
    pendingOrScheduledSessions: 0,
    cancelledSessions: 0,
    rescheduledSessions: 0,
    trialSessions: 0
  });
  
  const [isYourMaterialModalVisible, setIsYourMaterialModalVisible] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [joiningRoom, setJoiningRoom] = useState(false);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [isDateFiltered, setIsDateFiltered] = useState(false);
  const [filterStatus, setFilterStatus] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [monthlyBookingsData, setMonthlyBookingsData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [showTimezonePrompt, setShowTimezonePrompt] = useState(false);
  const [initialTimezone, setInitialTimezone] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [datesWithBookings, setDatesWithBookings] = useState([]);
  const [isTrialPreferencesModalVisible, setIsTrialPreferencesModalVisible] = useState(false);
  const [trialPreferencesData, setTrialPreferencesData] = useState(null);
  const [trialPreferencesMap, setTrialPreferencesMap] = useState({});
  const [isNotesModalVisible, setIsNotesModalVisible] = useState(false);
const [selectedStudent, setSelectedStudent] = useState(null);

  // Stats cards data
  const statsData = [
    {
      title: t('TeacherDash.Income'),
      value: stats.income,  // Use stats.income directly
      prefix: currency || '$',
      icon: <DollarOutlined style={{ fontSize: 24, color: '#1890ff' }} />,
      color: '#1890ff'
    },
    {
      title: t('TeacherDash.CompletedSessions'),
      value: stats.completedSessions,  // Use stats.completedSessions directly
      suffix: t('TeacherDash.sessions'),
      icon: <CheckCircleOutlined style={{ fontSize: 24, color: '#52c41a' }} />,
      color: '#52c41a'
    },
    {
      title: t('TeacherDash.TotalLectures'),
      value: stats.totalLectures,  // Use stats.totalLectures directly
      suffix: t('TeacherDash.total'), 
      icon: <BookOutlined style={{ fontSize: 24, color: '#722ed1' }} />,
      color: '#722ed1'
    },
    {
      title: t('TeacherDash.PendingSessions'),
      value: stats.pendingOrScheduledSessions,
      suffix: t('TeacherDash.pending'),
      icon: <ClockCircleOutlined style={{ fontSize: 24, color: '#faad14' }} />,
      color: '#faad14'
    },
    {
      title: t('TeacherDash.CancelledSessions'),
      value: stats.cancelledSessions,
      suffix: t('TeacherDash.cancelled'),
      icon: <CloseCircleOutlined style={{ fontSize: 24, color: '#ff4d4f' }} />,
      color: '#ff4d4f'
    },
    {
      title: t('TeacherDash.Rescheduled'),
      value: stats.rescheduledSessions,
      suffix: t('TeacherDash.rescheduled'),
      icon: <SwapOutlined style={{ fontSize: 24, color: '#36cfc9' }} />,
      color: '#36cfc9'
    },
    {
      title: t('TeacherDash.Trial'),
      value: stats.trialSessions,
      suffix: t('TeacherDash.trials'),
      icon: <ExperimentOutlined style={{ fontSize: 24, color: '#9254de' }} />,
      color: '#9254de'
    }
  ];

  // Utility functions
  const getBookingStatus = useCallback((date, start, end, originalStatus) => {
    if (!date || !start || !end) return originalStatus;
    
    const now = moment().utc();
    const bookingStart = moment.utc(`${date} ${start}`);
    const bookingEnd = moment.utc(`${date} ${end}`);
  
    if (originalStatus === "Rescheduled") {
      if (now.isAfter(bookingEnd)) return t('status.Completed'); 
      if (now.isSameOrAfter(bookingStart) && now.isBefore(bookingEnd)) return t('status.inProgress');  // Translation key
    return t('status.Rescheduled');
    }
    if (originalStatus === "Cancelled") return t('status.Cancelled');  // Translation key
  if (now.isAfter(bookingEnd)) return t('status.Completed');  // Translation key
  if (now.isSameOrAfter(bookingStart) && now.isBefore(bookingEnd)) return t('status.inProgress');  // Translation key
  return t('status.Scheduled');  // Translation key
}, [t]);  // Add t to dependencies

const handleOpenNotes = (booking) => {
  setSelectedBookingId(booking._id);
  setSelectedStudent({
    id: booking.Student_ID?._id,
    name: formatStudentName(booking?.Student_ID, booking)
  });
  setIsNotesModalVisible(true);
};

  const getCurrentAndUpcomingBookings = useCallback((bookings, trailBookings) => {
    const now = moment().tz(selectedTimezone);
    const todayStart = now.clone().startOf('day');
    
    const regularBookings = bookings?.filter(booking => {
      return booking?.Scheduled_Dates?.some(dateObj => {
        const date = Object.keys(dateObj)[0];
        const slots = dateObj[date];
        return slots?.some(slot => {
          const endDateTime = moment.tz(
            `${date} ${slot.end}`, 
            'YYYY-MM-DD HH:mm', 
            'UTC'
          ).tz(selectedTimezone);
          return endDateTime.isSameOrAfter(todayStart);
        });
      });
    }) || [];

    const processedTrialBookings = trailBookings?.filter(booking => {
      if (booking.Status !== "Trial") return false;
      return booking?.Scheduled_Dates?.some(dateObj => {
        const date = Object.keys(dateObj)[0];
        const slots = dateObj[date];
        return slots?.some(slot => {
          const endDateTime = moment.tz(
            `${date} ${slot.end}`, 
            'YYYY-MM-DD HH:mm', 
            'UTC'
          ).tz(selectedTimezone);
          return endDateTime.isSameOrAfter(todayStart);
        });
      });
    }) || [];

    return [...regularBookings, ...processedTrialBookings];
  }, [selectedTimezone]);

  const mergeConsecutiveBookings = useCallback((bookings) => {
    if (!Array.isArray(bookings)) return [];
    
    const mergedBookings = [];
    let currentMergedBooking = null;

    bookings.forEach(booking => {
      if (!booking?.Scheduled_Dates) return;

      booking.Scheduled_Dates.forEach(dateObj => {
        const date = Object.keys(dateObj)[0];
        if (!date || !dateObj[date]) return;

        let timeSlots = Array.isArray(dateObj[date]) ? dateObj[date] : [dateObj[date]];
        timeSlots = timeSlots.filter(slot => slot?.start && slot?.end);

        timeSlots.sort((a, b) => {
          const startA = moment(a.start, 'HH:mm');
          const startB = moment(b.start, 'HH:mm');
          return startA.diff(startB);
        });

        timeSlots.forEach(slot => {
          const currentStatus = getBookingStatus(date, slot.start, slot.end, booking.Status);
          
          if (!currentMergedBooking) {
            currentMergedBooking = {
              ...booking,
              Scheduled_Dates: [{ [date]: [{ ...slot, isConsecutive: false }] }],
              isConsecutive: false,
              status: currentStatus
            };
          } else {
            try {
              const lastDate = Object.keys(currentMergedBooking.Scheduled_Dates[currentMergedBooking.Scheduled_Dates.length - 1])[0];
              const lastSlots = currentMergedBooking.Scheduled_Dates[currentMergedBooking.Scheduled_Dates.length - 1][lastDate];
              
              if (!Array.isArray(lastSlots)) {
                throw new Error('Last slots is not an array');
              }

              const lastSlot = lastSlots[lastSlots.length - 1];

              if (date === lastDate && 
                  moment(slot.start, 'HH:mm').isSame(moment(lastSlot.end, 'HH:mm'))) {
                lastSlot.end = slot.end;
                lastSlot.isConsecutive = true;
                currentMergedBooking.isConsecutive = true;
                currentMergedBooking.status = currentStatus;
              } else {
                mergedBookings.push(currentMergedBooking);
                currentMergedBooking = {
                  ...booking,
                  Scheduled_Dates: [{ [date]: [{ ...slot, isConsecutive: false }] }],
                  isConsecutive: false,
                  status: currentStatus
                };
              }
            } catch (error) {
              console.error('Error processing time slots:', error);
              currentMergedBooking = {
                ...booking,
                Scheduled_Dates: [{ [date]: [{ ...slot, isConsecutive: false }] }],
                isConsecutive: false,
                status: currentStatus
              };
            }
          }
        });
      });
    });

    if (currentMergedBooking) {
      mergedBookings.push(currentMergedBooking);
    }

    return mergedBookings;
  }, [getBookingStatus]);

  // Event handlers
  const handleViewTrialPreferences = async (bookingId) => {
    try {
      const result = await dispatch(Get_Trial_Bookings_By_Package(bookingId));
      if (result.payload?.data?.[0]) {
        setTrialPreferencesData(result.payload.data[0]);
        setIsTrialPreferencesModalVisible(true);
      } else {
        message.info(t('notifications.noTrialPreferences'));
      }
    } catch (error) {
      message.error(t('notifications.failedFetchPreferences'));  
      console.error('Error fetching trial preferences:', error);
    }
  };

  const joinRoom = useCallback(async (bookingId) => {
    setJoiningRoom(true);
    try {
      const joinSessionResult = await dispatch(teacherJoinSession({ 
        bookingId, 
        teacherId: teacher._id 
      }));
      
      if (joinSessionResult.error) {
        throw new Error(joinSessionResult.error.message);
      }
      
      message.success(t('notifications.joinedRoom'));
      const roleInfo = encodeURIComponent(JSON.stringify({ 
        role: 'teacher', 
        teacherId: teacher._id 
      }));
      window.open(
        `/room/meeting/${bookingId}?roleInfo=${roleInfo}`, 
        '_blank', 
        'noopener,noreferrer'
      );
      
    } catch (error) {
      console.error('Error joining the room:', error);
      message.error(`Failed to join the room: ${error.message}`);
    } finally {
      setJoiningRoom(false);
    }
  }, [dispatch, teacher._id]);

  const handleCalendarClick = (value) => {
    const selectedDate = moment(value).tz(selectedTimezone).startOf('day');
    const endOfSelectedDate = selectedDate.clone().endOf('day');
  
    const filtered = bookings?.filter(booking =>
      booking?.Scheduled_Dates?.some(dateObj => {
        const bookingDate = Object.keys(dateObj)[0];
        const bookingStartTime = dateObj[bookingDate][0].start;
        const bookingEndTime = dateObj[bookingDate][0].end;
  
        const bookingStartDateTime = moment.tz(
          `${bookingDate} ${bookingStartTime}`, 
          'YYYY-MM-DD HH:mm', 
          'UTC'
        ).tz(selectedTimezone);
        const bookingEndDateTime = moment.tz(
          `${bookingDate} ${bookingEndTime}`, 
          'YYYY-MM-DD HH:mm', 
          'UTC'
        ).tz(selectedTimezone);
  
        return (
          (bookingStartDateTime.isSameOrAfter(selectedDate) && 
           bookingStartDateTime.isSameOrBefore(endOfSelectedDate)) ||
          (bookingEndDateTime.isSameOrAfter(selectedDate) && 
           bookingEndDateTime.isSameOrBefore(endOfSelectedDate)) ||
          (bookingStartDateTime.isBefore(selectedDate) && 
           bookingEndDateTime.isAfter(endOfSelectedDate

            // ... continuing from previous code

          ))
        );
      })
    );
  
    setFilteredBookings(filtered);
    setDate(selectedDate.toDate());
    setIsDateFiltered(true);
  };

  const resetDateFilter = () => {
    setFilteredBookings([]);
    setIsDateFiltered(false);
  };

  const isWithinScheduledTime = (scheduledDate, startTimeUTC, endTimeUTC) => {
    const now = moment().tz(selectedTimezone);
    const startDateTime = moment.tz(
      `${scheduledDate} ${startTimeUTC}`, 
      'YYYY-MM-DD HH:mm', 
      'UTC'
    ).tz(selectedTimezone);
    const endDateTime = moment.tz(
      `${scheduledDate} ${endTimeUTC}`, 
      'YYYY-MM-DD HH:mm', 
      'UTC'
    ).tz(selectedTimezone);
    
    if (endDateTime.isBefore(startDateTime)) {
      endDateTime.add(1, 'day');
    }
    
    const tenMinutesBefore = startDateTime.clone().subtract(10, 'minutes');
    return now.isSameOrAfter(tenMinutesBefore) && now.isSameOrBefore(endDateTime);
  };

  const getStatusColor = useCallback((status) => {
    switch (status) {
      case 'Completed': return '#722ed1';
      case 'In Progress': return '#faad14';
      case 'Scheduled': return '#52c41a';
      case 'Cancelled': return '#ff4d4f';
      case 'Rescheduled': return '#1890ff';
      case 'Trial': return '#13c2c2';
      default: return '#d9d9d9';
    }
  }, []);

  const formatStudentName = (student, booking) => {
  if (booking.Status === 'Trial' || booking.Status === 'Trail') {
    const trialData = trialPreferencesMap[booking._id];
    if (trialData?.studentDetails?.name) {
      return trialData.studentDetails.name;
    }
    
    if (student?.Username) {
      const parts = student.Username.trim().split(' ').filter(part => part.length > 0);
      if (parts.length === 0) return 'Trial Student';
      
      const firstName = parts[0];
      const lastInitial = parts.length > 1 ? parts[1][0] : '';
      return `${firstName} ${lastInitial}${lastInitial ? '.' : ''}`;
    }
    
    return 'Trial Student';
  }
  
  if (!student?.Username) return 'Unknown Student';
  
  const parts = student.Username.trim().split(' ').filter(part => part.length > 0);
  if (parts.length === 0) return 'Unknown Student';
  
  const firstName = parts[0];
  const lastInitial = parts.length > 1 ? parts[1][0] : '';
  return `${firstName} ${lastInitial}${lastInitial ? '.' : ''}`;
};

  const renderBookingDateTime = (booking) => {
    try {
      return booking.Scheduled_Dates?.map((dateObj, dateIndex) => {
        const originalDate = Object.keys(dateObj)[0];
        if (!originalDate || !dateObj[originalDate]) return null;

        return dateObj[originalDate].map((slot, slotIndex) => {
          if (!slot?.start || !slot?.end) return null;

          const startDateTime = moment.tz(
            `${originalDate} ${slot.start}`, 
            'YYYY-MM-DD HH:mm', 
            'UTC'
          ).tz(selectedTimezone);
          
          const endDateTime = moment.tz(
            `${originalDate} ${slot.end}`, 
            'YYYY-MM-DD HH:mm', 
            'UTC'
          ).tz(selectedTimezone);

          if (!startDateTime.isValid() || !endDateTime.isValid()) return null;

          return (
            <div key={`${dateIndex}-${originalDate}-${slotIndex}`}>
              <Text type="secondary">
                {startDateTime.format('ddd MMM DD YYYY')}
              </Text>
              <br />
              <Text>
                {startDateTime.format('HH:mm')} - {endDateTime.format('HH:mm')}
              </Text>
              {slot.isConsecutive && (
                <Tag color="blue" style={{ marginLeft: 8 }}>
                  {t('TeacherDash.ConsecutiveBooking')}
                </Tag>
              )}
              {!startDateTime.isSame(endDateTime, 'day') && (
                <Tag color="orange" style={{ marginLeft: 8 }}>
                  {t('bookings.overnightSession')} 
                </Tag>
              )}
            </div>
          );
        });
      });
    } catch (error) {
      console.error('Error rendering booking date/time:', error);
      return null;
    }
  };

  // Effects
  useEffect(() => {
    if (teacher?._id) {
      dispatch(GetBookingsByTeacherID(teacher._id));
      dispatch(fetchTeacherWalletData(teacher._id));
    }
    
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
  
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch, teacher._id]);

  const getAllBookings = useCallback(() => {
    return [...(bookings || []), ...(trailBookings || [])];
  }, [bookings, trailBookings]);

  // Add this useEffect to update stats when summary data changes
  useEffect(() => {
    // First prioritize wallet data if available
    if (summary) {
      setStats(prevStats => ({
        ...prevStats,
        income: summary.totalEarnings || prevStats.income,
        totalLectures: summary.totalBookings || prevStats.totalLectures,
        completedSessions: summary.completedBookings || prevStats.completedSessions
      }));
    }
    
    // If we have booking data, calculate additional stats
    if (bookings?.length > 0 || trailBookings?.length > 0) {
      const allBookings = getAllBookings();
      
      // Calculate statistics from all bookings (not just upcoming)
      const completedSessions = allBookings.filter(
        booking => booking.Status === 'Completed'
      ).length;
      const trialSessions = allBookings.filter(
        booking => booking.Status === 'Trial' || booking.Status === 'Trail'
      ).length;
      const pendingOrScheduledSessions = allBookings.filter(
        booking => ['Scheduled', 'Trial', 'Trail'].includes(booking.Status)
      ).length;
      const rescheduledSessions = allBookings.filter(
        booking => booking.Status === 'Rescheduled'
      ).length;
      const cancelledSessions = allBookings.filter(
        booking => booking.Status === 'Cancelled'
      ).length;
    
      setStats(prevStats => ({
        ...prevStats,
        income: summary?.totalEarnings || completedSessions * (teacher.Purchase_Price || 0),
        totalLectures: summary?.totalBookings || allBookings.length,
        completedSessions: summary?.completedBookings || completedSessions,
        pendingOrScheduledSessions,
        cancelledSessions,
        rescheduledSessions,
        trialSessions
      }));
      
      // Update pie chart data
      setPieChartData([
        { name: 'Completed', value: summary?.completedBookings || completedSessions },
        { name: 'Pending', value: pendingOrScheduledSessions },
        { name: 'Rescheduled', value: rescheduledSessions },
        { name: 'Cancelled', value: cancelledSessions },
        { name: 'Trial', value: trialSessions }
      ]);
    }
  }, [bookings, trailBookings, getAllBookings, teacher.Purchase_Price, summary]);

  // useEffect(() => {
  //   if (bookings?.length > 0 || trailBookings?.length > 0) {
  //     const currentAndUpcomingBookings = getCurrentAndUpcomingBookings(bookings, trailBookings);
  //     const mergedBookings = mergeConsecutiveBookings(currentAndUpcomingBookings);
      
  //     const completedSessions = mergedBookings.filter(
  //       booking => booking.Status === 'Completed'
  //     ).length;
  //     const trialSessions = mergedBookings.filter(
  //       booking => booking.Status === 'Trial' || booking.Status === 'Trail'
  //     ).length;
  //     const pendingOrScheduledSessions = mergedBookings.filter(
  //       booking => ['Scheduled', 'Trial', 'Trail'].includes(booking.Status)
  //     ).length;
  //     const rescheduledSessions = mergedBookings.filter(
  //       booking => booking.Status === 'Rescheduled'
  //     ).length;
  //     const cancelledSessions = mergedBookings.filter(
  //       booking => booking.Status === 'Cancelled'
  //     ).length;
  
  //     setStats({
  //       income: summary?.totalEarnings || completedSessions * (teacher.Purchase_Price || 0),
  //       totalLectures: summary?.totalBookings || mergedBookings.length,
  //       completedSessions: summary?.completedBookings || completedSessions,
  //       pendingOrScheduledSessions,
  //       cancelledSessions,
  //       rescheduledSessions,
  //       trialSessions
  //     });

  //     // Update chart data
  //     const bookingsData = mergedBookings.reduce((acc, booking) => {
  //       const date = moment(booking.createdAt).format('MMM DD');
  //       acc[date] = (acc[date] || 0) + 1;
  //       return acc;
  //     }, {});

  //     const chartData = Object.entries(bookingsData).map(([date, count]) => ({
  //       date,
  //       bookings: count
  //     }));

  //     setMonthlyBookingsData(chartData);

  //     // Update pie chart data
  //     setPieChartData([
  //       { name: 'Completed', value: summary?.completedBookings || completedSessions },
  //       { name: 'Pending', value: pendingOrScheduledSessions },
  //       { name: 'Rescheduled', value: rescheduledSessions },
  //       { name: 'Cancelled', value: cancelledSessions },
  //       { name: 'Trial', value: trialSessions }
  //     ]);

  //     // Update dates with bookings for calendar
  //     const bookingDates = new Set();
  //     mergedBookings.forEach(booking => {
  //       booking.Scheduled_Dates?.forEach(dateObj => {
  //         const date = Object.keys(dateObj)[0];
  //         bookingDates.add(moment(date).format('YYYY-MM-DD'));
  //       });
  //     });
  //     setDatesWithBookings(Array.from(bookingDates));
  //   }
  // }, [bookings, trailBookings, teacher.Purchase_Price, getCurrentAndUpcomingBookings, mergeConsecutiveBookings, summary]);

  // Memoized filtered bookings list
  const filteredBookingsList = useMemo(() => {
    const currentAndUpcomingBookings = getCurrentAndUpcomingBookings(
      isDateFiltered ? filteredBookings : bookings,
      isDateFiltered ? filteredBookings : trailBookings
    );
    
    return mergeConsecutiveBookings(currentAndUpcomingBookings)?.filter(booking =>
      (booking?.Student_ID?.Username || '').toLowerCase().includes(searchTerm.toLowerCase()) &&
      (filterStatus === 'All' || 
       booking.Status === filterStatus || 
       (filterStatus === 'Trial' && booking.Status === 'Trial'))
    );
  }, [getCurrentAndUpcomingBookings, isDateFiltered, filteredBookings, bookings, trailBookings, mergeConsecutiveBookings, searchTerm, filterStatus]);

  // Components
  const TrialPreferencesModal = ({ visible, onClose, data }) => {
    if (!data) return null;
    
    const { studentDetails, learningPreferences, timeSlot, scheduledDate } = data;
    
    return (
      <Modal
      title={t('trialPreferences.title')} 
        open={visible}
        onCancel={onClose}
        footer={[
          <Button key="close" onClick={onClose}>
            Close
          </Button>
        ]}
        width={700}
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Card>
            <Title level={5}>Student Information</Title>
            <Space direction="vertical">
            <Text strong>{t('trialPreferences.name')}:</Text> {studentDetails.name}  // Add translation key
        <Text strong>{t('trialPreferences.email')}:</Text> {studentDetails.email}  // Add translation key
        <Text strong>{t('trialPreferences.phone')}:</Text> {studentDetails.phone}  // Add translation key
        <Text strong>{t('trialPreferences.age')}:</Text> {learningPreferences.sessionPreferences.age}
            </Space>
          </Card>

          <Card>
            <Title level={5}>Schedule</Title>
            <Space direction="vertical">
              <Text strong>Date:</Text> {moment(scheduledDate).format('MMMM DD, YYYY')}
              <Text strong>Time:</Text> {timeSlot.start} - {timeSlot.end}
            </Space>
          </Card>

          <Card>
            <Title level={5}>Learning Objectives</Title>
            <Space wrap>
              {learningPreferences.learningObjectives.map((objective, index) => (
                <Tag key={index} color="blue">
                  {objective.skillType} ({objective.proficiencyLevel}) - {objective.priority} priority
                </Tag>
              ))}
            </Space>
          </Card>

          <Card>
            <Title level={5}>Session Preferences</Title>
            <Space direction="vertical" style={{ width: '100%' }}>
              <div>
                <Text strong>Focus Areas:</Text>
                <Space wrap style={{ marginTop: 8 }}>
                  {learningPreferences.sessionPreferences.focusAreas.map((area, index) => (
                    <Tag key={index} color="green">
                      {area.replace(/_/g, ' ')}
                    </Tag>
                  ))}
                </Space>
              </div>
              
              <div>
                <Text strong>Preferred Topics:</Text>
                <Space wrap style={{ marginTop: 8 }}>
                  {learningPreferences.sessionPreferences.preferredTopics.map((topic, index) => (
                    <Tag key={index} color="purple">
                      {topic.replace(/_/g, ' ')}
                    </Tag>
                  ))}
                </Space>
              </div>
              
              <div>
                <Text strong>Custom Topics:</Text>
                <Space wrap style={{ marginTop: 8 }}>
                  {learningPreferences.sessionPreferences.customTopics.map((topic, index) => (
                    <Tag key={index} color="orange">
                      {topic}
                    </Tag>
                  ))}
                </Space>
              </div>
              
              <div>
                <Text strong>Lesson Style:</Text>
                <Text>{learningPreferences.sessionPreferences.lessonStyle}</Text>
              </div>
            </Space>
          </Card>
        </Space>
      </Modal>
    );
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      {/* Header Section */}
      <Card 
        bordered={false}
        className="mb-6"
        style={{
          background: 'linear-gradient(135deg, #1890ff 0%, #722ed1 100%)',
          borderRadius: '12px'
        }}
      >
        <Row align="middle" justify="space-between">
          <Col>
            <Title level={2} style={{ color: 'white', marginBottom: '8px' }}>
              {t('TeacherDash.Welcome')}, {teacher?.Username}
            </Title>
            <Text style={{ color: 'rgba(255, 255, 255, 0.85)' }}>
              {moment().tz(selectedTimezone).format('dddd, MMMM D, YYYY')}
            </Text>
          </Col>
          <Col>
            <Card style={{ background: 'rgba(255, 255, 255, 0.1)', border: 'none' }}>
              <Statistic
                title={<Text style={{ color: 'rgba(255, 255, 255, 0.85)' }}>{selectedTimezone}</Text>}
                value={moment().tz(selectedTimezone).format('HH:mm:ss')}
                valueStyle={{ color: 'white' }}
                prefix={<ClockCircleOutlined />}
              />
            </Card>
          </Col>
        </Row>
      </Card>

      {/* Stats Grid */}
      <Row gutter={[16, 16]} className="mb-6">
        {statsData.map((stat, index) => (
          <Col xs={24} sm={12} lg={6} key={index}>
            <Card bordered={false} style={{ borderRadius: '8px' }}>
              <Space direction="horizontal" size="large" align="start">
                <div style={{
                  padding: '12px',
                  borderRadius: '8px',
                  background: `${stat.color}10`
                }}>
                  {stat.icon}
                </div>
                <Statistic
  title={stat.title}
  value={stat.value}
  prefix={stat.prefix}
  suffix={stat.suffix}
  valueStyle={{ color: stat.color }}
  loading={walletLoading && index < 3}
/>
              </Space>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Main Content */}
     
        {/* Bookings List */}
        <Col >
          <Card 
            title={
              <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                <Title level={4}>{t('TeacherDash.Meetings')}</Title>
                <Space>
                  <Input
                    placeholder={t('TeacherDash.SearchStudent')}
                    prefix={<SearchOutlined />}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ width: 200 }}
                  />
                  <Select
                    defaultValue="All"
                    style={{ width: 120 }}
                    onChange={(value) => setFilterStatus(value)}
                  >
                    <Option value="All">{t('TeacherDash.AllStatus')}</Option>
                    <Option value="Trial">{t('TeacherDash.Trial')}</Option>
                    <Option value="Scheduled">{t('TeacherDash.Scheduled')}</Option>
                    <Option value="Completed">{t('TeacherDash.Completed')}</Option>
                    <Option value="Rescheduled">{t('TeacherDash.Rescheduled')}</Option>
                    <Option value="Cancelled">{t('TeacherDash.Cancelled')}</Option>
                  </Select>
                </Space>
              </Space>
            }
            bordered={false}
            style={{ borderRadius: '8px', marginBottom: 16 }}
          >
            {isDateFiltered && (
              <Button 
                onClick={resetDateFilter}
                style={{ marginBottom: 16 }}
              >
                {t('TeacherDash.ShowAllMeetings')}
              </Button>
            )}

<List
  itemLayout="horizontal"
  dataSource={filteredBookingsList}
  renderItem={(booking) => (
    <List.Item
      actions={[
        <Button
          type="primary"
          onClick={() => joinRoom(booking._id)}
          disabled={!isWithinScheduledTime(
            Object.keys(booking.Scheduled_Dates[0])[0],
            booking.Scheduled_Dates[0][Object.keys(booking.Scheduled_Dates[0])[0]][0].start,
            booking.Scheduled_Dates[0][Object.keys(booking.Scheduled_Dates[0])[0]][0].end
          )}
          icon={<CheckCircleOutlined />}
        >
          {t('TeacherDash.JoinRoom')}
        </Button>,
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setSelectedBookingId(booking._id);
            setIsYourMaterialModalVisible(true);
          }}
          icon={<BookOutlined />}
        >
          {t('TeacherDash.YourMaterial')}
        </Button>,
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleOpenNotes(booking);
          }}
          icon={<FileTextOutlined />}
        >
          {t('TeacherDash.Notes')}
        </Button>,
        (booking.Status === "Trial" || booking.Status === "Trail") && (
          <Button
            type="default"
            icon={<ExperimentOutlined />}
            onClick={() => handleViewTrialPreferences(booking._id)}
          >
            {t('TeacherDash.ViewPreferences')} 
          </Button>
        )
      ]}
    >
                  <List.Item.Meta
                    avatar={
                      <Avatar 
                        size="large"
                        icon={<UserOutlined />}
                        style={{ backgroundColor: getStatusColor(booking.Status) }}
                      />
                    }
                    title={
                      <Space>
                        {formatStudentName(booking?.Student_ID, booking)}
                        <Tag color={getStatusColor(booking.Status)}>
                        {t(`status.${booking.Status}`)}
                        </Tag>
                      </Space>
                    }
                    description={renderBookingDateTime(booking)}
                  />
                </List.Item>
              )}
              locale={{
                emptyText: (
                  <div style={{ textAlign: 'center', padding: '24px' }}>
                    <BookOutlined style={{ fontSize: 24, marginBottom: 8 }} />
                    <Text>{t('TeacherDash.NoMeetingsAvailable')}</Text>
                  </div>
                )
              }}
            />
          </Card>
         <TeacherEvaluation 
  booking={filteredBookingsList} // Pass the filtered bookings list
  teacher={teacher} // Pass the teacher object
  onSubmit={(evaluation) => {
    // Handle evaluation submission if needed
    message.success('Evaluation submitted successfully');
  }}
/>

          
        </Col>

        
      

      {/* Modals */}
      <YourMaterialModal
        isVisible={isYourMaterialModalVisible}
        onClose={() => setIsYourMaterialModalVisible(false)}
        bookingId={selectedBookingId}
      />

      <TrialPreferencesModal
        visible={isTrialPreferencesModalVisible}
        onClose={() => setIsTrialPreferencesModalVisible(false)}
        data={trialPreferencesData}
      />

      <Modal
        visible={joiningRoom}
        title={t("TeacherDash.JoiningRoom")}
        footer={null}
        closable={false}
      >
        <div style={{ textAlign: 'center', padding: '24px' }}>
          <Spin size="large" />
          <Text style={{ display: 'block', marginTop: 16 }}>
            {t("TeacherDash.ConnectingToRoom")}
          </Text>
        </div>
      </Modal>

      <TeacherNotesModal
  isVisible={isNotesModalVisible}
  onClose={() => setIsNotesModalVisible(false)}
  bookingId={selectedBookingId}
  studentId={selectedStudent?.id}
  studentName={selectedStudent?.name}
/>

      {/* Timezone Prompt Modal */}
      {showTimezonePrompt && (
        <Modal
        title={t('timezone.updateTitle')}
          open={showTimezonePrompt}
          onOk={() => {
            setSelectedTimezone(initialTimezone);
            setShowTimezonePrompt(false);
            localStorage.setItem('selectedTimezone', initialTimezone);
          }}
          onCancel={() => {
            setShowTimezonePrompt(false);
            Cookies.set('keepCurrentTimezone', 'true', { expires: 10/24 });
          }}
          okText={t('timezone.yesUpdate')}
          cancelText={t('timezone.noKeepCurrent')} 
        >
          {t('timezone.promptMessage', { initialTimezone, selectedTimezone })}
        </Modal>
      )}
    </div>
  );
};

export default TeacherDashboard;
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { 
  fetchWalletBalance, 
  fetchWalletTransactions,
  purchaseHours
} from "../../store/actions/walletActions";
import { fetchAllpackages, addFreeHour, getStudentFreeHourUsage } from "../../store/actions/packagesActions";
import AdminNav from "../admin-dashboard-components/AdminNav";
import { useTranslation } from "react-i18next";
import { FaSearch, FaClock, FaBookOpen, FaChalkboardTeacher } from 'react-icons/fa';
import ChatButton from "./ChatButton";
import { toast } from 'react-toastify';
import { useCurrencyConversion } from "../currencyConversionUtil";
import Loader2 from "../Loader2";
import Modal from 'react-modal';
import EnhancedProfileTour from './EnhancedProfileTour';
import PurchaseSteps from './PurchaseSteps';

const WalletStudent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("global");
  const student = useSelector((state) => state.students.user);
  const wallet = useSelector((state) => state.wallet);
  const packages = useSelector((state) => state.packages?.packageslist?.packages ?? []);
  const studentFreeHourUsage = useSelector((state) => state.packages.studentFreeHourUsage);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const { convertPrice, loading: currencyLoading } = useCurrencyConversion(student?.Timezone || 'Europe/Warsaw');
  const [showModal, setShowModal] = useState(false);
  const [showFreePackageModal, setShowFreePackageModal] = useState(false);
  const [showFreeHoursUsedModal, setShowFreeHoursUsedModal] = useState(false);
  const [runTour, setRunTour] = useState(false);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [showPurchaseSteps, setShowPurchaseSteps] = useState(false);
  const [selectedLectureOption, setSelectedLectureOption] = useState(null);


  useEffect(() => {
    const hasSeenWalletTour = localStorage.getItem('hasSeenWalletTour');
    if (!hasSeenWalletTour) {
      setRunTour(true);
    }
  }, []);

  const [steps] = useState([
    {
      target: '.packages-title',
      title: '💰 Welcome to Your Wallet',
      content: 'Here you can manage your class packages and purchase hours!',
      placement: 'bottom',
      disableBeacon: true,
      emoji: '✨'
    },
    {
      target: '.packages-search',
      title: '🔍 Find Your Package',
      content: 'Use the search bar to quickly find specific packages.',
      placement: 'bottom',
      emoji: '📝'
    },
    {
      target: '.free-package',
      title: '🎁 Free Trial Package',
      content: 'Look for packages marked as FREE to get started with trial sessions!',
      placement: 'top',
      emoji: '🎯'
    },
    {
      target: '.package-actions',
      title: '🎓 Get Started',
      content: 'Click "Buy Now" after free trial sessions!',
      placement: 'top',
      spotlightClicks: true,
      emoji: '🚀'
    }
  ]);

  const fetchData = useCallback(() => {
    if (student?._id) {
      dispatch(fetchWalletBalance(student._id));
      dispatch(fetchWalletTransactions(student._id));
      dispatch(getStudentFreeHourUsage(student._id));
    }
  }, [dispatch, student]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setIsLoading(true);
        await dispatch(fetchAllpackages());
        await fetchData();
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error('Failed to load packages. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchInitialData();
  }, [dispatch, fetchData]);

  const calculatePackagePricing = useCallback((pack) => {
    if (!pack?.lectures || !Array.isArray(pack.lectures)) {
      return {
        minPrice: pack?.Package_Amount || 0,
        maxDiscount: 0,
        lectureOptions: [{
          count: pack?.Number_of_Lectures || 1,
          basePrice: pack?.Package_Amount || 0,
          discount: 0,
          finalPrice: pack?.Package_Amount || 0
        }]
      };
    }
  
    const lectureOptions = pack.lectures.map(lecture => {
      const lectureData = lecture._doc || lecture;
      return {
        count: lectureData.count || 0,
        basePrice: lectureData.basePrice || 0,
        discount: lectureData.discount || 0,
        finalPrice: lectureData.finalPrice || lectureData.basePrice || 0
      };
    });
  
    const minPrice = Math.min(...lectureOptions.map(opt => opt.finalPrice));
    const maxDiscount = Math.max(...lectureOptions.map(opt => opt.discount));
  
    return {
      minPrice,
      maxDiscount,
      lectureOptions
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(fetchData, 30000); 
    return () => clearInterval(intervalId);
  }, [fetchData]);

  const checkFreeHourUsage = useCallback((packageId) => {
    if (!Array.isArray(studentFreeHourUsage)) return false;
    return studentFreeHourUsage.some(usage => 
      usage.packageId === packageId && usage.hourUsed === true
    );
  }, [studentFreeHourUsage]);

  const filteredPackages = useMemo(() => {
    if (!Array.isArray(packages)) return [];
    return packages.filter(pack => {
      if (!pack?.Package_Name) return false;
      return pack.Package_Name.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [packages, searchTerm]);

  const calculateDiscountedPrice = (pack) => {
    if (!pack.Package_Amount || !pack.Number_of_Lectures) return 0;
    const originalPrice = pack.Package_Amount;
    const discount = pack.Discount || 0;
    return originalPrice - (originalPrice * (discount / 100));
  };

  const handlePurchaseClick = useCallback((pack, lectureOption = null) => {
    if (!pack) return;
    
    setSelectedPackage(pack);
    setSelectedLectureOption(lectureOption);
    
    if (pack.Free_Package) {
      if (checkFreeHourUsage(pack._id)) {
        setShowFreeHoursUsedModal(true);
      } else {
        setShowFreePackageModal(true);
      }
    } else {
      setShowPurchaseSteps(true);
    }
  }, [checkFreeHourUsage]);

  const handleFreePackagePurchase = async () => {
    if (!selectedPackage || !student?._id) {
      toast.error('Invalid package or student data');
      return;
    }
  
    const lectureCount = selectedPackage.lectures?.[0]?._doc?.count;
  
    if (!lectureCount || isNaN(Number(lectureCount))) {
      toast.error('Invalid number of hours in package');
      return;
    }
  
    try {
      setIsLoading(true);
      const result = await dispatch(purchaseHours({
        studentId: student._id,
        packageId: selectedPackage._id,
        hours: Number(lectureCount),
        amount: 0
      }));
        
      if (purchaseHours.fulfilled.match(result)) {
        await dispatch(addFreeHour({
          StudentID: student._id,
          PackageID: selectedPackage._id,
          Free_hour_used: true
        }));
          
        await dispatch(fetchWalletBalance(student._id));
        await dispatch(getStudentFreeHourUsage(student._id));
        toast.success(t("FreePackageAdded"));
      } else {
        throw new Error(result.error || 'Failed to add free package');
      }
    } catch (error) {
      console.error("Error adding free package:", error);
      toast.error(error.message || 'Error adding free package');
    } finally {
      setIsLoading(false);
      setShowFreePackageModal(false);
    }
  };

  const handleBookNow = (packId) => {
    const packageHours = wallet.packageBalances[packId]?.hours || 0;
    if (packageHours > 0) {
      navigate(`/Student-dashboard/EditPackageTeacher/${packId}`);
    } else {
      setSelectedPackage(packages.find(p => p._id === packId));
      setShowModal(true);
    }
  };

  const transformTeacherAvailability = (teacherSchedule) => {
    const transformedAvailability = {};
    
    if (teacherSchedule && teacherSchedule.availability) {
      Object.entries(teacherSchedule.availability).forEach(([date, slots]) => {
        transformedAvailability[date] = {
          slots: slots.map(slot => ({
            start: slot.startTime,
            end: slot.endTime
          }))
        };
      });
    }
    
    return transformedAvailability;
  };

  if (isLoading || currencyLoading) {
    return <Loader2 loading={true} text="Loading" fullScreen={true} size={300} customStyles={{ backgroundColor: 'rgba(15, 15, 15, 0.5)' }} />;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <AdminNav />
      
      <div className="max-w-7xl mx-auto px-4 py-8">
        {/* Header Section */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 mb-6">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <div>
              <h1 className="text-2xl font-semibold text-gray-900 packages-title">
                {t("StudentWallet.WalletAndPackages")}
              </h1>
              <p className="text-gray-500 mt-1">
                {t("StudentWallet.ChooseThePerfect")}
              </p>
            </div>
            
            <div className="relative w-full md:w-96">
              <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                className="packages-search block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder={t("StudentWallet.SearchPackages")}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
        </div>
  
        {/* Packages Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredPackages?.map((pack) => {
            const hoursAvailable = wallet?.packageBalances?.[pack._id]?.hours || 0;
            const totalHours = pack.lectures?.reduce(
              (acc, lecture) => acc + (lecture._doc?.count || 0), 0
            ) || pack.Number_of_Lectures || 0;
            const progressPercentage = (hoursAvailable / totalHours) * 100;
  
            return (
              <div 
                key={pack._id}
                className={`
                  relative overflow-hidden bg-gradient-to-br flex flex-col
                  ${pack.Free_Package ? 'from-purple-50 to-violet-50' : 'from-purple-50 to-violet-50'}
                  rounded-xl shadow-sm border border-gray-100 transition-all duration-300
                  hover:shadow-lg hover:scale-[1.01]
                `}
              >
                <div className="p-6 relative flex flex-col flex-grow">
                  {/* Package Header with E-commerce Style Pricing */}
                  <div className="flex justify-between items-start mb-4">
                    <h2 className="text-xl font-bold text-gray-900">
                      {pack.Package_Name}
                    </h2>
                    <div className="text-right">
                      {pack.Free_Package ? (
                        <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-purple-100 text-purple-800">
                          {t("StudentWallet.FreePackage")}
                        </span>
                      ) : (
                        <>
                          {pack.lectures && pack.lectures.length > 0 ? (
                            // For packages with lecture options
                            pack.lectures.map((lecture, idx) => {
                              const lectureData = lecture._doc || lecture;
                              return (
                                <div key={idx} className="text-right mb-2 last:mb-0">
                                  <div className="flex items-center justify-end">
                                    <span className="text-sm text-gray-500 line-through mr-2">
                                      {convertPrice(lectureData.basePrice)}
                                    </span>
                                    {lectureData.discount > 0 && (
                                      <span className="px-2 py-0.5 bg-red-100 text-red-800 text-xs font-medium rounded-full">
                                        -{lectureData.discount}%
                                      </span>
                                    )}
                                  </div>
                                  <div className="text-2xl font-bold text-gray-900">
                                    {convertPrice(lectureData.finalPrice)}
                                  </div>
                                  <div className="text-sm text-gray-600">
                                    for {lectureData.count} lectures
                                    <div className="text-xs text-gray-500 mt-1">
                                      ({convertPrice(Math.round(lectureData.finalPrice / lectureData.count))} per lecture)
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            // For packages without lecture options
                            <>
                              <div className="flex items-center justify-end">
                                <span className="text-sm text-gray-500 line-through mr-2">
                                  {convertPrice(pack.Package_Amount)}
                                </span>
                                {pack.Discount > 0 && (
                                  <span className="px-2 py-0.5 bg-red-100 text-red-800 text-xs font-medium rounded-full">
                                    -{pack.Discount}%
                                  </span>
                                )}
                              </div>
                              <div className="text-2xl font-bold text-gray-900">
                                {convertPrice(calculateDiscountedPrice(pack))}
                              </div>
                              <div className="text-sm text-gray-600">
                                for {pack.Number_of_Lectures} lectures
                                <div className="text-xs text-gray-500 mt-1">
                                  ({convertPrice(Math.round(calculateDiscountedPrice(pack) / pack.Number_of_Lectures))} per lecture)
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
  
                  {/* Progress Bar */}
                  <div className="mb-4">
  <div className="h-2 bg-gray-200 rounded-full overflow-hidden flex">
    <div 
      className="h-full bg-red-500 transition-all duration-300"
      style={{ width: `${((totalHours - hoursAvailable) / totalHours) * 100}%` }}
    />
    <div 
      className="h-full bg-blue-500 transition-all duration-300"
      style={{ width: `${(hoursAvailable / totalHours) * 100}%` }}
    />
  </div>
  <div className="flex justify-between mt-1">
    <span className="text-sm font-medium text-red-600">
      {totalHours - hoursAvailable} hours used
    </span>
    <span className="text-sm font-medium text-blue-600">
      {hoursAvailable === 0 ? "All hours used" : `${hoursAvailable} hours remaining`}
    </span>
  </div>
</div>
  
                  {/* Stats Grid */}
                  <div className="grid grid-cols-2 gap-4 mb-4">
                    <div className="flex items-center space-x-2 bg-white/60 p-2 rounded-lg backdrop-blur-sm">
                      <FaChalkboardTeacher className="text-gray-500" />
                      <div>
                        <p className="text-xs text-gray-500">Lectures</p>
                        <p className="text-sm text-gray-900">
                          {pack.lectures?.map(lecture => lecture._doc?.count).join(' / ') || pack.Number_of_Lectures || 0}
                        </p>
                      </div>
                    </div>
  
                    <div className="flex items-center space-x-2 bg-white/60 p-2 rounded-lg backdrop-blur-sm">
                      <FaBookOpen className="text-gray-500" />
                      <div>
                        <p className="text-xs text-gray-500">Courses</p>
                        <p className="text-sm text-gray-900">
                          {pack.Course_IDs?.length || 0}
                        </p>
                      </div>
                    </div>
                  </div>
  
                  {/* Course Info */}
                  <div className="mb-4 bg-white/60 p-3 rounded-lg">
                    <h3 className="text-sm font-semibold text-gray-900 mb-2">
                      Available Courses
                    </h3>
                    <div className="space-y-3">
                      {pack.Course_IDs?.map((course, idx) => (
                        <div key={idx} className="p-2 bg-white rounded border border-gray-100">
                          <h4 className="text-sm font-medium text-gray-900">
                            {course.Course_Name}
                          </h4>
                          <p className="text-sm text-gray-600 mt-1">
                            {course.Description}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
  
                  {/* Teachers Section */}
                  <div className="border-t border-gray-100 pt-4 mb-4">
                    <h4 className="text-sm font-semibold text-gray-900 mb-2">
                      {t("StudentPackage.TeachersAssigned")}
                    </h4>
                    <div className="flex flex-wrap gap-2">
                      {pack.Teacher_IDs?.map((teacher) => (
                        <span 
                          key={teacher._id}
                          className="inline-flex items-center px-2 py-1 rounded-full text-sm bg-purple-100 text-purple-800"
                        >
                          {teacher.Username}
                        </span>
                      ))}
                    </div>
                  </div>
  
                  {/* Action Buttons */}
                  <div className="flex space-x-3 mt-auto">
                    <button
                      onClick={() => handlePurchaseClick(pack)}
                      className="flex-1 bg-purple-600 text-white px-4 py-2 rounded-lg font-medium 
                               hover:bg-purple-700 transition-colors duration-200 flex items-center justify-center"
                    >
                      {pack.Free_Package ? t("StudentWallet.GetFreeHours") : t("StudentWallet.PurchaseHours")}
                    </button>
                    <button
                      onClick={() => handleBookNow(pack._id)}
                      className="flex-1 bg-white text-gray-700 px-4 py-2 rounded-lg font-medium 
                               border border-gray-300 hover:bg-gray-50 transition-colors duration-200"
                    >
                      {t("StudentPackage.BookNow")}
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Modals */}
      <Modal
  isOpen={showModal}
  onRequestClose={() => setShowModal(false)}
  contentLabel="No Hours Available"
  className="relative bg-white rounded-lg max-w-md w-full p-6 shadow-xl"
  overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
  style={{
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)", // Centers modal both vertically and horizontally
      maxWidth: "500px",
      width: "100%",
      padding: "20px",
      background: "white",
      borderRadius: "8px",
      boxShadow: "0 10px 15px rgba(0, 0, 0, 0.1)",
    },
    overlay: {
      zIndex: 1000,
    },
  }}
>
  <div className="space-y-4">
    <h2 className="text-2xl font-bold text-gray-900">
      {t("StudentPackage.NoHoursAvailable")}
    </h2>
    <p className="text-gray-600">
      {t("StudentPackage.PurchaseHoursMessage")}
    </p>
    <div className="flex space-x-3 pt-4">
      <button
        className="flex-1 bg-purple-600 text-white px-4 py-2 rounded-lg font-medium hover:bg-purple-700 transition-colors duration-200"
        onClick={() => {
          setShowModal(false);
          handlePurchaseClick(selectedPackage);
        }}
      >
        {t("StudentPackage.PurchaseHours")}
      </button>
      <button
        className="flex-1 bg-white text-gray-700 px-4 py-2 rounded-lg font-medium border border-gray-300 hover:bg-gray-50 transition-colors duration-200"
        onClick={() => setShowModal(false)}
      >
        {t("StudentPackage.Close")}
      </button>
    </div>
  </div>
</Modal>


      <Modal
        isOpen={showFreePackageModal}
        onRequestClose={() => setShowFreePackageModal(false)}
        contentLabel="Free Package Confirmation"
        className="relative bg-white rounded-lg max-w-md mx-auto mt-24 p-6 shadow-xl"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center"
        style={{
          overlay: {
            zIndex: 1000,
          }
        }}
      >
        <div className="space-y-4">
          <h2 className="text-2xl font-bold text-gray-900">{t("StudentWallet.FreePackageConfirmation")}</h2>
          <p className="text-gray-600">
            {t("StudentWallet.AddFreeHoursMessage", { 
              hours: selectedPackage?.lectures?.[0]?._doc?.count || 0 
            })}
          </p>
          <div className="flex space-x-3 pt-4">
            <button 
              className="flex-1 bg-purple-600 text-white px-4 py-2 rounded-lg font-medium hover:bg-purple-700 transition-colors duration-200"
              onClick={handleFreePackagePurchase}
            >
              {t("StudentWallet.ConfirmAddFreeHours")}
            </button>
            <button 
              className="flex-1 bg-white text-gray-700 px-4 py-2 rounded-lg font-medium border border-gray-300 hover:bg-gray-50 transition-colors duration-200"
              onClick={() => setShowFreePackageModal(false)}
            >
              {t("StudentPackage.Cancel")}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
  isOpen={showFreeHoursUsedModal}
  onRequestClose={() => setShowFreeHoursUsedModal(false)}
  contentLabel="Free Hours Already Used"
  className="relative bg-white rounded-lg max-w-md p-6 shadow-xl"
  overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
  style={{
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)", // Centers the modal both vertically and horizontally
      maxWidth: "500px",
      width: "100%",
      padding: "20px",
      background: "white",
      borderRadius: "8px",
      boxShadow: "0 10px 15px rgba(0, 0, 0, 0.1)",
    },
    overlay: {
      zIndex: 1000, // Ensures the modal overlay is above other elements
    },
  }}
>
  <div className="space-y-4">
    <h2 className="text-2xl font-bold text-gray-900">
      {t("StudentWallet.FreeHoursUsed")}
    </h2>
    <p className="text-gray-600">
      {t("StudentWallet.FreeHoursUsedMessage")}
    </p>
    <div className="flex justify-end pt-4">
      <button
        className="bg-white text-gray-700 px-6 py-2 rounded-lg font-medium border border-gray-300 hover:bg-gray-50 transition-colors duration-200"
        onClick={() => setShowFreeHoursUsedModal(false)}
      >
        {t("StudentPackage.Close")}
      </button>
    </div>
  </div>
</Modal>


      <PurchaseSteps
        isOpen={showPurchaseSteps}
        onClose={() => setShowPurchaseSteps(false)}
        pack={selectedPackage}
        lectureOption={selectedLectureOption}
        student={student}
        convertPrice={convertPrice}
        onConfirm={({ lectureDetails }) => {
          const { minPrice } = calculatePackagePricing(selectedPackage);
          navigate(`/Student-dashboard/CheckDetails/${selectedPackage?._id}`, {
            state: {
              packageName: selectedPackage?.Package_Name,
              hours: lectureDetails?.count || selectedPackage?.Number_of_Lectures,
              totalAmount: lectureDetails ? 
                convertPrice(lectureDetails.finalPrice) : 
                convertPrice(minPrice),
              lectureDetails
            }
          });
        }}
      />

      <ChatButton />
      
      <EnhancedProfileTour 
        steps={steps}
        isRunning={runTour}
        onComplete={() => {
          setRunTour(false);
          localStorage.setItem('hasSeenWalletTour', 'true');
        }}
        styles={{
          options: {
            arrowColor: '#ffffff',
            backgroundColor: '#ffffff',
            primaryColor: '#9333ea',
            textColor: '#1F2937',
            zIndex: 1000,
            spotlightPadding: 16,
          },
          tooltip: {
            padding: '24px',
            borderRadius: '16px',
            boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
          },
          tooltipContainer: {
            textAlign: 'center',
            minWidth: '300px',
          },
          buttonNext: {
            backgroundColor: '#9333ea',
            padding: '12px 24px',
            fontSize: '1rem',
            fontWeight: '600',
            borderRadius: '12px',
            border: 'none',
            color: '#ffffff',
            transition: 'all 0.3s',
          },
          buttonBack: {
            marginRight: '12px',
            padding: '12px 24px',
            fontSize: '1rem',
            fontWeight: '600',
            borderRadius: '12px',
            border: '2px solid #9333ea',
            backgroundColor: '#ffffff',
            color: '#9333ea',
            transition: 'all 0.3s',
          }
        }}
      />
    </div>
  );
};

export default WalletStudent;
import React, { useState, useEffect } from 'react';
import { Bug } from 'lucide-react';
import axios from '../../helpers/axiosconfig';

const BugList = () => {
  const [bugReports, setBugReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBugReports = async () => {
      try {
        setLoading(true);
        const response = await axios.get('reports');
        setBugReports(response.data.bugReports);
        setError(null);
      } catch (err) {
        setError(err.response?.data?.message || 'Failed to fetch bug reports');
      } finally {
        setLoading(false);
      }
    };

    fetchBugReports();
  }, []);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  if (loading) {
    return (
      <div className="w-full max-w-4xl mx-auto p-4 text-center">
        <div className="animate-pulse text-gray-600">Loading bug reports...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full max-w-4xl mx-auto p-4">
        <div className="bg-red-50 border border-red-200 rounded-lg p-4 text-red-600">
          Error loading bug reports: {error}
        </div>
      </div>
    );
  }

  return (
    <div className="w-full max-w-4xl mx-auto p-4">
      <div className="bg-white rounded-lg shadow-lg">
        <div className="border-b p-4">
          <h1 className="text-xl font-semibold flex items-center gap-2">
            <Bug className="h-6 w-6" />
            Bug Reports ({bugReports?.length || 0})
          </h1>
        </div>
        
        <div className="p-4">
          <div className="space-y-4">
            {bugReports?.map((bug) => (
              <div 
                key={bug._id}
                className="border rounded-lg p-4 hover:shadow-md transition-shadow"
              >
                <div className="space-y-2">
                  <h3 className="text-lg font-semibold">{bug.title}</h3>
                  <p className="text-gray-600">{bug.description}</p>
                  {bug.imageName && (
                    <div className="mt-4">
                      <img 
                        src={`https://speakable.online/api/image/${bug.imageName}`}
                        alt="Bug screenshot" 
                        className="rounded-md max-w-full h-auto"
                      />
                      <p className="text-sm text-gray-500 mt-1">{bug.imageName}</p>
                    </div>
                  )}
                  <div className="text-sm text-gray-500 mt-4">
                    Reported: {formatDate(bug.createdAt)}
                  </div>
                </div>
              </div>
            ))}
          </div>
          
          {(!bugReports || bugReports.length === 0) && (
            <div className="text-center text-gray-500 py-8">
              No bug reports found.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BugList;
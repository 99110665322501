import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DateRangePicker } from 'react-date-range';
import { notification, Select, Button, Switch, TimePicker, Modal, Row, Col, Typography, Divider, Checkbox } from "antd";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';
import { Signup_Teacher_By_Admin } from "../../store/actions/teachersActions";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { EditOutlined, DeleteOutlined, PlusOutlined, CalendarOutlined } from '@ant-design/icons';
import { 
  addDays, 
  format, 
  startOfMonth, 
  endOfMonth, 
  startOfWeek,
  endOfWeek,
  addMonths,
  isSameMonth,
  isSameDay
} from 'date-fns';

dayjs.extend(utc);
dayjs.extend(timezone);

const { Option } = Select;
const { Title, Text } = Typography;

const timeZones = moment.tz.names();
const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const PREDEFINED_SLOTS = [
  { start: "00:00", end: "00:25" },
  { start: "00:30", end: "00:55" },
  { start: "01:00", end: "01:25" },
  { start: "01:30", end: "01:55" },
  { start: "02:00", end: "02:25" },
  { start: "02:30", end: "02:55" },
  { start: "03:00", end: "03:25" },
  { start: "03:30", end: "03:55" },
  { start: "04:00", end: "04:25" },
  { start: "04:30", end: "04:55" },
  { start: "05:00", end: "05:25" },
  { start: "05:30", end: "05:55" },
  { start: "06:00", end: "06:25" },
  { start: "06:30", end: "06:55" },
  { start: "07:00", end: "07:25" },
  { start: "07:30", end: "07:55" },
  { start: "08:00", end: "08:25" },
  { start: "08:30", end: "08:55" },
  { start: "09:00", end: "09:25" },
  { start: "09:30", end: "09:55" },
  { start: "10:00", end: "10:25" },
  { start: "10:30", end: "10:55" },
  { start: "11:00", end: "11:25" },
  { start: "11:30", end: "11:55" },
  { start: "12:00", end: "12:25" },
  { start: "12:30", end: "12:55" },
  { start: "13:00", end: "13:25" },
  { start: "13:30", end: "13:55" },
  { start: "14:00", end: "14:25" },
  { start: "14:30", end: "14:55" },
  { start: "15:00", end: "15:25" },
  { start: "15:30", end: "15:55" },
  { start: "16:00", end: "16:25" },
  { start: "16:30", end: "16:55" },
  { start: "17:00", end: "17:25" },
  { start: "17:30", end: "17:55" },
  { start: "18:00", end: "18:25" },
  { start: "18:30", end: "18:55" },
  { start: "19:00", end: "19:25" },
  { start: "19:30", end: "19:55" },
  { start: "20:00", end: "20:25" },
  { start: "20:30", end: "20:55" },
  { start: "21:00", end: "21:25" },
  { start: "21:30", end: "21:55" },
  { start: "22:00", end: "22:25" },
  { start: "22:30", end: "22:55" },
  { start: "23:00", end: "23:25" },
  { start: "23:30", end: "23:55" }
];

const CustomDay = ({ day, selectedDates }) => {
  const dateStr = format(day, 'yyyy-MM-dd');
  const isSelected = selectedDates[dateStr];

  return (
    <div className={`custom-day ${isSelected ? 'selected' : ''}`}>
      {format(day, 'd')}
    </div>
  );
};

const MiniCalendar = ({ selectedDates, onDateToggle, currentMonth, setCurrentMonth }) => {
  const firstDayOfMonth = startOfMonth(currentMonth);
  const lastDayOfMonth = endOfMonth(currentMonth);
  const startDate = startOfWeek(firstDayOfMonth);
  const endDate = endOfWeek(lastDayOfMonth);

  const dateFormat = "d";
  const rows = [];
  let days = [];
  let day = startDate;

  const changeMonth = (amount) => {
    setCurrentMonth(addMonths(currentMonth, amount));
  };

  while (day <= endDate) {
    for (let i = 0; i < 7; i++) {
      const cloneDay = day;
      const dateStr = format(cloneDay, 'yyyy-MM-dd');
      days.push(
        <div
          className={`calendar-day ${
            !isSameMonth(day, firstDayOfMonth) ? "disabled" :
            isSameDay(day, new Date()) ? "today" : ""
          } ${selectedDates[dateStr] ? "selected" : ""}`}
          key={day}
          onClick={() => onDateToggle(dateStr)}
        >
          <span>{format(day, dateFormat)}</span>
        </div>
      );
      day = addDays(day, 1);
    }
    rows.push(
      <div className="calendar-row" key={day}>
        {days}
      </div>
    );
    days = [];
  }

  return (
    <div className="mini-calendar-container">
      <div className="calendar-header">
        <button onClick={() => changeMonth(-1)}>&lt;</button>
        <h3>{format(currentMonth, 'MMMM yyyy')}</h3>
        <button onClick={() => changeMonth(1)}>&gt;</button>
      </div>
      <div className="calendar-days">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div className="calendar-day-header" key={day}>{day}</div>
        ))}
      </div>
      <div className="calendar-body">
        {rows}
      </div>
    </div>
  );
};

const AdminAddAvailability = () => {
  const location = useLocation();
  const formData = location.state.Alldata;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDates, setSelectedDates] = useState({});
  const [availability, setAvailability] = useState(() => {
    return daysOfWeek.reduce((acc, day) => {
      acc[day] = { 
        enabled: false,
        selectedSlots: new Set()
      };
      return acc;
    }, {});
  });

  const [userTimezone, setUserTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLongRangeModalVisible, setIsLongRangeModalVisible] = useState(false);
  const [minDate, setMinDate] = useState(new Date());
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const [selectedDays, setSelectedDays] = useState({
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false
  });
  const [hourFormat, setHourFormat] = useState('24');
  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  });
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState({});
  const [showMiniCalendar, setShowMiniCalendar] = useState(false);
  const [timeRange, setTimeRange] = useState({
    start: "00:00",
    end: "23:55"
  });

  const handleTimeRangeChange = (type, value) => {
    setTimeRange(prev => ({
      ...prev,
      [type]: value
    }));
  };

  const applyTimeRange = (day) => {
    const startIndex = PREDEFINED_SLOTS.findIndex(slot => slot.start === timeRange.start);
    const endIndex = PREDEFINED_SLOTS.findIndex(slot => slot.end === timeRange.end);
    
    if (startIndex === -1 || endIndex === -1) return;

    setAvailability(prev => {
      const newSlots = new Set(prev[day].selectedSlots);
      
      for (let i = startIndex; i <= endIndex; i++) {
        const slot = PREDEFINED_SLOTS[i];
        const slotStr = `${slot.start}-${slot.end}`;
        newSlots.add(slotStr);
      }

      return {
        ...prev,
        [day]: {
          ...prev[day],
          selectedSlots: newSlots
        }
      };
    });
  };

  const handleSlotToggle = (day, slotIndex) => {
    const slot = PREDEFINED_SLOTS[slotIndex];
    const slotStr = `${slot.start}-${slot.end}`;
    
    setAvailability(prev => {
      const newAvailability = { ...prev };
      const dayAvailability = new Set(prev[day].selectedSlots);
      
      if (dayAvailability.has(slotStr)) {
        dayAvailability.delete(slotStr);
      } else {
        dayAvailability.add(slotStr);
      }
      
      newAvailability[day] = {
        ...prev[day],
        selectedSlots: dayAvailability
      };
      
      return newAvailability;
    });
  };

  const handleCheckboxChange = (day) => {
    setAvailability({
      ...availability,
      [day]: { ...availability[day], enabled: !availability[day].enabled },
    });
  };

  const handleRangeChange = (item) => {
    const newSelectedDates = { ...selectedDates };
    let currentDate = new Date(item.selection.startDate);
    const endDate = new Date(item.selection.endDate);
  
    while (currentDate <= endDate) {
      const dateStr = format(currentDate, 'yyyy-MM-dd');
      const dayName = format(currentDate, 'EEEE');
      newSelectedDates[dateStr] = selectedDays[dayName];
      currentDate = addDays(currentDate, 1);
    }
  
    setSelectedDates(newSelectedDates);
    setState({ ...state, ...item });
  };

  const handleDateToggle = (dateStr) => {
    const newSelectedDates = {
      ...selectedDates,
      [dateStr]: !selectedDates[dateStr]
    };
    setSelectedDates(newSelectedDates);

    const selectedDatesList = Object.keys(newSelectedDates).filter(date => newSelectedDates[date]);
    if (selectedDatesList.length > 0) {
      const startDate = new Date(selectedDatesList[0]);
      const endDate = new Date(selectedDatesList[selectedDatesList.length - 1]);
      setState({
        ...state,
        selection: {
          startDate,
          endDate,
          key: 'selection'
        }
      });
    } else {
      setState({
        ...state,
        selection: {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
      });
    }
  };

  const handleDayCheckboxChange = (day) => {
    setSelectedDays(prevDays => {
      const newDays = { ...prevDays, [day]: !prevDays[day] };
      
      setAvailability(prevAvailability => ({
        ...prevAvailability,
        [day]: { 
          ...prevAvailability[day], 
          enabled: newDays[day]
        }
      }));
  
      setSelectedDates(prevDates => {
        const newDates = { ...prevDates };
        const startDate = state.selection.startDate;
        const endDate = state.selection.endDate;
        let currentDate = new Date(startDate);
  
        while (currentDate <= endDate) {
          const dateStr = format(currentDate, 'yyyy-MM-dd');
          const dayOfWeek = format(currentDate, 'EEEE');
          
          if (dayOfWeek === day) {
            newDates[dateStr] = newDays[day];
          }
  
          currentDate = addDays(currentDate, 1);
        }
  
        return newDates;
      });
  
      return newDays;
    });
  };

  const handleTimeChange = (day, index, field, newValue) => {
    setAvailability(prev => {
      const newSlots = prev[day].slots.map((slot, i) => {
        if (i === index) {
          if (field === 'fromTime') {
            const toTime = newValue.add(25, 'minute');
            return { ...slot, fromTime: newValue, toTime };
          }
          return { ...slot, [field]: newValue };
        }
        return slot;
      });
      return { ...prev, [day]: { ...prev[day], slots: newSlots } };
    });
  };

  const handleAddConsecutiveSlot = (day, index) => {
    setAvailability(prev => {
      const updatedSlots = [...prev[day].slots];
      const currentSlot = updatedSlots[index];
      const newFromTime = currentSlot.toTime;
      const newToTime = newFromTime.add(25, 'minute');
      updatedSlots.splice(index + 1, 0, { id: uuidv4(), fromTime: newFromTime, toTime: newToTime });
      return { ...prev, [day]: { ...prev[day], slots: updatedSlots } };
    });
  };
  
  const handleAddSlot = (day) => {
    setAvailability(prev => {
      const fromTime = dayjs().hour(12).minute(0);
      const toTime = fromTime.add(25, 'minute');
      const newSlot = {
        id: uuidv4(),
        fromTime,
        toTime
      };
      return { 
        ...prev, 
        [day]: { 
          ...prev[day], 
          slots: [...prev[day].slots, newSlot]
        } 
      };
    });
  };

  const handleRemoveSlot = (day, index) => {
    setAvailability(prev => {
      const newSlots = prev[day].slots.filter((_, i) => i !== index);
      return { ...prev, [day]: { ...prev[day], slots: newSlots } };
    });
  };

  const handleEditClick = (day) => {
    setAvailability({
      ...availability,
      [day]: { ...availability[day], isEditing: !availability[day].isEditing },
    });
  };

  const handleLongRangeSelection = () => {
    const newSelectedDates = {};
    selectedYears.forEach(year => {
      const startOfYear = new Date(year, 0, 1);
      const endOfYear = new Date(year, 11, 31);
      let currentDate = startOfYear;
  
      while (currentDate <= endOfYear) {
        const dateStr = format(currentDate, 'yyyy-MM-dd');
        const monthStr = format(currentDate, 'yyyy-MM');
        const dayName = format(currentDate, 'EEEE');
  
        if (selectedMonths[monthStr] !== false) {
          newSelectedDates[dateStr] = selectedDays[dayName];
        }
  
        currentDate = addDays(currentDate, 1);
      }
    });
  
    setSelectedDates(newSelectedDates);
    setState({
      ...state,
      selection: {
        startDate: new Date(Math.min(...Object.keys(newSelectedDates).map(date => new Date(date)))),
        endDate: new Date(Math.max(...Object.keys(newSelectedDates).map(date => new Date(date)))),
        key: 'selection'
      }
    });
    setIsLongRangeModalVisible(false);
  };

  const handleDeleteEntireAvailability = () => {
    setSelectedDates({});
    setAvailability(
      daysOfWeek.reduce((acc, day) => {
        acc[day] = { enabled: false, slots: [], isEditing: false };
        return acc;
      }, {})
    );
    setSelectedDays(
      daysOfWeek.reduce((acc, day) => {
        acc[day] = false;
        return acc;
      }, {})
    );
    setIsDeleteConfirmVisible(false);
  };

  const handleSubmit = async () => {
    try {
      const availabilitySlots = [];

      Object.entries(selectedDates).forEach(([date, isSelected]) => {
        if (isSelected) {
          const dayOfWeek = format(new Date(date), 'EEEE');
          if (selectedDays[dayOfWeek] && availability[dayOfWeek]) {
            availability[dayOfWeek].selectedSlots.forEach(slotStr => {
              const [start, end] = slotStr.split('-');
              const slotDate = dayjs.tz(date, userTimezone);
              const fromTime = slotDate
                .hour(parseInt(start.split(':')[0]))
                .minute(parseInt(start.split(':')[1]));
              const toTime = slotDate
                .hour(parseInt(end.split(':')[0]))
                .minute(parseInt(end.split(':')[1]));

              availabilitySlots.push({
                start: fromTime.utc().format('YYYY-MM-DDTHH:mm'),
                end: toTime.utc().format('YYYY-MM-DDTHH:mm')
              });
            });
          }
        }
      });

      const availabilityData = {
        slots: availabilitySlots
      };

      await dispatch(Signup_Teacher_By_Admin({
        ...formData,
        Availability: availabilityData,
        selectedDays,
        userTimezone,
      }));

      notification.success({
        message: 'Teacher Added',
        description: 'The teacher has been successfully added with the specified availability.',
      });

      navigate("/Admin-Dashboard/Teachers");
    } catch (error) {
      console.error("Error adding teacher:", error);
      notification.error({
        message: 'Submission Error',
        description: 'There was an error adding the teacher. Please try again.',
      });
    }
  };

  return (
    <>
      <div className="styled-container">
        <div className="calendar-container">
          <h2>Add Teacher Availability</h2>
          
          <div className="day-selection">
            {Object.keys(selectedDays).map(day => (
              <div key={day} className="day-switch">
                <Switch
                  checked={selectedDays[day]}
                  onChange={() => handleDayCheckboxChange(day)}
                  className="day-switch-toggle"
                />
                <span className={selectedDays[day] ? 'day-label' : 'day-label-disabled'}>
                  {day}
                </span>
              </div>
            ))}
          </div>
          
          <DateRangePicker
            onChange={item => handleRangeChange(item)}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[state.selection]}
            direction="horizontal"
            minDate={minDate}
            dayContentRenderer={day => <CustomDay day={day} selectedDates={selectedDates} />}
          />
          
          <div className="mini-calendar-switch">
            <Switch
              checked={showMiniCalendar}
              onChange={setShowMiniCalendar}
              checkedChildren="Edit Specific Dates"
              unCheckedChildren="Edit Specific Dates"
            />
          </div>
          
          {showMiniCalendar && (
            <MiniCalendar 
              selectedDates={selectedDates}
              onDateToggle={handleDateToggle}
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
            />
          )}
          
          <div className="action-buttons">
            <Button 
              icon={<CalendarOutlined />}
              onClick={() => setIsLongRangeModalVisible(true)}
              className="select-long-range-button"
            >
              Select Long Range
            </Button>
            <Button 
              onClick={() => setIsModalVisible(true)}
              type="primary"
              className="set-availability-button"
            >
              Set Availability for Selected Dates
            </Button>
            <Button 
              onClick={() => setIsDeleteConfirmVisible(true)}
              type="danger"
              className="delete-availability-button"
            >
              Delete Entire Availability
            </Button>
          </div>
        </div>
      </div>

      <Modal
        title="Weekly Availability"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Submit
          </Button>,
        ]}
        width={1000}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="modal-content">
            <div className="timezone-selector">
              <label>Timezone availability:</label>
              <Select
                style={{ width: 200 }}
                value={userTimezone}
                onChange={setUserTimezone}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                }
              >
                {timeZones.map(tz => (
                  <Option key={tz} value={tz}>{tz}</Option>
                ))}
              </Select>
            </div>

            {daysOfWeek.map((day) => (
              <div key={day} className="day-availability">
                <div className="day-header">
                  <div className="day-controls">
                    <Switch
                      checked={selectedDays[day]}
                      onChange={() => handleDayCheckboxChange(day)}
                      className="day-switch-toggle"
                    />
                    <span className={selectedDays[day] ? 'day-label' : 'day-label-disabled'}>
                      {day}
                    </span>
                    {selectedDays[day] && (
                      <>
                        <div className="time-range-controls">
                          <Select
                            size="small"
                            style={{ width: 100 }}
                            value={timeRange.start}
                            onChange={(value) => handleTimeRangeChange('start', value)}
                          >
                            {PREDEFINED_SLOTS.map(slot => (
                              <Option key={slot.start} value={slot.start}>
                                {slot.start}
                              </Option>
                            ))}
                          </Select>
                          <span style={{ margin: '0 8px' }}>to</span>
                          <Select
                            size="small"
                            style={{ width: 100 }}
                            value={timeRange.end}
                            onChange={(value) => handleTimeRangeChange('end', value)}
                          >
                            {PREDEFINED_SLOTS.map(slot => (
                              <Option key={slot.end} value={slot.end}>
                                {slot.end}
                              </Option>
                            ))}
                          </Select>
                          <Button
                            size="small"
                            type="primary"
                            style={{ marginLeft: 8 }}
                            onClick={() => applyTimeRange(day)}
                          >
                            Apply Range
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {selectedDays[day] && (
                  <div className="time-slots-grid">
                    {PREDEFINED_SLOTS.map((slot, index) => {
                      const slotStr = `${slot.start}-${slot.end}`;
                      const isSelected = availability[day].selectedSlots.has(slotStr);
                      
                      return (
                        <Checkbox
                          key={index}
                          checked={isSelected}
                          onChange={() => handleSlotToggle(day, index)}
                          className="slot-checkbox"
                        >
                          <span>
                            {`${slot.start} - ${slot.end}`}
                          </span>
                        </Checkbox>
                      );
                    })}
                  </div>
                )}
              </div>
            ))}
          </div>
        </LocalizationProvider>
      </Modal>

      <Modal
        title="Confirm Deletion"
        visible={isDeleteConfirmVisible}
        onCancel={() => setIsDeleteConfirmVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsDeleteConfirmVisible(false)}>
            Cancel
          </Button>,
          <Button key="delete" type="danger" onClick={handleDeleteEntireAvailability}>
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure you want to delete the entire availability? This action cannot be undone.</p>
      </Modal>

      <Modal
        title={<Title level={3}><CalendarOutlined /> Select Long Range</Title>}
        visible={isLongRangeModalVisible}
        onCancel={() => setIsLongRangeModalVisible(false)}
        onOk={handleLongRangeSelection}
        width={800}
        footer={[
          <Button key="cancel" onClick={() => setIsLongRangeModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleLongRangeSelection}>
            Apply Selection
          </Button>,
        ]}
      >
        <div style={{ marginBottom: '20px' }}>
          <Title level={4}>Select Years</Title>
          <Row gutter={[16, 16]}>
            {[...Array(10)].map((_, index) => {
              const year = new Date().getFullYear() + index;
              return (
                <Col key={year} span={6}>
                  <Checkbox
                    checked={selectedYears.includes(year)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedYears([...selectedYears, year]);
                        setSelectedMonths(prev => ({
                          ...prev,
                          ...Array.from({ length: 12 }, (_, i) => `${year}-${String(i + 1).padStart(2, '0')}`).reduce((acc, month) => {
                            acc[month] = true;
                            return acc;
                          }, {})
                        }));
                      } else {
                        setSelectedYears(selectedYears.filter(y => y !== year));
                        setSelectedMonths(prev => {
                          const newMonths = { ...prev };
                          Array.from({ length: 12 }, (_, i) => `${year}-${String(i + 1).padStart(2, '0')}`).forEach(month => {
                            delete newMonths[month];
                          });
                          return newMonths;
                        });
                      }
                    }}
                  >
                    <Text strong>{year}</Text>
                  </Checkbox>
                </Col>
              );
            })}
          </Row>
        </div>

        <Divider />

        <div style={{ marginBottom: '20px' }}>
          <Title level={4}>Select Months</Title>
          {selectedYears.map(year => (
            <div key={year}>
              <Title level={5} style={{ marginBottom: '10px' }}>{year}</Title>
              <Row gutter={[16, 16]}>
                {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month, index) => {
                  const monthStr = `${year}-${String(index + 1).padStart(2, '0')}`;
                  return (
                    <Col key={monthStr} span={6}>
                      <Checkbox
                        checked={selectedMonths[monthStr] !== false}
                        onChange={(e) => {
                          setSelectedMonths(prev => ({
                            ...prev,
                            [monthStr]: e.target.checked
                          }));
                        }}
                      >
                        <Text>{month}</Text>
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
              <Divider />
            </div>
          ))}
        </div>

        <div>
          <Title level={4}>Select Days of Week</Title>
          <Row gutter={[16, 16]}>
            {Object.keys(selectedDays).map(day => (
              <Col key={day} span={6}>
                <Checkbox
                  checked={selectedDays[day]}
                  onChange={() => handleDayCheckboxChange(day)}
                >
                  <Text>{day}</Text>
                </Checkbox>
              </Col>
            ))}
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default AdminAddAvailability;
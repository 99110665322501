import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Share2, Copy, Award, CheckCircle, UserPlus, Clock, Users, Gift, Mail, Send, X, Plus } from 'lucide-react';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { fetchWalletBalance, fetchWalletTransactions } from "../../store/actions/walletActions";
import instance from "../../helpers/axiosconfig"; // Using your configured axios instance

const ReferralDashboard = () => {
  const { t } = useTranslation("global");
  const dispatch = useDispatch();
  const [referralData, setReferralData] = useState({
    referralCode: '',
    shareLink: '',
    stats: { totalReferrals: 0, activeReferrals: 0, completedReferrals: 0 },
    rewards: { earned: 0, pending: 0, claimed: 0 },
    referrals: []
  });
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(true);
  const [claimLoading, setClaimLoading] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState('');
  const [packages, setPackages] = useState([]);
  
  // New states for email invitation
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteName, setInviteName] = useState('');
  const [sendingInvite, setSendingInvite] = useState(false);
  const [emailList, setEmailList] = useState([]);
  
  const user = useSelector(state => state.students.user);

  
  
  // Fetch referral data
  useEffect(() => {
    const fetchReferralData = async () => {
      try {
        setLoading(true);
        const response = await instance.get('/referral/stats');
        setReferralData(response.data);
      } catch (error) {
        console.error('Error fetching referral data:', error);
        toast.error(t('referral.loadingError') || 'Failed to load referral data');
        
        // Set demo data for testing when API is not yet implemented
        setReferralData({
          referralCode: 'DEMO123XYZ',
          shareLink: 'https://speakable.online/signup?ref=DEMO123XYZ',
          stats: { totalReferrals: 2, activeReferrals: 1, completedReferrals: 1 },
          rewards: { earned: 3, pending: 1, claimed: 2 },
          referrals: [
            {
              id: '1',
              user: {
                name: 'John Smith',
                email: 'john@example.com',
                joinedOn: '2024-02-15'
              },
              status: 'completed',
              milestones: [
                { type: 'signup', rewardAmount: 1, achieved: true, claimed: true },
                { type: 'purchase', rewardAmount: 2, achieved: true, claimed: true }
              ]
            },
            {
              id: '2',
              user: {
                name: 'Jane Doe',
                email: 'jane@example.com',
                joinedOn: '2024-03-10'
              },
              status: 'registered',
              milestones: [
                { type: 'signup', rewardAmount: 1, achieved: true, claimed: false },
                { type: 'purchase', rewardAmount: 2, achieved: false, claimed: false }
              ]
            }
          ]
        });
      } finally {
        setLoading(false);
      }
    };
    
    // Fetch available packages
    const fetchPackages = async () => {
      try {
        const response = await instance.get('/getpackages');
        setPackages(response.data.packages || []);
      } catch (error) {
        console.error('Error fetching packages:', error);
        
        // Set demo packages for testing
        setPackages([
          { _id: 'pkg1', Package_Name: 'Basic English Course' },
          { _id: 'pkg2', Package_Name: 'Business English' },
          { _id: 'pkg3', Package_Name: 'Conversation Practice' }
        ]);
      }
    };
    
    if (user) {
      fetchReferralData();
      fetchPackages();
    }
  }, [user, t]);
  
  const copyReferralLink = () => {
    // Generate the appropriate URL based on environment
    const isProduction = process.env.NODE_ENV === 'production';
    
    // If in production, use the stored shareLink
    // If in development, replace the domain with the current origin
    let linkToCopy = referralData.shareLink;
    
    if (!isProduction) {
      // Extract just the referral code from the shareLink
      const refCode = referralData.referralCode;
      // Create a new link with the current origin
      linkToCopy = `${window.location.origin}/signup?ref=${refCode}`;
    }
    
    // Copy the appropriate link
    navigator.clipboard.writeText(linkToCopy);
    setCopied(true);
    toast.success(t('referral.codeCopied') || 'Referral link copied to clipboard!');
    setTimeout(() => setCopied(false), 2000);
  };
  
  const shareReferralLink = () => {
    // Generate the appropriate URL based on environment
    const isProduction = process.env.NODE_ENV === 'production';
    
    // If in production, use the stored shareLink
    // If in development, replace the domain with the current origin
    let linkToShare = referralData.shareLink;
    
    if (!isProduction) {
      // Extract just the referral code from the shareLink
      const refCode = referralData.referralCode;
      // Create a new link with the current origin
      linkToShare = `${window.location.origin}/signup?ref=${refCode}`;
    }
    
    if (navigator.share) {
      navigator.share({
        title: t('referral.joinTitle') || 'Join me on Speakable',
        text: t('referral.joinMessage') || 'Sign up using my referral code and get free hours!',
        url: linkToShare
      }).catch(err => console.error('Error sharing:', err));
    } else {
      // Fall back to copying, which will use our updated copyReferralLink function
      copyReferralLink();
    }
  };
  
  const handleClaimRewards = async () => {
    if (referralData.rewards.pending <= 0) {
      toast.info(t('referral.noRewards') || 'No rewards to claim');
      return;
    }
    
    try {
      setClaimLoading(true);
      
      const response = await instance.post('/referral/claim', {
        packageId: selectedPackage || undefined
      });
      
      // Update referral data after claiming
      const updatedStats = await instance.get('/referral/stats');
      setReferralData(updatedStats.data);

      // Add these lines to refresh wallet data in redux store
      if (user?._id) {
        dispatch(fetchWalletBalance(user._id));
        dispatch(fetchWalletTransactions(user._id));
      }
      
      toast.success(response.data.message || t('referral.claimSuccess') || 'Rewards claimed successfully!');
    } catch (error) {
      console.error('Error claiming rewards:', error);
      toast.error(error.response?.data?.message || t('referral.claimError') || 'Failed to claim rewards');
    } finally {
      setClaimLoading(false);
    }
  };

  // New function to add friend to invitation list
  const addFriendToList = () => {
    if (!inviteEmail || !inviteName) {
      toast.warning(t('referral.emailNameRequired') || 'Both name and email are required');
      return;
    }

    // Simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(inviteEmail)) {
      toast.warning(t('referral.invalidEmail') || 'Please enter a valid email address');
      return;
    }

    setEmailList([...emailList, { email: inviteEmail, name: inviteName }]);
    setInviteEmail('');
    setInviteName('');
  };

  // New function to remove friend from invitation list
  const removeFriendFromList = (index) => {
    const updatedList = [...emailList];
    updatedList.splice(index, 1);
    setEmailList(updatedList);
  };

  // New function to send email invitations
  const sendEmailInvitations = async () => {
    if (emailList.length === 0) {
      toast.info(t('referral.noEmails') || 'Please add at least one friend to invite');
      return;
    }

    setSendingInvite(true);
    
    try {
      let successCount = 0;

      // Send emails one by one to track success/failure better
      for (const friend of emailList) {
        try {
          await instance.post('referral/send-invitation', {
            studentEmail: friend.email,
            studentName: friend.name,
            referralCode: referralData.referralCode,
            referrerName: user?.firstName ? `${user.firstName} ${user.lastName || ''}` : user?.Username
          });
          successCount++;
        } catch (err) {
          console.error(`Failed to send invitation to ${friend.email}:`, err);
        }
      }

      // Show result toast
      if (successCount === emailList.length) {
        toast.success(t('referral.inviteSentAll') || 'All invitations sent successfully!');
        setEmailList([]);
        setShowEmailForm(false);
      } else if (successCount > 0) {
        toast.info(`${successCount} of ${emailList.length} invitations sent successfully.`);
      } else {
        toast.error(t('referral.inviteFailed') || 'Failed to send invitations. Please try again.');
      }
    } catch (error) {
      console.error('Error sending invitations:', error);
      toast.error(t('referral.inviteFailed') || 'Failed to send invitations. Please try again.');
    } finally {
      setSendingInvite(false);
    }
  };
  
  // Status badge component
  const StatusBadge = ({ status }) => {
    const statusConfig = {
      'registered': { color: 'bg-blue-100 text-blue-800', label: t('referral.statusSignedUp') || 'Signed Up' },
      'first_purchase': { color: 'bg-purple-100 text-purple-800', label: t('referral.statusPurchase') || 'Made Purchase' },
      'completed': { color: 'bg-green-100 text-green-800', label: t('referral.statusCompleted') || 'Completed' },
      'pending': { color: 'bg-yellow-100 text-yellow-800', label: t('referral.statusPending') || 'Pending' }
    };
    
    const config = statusConfig[status] || { color: 'bg-gray-100 text-gray-800', label: status };
    
    return (
      <span className={`px-2 py-1 text-xs rounded-full ${config.color}`}>
        {config.label}
      </span>
    );
  };
  
  // Format date helper
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };
  
  return (
    <div className="w-full mx-auto bg-white rounded-lg shadow-md p-3 sm:p-6">
      {loading ? (
        <div className="flex items-center justify-center py-12">
          <div className="w-8 h-8 border-t-2 border-b-2 border-purple-500 rounded-full animate-spin"></div>
          <span className="ml-2 text-gray-600">{t('referral.loading') || 'Loading referral data...'}</span>
        </div>
      ) : (
        <>
          {/* Header Section */}
          <div className="border-b pb-4 sm:pb-6 mb-4 sm:mb-6">
            <h2 className="text-xl sm:text-2xl font-bold text-gray-800 flex items-center">
              <Users className="mr-2 text-purple-600" />
              {t('referral.heading') || 'Refer & Earn Program'}
            </h2>
            <p className="text-sm sm:text-base text-gray-600 mt-2">
              {t('referral.subheading') || 'Invite friends and earn free lesson hours! You\'ll receive 1 hour when they sign up, and 2 more hours when they make their first purchase.'}
            </p>
          </div>
          
          {/* Referral Code */}
          <div className="bg-purple-50 rounded-lg p-3 sm:p-4 mb-4 sm:mb-6">
  <h3 className="text-md sm:text-lg font-semibold text-gray-800 mb-2">{t('referral.yourCode') || 'Your Referral Code'}</h3>
  <div className="flex flex-col sm:flex-row mb-3 sm:mb-0">
    {/* Using inline styles to force identical heights */}
    <div 
      className="bg-white border rounded-lg sm:rounded-l-lg sm:rounded-r-none font-mono text-base sm:text-lg font-medium w-full sm:flex-grow mb-2 sm:mb-0 flex items-center justify-center sm:justify-start"
      style={{ height: '42px' }}  /* Fixed height */
    >
      <span className="px-3 sm:px-4">{referralData.referralCode}</span>
    </div>
    <button 
      onClick={copyReferralLink}
      className="bg-purple-600 hover:bg-purple-700 text-white rounded-lg sm:rounded-l-none sm:rounded-r-lg px-4 flex items-center justify-center w-full sm:w-auto"
      style={{ height: '42px' }}  /* Exactly the same height */
    >
      {copied ? <CheckCircle size={18} className="mr-1" /> : <Copy size={18} className="mr-1" />}
      {copied ? (t('referral.copied') || 'Copied!') : (t('referral.copyLink') || 'Copy Link')}
    </button>
  </div>
            
            <div className="flex flex-col sm:flex-row gap-2 mt-3">
              <button 
                onClick={shareReferralLink}
                className="flex-1 bg-gray-100 hover:bg-gray-200 text-gray-800 rounded-lg px-4 py-2 flex items-center justify-center"
              >
                <Share2 size={18} className="mr-2" />
                {t('referral.shareWithFriends') || 'Share with Friends'}
              </button>
              
              <button 
                onClick={() => setShowEmailForm(!showEmailForm)}
                className="flex-1 bg-blue-600 hover:bg-blue-700 text-white rounded-lg px-4 py-2 flex items-center justify-center mt-2 sm:mt-0"
              >
                <Mail size={18} className="mr-2" />
                {showEmailForm 
                  ? (t('referral.hideEmailForm') || 'Hide Email Form') 
                  : (t('referral.sendEmailInvitation') || 'Send Email Invitation')}
              </button>
            </div>
          </div>
          
          {/* Email Invitation Form */}
          {showEmailForm && (
            <div className="bg-blue-50 rounded-lg p-3 sm:p-4 mb-4 sm:mb-6 border border-blue-100">
              <h3 className="text-md sm:text-lg font-semibold text-gray-800 mb-2">
                {t('referral.inviteFriends') || 'Invite Friends via Email'}
              </h3>
              
              <div className="bg-white rounded-lg p-3 sm:p-4 mb-4">
                <div className="flex flex-col gap-2 mb-3">
                  <input
                    type="text"
                    value={inviteName}
                    onChange={(e) => setInviteName(e.target.value)}
                    placeholder={t('referral.friendName') || "Friend's Name"}
                    className="flex-1 border rounded-lg px-3 py-2"
                  />
                  <input
                    type="email"
                    value={inviteEmail}
                    onChange={(e) => setInviteEmail(e.target.value)}
                    placeholder={t('referral.friendEmail') || "Friend's Email"}
                    className="flex-1 border rounded-lg px-3 py-2 mt-2 sm:mt-0"
                  />
                  <button
                    onClick={addFriendToList}
                    className="bg-green-600 hover:bg-green-700 text-white rounded-lg px-4 py-2 flex items-center justify-center mt-2 sm:mt-0"
                  >
                    <Plus size={18} className="mr-1" />
                    {t('referral.addFriend') || 'Add Friend'}
                  </button>
                </div>
                
                {emailList.length > 0 && (
                  <div className="mb-3">
                    <p className="text-sm font-medium text-gray-700 mb-2">
                      {t('referral.inviteList') || 'Friends to invite:'}
                    </p>
                    <div className="max-h-32 overflow-y-auto">
                      {emailList.map((friend, index) => (
                        <div key={index} className="flex justify-between items-center bg-gray-50 p-2 rounded mb-1">
                          <div className="overflow-hidden">
                            <span className="font-medium text-sm sm:text-base">{friend.name}</span>
                            <span className="text-xs sm:text-sm text-gray-500 ml-2 block sm:inline-block truncate">{friend.email}</span>
                          </div>
                          <button 
                            onClick={() => removeFriendFromList(index)}
                            className="text-red-500 hover:text-red-700 flex-shrink-0 ml-2"
                          >
                            <X size={16} />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                
                <button
                  onClick={sendEmailInvitations}
                  disabled={emailList.length === 0 || sendingInvite}
                  className="w-full bg-blue-600 hover:bg-blue-700 text-white rounded-lg py-2 flex items-center justify-center disabled:bg-gray-400"
                >
                  {sendingInvite ? (
                    <>
                      <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></div>
                      {t('referral.sending') || 'Sending...'}
                    </>
                  ) : (
                    <>
                      <Send size={18} className="mr-2" />
                      {t('referral.sendInvitations') || 'Send Invitations'}
                    </>
                  )}
                </button>
              </div>
              
              <p className="text-xs sm:text-sm text-gray-600 italic">
                {t('referral.emailDisclaimer') || 'We will send a personalized invitation email with your referral code to each person in your list.'}
              </p>
            </div>
          )}
          
          {/* Stats Cards */}
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-3 sm:gap-4 mb-4 sm:mb-6">
            <div className="bg-white border rounded-lg p-3 sm:p-4 shadow-sm">
              <div className="flex items-center mb-2">
                <Users className="text-purple-600 mr-2" size={18} />
                <h3 className="text-md sm:text-lg font-semibold text-gray-700">{t('referral.totalReferrals') || 'Total Referrals'}</h3>
              </div>
              <p className="text-2xl sm:text-3xl font-bold text-gray-900">{referralData.stats.totalReferrals}</p>
            </div>
            
            <div className="bg-white border rounded-lg p-3 sm:p-4 shadow-sm">
              <div className="flex items-center mb-2">
                <Award className="text-green-600 mr-2" size={18} />
                <h3 className="text-md sm:text-lg font-semibold text-gray-700">{t('referral.hoursEarned') || 'Hours Earned'}</h3>
              </div>
              <p className="text-2xl sm:text-3xl font-bold text-gray-900">{referralData.rewards.earned}</p>
            </div>
            
            <div className="bg-white border rounded-lg p-3 sm:p-4 shadow-sm">
              <div className="flex items-center mb-2">
                <Gift className="text-blue-600 mr-2" size={18} />
                <h3 className="text-md sm:text-lg font-semibold text-gray-700">{t('referral.pendingHours') || 'Pending Hours'}</h3>
              </div>
              <p className="text-2xl sm:text-3xl font-bold text-gray-900">{referralData.rewards.pending}</p>
            </div>
          </div>
          
          {/* Claim Rewards */}
          {referralData.rewards.pending > 0 && (
            <div className="bg-green-50 border border-green-100 rounded-lg p-3 sm:p-4 mb-4 sm:mb-6">
              <h3 className="text-md sm:text-lg font-semibold text-gray-800 mb-2">
                {t('referral.claimRewards') || 'Claim Your Rewards'}
              </h3>
              <p className="text-sm sm:text-base text-gray-600 mb-4">
                {t('referral.claimInstructions', { hours: referralData.rewards.pending }) || 
                `You have ${referralData.rewards.pending} free hours available to claim. Select a package to add these hours to (optional).`}
              </p>
              
              <div className="flex flex-col gap-3">
                <select 
                  value={selectedPackage}
                  onChange={(e) => setSelectedPackage(e.target.value)}
                  className="w-full border rounded-lg p-2"
                >
                  <option value="">{t('referral.allPackages') || 'All Packages (General Balance)'}</option>
                  {packages.map(pkg => (
                    <option key={pkg._id} value={pkg._id}>
                      {pkg.Package_Name}
                    </option>
                  ))}
                </select>
                
                <button
                  onClick={handleClaimRewards}
                  disabled={claimLoading}
                  className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-lg flex items-center justify-center disabled:bg-gray-400"
                >
                  {claimLoading ? (
                    <>
                      <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></div>
                      {t('referral.claiming') || 'Claiming...'}
                    </>
                  ) : (
                    <>
                      <Award size={18} className="mr-2" />
                      {t('referral.claimNow') || 'Claim Now'}
                    </>
                  )}
                </button>
              </div>
            </div>
          )}
          
          {/* Referral History */}
          <div>
            <h3 className="text-lg sm:text-xl font-bold text-gray-800 mb-3 sm:mb-4">{t('referral.history') || 'Referral History'}</h3>
            {referralData.referrals.length === 0 ? (
              <div className="text-center py-6 sm:py-8 bg-gray-50 rounded-lg">
                <p className="text-gray-500 text-sm sm:text-base">{t('referral.noReferrals') || "You haven't referred anyone yet"}</p>
                <div className="flex flex-col sm:flex-row gap-2 justify-center mt-3">
                  <button 
                    onClick={shareReferralLink}
                    className="bg-purple-600 hover:bg-purple-700 text-white rounded-lg px-4 py-2 flex items-center justify-center"
                  >
                    <Share2 size={18} className="mr-2" />
                    {t('referral.shareYourCode') || 'Share Your Code'}
                  </button>
                  <button 
                    onClick={() => setShowEmailForm(true)}
                    className="bg-blue-600 hover:bg-blue-700 text-white rounded-lg px-4 py-2 flex items-center justify-center mt-2 sm:mt-0"
                  >
                    <Mail size={18} className="mr-2" />
                    {t('referral.sendEmails') || 'Send Emails'}
                  </button>
                </div>
              </div>
            ) : (
              <div className="overflow-x-auto -mx-3 sm:mx-0">
                <table className="w-full min-w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="text-left px-4 py-2 text-xs sm:text-sm font-semibold text-gray-600">{t('referral.name') || 'Name'}</th>
                      <th className="text-left px-4 py-2 text-xs sm:text-sm font-semibold text-gray-600">{t('referral.joined') || 'Joined'}</th>
                      <th className="text-left px-4 py-2 text-xs sm:text-sm font-semibold text-gray-600">{t('referral.status') || 'Status'}</th>
                      <th className="text-left px-4 py-2 text-xs sm:text-sm font-semibold text-gray-600">{t('referral.rewards') || 'Rewards'}</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y">
                    {referralData.referrals.map(referral => (
                      <tr key={referral.id} className="hover:bg-gray-50">
                        <td className="px-4 py-3">
                          <div className="font-medium text-sm sm:text-base text-gray-900">{referral.user.name}</div>
                          <div className="text-xs sm:text-sm text-gray-500 truncate max-w-32 sm:max-w-none">{referral.user.email}</div>
                        </td>
                        <td className="px-4 py-3 text-xs sm:text-sm text-gray-700">
                          {formatDate(referral.user.joinedOn)}
                        </td>
                        <td className="px-4 py-3">
                          <StatusBadge status={referral.status} />
                        </td>
                        <td className="px-4 py-3">
                          {referral.milestones?.map((milestone, idx) => (
                            <div key={idx} className="flex items-center text-xs sm:text-sm">
                              <span className={`mr-1 sm:mr-2 ${milestone.claimed ? 'text-green-600' : 'text-gray-500'}`}>
                                {milestone.claimed ? <CheckCircle size={14} /> : <Clock size={14} />}
                              </span>
                              <span className="text-gray-700 truncate">
                                {milestone.rewardAmount} {t('referral.hour', { count: milestone.rewardAmount }) || 'hour(s)'} 
                                ({milestone.type === 'signup' ? 
                                  (t('referral.signup') || 'signup') : 
                                  (t('referral.purchase') || 'purchase')})
                              </span>
                            </div>
                          ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          
          {/* How It Works */}
          <div className="mt-6 sm:mt-8 bg-gray-50 rounded-lg p-4 sm:p-6">
            <h3 className="text-md sm:text-lg font-bold text-gray-800 mb-3 sm:mb-4">{t('referral.howItWorks') || 'How It Works'}</h3>
            <ol className="list-decimal list-inside space-y-2 text-sm sm:text-base text-gray-700">
              <li>{t('referral.step1') || 'Share your unique referral code with friends'}</li>
              <li>{t('referral.step2') || 'When they sign up, you both get 1 free hour'}</li>
              <li>{t('referral.step3') || 'When they make their first purchase, you get 2 more hours'}</li>
              <li>{t('referral.step4') || 'Claim your free hours to use for any lesson package'}</li>
            </ol>
          </div>
        </>
      )}
    </div>
  );
};

export default ReferralDashboard;
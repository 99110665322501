import { createSlice } from "@reduxjs/toolkit";
import {
  setTeacherEarningRate,
  creditLessonEarnings,
  getTeacherEarningsBalance,
  getTeacherEarningsHistory,
  fetchTeacherWalletData,
} from "../actions/teacherWallet";

const initialState = {
  balance: 0,
  earningRate: 0,
  currency: 'PLN',
  earningsHistory: [],
  pagination: {
    total: 0,
    page: 1,
    pages: 1
  },
  stats: {
    totalEarned: 0,
    totalWithdrawn: 0,
    currentBalance: 0,
    completedLessons: 0
  },
  loading: false,
  error: null,
  success: false
};

const teacherEarningsSlice = createSlice({
  name: "teacherEarnings",
  initialState,
  reducers: {
    clearEarningsState: (state) => {
      state.error = null;
      state.success = false;
    },
    setHistoryPage: (state, action) => {
      state.pagination.page = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // Set Earnings Rate
      .addCase(fetchTeacherWalletData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTeacherWalletData.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.balance = action.payload.walletBalance;
        state.stats = {
          totalEarned: action.payload.summary.totalEarnings,
          totalWithdrawn: 0, // This will be updated if available in the API response
          currentBalance: action.payload.walletBalance,
          completedLessons: action.payload.summary.completedBookings
        };
        // If the API returns earnings history, update it
        if (action.payload.bookings) {
          state.earningsHistory = action.payload.bookings
            .filter(booking => booking.Status === 'Completed')
            .map(booking => ({
              ...booking,
              amount: booking.earnings?.amount || 0,
              timestamp: booking.completedAt || new Date(),
              type: 'credit'
            }));
        }
      })
      .addCase(fetchTeacherWalletData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(setTeacherEarningRate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(setTeacherEarningRate.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.earningRate = action.payload.data.teacher.earningsPerLesson;
        state.currency = action.payload.data.teacher.earningsCurrency;
      })
      .addCase(setTeacherEarningRate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Credit Lesson Earnings
      .addCase(creditLessonEarnings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(creditLessonEarnings.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.balance = action.payload.data.wallet.balance;
        if (state.earningsHistory.length > 0) {
          state.earningsHistory.unshift(action.payload.data.wallet.transactions[0]);
        }
        state.stats.totalEarned += action.payload.data.wallet.transactions[0].amount;
        state.stats.completedLessons += 1;
      })
      .addCase(creditLessonEarnings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Get Balance
      .addCase(getTeacherEarningsBalance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTeacherEarningsBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.balance = action.payload.data.wallet.balance;
        state.stats = action.payload.data.stats;
      })
      .addCase(getTeacherEarningsBalance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Get History
      .addCase(getTeacherEarningsHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTeacherEarningsHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.earningsHistory = action.payload.data.transactions;
        state.pagination = action.payload.data.pagination;
      })
      .addCase(getTeacherEarningsHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const { clearEarningsState, setHistoryPage } = teacherEarningsSlice.actions;
export default teacherEarningsSlice.reducer;
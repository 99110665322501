import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { debounce } from 'lodash';
import { fetchPackage, deductFreeHoursFromPackage } from "../../store/actions/packagesActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AdminNav from "../admin-dashboard-components/AdminNav";
import { Add_booking } from "../../store/actions/bookingActions";
import moment from 'moment-timezone';
import Select from 'react-select';
import { useMediaQuery } from 'react-responsive';
import {
  GetExistingTeacher_Availability,
} from "../../store/actions/teachersActions";
import { useHours as spendHoursAction, fetchWalletBalance } from "../../store/actions/walletActions";
import { fetchExistingBookings, fetchBookingsExcludingStudent } from "../../store/actions/bookingActions";
import { Button, Spin } from "antd";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Calendar from './Calendar';
import MobileCalendar from './MobileCalendar';
// import MobileCalendar from './DSTTestCalendar'; // Temporary replacement for testing


const EditPackageTeacher = () => {
  const { Package_ID } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const [freeHours, setFreeHours] = useState(0);
  const [availability, setAvailability] = useState([]);
  const [selectedTeacherId, setSelectedTeacherId] = useState(null);
  const [selectedSlots, setSelectedSlots] = useState({});
  const [teacherOptions, setTeacherOptions] = useState([]);
  const [walletHours, setWalletHours] = useState({ initial: 0, free: 0, used: 0 });
  const [usedHours, setUsedHours] = useState(0);
  const [otherStudentBookings, setOtherStudentBookings] = useState([]);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [existingBookings, setExistingBookings] = useState([]);
  const [hourFormat] = useState(localStorage.getItem('hourFormat') || '24');
  const user = useSelector((state) => state.students.user);
  const [initialHours, setInitialHours] = useState(0);
  const pack = useSelector((state) => state.packages.currentPackage);
  const wallet = useSelector((state) => state.wallet);
  const selectedTimezone = useSelector((state) => state.students.user.Timezone);
  const [freeHoursUsed, setFreeHoursUsed] = useState(0);
  const [paidHoursUsed, setPaidHoursUsed] = useState(0);
  const lastProcessedSlotRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    dispatch(fetchPackage(Package_ID));
  }, [Package_ID, dispatch]);

  useEffect(() => {
    if (user?._id) {
      dispatch(fetchWalletBalance(user._id));
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (pack?.Teacher_IDs) {
      const options = pack.Teacher_IDs.map(teacher => ({
        value: teacher._id,
        label: teacher.Username
      }));
      setTeacherOptions(options);
    }
  }, [pack]);

  const formatOtherStudentBookings = useCallback((bookings) => {
    return bookings.flatMap(booking => 
      booking.slots.map((slot, index) => ({
        id: `other-${booking.date}-${index}`,
        start: moment.tz(`${booking.date} ${slot.start}`, 'UTC').tz(selectedTimezone).toDate(),
        end: moment.tz(`${booking.date} ${slot.end}`, 'UTC').tz(selectedTimezone).toDate(),
        title: 'Booked by Another Student',
        backgroundColor: '#FF9800',
        borderColor: '#FF9800',
        textColor: '#ffffff',
        extendedProps: {
          status: 'OtherStudentBooking'
        }
      }))
    );
  }, [selectedTimezone]);

  useEffect(() => {
    if (pack?._id && wallet.packageBalances) {
      const totalHours = wallet.packageBalances[pack._id]?.hours || 0;
      const freeHours = pack.FreeHours || 0;
      setInitialHours(totalHours + freeHours);
      setWalletHours(prevState => ({
        ...prevState,
        initial: totalHours,
        free: freeHours
      }));
      setUsedHours(0);
      setFreeHoursUsed(0);
      setPaidHoursUsed(0);
      console.log('Initial state set:', { totalHours, freeHours });
    }
  }, [pack, wallet.packageBalances]);

  const remainingHours = useMemo(() => {
    return initialHours - usedHours;
  }, [initialHours, usedHours]);

  const remainingFreeHours = useMemo(() => {
    return Math.max(walletHours.free - freeHoursUsed, 0);
  }, [walletHours.free, freeHoursUsed]);

  const convertToSelectedTimezone = useCallback((utcDateTime) => {
    return moment.utc(utcDateTime).tz(selectedTimezone);
  }, [selectedTimezone]);

  

  const renderDateTime = useCallback((time) => {
    if (!time) return '';
    const timeObj = convertToSelectedTimezone(time);
    const isDstChangeDay = timeObj.format('YYYY-MM-DD') === '2024-10-27';
    const timeFormat = hourFormat === '12' ? 'hh:mm A' : 'HH:mm';
    
    if (isDstChangeDay && timeObj.hour() === 2) {
      // Special handling for the repeated hour during DST change
      const isDST = timeObj.isDST();
      const suffix = isDST ? ' CEST' : ' CET';
      return `${timeObj.format(`MMM D, YYYY ${timeFormat}`)}${suffix}`;
    }
    
    return timeObj.format(`MMM D, YYYY ${timeFormat}`);
  }, [convertToSelectedTimezone, hourFormat]);

  const formatSlotTime = (slot) => {
    const start = moment(slot.start).tz(selectedTimezone);
    const end = moment(slot.end).tz(selectedTimezone);
    const isDstChangeDay = start.format('YYYY-MM-DD') === '2024-10-27';
    
    if (isDstChangeDay && (start.hour() === 2 || end.hour() === 2)) {
      const startSuffix = start.isDST() ? ' CEST' : ' CET';
      const endSuffix = end.isDST() ? ' CEST' : ' CET';
      return {
        start: `${start.format('HH:mm')}${startSuffix}`,
        end: `${end.format('HH:mm')}${endSuffix}`
      };
    }
    
    return {
      start: start.format('HH:mm'),
      end: end.format('HH:mm')
    };
  };

  const handleTeacherSelect = async (selectedOption) => {
    const teacherId = selectedOption.value;
    setSelectedTeacherId(teacherId);
    try {
      console.log('Fetching data for teacher:', teacherId);
      
      const [availabilityResult, existingBookingsResult, otherBookingsResult] = await Promise.all([
        dispatch(GetExistingTeacher_Availability(teacherId)),
        dispatch(fetchExistingBookings({ studentId: user._id, teacherId })),
        dispatch(fetchBookingsExcludingStudent({ studentId: user._id, teacherId }))
      ]);

      console.log('API calls completed');

      let newAvailability = [];
      let newExistingBookings = [];
      let newOtherStudentBookings = [];

      if (GetExistingTeacher_Availability.fulfilled.match(availabilityResult)) {
        newAvailability = formatAvailabilityToEvents(availabilityResult.payload);
        console.log("Formatted availability events:", newAvailability);
      } else {
        console.error('Failed to fetch teacher availability:', availabilityResult.error);
      }

      if (fetchExistingBookings.fulfilled.match(existingBookingsResult)) {
        newExistingBookings = formatExistingBookingsToEvents(existingBookingsResult.payload.bookings);
        console.log("Formatted existing bookings:", newExistingBookings);
      } else {
        console.error('Failed to fetch existing bookings:', existingBookingsResult.error);
      }

      if (fetchBookingsExcludingStudent.fulfilled.match(otherBookingsResult)) {
        newOtherStudentBookings = formatOtherStudentBookings(otherBookingsResult.payload);
        console.log("Formatted other students bookings:", newOtherStudentBookings);
      } else {
        console.error('Failed to fetch other students bookings:', otherBookingsResult.error);
      }

      // Merge all events, giving priority to existing bookings and other student bookings
      const allEvents = [
        ...newAvailability.filter(availableSlot => 
          !newExistingBookings.some(booking => 
            moment(availableSlot.start).isSame(booking.start) && 
            moment(availableSlot.end).isSame(booking.end)
          ) &&
          !newOtherStudentBookings.some(otherBooking => 
            moment(availableSlot.start).isSame(otherBooking.start) && 
            moment(availableSlot.end).isSame(otherBooking.end)
          )
        ),
        ...newExistingBookings,
        ...newOtherStudentBookings
      ];

      console.log('Final merged events:', allEvents);

      setAvailability(allEvents);
    } catch (error) {
      console.error('Error in handleTeacherSelect:', error);
      toast.error("An error occurred while fetching teacher data");
    }
  };

  const formatAvailabilityToEvents = useCallback((teacherData) => {
    if (teacherData && Array.isArray(teacherData.AvailableTimeSlots)) {
      return teacherData.AvailableTimeSlots.map((slot, index) => ({
        id: `available-${index}`,
        start: convertToSelectedTimezone(slot.start).toDate(),
        end: convertToSelectedTimezone(slot.end).toDate(),
        title: 'Available',
        backgroundColor: '#4CAF50',
        borderColor: '#4CAF50',
        textColor: '#ffffff',
        extendedProps: {
          status: 'Available'
        }
      }));
    }
    return [];
  }, [convertToSelectedTimezone]);

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'scheduled':
        return '#5C7153'; // Green
      case 'rescheduled':
        return '#FFC107'; // Yellow
      case 'cancelled':
        return '#F44336'; // Red
      case 'completed':
        return '#2196F3'; // Blue
      default:
        return '#5C7153'; // Default green for 'Booked' or unknown status
    }
  };

  const formatExistingBookingsToEvents = useCallback((bookings) => {
    return bookings.flatMap(booking => 
      booking.Dates.flatMap(({ date, slots }) => 
        slots.map((slot, index) => ({
          id: `booking-${booking._id}-${index}`,
          start: convertToSelectedTimezone(`${date} ${slot.start}`).toDate(),
          end: convertToSelectedTimezone(`${date} ${slot.end}`).toDate(),
          title: booking.Status,
          backgroundColor: getStatusColor(booking.Status),
          borderColor: getStatusColor(booking.Status),
          textColor: '#ffffff',
          extendedProps: {
            status: booking.Status
          }
        }))
      )
    );
  }, [convertToSelectedTimezone, getStatusColor]);

  useEffect(() => {
    console.log('State update:', {
      remainingHours,
      usedHours,
      freeHoursUsed,
      paidHoursUsed,
      walletHours
    });
  }, [remainingHours, usedHours, freeHoursUsed, paidHoursUsed, walletHours]);

  const handleSlotToggle = useCallback((event) => {
    const eventId = event.id;
    
    if (lastProcessedSlotRef.current === eventId) {
      console.log(`Slot ${eventId} already processed, skipping`);
      return;
    }
    
    lastProcessedSlotRef.current = eventId;
    
    const eventDate = convertToSelectedTimezone(event.start);
    const now = moment().tz(selectedTimezone);
  
    if (eventDate.isBefore(now, 'day')) {
      toast.error("Cannot book slots in the past.");
      return;
    }
  
    const isBooked = existingBookings.some(booking => 
      moment(booking.start).isSame(event.start) &&
      moment(booking.end).isSame(event.end)
    );
  
    if (isBooked) {
      toast.error("This slot is already booked and cannot be selected.");
      return;
    }
  
    setSelectedSlots(prevSlots => {
      const newSlots = { ...prevSlots };
      let hoursDelta = 0;
  
      if (eventId in newSlots) {
        delete newSlots[eventId];
        hoursDelta = -1;
        toast.info(t('EditPackageTeacher.SlotRemoved'));
      } else if (remainingHours > 0) {
        const startInTimezone = convertToSelectedTimezone(event.start);
        const endInTimezone = convertToSelectedTimezone(event.end);
        
        newSlots[eventId] = {
          id: eventId,
          start: startInTimezone.toDate(),
          end: endInTimezone.toDate(),
          title: t('Calendar.SelectedLessons')
        };
        hoursDelta = 1;
        toast.success(t('EditPackageTeacher.SlotAdded'));
      } else {
        toast.error(t('EditPackageTeacher.NotEnoughHours'));
      }
  
      if (hoursDelta !== 0) {
        setUsedHours(prevHours => {
          const newUsedHours = Math.max(0, prevHours + hoursDelta);
          console.log('Updated usedHours:', newUsedHours); // Add this log
          return newUsedHours;
        });
  
        if (hoursDelta > 0) {
          if (remainingFreeHours > 0) {
            setFreeHoursUsed(prev => prev + 1);
          } else {
            setPaidHoursUsed(prev => prev + 1);
          }
        } else {
          if (paidHoursUsed > 0) {
            setPaidHoursUsed(prev => prev - 1);
          } else {
            setFreeHoursUsed(prev => prev - 1);
          }
        }
      }
  
      return hoursDelta !== 0 ? newSlots : prevSlots;
    });
    
    setTimeout(() => {
      lastProcessedSlotRef.current = null;
    }, 100);
  }, [existingBookings, remainingHours, remainingFreeHours, paidHoursUsed, t, convertToSelectedTimezone, selectedTimezone]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) {
      return;
    }

    const selectedSlotsArray = Object.values(selectedSlots);
    if (selectedSlotsArray.length === 0) {
      return toast.error("Please select slots for your lessons");
    }

    setIsSubmitting(true);
  
    try {
      console.log("Starting booking process...");
      console.log("Selected slots:", selectedSlotsArray);
  
      const totalHoursToUse = selectedSlotsArray.length;
      const freeHoursToUse = freeHoursUsed;
      const paidHoursToUse = paidHoursUsed;
  
      if (paidHoursToUse > 0) {
        const spendResult = await dispatch(spendHoursAction({
          studentId: user._id,
          packageId: pack._id,
          hours: paidHoursToUse
        }));
        
        console.log("Spend hours result:", spendResult);
  
        if (spendHoursAction.rejected.match(spendResult)) {
          throw new Error(spendResult.payload || "Failed to use hours from wallet");
        }
      }
  
      if (freeHoursToUse > 0) {
        console.log("Deducting free hours:", {
          packageId: pack._id,
          hours: freeHoursToUse,
          studentId: user._id
        });
        const deductFreeHoursResult = await dispatch(deductFreeHoursFromPackage({
          packageId: pack._id,
          hours: freeHoursToUse,
          studentId: user._id
        }));
      
        console.log("Deduct free hours result:", deductFreeHoursResult);
      
        if (deductFreeHoursFromPackage.rejected.match(deductFreeHoursResult)) {
          const errorMessage = deductFreeHoursResult.payload?.message || "Failed to deduct free hours from package";
          console.error("Error deducting free hours:", errorMessage);
          throw new Error(errorMessage);
        }
      }
  
      const bookingData = {
        Student_ID: user._id,
        Package_ID: pack._id,
        Teacher_ID: [selectedTeacherId],
        Status: "Scheduled",
        Scheduled_Dates: selectedSlotsArray.reduce((acc, slot) => {
          const date = moment(slot.start).utc().format('YYYY-MM-DD');
          if (!acc[date]) {
            acc[date] = [{
              start: moment(slot.start).utc().format('HH:mm'),
              end: moment(slot.end).utc().format('HH:mm')}];
            } else {
              acc[date].push({
                start: moment(slot.start).utc().format('HH:mm'),
                end: moment(slot.end).utc().format('HH:mm')
              });
            }
            return acc;
          }, {}),
        FreeHoursUsed: freeHoursToUse
      };
        
      console.log("Booking data being sent:", JSON.stringify(bookingData, null, 2));
  
      const createResult = await dispatch(Add_booking(bookingData));
      
      console.log("Booking creation result:", createResult);
  
      if (Add_booking.rejected.match(createResult)) {
        console.error("Booking error:", createResult.payload);
        throw new Error(createResult.payload?.message || "Failed to create booking");
      }
    
      console.log("Booking successful!");
      toast.success(t('EditPackageTeacher.BookingSuccess'));
      navigate(`/Student-dashboard/Bookings`, {
        state: { totalCount: selectedSlotsArray.length, selectedSlots: selectedSlotsArray },
      });
  
    } catch (error) {
      console.error("Operation failed:", error);
      toast.error(error.message);
      if (error.message.includes("Authentication token is missing")) {
        navigate('/login');
      }
      dispatch(fetchWalletBalance(user._id));
    }
  };

  const SelectedSlots = () => {
    const handleRemoveSlot = (slotId) => {
      setSelectedSlots(prevSlots => {
        const newSlots = { ...prevSlots };
        delete newSlots[slotId];
        setUsedHours(prevHours => Math.max(0, prevHours - 1));
        if (paidHoursUsed > 0) {
          setPaidHoursUsed(prev => prev - 1);
        } else {
          setFreeHoursUsed(prev => prev - 1);
        }
        toast.info(t('EditPackageTeacher.SlotRemoved'));
        return newSlots;
      });
    };
  
    return (
      <div className="selected-slots">
        <h3>{t("EditPackageTeacher.SelectedSlots")}</h3>
        {Object.entries(selectedSlots).map(([slotId, slot]) => {
          const startTime = convertToSelectedTimezone(slot.start);
          const endTime = convertToSelectedTimezone(slot.end);
          const timeFormat = hourFormat === '12' ? 'hh:mm A' : 'HH:mm';
          
          return (
            <div key={slotId} className="slot" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
              <span>
                {startTime.format(`MMM DD YYYY ${timeFormat}`)} - {endTime.format(`MMM DD YYYY ${timeFormat}`)}
              </span>
              <button 
                onClick={() => handleRemoveSlot(slotId)}
                style={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  color: '#FF0000',
                  fontSize: '20px',
                  padding: '0 5px'
                }}
                aria-label={t("EditPackageTeacher.RemoveSlot")}
              >
                ×
              </button>
            </div>
          );
        })}
      </div>
    );
  };

  const statusColors = {
    [t("Calendar.scheduled")]: '#5C7153',  // Green
    [t("Calendar.rescheduled")]: '#FFC107',  // Yellow
    [t("Calendar.cancelled")]: '#F44336',  // Red
    [t("Calendar.completed")]: '#2196F3',  // Blue
    [t("Calendar.available")]: '#4CAF50',  // Light Green
  };

  const Legend = () => (
    <div className="calendar-legend" style={{ marginTop: '20px', marginBottom: '20px', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
      {Object.entries(statusColors).map(([status, color]) => (
        <div key={status} style={{ display: 'flex', alignItems: 'center', margin: '0 10px 10px 0' }}>
          <div style={{ width: '20px', height: '20px', backgroundColor: color, marginRight: '5px' }}></div>
          <span>{status}</span>
        </div>
      ))}
    </div>
  );

  const WalletInfo = React.memo(({ remainingHours, freeHours, usedHours, freeHoursUsed, paidHoursUsed }) => (
    <div className="wallet-info">
      <p>{t("EditPackageTeacher.SessionsAvailable")}: {Math.max(remainingHours, 0)}</p>
      <p>{t("EditPackageTeacher.SelectedSessions")}: {usedHours}</p>
    </div>
  ));

  return (
    <>
      <AdminNav />
      <div className="package-page" style={{ paddingBottom: isMobile ? '120px' : '0' }}>
        <h2 className="page-title">{t("EditPackageTeacher.PleaseSelect")}</h2>
        <WalletInfo 
  remainingHours={remainingHours}
  freeHours={walletHours.free}
  usedHours={usedHours}
  freeHoursUsed={freeHoursUsed}
  paidHoursUsed={paidHoursUsed}
/>
        <Legend />
        <div className="teacher-select">
          <Select
            options={teacherOptions}
            onChange={handleTeacherSelect}
            placeholder={t("EditPackageTeacher.SelectTeacher")}
            className="basic-single"
            classNamePrefix="select"
          />
        </div>

        {selectedTeacherId && (
          isMobile ? (
            <MobileCalendar 
          selectedTeacherId={selectedTeacherId}
          availability={availability}
          selectedSlots={selectedSlots}
          handleSlotToggle={handleSlotToggle}
          selectedTimezone={selectedTimezone}
          renderDateTime={renderDateTime}
          freeHours={walletHours.free}
          usedHours={walletHours.used}
          t={t}
        />
          ) : (

            // <Calendar 
            //   availability={availability}
            //   existingBookings={existingBookings}
            //   selectedSlots={selectedSlots}
            //   handleSlotToggle={handleSlotToggle}
            //   selectedTimezone={selectedTimezone}
            //   renderDateTime={renderDateTime}
            //   freeHours={walletHours.free}
            //   usedHours={walletHours.used}
            // />
            <MobileCalendar 
          selectedTeacherId={selectedTeacherId}
          availability={availability}
          selectedSlots={selectedSlots}
          handleSlotToggle={handleSlotToggle}
          selectedTimezone={selectedTimezone}
          renderDateTime={renderDateTime}
          freeHours={walletHours.free}
          usedHours={walletHours.used}
          t={t}
        />

            
          )
        )}

        <SelectedSlots />

        <Button 
          onClick={handleSubmit} 
          type="primary" 
          className="submit-button"
          disabled={Object.keys(selectedSlots).length === 0 || isSubmitting}
          style={{ position: 'relative' }}
        >
          {isSubmitting ? (
            <>
              <Spin style={{ marginRight: '8px' }} />
              {t("EditPackageTeacher.Processing")}
            </>
          ) : (
            `${t("EditPackageTeacher.BookSlots")} (${Object.keys(selectedSlots).length} hours)`
          )}
        </Button>
      </div>
    </>
  );
};

export default EditPackageTeacher;




 // <Calendar 
            //   availability={availability}
            //   existingBookings={existingBookings}
            //   selectedSlots={selectedSlots}
            //   handleSlotToggle={handleSlotToggle}
            //   selectedTimezone={selectedTimezone}
            //   renderDateTime={renderDateTime}
            //   freeHours={walletHours.free}
            //   usedHours={walletHours.used}
            // />
// actions/teacherNotesActions.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axiosconfig";
import { toast } from "react-toastify";

// Create a new note
export const createNote = createAsyncThunk(
  "teacherNotes/createNote",
  async (noteData, { rejectWithValue }) => {
    try {
      const response = await axios.post('notes', noteData);
      return response.data.note;
    } catch (error) {
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

// Get all notes for a specific student
export const getStudentNotes = createAsyncThunk(
  "teacherNotes/getStudentNotes",
  async ({ studentId, params = {} }, { rejectWithValue }) => {
    try {
      const queryParams = new URLSearchParams();
      if (params.category) queryParams.append('category', params.category);
      if (params.archived !== undefined) queryParams.append('archived', params.archived);
      if (params.search) queryParams.append('search', params.search);
      if (params.sort) queryParams.append('sort', params.sort);

      const url = `notes/student/${studentId}${queryParams.toString() ? `?${queryParams.toString()}` : ''}`;
      const response = await axios.get(url);
      return {
        studentId,
        notes: response.data.notes,
        count: response.data.count
      };
    } catch (error) {
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

// Get all notes created by the teacher
export const getTeacherNotes = createAsyncThunk(
  "teacherNotes/getTeacherNotes",
  async (params = {}, { rejectWithValue }) => {
    try {
      const queryParams = new URLSearchParams();
      if (params.studentId) queryParams.append('studentId', params.studentId);
      if (params.category) queryParams.append('category', params.category);
      if (params.archived !== undefined) queryParams.append('archived', params.archived);
      if (params.search) queryParams.append('search', params.search);
      if (params.sort) queryParams.append('sort', params.sort);
      if (params.page) queryParams.append('page', params.page);
      if (params.limit) queryParams.append('limit', params.limit);

      const url = `notes/teacher${queryParams.toString() ? `?${queryParams.toString()}` : ''}`;
      const response = await axios.get(url);
      
      return {
        notes: response.data.notes,
        pagination: response.data.pagination || { 
          currentPage: 1, 
          totalPages: 1, 
          limit: response.data.notes.length 
        },
        total: response.data.total
      };
    } catch (error) {
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

// Get a single note by ID
export const getNoteById = createAsyncThunk(
  "teacherNotes/getNoteById",
  async (noteId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`notes/${noteId}`);
      return response.data.note;
    } catch (error) {
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

// Update a note
export const updateNote = createAsyncThunk(
  "teacherNotes/updateNote",
  async ({ noteId, updateData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`notes/${noteId}`, updateData);
      return response.data.note;
    } catch (error) {
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

// Delete a note
export const deleteNote = createAsyncThunk(
  "teacherNotes/deleteNote",
  async (noteId, { rejectWithValue }) => {
    try {
      await axios.delete(`notes/${noteId}`);
      return noteId;
    } catch (error) {
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

// Toggle archive status of a note
export const toggleArchiveStatus = createAsyncThunk(
  "teacherNotes/toggleArchiveStatus",
  async (noteId, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`notes/${noteId}/archive`);
      return response.data.note;
    } catch (error) {
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

// Get notes for a specific booking
export const getNotesByBooking = createAsyncThunk(
  "teacherNotes/getNotesByBooking",
  async (bookingId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`notes/booking/${bookingId}`);
      return {
        bookingId,
        notes: response.data.notes,
        count: response.data.count
      };
    } catch (error) {
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

// Get recent notes
export const getRecentNotes = createAsyncThunk(
  "teacherNotes/getRecentNotes",
  async (limit = 5, { rejectWithValue }) => {
    try {
      const response = await axios.get(`notes/recent?limit=${limit}`);
      return {
        notes: response.data.notes,
        count: response.data.count
      };
    } catch (error) {
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

// Get note statistics
export const getNoteStatistics = createAsyncThunk(
  "teacherNotes/getNoteStatistics",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('notes/statistics');
      return response.data.statistics;
    } catch (error) {
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);
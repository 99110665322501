import React, { useState, useMemo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { async_removeuser } from '../../store/actions/studentsActions';
import { createBugReport, imageUpload } from '../../store/actions/teachersActions';
import ChatPopup from '../../components/admin-dashboard-components/ChatPopup';

const TeacherDashboard = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [isPinned, setPinned] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("global");
  const teacher = useSelector((state) => state.students.user);
  const bookings = useSelector((state) => state.bookings.Teacher_Bookings);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showBetaNotice, setShowBetaNotice] = useState(false);
  const [showBugModal, setShowBugModal] = useState(false);
const [formData, setFormData] = useState({ title: '', description: '' });
const [files, setFiles] = useState([]);
const [isSubmitting, setIsSubmitting] = useState(false);
const [submitError, setSubmitError] = useState('');
const [submitSuccess, setSubmitSuccess] = useState(false);
const { loading = false, error = null } = useSelector((state) => state.teacher || {});




  const themeColors = {
    dashboard: { bg: 'bg-blue-100', text: 'text-blue-700', hover: 'hover:bg-blue-50' },
    profile: { bg: 'bg-purple-100', text: 'text-purple-700', hover: 'hover:bg-purple-50' },
    // courses: { bg: 'bg-emerald-100', text: 'text-emerald-700', hover: 'hover:bg-emerald-50' },
    bookings: { bg: 'bg-amber-100', text: 'text-amber-700', hover: 'hover:bg-amber-50' },
    wallet: { bg: 'bg-indigo-100', text: 'text-indigo-700', hover: 'hover:bg-indigo-50' } // New Wallet theme
  };


  const handleBugReportClose = () => {
    setShowBugModal(false);
    setFormData({ title: '', description: '' });
    setFiles([]);
    setSubmitError('');
    setSubmitSuccess(false);
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };
  
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles.slice(0, 3)); // Limit to 3 files
  };
  
  const handleSubmitBug = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitError('');
  
    try {
      // If there are files, upload them first
      let imageName = null;
      if (files.length > 0) {
        // Upload first file as image (you can modify this to handle multiple files if needed)
        const imageResponse = await dispatch(imageUpload(files[0]));
        if (imageResponse.payload) {
          imageName = imageResponse.payload;
        }
      }
  
      // Create bug report
      const bugData = {
        title: formData.title,
        description: formData.description,
        imageName: imageName // Add the image name if an image was uploaded
      };
  
      const response = await dispatch(createBugReport(bugData)).unwrap();
      
      setSubmitSuccess(true);
      setTimeout(() => {
        handleBugReportClose();
      }, 2000);
  
    } catch (error) {
      setSubmitError(error.message || t('bugReport.submitError'));
    } finally {
      setIsSubmitting(false);
    }
  };
  

  const chatUsers = useMemo(() => {
    const uniqueStudents = new Map();
    
    bookings?.forEach(booking => {
      const student = booking.Student_ID;
      if (student && student._id && !uniqueStudents.has(student._id)) {
        uniqueStudents.set(student._id, {
          id: student._id,
          name: student.Username,
          email: student.Email,
          type: 'student',
          avatar: student.Profile_Image?.[0] ? 
            `https://speakable.online/api/image/${student.Profile_Image[0]}` : 
            null
        });
      }
    });
  
    return [
      ...Array.from(uniqueStudents.values()),
      {
        id: '667fd808df2795ef057c93b5',
        name: 'admin',
        email: 'admin@speakable.com',
        type: 'admin',
        avatar: null
      }
    ];
  }, [bookings]);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('i18nextLng');
    if (savedLanguage && savedLanguage !== i18n.language) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const languages = [
    { value: 'en', label: t('languages.English'), flag: '🇬🇧' },
    { value: 'pl', label: t('languages.Polish'), flag: '🇵🇱' },
  ];

  const handleLogout = () => {
    dispatch(async_removeuser());
  };

  const handleLanguageChange = (selectedOption) => {
    const newLang = selectedOption.value;
    i18n.changeLanguage(newLang);
    localStorage.setItem('i18nextLng', newLang);
  };

  const getCurrentLanguage = () => {
    const currentLang = i18n.language || localStorage.getItem('i18nextLng') || 'en';
    return languages.find(lang => lang.value === currentLang) || languages[0];
  };

  const getTheme = (path) => {
    if (path.includes("dash")) return themeColors.dashboard;
    if (path.includes("profile")) return themeColors.profile;
    // if (path.includes("Courses")) return themeColors.courses;
    if (path.includes("Bookings")) return themeColors.bookings;
    if (path.includes("Wallet")) return themeColors.wallet; // New Wallet condition
    return themeColors.dashboard;
  };

  const handleSidebarMouseEnter = () => {
    if (!isPinned) setCollapsed(false);
  };

  const handleSidebarMouseLeave = () => {
    if (!isPinned) setCollapsed(true);
  };

  const togglePin = () => {
    setPinned(!isPinned);
    if (!isPinned) setCollapsed(false);
  };

  const selectStyles = {
    control: (base) => ({
      ...base,
      background: 'transparent',
      border: '1px solid rgb(229 231 235)',
      borderRadius: '1rem',
      padding: '2px',
      boxShadow: 'none',
      minHeight: '2rem',
      '&:hover': {
        borderColor: getTheme(location.pathname).text
      }
    }),
    option: (base, { isSelected }) => ({
      ...base,
      backgroundColor: isSelected ? getTheme(location.pathname).bg : 'white',
      color: isSelected ? getTheme(location.pathname).text : 'gray',
      fontSize: '0.875rem',
      '&:hover': {
        backgroundColor: getTheme(location.pathname).hover
      }
    }),
    singleValue: (base) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      fontSize: '0.875rem'
    }),
    menu: (base) => ({
      ...base,
      fontSize: '0.875rem'
    })
  };

  const menuItems = [
    { label: t('TeacherDashboard.subhead1'), icon: "bar-chart-fill", path: "/Teacher-dashboard/dash" },
    { label: t('TeacherDashboard.subhead2'), icon: "person-fill", path: "/Teacher-dashboard/profile" },
    // { label: t('TeacherDashboard.subhead3'), icon: "book-fill", path: "/Teacher-dashboard/Courses" },
    { label: t('TeacherDashboard.subhead4'), icon: "calendar-check", path: "/Teacher-dashboard/Bookings" },
    { label: t('TeacherDashboard.subhead6'), icon: "wallet-fill", path: "/Teacher-dashboard/Wallet" }, // New Wallet entry
    { label: t('TeacherDashboard.subhead5'), icon: "box-arrow-right", path: null, onClick: handleLogout }
  ];

  const mobileMenuItems = [
    { label: t('TeacherDashboard.subhead1'), icon: "bar-chart-fill", path: "/Teacher-dashboard/dash" },
    { label: t('TeacherDashboard.subhead2'), icon: "person-fill", path: "/Teacher-dashboard/profile" },
    // { label: t('TeacherDashboard.subhead3'), icon: "book-fill", path: "/Teacher-dashboard/Courses" },
    { label: t('TeacherDashboard.subhead4'), icon: "calendar-check", path: "/Teacher-dashboard/Bookings" },
    { label: t('TeacherDashboard.subhead6'), icon: "wallet-fill", path: "/Teacher-dashboard/Wallet" },
    { label: t('common.more'), icon: "three-dots", onClick: () => setShowMobileMenu(!showMobileMenu) }
  ];


  const currentLanguage = getCurrentLanguage();

  return (
    <div className="flex min-h-screen bg-gray-50/95">

      {/* Beta Testing Notice */}
<div className="fixed top-4 right-4 z-50">
  <div className="relative">
    <button
      onClick={() => setShowBetaNotice(!showBetaNotice)}
      className="bg-red-500 text-white px-3 py-1 rounded-full text-sm font-medium shadow-lg hover:bg-red-600 transition-colors flex items-center gap-1"
    >
      <i className="bi bi-bug-fill text-xs" />
      <span>Beta</span>
    </button>
    
    {showBetaNotice && (
      <div className="absolute right-0 top-full mt-2 w-64 bg-white rounded-lg shadow-lg p-4 border border-gray-200">
        <button
          onClick={() => setShowBetaNotice(false)}
          className="absolute top-1 right-1 text-gray-400 hover:text-gray-600"
        >
          <i className="bi bi-x-lg text-sm" />
        </button>
        <p className="text-sm text-gray-700 mb-2">
          {t('beta.notice')}
        </p>
        <button
          onClick={() => {
            setShowBetaNotice(false);
            setShowBugModal(true);
          }}
          className="text-blue-600 hover:text-blue-800 text-sm underline"
        >
          {t('beta.report')}
        </button>
      </div>
    )}
  
  </div>
</div>
      <div className="hidden md:block relative">
        <div 
          className="transition-all duration-500 ease-in-out transform sticky top-0"
          onMouseEnter={handleSidebarMouseEnter}
          onMouseLeave={handleSidebarMouseLeave}
        >
          <Sidebar
            collapsed={collapsed}
            width="min(300px, 30vw)"
            collapsedWidth="min(80px, 10vw)"
            className="h-screen"
            backgroundColor="transparent"
            rootStyles={{
              border: 'none',
              minHeight: '100vh',
              position: 'relative'
            }}
          >
            <div className="h-full bg-white/90 backdrop-blur-sm shadow-xl rounded-r-3xl relative overflow-y-auto border-r border-gray-100">
              <div className={`absolute top-0 left-0 w-full h-40 ${getTheme(location.pathname).bg} transition-colors duration-300`} />
              
              <div className="relative pt-8 pb-6 px-6">
                <div className="flex items-center justify-between">
                  <h3 className={`text-2xl font-bold ${getTheme(location.pathname).text} transition-all duration-300 flex items-center gap-3 ${collapsed ? 'justify-center' : ''}`}>
                    <span className="text-3xl">👨‍🏫</span>
                    <span className={`transition-all duration-300 ${collapsed ? 'opacity-0 scale-0' : 'opacity-100 scale-100'}`}>
                      {t('TeacherDashboard.head')}
                    </span>
                  </h3>
                  {!collapsed && (
                    <button
                      onClick={togglePin}
                      className={`p-2 rounded-lg transition-colors duration-200 ${
                        isPinned 
                          ? `${getTheme(location.pathname).bg} ${getTheme(location.pathname).text}`
                          : 'text-gray-400 hover:bg-gray-100'
                      }`}
                    >
                      <i className={`bi bi-pin${isPinned ? '-fill' : ''} text-lg`} />
                    </button>
                  )}
                </div>

                {!collapsed && (
                  <div className="mt-4">
                    <Select
                      options={languages}
                      defaultValue={currentLanguage}
                      onChange={handleLanguageChange}
                      styles={selectStyles}
                      formatOptionLabel={({ label, flag }) => (
                        <div className="flex items-center gap-2">
                          <span>{flag}</span>
                          <span>{label}</span>
                        </div>
                      )}
                    />
                  </div>
                )}
              </div>

              <Menu
                menuItemStyles={{
                  button: ({ active }) => {
                    const theme = getTheme(location.pathname);
                    return {
                      backgroundColor: active ? theme.bg : 'transparent',
                      backdropFilter: 'blur(8px)',
                      borderRadius: '1rem',
                      margin: '0.25rem 0.75rem',
                      padding: '0.75rem',
                      color: active ? theme.text : 'rgb(107 114 128)',
                      fontWeight: '500',
                      fontSize: '0.875rem',
                      transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                      transform: hoveredItem === active ? 'scale(1.05)' : 'scale(1)',
                      '&:hover': {
                        backgroundColor: theme.hover,
                        color: theme.text,
                        transform: 'translateX(0.5rem)',
                        boxShadow: '0 8px 16px -4px rgb(0 0 0 / 0.1)',
                      },
                    };
                  },
                  icon: {
                    fontSize: '1.125rem',
                    transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                  }
                }}
              >
                {!collapsed && (
                  <div className="px-6 py-4 text-xs font-bold text-gray-400 uppercase tracking-widest">
                    {t('TeacherDashboard.panel')}
                  </div>
                )}
                {menuItems.map((item) => (
                  <MenuItem
                    key={item.label}
                    icon={<i className={`bi bi-${item.icon} text-xl`} />}
                    active={location.pathname === item.path}
                    onClick={() => item.onClick ? item.onClick() : navigate(item.path)}
                    onMouseEnter={() => setHoveredItem(item.label)}
                    onMouseLeave={() => setHoveredItem(null)}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Sidebar>
        </div>
      </div>

      <main className="flex-1 min-h-screen overflow-y-auto">
        <div className="p-8">
          <Outlet />
        </div>
      </main>

      <div className="fixed bottom-6 right-6 z-50">
        <ChatPopup 
          currentUser={{
            id: teacher._id,
            name: teacher.Username,
            type: 'teacher'
          }}
          availableUsers={chatUsers}
        />
      </div>
      {/* Mobile Navigation */}
      <div className="md:hidden fixed bottom-0 left-0 right-0 z-40">
        {showMobileMenu && (
          <div className="absolute bottom-full w-full mb-2 px-4 max-h-[80vh] overflow-y-auto">
            <div className="bg-white rounded-lg shadow-lg border border-gray-100">
              {/* Language Selection */}
              <div className="px-4 py-3 border-b border-gray-100">
                <div className="text-sm font-medium text-gray-500 mb-2">{t('common.Language')}</div>
                <div className="space-y-2">
                  {languages.map((lang) => (
                    <button
                      key={lang.value}
                      onClick={() => handleLanguageChange(lang)}
                      className={`w-full px-3 py-2 rounded-lg flex items-center gap-3 transition-colors duration-200 ${
                        lang.value === currentLanguage.value
                          ? `${getTheme(location.pathname).bg} ${getTheme(location.pathname).text}`
                          : 'hover:bg-gray-50'
                      }`}
                    >
                      <span className="text-lg">{lang.flag}</span>
                      <span>{lang.label}</span>
                      {lang.value === currentLanguage.value && (
                        <i className="bi bi-check2 ml-auto" />
                      )}
                    </button>
                  ))}
                </div>
              </div>

              {/* Logout Option */}
              <button
                onClick={handleLogout}
                className="w-full px-4 py-3 flex items-center gap-3 text-red-600 hover:bg-red-50 transition-colors duration-200"
              >
                <i className="bi bi-box-arrow-right text-lg" />
                <span>{t('TeacherDashboard.subhead5')}</span>
              </button>

              {/* Cancel Button */}
              <button
                onClick={() => setShowMobileMenu(false)}
                className="w-full px-4 py-3 flex items-center gap-3 text-gray-600 hover:bg-gray-50 border-t border-gray-100 transition-colors duration-200"
              >
                <i className="bi bi-x-lg text-lg" />
                <span>{t('common.cancel')}</span>
              </button>
            </div>
          </div>
        )}

        {/* Mobile Navigation Bar */}
        <div className="bg-white border-t border-gray-100 shadow-lg">
          <div className="flex justify-around items-center h-16">
            {mobileMenuItems.map((item) => {
              const isActive = item.path ? location.pathname === item.path : false;
              const theme = getTheme(location.pathname);
              
              return (
                <button
                  key={item.label}
                  onClick={item.onClick || (() => navigate(item.path))}
                  className={`flex flex-col items-center justify-center w-full h-full px-2 ${
                    isActive ? `${theme.text} ${theme.bg}` : 'text-gray-500'
                  } transition-colors duration-200`}
                >
                  <i className={`bi bi-${item.icon} text-base md:text-lg`} />
                  <span className="text-xs mt-1">{item.label}</span>
                </button>
              );
            })}
          </div>
        </div>
      </div>
      {showBugModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
    <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold">{t('bugReport.title')}</h3>
          <button
            onClick={handleBugReportClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <i className="bi bi-x-lg text-xl" />
          </button>
        </div>

        {submitSuccess && (
          <div className="mb-4 p-4 bg-green-100 text-green-700 rounded-lg">
            {t('bugReport.success')}
          </div>
        )}

        {submitError && (
          <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg">
            {submitError}
          </div>
        )}

        <form onSubmit={handleSubmitBug} className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">
              {t('bugReport.titleLabel')}
            </label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">
              {t('bugReport.descriptionLabel')}
            </label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              rows="4"
              className="w-full p-2 border rounded-md"
              required
            />
          </div>

          <div className="relative">
            <label className="block text-sm font-medium mb-1">
              {t('bugReport.attachmentsLabel')} ({t('bugReport.maxFiles')})
            </label>
            <div className="relative border-2 border-dashed border-gray-300 rounded-md p-4">
              <div className="text-center">
                <input
                  type="file"
                  onChange={handleFileChange}
                  multiple
                  accept="image/*"
                  className="hidden"
                  id="file-upload"
                />
                <label
                  htmlFor="file-upload"
                  className="inline-flex items-center px-4 py-2 bg-white border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 cursor-pointer"
                >
                  <i className="bi bi-upload me-2" />
                  {t('bugReport.uploadLabel')}
                </label>
                <p className="text-sm text-gray-500 mt-2">
                  {/* {t('bugReport.dragDrop')} */}
                </p>
                <p className="text-xs text-gray-500">
                  {t('bugReport.fileTypes')}
                </p>
              </div>

              {files.length > 0 && (
  <div className="mt-4 grid grid-cols-3 gap-2">
    {files.map((file, index) => (
      <div key={index} className="relative">
        <img
          src={URL.createObjectURL(file)}
          alt={`Preview ${index + 1}`}
          className="h-24 w-full object-cover rounded-md"
        />
        <button
          type="button"
          onClick={() => setFiles(files.filter((_, i) => i !== index))}
          className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
        >
          <i className="bi bi-x text-sm" />
        </button>
      </div>
    ))}
  </div>
)}
            </div>
          </div>

          <button
  type="submit"
  disabled={loading || isSubmitting}
  className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 disabled:bg-gray-400"
>
  {loading || isSubmitting ? t('bugReport.submitting') : t('bugReport.submitButton')}
</button>

{error && !submitError && (
  <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg">
    {error}
  </div>
)}
        </form>
      </div>
    </div>
  </div>
)}
    </div>
  );
};

export default TeacherDashboard;
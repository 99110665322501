import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWalletHistory } from '../../store/actions/walletActions';
import { Book, Clock, CreditCard, Wallet } from 'lucide-react';
import moment from 'moment';
import { useTranslation } from "react-i18next";


const WalletHistoryPage = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Match backend default
  const { walletHistory, loading } = useSelector((state) => state.wallet);
  const studentId = useSelector((state) => state.students.user?._id);
  const { t } = useTranslation("global");
  const [showDeletedItems, setShowDeletedItems] = useState(false);

  useEffect(() => {
    if (studentId) {
      dispatch(fetchWalletHistory({ 
        studentId,
        page: currentPage,
        limit: itemsPerPage
      }));
    }
  }, [dispatch, studentId, currentPage, itemsPerPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    window.scrollTo(0, 0);
  };

  const renderTransactionBadge = (type) => {
    const badgeClass = type === 'purchase' 
      ? 'bg-green-100 text-green-800'
      : 'bg-red-100 text-red-800';
      return (
        <span className={`px-3 py-1 rounded-full text-xs font-semibold ${badgeClass}`}>
          {type === 'purchase' ? t("StudentWallet.StatusPurchase") : t("StudentWallet.StatusUsage")}
        </span>
      );
  };

  // Function to display formatted package/course name
  const displayItemName = (name) => {
    if (name === "Unknown Package" || name === "Unknown Course") {
      return t("StudentWallet.PackageDeleted");
    }
    return name;
  };

  // Toggle function for showing/hiding deleted items
  const toggleDeletedItems = () => {
    setShowDeletedItems(!showDeletedItems);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Header Section */}
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">{t("StudentWallet.WalletHistory")}</h1>
        <p className="mt-2 text-sm text-gray-600">{t("StudentWallet.ViewTransactionsHistory")}</p>
      </div>
      
      {/* Toggle Button for Deleted Items */}
      <div className="mb-4 flex justify-end">
        <button
          onClick={toggleDeletedItems}
          className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
        >
          {showDeletedItems ? t("StudentWallet.HideDeleted") || "Hide Deleted Items" : t("StudentWallet.ShowDeleted") || "Show Deleted Items"}
        </button>
      </div>

      {/* Summary Cards */}
      {walletHistory?.summary && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-gray-600">{t("StudentWallet.TotalPurchases")}</p>
                <p className="mt-2 text-3xl font-bold text-gray-900">
                  {walletHistory.summary.totalPurchases}
                </p>
              </div>
              <CreditCard className="h-8 w-8 text-blue-500" />
            </div>
          </div>

          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-gray-600">{t("StudentWallet.HoursPurchased")}</p>
                <p className="mt-2 text-3xl font-bold text-gray-900">
                  {walletHistory.summary.totalHoursPurchased}
                </p>
              </div>
              <Book className="h-8 w-8 text-green-500" />
            </div>
          </div>

          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-gray-600">{t("StudentWallet.HoursUsed")}</p>
                <p className="mt-2 text-3xl font-bold text-gray-900">
                  {walletHistory.summary.totalHoursUsed}
                </p>
              </div>
              <Clock className="h-8 w-8 text-orange-500" />
            </div>
          </div>

          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-gray-600">{t("StudentWallet.CurrentBalance")}</p>
                <p className="mt-2 text-3xl font-bold text-gray-900">
                  {walletHistory.summary.currentBalance}
                </p>
              </div>
              <Wallet className="h-8 w-8 text-purple-500" />
            </div>
          </div>
        </div>
      )}

      {/* Transactions Table */}
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="px-6 py-4 border-b border-gray-200">
          <h2 className="text-lg font-medium text-gray-900">{t("StudentWallet.TransactionHistory")}</h2>
        </div>
        
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t("StudentWallet.Date")}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t("StudentWallet.Type")}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t("StudentWallet.Package")}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t("StudentWallet.Course")}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t("StudentWallet.Hours")}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t("StudentWallet.Amount")}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {walletHistory?.transactions?.filter(transaction => {
                // If showDeletedItems is true, show all transactions
                if (showDeletedItems) return true;
                // Otherwise, filter out transactions with "Unknown Package" or "Unknown Course"
                return transaction.packageName !== "Unknown Package" && transaction.courseName !== "Unknown Course";
              }).map((transaction) => (
                <tr key={transaction.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {moment(transaction.date).format('MMM DD, YYYY HH:mm')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {renderTransactionBadge(transaction.type)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {displayItemName(transaction.packageName)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {displayItemName(transaction.courseName)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {transaction.hours}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {transaction.amount > 0 ? `$${transaction.amount}` : t("StudentWallet.Free")}
                  </td>
                </tr>
              ))}
              {(!walletHistory?.transactions || walletHistory.transactions.length === 0) && (
                <tr>
                  <td colSpan="6" className="px-6 py-4 text-center text-sm text-gray-500">
                    {t("StudentWallet.NoTransactionsFound")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination Section */}
        {walletHistory?.pagination && walletHistory.pagination.totalPages > 1 && (
          <div className="bg-white px-4 py-3 border-t border-gray-200 sm:px-6">
            <div className="flex items-center justify-between">
              {/* Mobile Pagination */}
              <div className="flex-1 flex justify-between sm:hidden">
                <button
                  onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                  disabled={currentPage === 1}
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {t("StudentWallet.Previous")}
                </button>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === walletHistory.pagination.totalPages}
                  className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {t("StudentWallet.Next")}
                </button>
              </div>

              {/* Desktop Pagination */}
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    {t("StudentWallet.ShowingPage")} <span className="font-medium">{currentPage}</span> {t("StudentWallet.Of")}{' '}
                    <span className="font-medium">{walletHistory.pagination.totalPages}</span>
                    {' '}({walletHistory.pagination.totalItems} {t("StudentWallet.TotalItems")})
                  </p>
                </div>
                <div>
                  <nav 
                    className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" 
                    aria-label="Pagination"
                  >
                    {/* First Page */}
                    <button
                      onClick={() => handlePageChange(1)}
                      disabled={currentPage === 1}
                      className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      <span className="sr-only">{t("StudentWallet.First")}</span>
                      {t("StudentWallet.First")}
                    </button>

                    {/* Previous Page */}
                    <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                      className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      <span className="sr-only">{t("StudentWallet.Previous")}</span>
                      {t("StudentWallet.Previous")}
                    </button>

                    {/* Page Numbers */}
                    {Array.from({ length: walletHistory.pagination.totalPages }).map((_, index) => {
                      const pageNumber = index + 1;
                      if (
                        pageNumber === 1 ||
                        pageNumber === walletHistory.pagination.totalPages ||
                        (pageNumber >= currentPage - 2 && pageNumber <= currentPage + 2)
                      ) {
                        return (
                          <button
                            key={pageNumber}
                            onClick={() => handlePageChange(pageNumber)}
                            aria-current={currentPage === pageNumber ? 'page' : undefined}
                            className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${
                              currentPage === pageNumber
                                ? 'z-10 bg-blue-50 border-blue-500 text-blue-600'
                                : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                            }`}
                          >
                            {pageNumber}
                          </button>
                        );
                      } else if (
                        pageNumber === currentPage - 3 ||
                        pageNumber === currentPage + 3
                      ) {
                        return (
                          <span
                            key={pageNumber}
                            className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                          >
                            ...
                          </span>
                        );
                      }
                      return null;
                    })}

                    {/* Next Page */}
                    <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === walletHistory.pagination.totalPages}
                      className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      <span className="sr-only">{t("StudentWallet.Next")}</span>
                      {t("StudentWallet.Next")}
                    </button>

                    {/* Last Page */}
                    <button
                      onClick={() => handlePageChange(walletHistory.pagination.totalPages)}
                      disabled={currentPage === walletHistory.pagination.totalPages}
                      className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      <span className="sr-only">{t("StudentWallet.Last")}</span>
                      {t("StudentWallet.Last")}
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WalletHistoryPage;
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import Select from 'react-select';
import { fetchTeacherDetails, getVideo } from '../store/actions/teachersActions';
import BookingModal from './BookingModal';
import { 
  Calendar as CalendarIcon, 
  Clock, 
  BookOpen, 
  Video,
  Globe,
  GraduationCap
} from 'lucide-react';

const TeacherDetails = () => {
  const { TeacherID } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation("global");
  
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoError, setVideoError] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState(moment.tz.guess());
  const [hourFormat, setHourFormat] = useState('24');
  const [transformedAvailability, setTransformedAvailability] = useState({});
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);


  const { TeacherDetails: teacher, loading, error } = useSelector((state) => state.teachers);

  const transformAvailabilityData = (teacherData) => {
    const newAvailability = {};
    
    if (!teacherData.Availability || !teacherData.Availability.slots) {
      return {};
    }
    
    teacherData.Availability.slots.forEach(slot => {
      const startDate = moment(slot.start).format('YYYY-MM-DD');
      
      if (!newAvailability[startDate]) {
        newAvailability[startDate] = {
          slots: []
        };
      }
      
      const startTime = moment(slot.start).format('HH:mm');
      const endTime = moment(slot.end).format('HH:mm');
      
      newAvailability[startDate].slots.push({
        start: startTime,
        end: endTime
      });
    });
    
    return newAvailability;
  };

  useEffect(() => {
    if (TeacherID) {
      dispatch(fetchTeacherDetails(TeacherID));
    }
  }, [TeacherID, dispatch]);

  useEffect(() => {
    if (teacher) {
      const transformed = transformAvailabilityData(teacher);
      setTransformedAvailability(transformed);

      if (teacher.Video) {
        dispatch(getVideo(teacher.Video))
          .unwrap()
          .then(url => {
            setVideoUrl(url);
            setVideoError(null);
          })
          .catch(err => {
            setVideoError(t('TeacherDetails.videoLoadError', { error: err.message }));
            setVideoUrl(null);
          });
      }

      const storedTimezone = localStorage.getItem('selectedTimezone');
      if (storedTimezone) {
        setSelectedTimezone(storedTimezone);
      }

      const storedHourFormat = localStorage.getItem('hourFormat');
      if (storedHourFormat) {
        setHourFormat(storedHourFormat);
      }
    }
  }, [teacher, dispatch, t]);

  const handleTimezoneChange = (selectedOption) => {
    const timezone = selectedOption.value;
    setSelectedTimezone(timezone);
    localStorage.setItem('selectedTimezone', timezone);
  };

  const handleHourFormatChange = (event) => {
    const format = event.target.value;
    setHourFormat(format);
    localStorage.setItem('hourFormat', format);
  };

  const timezoneOptions = moment.tz.names().map((tz) => ({
    value: tz,
    label: tz,
  }));

  const CourseItem = ({ course }) => {
    const [showFullDescription, setShowFullDescription] = useState(false);
    
    const handleBookTrial = () => {
      setSelectedPackage(course._id); // Set the selected package ID
      setIsBookingModalOpen(true);
    };
    
    return (
      <div className="bg-white rounded-xl p-4 border-2 border-indigo-100 hover:border-indigo-300 transition-all shadow-lg">
        <div className="flex items-start gap-4">
          <div className="bg-indigo-500 p-3 rounded-lg text-white">
            <BookOpen className="w-6 h-6" />
          </div>
          <div className="flex-1 min-w-0">
            <h3 className="text-lg font-semibold text-gray-900">
              {course.Course_Name || t('TeacherDetails.unnamedCourse')}
            </h3>
            {course.Description && (
              <p className="mt-2 text-gray-600">
                {showFullDescription ? course.Description : course.Description.slice(0, 100)}
                {course.Description.length > 100 && (
                  <button 
                    className="ml-2 text-indigo-600 hover:text-indigo-800 font-medium"
                    onClick={() => setShowFullDescription(!showFullDescription)}
                  >
                    {showFullDescription ? t('Courses.readLess') : t('Courses.readMore')}
                  </button>
                )}
              </p>
            )}
            {course.Purchase_Price && (
              <div className="mt-3 inline-flex items-center px-3 py-1 rounded-full bg-green-100 text-green-800">
                {t('TeacherDetails.price', { price: course.Purchase_Price })}
              </div>
            )}
          </div>
        </div>
        {/* <button
        onClick={handleBookTrial}
        className="mt-4 w-full px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
      >
        {t('TeacherDetails.bookTrial')}
      </button> */}
    </div>
  );
};

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-indigo-500 border-t-transparent"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 p-6 flex items-center justify-center">
        <div className="text-center">
          <div className="text-red-500 text-xl font-semibold mb-2">{t('common.Error')}</div>
          <p className="text-gray-600">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      {/* Header Card */}
      <div className="mb-6 bg-white rounded-xl shadow-lg overflow-hidden">
        <div className="p-6 bg-gradient-to-r from-cyan-50 to-blue-50">
          <div className="flex flex-col md:flex-row justify-between items-center gap-6">
            <div className="flex items-center gap-6">
              <div className="relative">
                <img
                  src={teacher?.Profile_Image?.[0] 
                    ? `https://speakable.online/api/image/${encodeURIComponent(teacher.Profile_Image[0])}` 
                    : "https://speakable.online/api/image/default-profile-image.png"}
                  alt={teacher?.Username || t('TeacherDetails.teacher')}
                  className="w-24 h-24 rounded-full object-cover border-4 border-white shadow-xl"
                />
                <div className="absolute bottom-0 right-0 w-4 h-4 bg-green-400 rounded-full border-2 border-white"></div>
              </div>
              <div>
                <h1 className="text-3xl font-bold text-gray-800">
                  {teacher?.Username || t('TeacherDetails.unnamedTeacher')}
                </h1>
                <p className="text-xl text-gray-600 mt-1">
                  {teacher?.Short_Title || t('TeacherDetails.teacher')}
                </p>
              </div>
            </div>
            
            <div className="flex flex-col sm:flex-row gap-4">
              <div className="bg-white p-4 rounded-xl shadow-lg">
                <div className="flex items-center gap-3">
                  <Globe className="w-6 h-6 text-indigo-500" />
                  <Select
                    value={{ value: selectedTimezone, label: selectedTimezone }}
                    onChange={handleTimezoneChange}
                    options={timezoneOptions}
                    className="min-w-[200px]"
                  />
                </div>
              </div>
              
              <div className="bg-white p-4 rounded-xl shadow-lg">
                <div className="flex items-center gap-3">
                  <Clock className="w-6 h-6 text-indigo-500" />
                  <select
                    value={hourFormat}
                    onChange={handleHourFormatChange}
                    className="border-2 border-gray-200 rounded-lg p-2"
                  >
                    <option value="12">12-hour</option>
                    <option value="24">24-hour</option>
                  </select>
                </div>
              </div>

              <button
                onClick={() => setIsBookingModalOpen(true)}
                className="px-6 py-3 bg-indigo-600 text-white rounded-xl font-medium hover:bg-indigo-700 transition-colors flex items-center gap-2"
              >
                <CalendarIcon className="w-5 h-5" />
                {t('navigation.bookNow')}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Main Content */}
        <div className="lg:col-span-2 space-y-6">
          {/* About Section */}
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="border-b border-gray-100 p-6">
              <h2 className="text-2xl font-bold text-indigo-700">
                {t('TeacherDetails.about')}
              </h2>
            </div>
            <div className="p-6">
              <p className="text-gray-600 leading-relaxed">
                {teacher?.Description || t('TeacherDetails.noDescription')}
              </p>
            </div>
          </div>

          {/* Video Section */}
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="border-b border-gray-100 p-6">
              <h2 className="text-2xl font-bold text-indigo-700 flex items-center gap-2">
                <Video className="w-6 h-6" />
                {t('TeacherDetails.introVideo')}
              </h2>
            </div>
            <div className="p-6">
              {videoUrl ? (
                <div className="aspect-video rounded-xl overflow-hidden">
                  <iframe
                    src={videoUrl}
                    className="w-full h-full"
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title={t('TeacherDetails.introVideo')}
                  />
                </div>
              ) : (
                <div className="aspect-video bg-gray-100 rounded-xl flex items-center justify-center">
                  <p className="text-gray-500">
                    {videoError || t('TeacherDetails.noVideo')}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Sidebar */}
        <div className="space-y-6">
          {/* Courses Section */}
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="border-b border-gray-100 p-6">
              <h2 className="text-2xl font-bold text-indigo-700 flex items-center gap-2">
                <GraduationCap className="w-6 h-6" />
                {t('TeacherDetails.assignedCourses')}
              </h2>
            </div>
            <div className="p-6">
              <div className="space-y-4">
                {Array.isArray(teacher?.Courses_assign) && teacher.Courses_assign.length > 0 ? (
                  teacher.Courses_assign.map((course) => (
                    <CourseItem key={course._id || `course-${Math.random()}`} course={course} />
                  ))
                ) : (
                  <p className="text-gray-500 text-center py-6">
                    {t('TeacherDetails.noCourses')}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Booking Modal */}
      <BookingModal
  isOpen={isBookingModalOpen}
  onClose={() => setIsBookingModalOpen(false)}
  teacher={teacher}
  transformedAvailability={transformedAvailability}
  selectedTimezone={selectedTimezone}
  hourFormat={hourFormat}
  t={t}
  packageId={selectedPackage} // Add this prop
/>
    </div>
  );
};

export default TeacherDetails;
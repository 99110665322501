import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axiosconfig";
import { toast } from "react-toastify";

export const setTeacherEarningRate = createAsyncThunk(
  "teacherEarnings/setRate",
  async (data) => {
    try {
      const response = await axios.post(`teacher-earnings/set-earnings`, data);
      toast.success("Teacher earnings rate set successfully");
      return response.data;
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to set earnings rate");
      throw error;
    }
  }
);

export const creditLessonEarnings = createAsyncThunk(
  "teacherEarnings/creditLesson",
  async ({ bookingId, adminNotes }) => {
    try {
      const response = await axios.post(`teacher-earnings/complete-lesson/${bookingId}`, { adminNotes });
      toast.success("Lesson completed and earnings credited");
      return response.data;
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to credit lesson earnings");
      throw error;
    }
  }
);

export const getTeacherEarningsBalance = createAsyncThunk(
  "teacherEarnings/getBalance",
  async (teacherId) => {
    const response = await axios.get(`teacher-earnings/balance/${teacherId}`);
    return response.data;
  }
);

export const getTeacherEarningsHistory = createAsyncThunk(
  "teacherEarnings/getHistory",
  async ({ teacherId, params }) => {
    const queryString = new URLSearchParams(params).toString();
    const response = await axios.get(
      `teacher-earnings/transactions/${teacherId}?${queryString}`
    );
    return response.data;
  }
);


export const fetchTeacherWalletData = createAsyncThunk(
    "teacherWallet/fetchData",
    async (teacherId) => {
      try {
        const response = await axios.get(`teacher-bookings-with-earnings/${teacherId}`);
        return response.data;
      } catch (error) {
        toast.error(error.response?.data?.message || "Failed to fetch teacher wallet data");
        throw error;
      }
    }
  );
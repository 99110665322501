import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdminNav from "../admin-dashboard-components/AdminNav";
import { changeTeacherPassword, fetchTeacherDetails } from "../../store/actions/teachersActions";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import Select from 'react-select';
import moment from 'moment-timezone';
import { Mail, MapPin, Phone, Key, FileText, BookOpen, Globe, Clock, CalendarCheck } from 'lucide-react';
import { useTranslation } from "react-i18next";
import MiniCalendar from './MiniCalendar'; 

const TeacherProfile = () => {
  const student = useSelector((state) => state.students.user);
  const dispatch = useDispatch();
  const teacher = useSelector((state) => state.teachers.currentTeacher);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTimezone, setSelectedTimezone] = useState('America/New_York');
  const [hourFormat, setHourFormat] = useState('24');
  const [currentLanguage, setCurrentLanguage] = useState("Language");
  const [t, i18n] = useTranslation("global");
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedDates, setSelectedDates] = useState({});
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [minDate] = useState(new Date());
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        if (student && student._id) {
          await dispatch(fetchTeacherDetails(student._id)).unwrap();
        } else {
          throw new Error("Student data is not available");
        }
      } catch (err) {
        console.error("Failed to fetch teacher details:", err);
        setError("Failed to load teacher details. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, student]);

  useEffect(() => {
    console.log(student);
    const storedTimezone = localStorage.getItem('selectedTimezone');
    if (storedTimezone) {
      setSelectedTimezone(storedTimezone);
    }

    const storedHourFormat = localStorage.getItem('hourFormat');
    if (storedHourFormat) {
      setHourFormat(storedHourFormat);
    }
  }, []);

  const handleDateChange = (date, timezone) => {
    const localDate = moment(date).tz(timezone);
    if (localDate.format('YYYY-MM-DD') !== moment(selectedDate).format('YYYY-MM-DD')) {
      setSelectedDate(localDate.toDate());
    }
  };

  useEffect(() => {
    if (student && student.Availability && student.Availability.slots) {
      setAvailableSlots(student.Availability.slots);
    }
  }, [student]);

  const handlePasswordChangeClick = () => {
    setShowPasswordChange(!showPasswordChange);
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setPasswordError('');
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setPasswordError(t("profile.passwordsDontMatch"));
      return;
    }
    if (newPassword.length < 6) {
      setPasswordError(t("profile.passwordTooShort"));
      return;
    }
    try {
      await dispatch(changeTeacherPassword({
        teacherId: formData._id,
        currentPassword,
        newPassword
      })).unwrap();
      setShowPasswordChange(false);
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setPasswordError('');
    } catch (error) {
      setPasswordError(error.message || 'An error occurred while changing the password');
    }
  };

  const formData = student || {}; 

  const handleEditAvailability = (id) => {
    const availabilityData = {
      _id: formData._id,
      Availability: formData.Availability,
      selectedDates: selectedDates,
      selectedDays: {
        Sunday: true,
        Monday: true,
        Tuesday: true,
        Wednesday: true,
        Thursday: true,
        Friday: true,
        Saturday: true
      },
      userTimezone: selectedTimezone,
      hourFormat: hourFormat,
      currentMonth: currentMonth.toISOString(),
      minDate: minDate.toISOString()
    };

    navigate(`/Teacher-dashboard/Edit-Availability/${id}`, {
      state: { formData: availabilityData },
    });
  };

  const handleCalendarClick = (value) => {
    setSelectedDate(value);
  };

  const handleHourFormatChange = (event) => {
    setHourFormat(event.target.value);
    localStorage.setItem('hourFormat', event.target.value);
  };

  const handleTimezoneChange = (selectedOption) => {
    const timezone = selectedOption.value;
    setSelectedTimezone(timezone);
    localStorage.setItem('selectedTimezone', timezone);
    // Recalculate the selected date in the new timezone
    setSelectedDate(moment(selectedDate).tz(timezone).toDate());
  };


  const timezoneOptions = moment.tz.names().map((tz) => ({
    value: tz,
    label: tz,
  }));

  const tileClassName = ({ date }) => {
    // Convert calendar date to the same format as your slot dates
    const calendarDate = moment(date).format('YYYY-MM-DD');
    
    return availableSlots.some(slot => {
      // Make sure both are compared in the same timezone context
      const slotDate = moment.utc(slot.start).tz(selectedTimezone).format('YYYY-MM-DD');
      const slotEndDate = moment.utc(slot.end).tz(selectedTimezone).format('YYYY-MM-DD');
      
      return slotDate === calendarDate || 
             (slotDate !== slotEndDate && slotEndDate === calendarDate);
    }) ? "has-slots" : null;
  };

  const handleChangeLanguage = (lang) => {
    setCurrentLanguage(lang === "pl" ? "Polski" : "English");
    i18n.changeLanguage(lang);
  };

  const renderTime = (timeString) => {
    if (!timeString) return '';
    const time = moment.utc(timeString).tz(selectedTimezone);
    return hourFormat === '12' 
      ? time.format('hh:mm A')
      : time.format('HH:mm');
  };

  const renderAvailability = () => {
    if (!availableSlots.length) return <p>{t("profile.noAvailability")}</p>;

    const selectedDateString = moment(selectedDate).tz(selectedTimezone).format('YYYY-MM-DD');
    const slotsForSelectedDate = availableSlots.filter(slot => {
      const slotStart = moment.utc(slot.start).tz(selectedTimezone);
      const slotEnd = moment.utc(slot.end).tz(selectedTimezone);
      return slotStart.format('YYYY-MM-DD') === selectedDateString || 
             (slotStart.isBefore(slotEnd) && slotEnd.format('YYYY-MM-DD') === selectedDateString);
    });

    if (!slotsForSelectedDate.length) {
      return <p>{t("profile.noAvailabilityForDate")}</p>;
    }

    return (
      <div className="availability-item p-2 mb-2">
        <span>{moment(selectedDate).tz(selectedTimezone).format('YYYY-MM-DD')} ({moment(selectedDate).tz(selectedTimezone).format('dddd')})</span>
        <div className="d-flex flex-wrap justify-content-center TimeListBox">
          {slotsForSelectedDate.map((slot, index) => {
            const start = moment.utc(slot.start).tz(selectedTimezone);
            const end = moment.utc(slot.end).tz(selectedTimezone);
            const crossesMidnight = !start.isSame(end, 'day');
            return (
              <div className="TimeItemBox" key={index}>
                <ul>
                  <li>
                    {renderTime(slot.start)} - {renderTime(slot.end)}
                    {crossesMidnight && ` (Ends ${end.format('MMM D')})`}
                  </li>
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <div>{t("common.loading")}</div>;
  }

  if (error) {
    return <div>{t("common.error")}: {error}</div>;
  }

  return (
    <>
      
{/* <AdminNav /> */}
<div className="min-h-screen bg-gray-50">
  {/* Header Section */}
  <div className="relative bg-white border-b">
    <div className="h-48 bg-gradient-to-r from-blue-50 to-blue-100">
      <div className="container mx-auto px-6">
        <div className="flex items-center h-full">
          <div className="flex items-center gap-6">
            <div className="relative">
              <div className="w-32 h-32 rounded-lg overflow-hidden shadow-lg border-2 border-blue-200">
                <img
                  src={`https://speakable.online/api/image/${formData?.Profile_Image}`}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="absolute -bottom-1 -right-1 w-5 h-5 rounded-full bg-green-500 border-2 border-white"></div>
            </div>
            <div>
              <h1 className="text-2xl font-semibold text-gray-800 mb-1">{formData?.Username}</h1>
              <p className="text-sm text-gray-600 font-medium bg-blue-50 px-3 py-1 rounded-full inline-block">
                {formData?.UserType}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

        {/* Main Content */}
        <div className="container mx-auto px-4 pt-24">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Contact Information */}
            <div className="bg-white rounded-xl shadow-sm p-6">
            <h2 className="text-xl font-semibold mb-4">{t("profile.contactInformation")}</h2>
              <div className="space-y-4">
                <div className="flex items-center">
                  <Mail className="w-5 h-5 text-blue-500 mr-3" />
                  <div>
                    <p className="text-sm text-gray-500">{t("TeacherProfile.Email")}</p>
                    <p className="text-gray-700">{formData?.Email}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <Phone className="w-5 h-5 text-blue-500 mr-3" />
                  <div>
                    <p className="text-sm text-gray-500">{t("TeacherProfile.Phone")}</p>
                    <p className="text-gray-700">{formData?.Phone_Number}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <MapPin className="w-5 h-5 text-blue-500 mr-3" />
                  <div>
                    <p className="text-sm text-gray-500">{t("TeacherProfile.Address")}</p>
                    <p className="text-gray-700">{formData?.Address}</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Profile Details */}
            <div className="bg-white rounded-xl shadow-sm p-6">
            <h2 className="text-xl font-semibold mb-4">{t("profile.profileDetails")}</h2>
              <div className="space-y-4">
                <div className="flex items-center">
                  <FileText className="w-5 h-5 text-blue-500 mr-3" />
                  <div>
                    <p className="text-sm text-gray-500">{t("TeacherProfile.Description")}</p>
                    <p className="text-gray-700">{formData?.Description}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <BookOpen className="w-5 h-5 text-blue-500 mr-3" />
                  <div>
                    <p className="text-sm text-gray-500">{t("TeacherProfile.ShortTitle")}</p>
                    <p className="text-gray-700">{formData?.Short_Title}</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Settings */}
            <div className="bg-white rounded-xl shadow-sm p-6">
            <h2 className="text-xl font-semibold mb-4">{t("profile.preferences")}</h2>
              <div className="space-y-4">
                <div className="flex items-start">
                  <Globe className="w-5 h-5 text-blue-500 mr-3 mt-2" />
                  <div className="flex-grow">
                  <p className="text-sm text-gray-500 mb-2">{t("profile.preferredTimezone")}</p>
                    <Select
                      id="timezone"
                      className="w-full"
                      value={timezoneOptions.find(option => option.value === selectedTimezone)}
                      onChange={handleTimezoneChange}
                      options={timezoneOptions}
                      isSearchable
                    />
                  </div>
                </div>
                <div className="flex items-start">
                  <Clock className="w-5 h-5 text-blue-500 mr-3 mt-2" />
                  <div className="flex-grow">
                  <p className="text-sm text-gray-500 mb-2">{t("profile.hourFormat")}</p>
                    <select
                      value={hourFormat}
                      onChange={handleHourFormatChange}
                      className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    >
                      <option value="12">{t("profile.12Hour")}</option>
                      <option value="24">{t("profile.24Hour")}</option>
                    </select>
                  </div>
                </div>
                <div className="flex items-start">
                  <Globe className="w-5 h-5 text-blue-500 mr-3 mt-2" />
                  <div className="flex-grow">
                  <p className="text-sm text-gray-500 mb-2">{t("profile.language")}</p>
                    <div className="dropdown">
                      <button
                        className="w-full px-4 py-2 text-left bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="true"
                      >
                        <i className="bi bi-globe-central-south-asia mr-2"></i>
                        {currentLanguage}
                      </button>
                      <ul className="dropdown-menu w-full" aria-labelledby="dropdownMenuButton">
                        <li>
                          <a
                            className="dropdown-item flex items-center px-4 py-2 hover:bg-gray-100"
                            href="#"
                            onClick={() => handleChangeLanguage("en")}
                          >
                            <img
                              className="mr-3 border border-gray-200"
                              width={25}
                              height={15}
                              src={require("../../assets/English.webp")}
                              alt="English"
                            />
                            English
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item flex items-center px-4 py-2 hover:bg-gray-100"
                            href="#"
                            onClick={() => handleChangeLanguage("pl")}
                          >
                            <img
                              className="mr-3 border border-gray-200"
                              width={25}
                              height={15}
                              src={require("../../assets/Polish.png")}
                              alt="Polish"
                            />
                            Polish
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Password Section */}
            <div className="bg-white rounded-xl shadow-sm p-6">
            <h2 className="text-xl font-semibold mb-4">{t("profile.security")}</h2>
              <div className="flex items-start">
                <Key className="w-5 h-5 text-blue-500 mr-3 mt-1" />
                <div className="flex-grow">
                  <p className="text-sm text-gray-500">{t("TeacherProfile.Password")}</p>
                  <button
                    className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                    onClick={handlePasswordChangeClick}
                  >
                    {showPasswordChange ? t("common.Cancel") : t("TeacherProfile.ChangePassword")}
                  </button>
                  {showPasswordChange && (
                    <form onSubmit={handlePasswordChange} className="mt-3 space-y-3">
                      <input
  type="password"
  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
  placeholder={t("profile.currentPasswordPlaceholder")}
  value={currentPassword}
  onChange={(e) => setCurrentPassword(e.target.value)}
  required
/>
<input
  type="password"
  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
  placeholder={t("profile.newPasswordPlaceholder")}
  value={newPassword}
  onChange={(e) => setNewPassword(e.target.value)}
  required
/>
<input
  type="password"
  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
  placeholder={t("profile.confirmPasswordPlaceholder")}
  value={confirmPassword}
  onChange={(e) => setConfirmPassword(e.target.value)}
  required
/>
<button type="submit" className="w-full px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors">
  {t("profile.saveNewPassword")}
</button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Calendar Section */}
          <div className="mt-6 bg-white rounded-xl shadow-sm p-6">
            <div className="flex items-center mb-4">
              <CalendarCheck className="w-6 h-6 text-blue-500 mr-2" />
              <h2 className="text-xl font-semibold">{t("TeacherProfile.Availability")}</h2>
            </div>
            <div className="calendar">
              <MiniCalendar
                onDateSelect={handleCalendarClick}
                tileClassName={tileClassName}
                selectedDates={availableSlots.map(slot => moment(slot.start).format('YYYY-MM-DD'))}
                currentMonth={currentMonth}
                setCurrentMonth={setCurrentMonth}
              />
              {renderAvailability()}
            </div>
            <button
              className="mt-4 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors flex items-center"
              onClick={() => handleEditAvailability(formData._id)}
            >
              {t("TeacherProfile.EditAvailability")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherProfile;
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPaymentDetails, getOrderDetails } from "../../store/actions/paymentActions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from 'react-responsive';
import { 
  Table, 
  Card, 
  Input, 
  Button, 
  Space, 
  Typography, 
  Tag, 
  List,
  Spin,
  Select,
} from 'antd';
import { SearchOutlined, CreditCardOutlined } from '@ant-design/icons';
import Loader2 from "../Loader2";
import instance from '../../helpers/axiosconfig';


const { Title, Text } = Typography;
const { Search } = Input;
const { Option } = Select;

const Payments = () => {
  const dispatch = useDispatch();
  const paymentsFromStore = useSelector((state) => state.payments.fetchedPaymentDetails);
  const currentStudentId = useSelector((state) => state.students.user?._id);
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [hoveredRow, setHoveredRow] = useState(null);
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const isMobile = useMediaQuery({ maxWidth: 767 });

  // Status translation mapping
  const getTranslatedStatus = (status) => {
    const statusMap = {
      'COMPLETED': t("Studentpayment.Completed"),
      'PENDING': t("Studentpayment.Pending"),
      'FAILED': t("Studentpayment.Failed"),
      'CANCELED': t("Studentpayment.Canceled")
    };
    return statusMap[status?.toUpperCase()] || status || 'N/A';
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchPaymentDetails());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const updatePaymentStatuses = async () => {
      if (paymentsFromStore.length > 0) {
        const filteredPayments = paymentsFromStore.filter(payment => 
          payment.Student_ID?._id === currentStudentId
        );
        const updatedPayments = await Promise.all(
          filteredPayments.map(async (payment) => {
            try {
              const result = await dispatch(getOrderDetails(payment.OrderID)).unwrap();
              return { ...payment, Status: result.status };
            } catch (error) {
              console.error(`Error fetching status for order ${payment.OrderID}:`, error);
              return payment;
            }
          })
        );
        setPayments(updatedPayments);
      }
    };
    updatePaymentStatuses();
  }, [paymentsFromStore, dispatch, currentStudentId]);

  const handleDownloadInvoice = async (payment) => {
    try {
      if (payment?.invoice?.orderId) {
        const response = await instance({
          method: 'get',
          url: `invoices/${payment.invoice.orderId}/pdf`, // Removed leading slash since baseURL includes it
          responseType: 'blob'  // Important for PDF handling
        });
        
        // Create blob from response data
        const blob = new Blob([response.data], { type: 'application/pdf' });
        
        // Create download link
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `invoice-${payment.invoice.orderId}.pdf`;
        
        // Trigger download
        document.body.appendChild(link);
        link.click();
        
        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      }
    } catch (error) {
      console.error('Error downloading invoice:', error);
    }
  };

  const getStatusTag = (status) => {
    const statusStyles = {
      'COMPLETED': { color: 'rgb(16, 185, 129)', background: 'rgb(209, 250, 229)' },
      'PENDING': { color: 'rgb(245, 158, 11)', background: 'rgb(254, 243, 199)' },
      'FAILED': { color: 'rgb(239, 68, 68)', background: 'rgb(254, 226, 226)' },
      'CANCELED': { color: 'rgb(107, 114, 128)', background: 'rgb(243, 244, 246)' }
    };
    const style = statusStyles[status?.toUpperCase()] || { color: 'rgb(107, 114, 128)', background: 'rgb(243, 244, 246)' };
    
    return (
      <span
        style={{
          padding: '6px 12px',
          borderRadius: '9999px',
          fontSize: '0.875rem',
          fontWeight: '500',
          ...style
        }}
      >
        {getTranslatedStatus(status)}
      </span>
    );
  };

  const filteredPayments = payments.filter(payment => {
    const searchLower = searchText.toLowerCase();
    const matchesSearch = (
      payment.OrderID?.toLowerCase().includes(searchLower) ||
      payment.Student_ID?.Username?.toLowerCase().includes(searchLower) ||
      payment.Package_ID?.Package_Name?.toLowerCase().includes(searchLower)
    );
    const matchesStatus = statusFilter === 'all' || 
      payment.Status?.toLowerCase() === statusFilter.toLowerCase();
    return matchesSearch && matchesStatus;
  });

  const columns = [
    {
      title: t("Studentpayment.OrderID"),
      dataIndex: 'OrderID',
      key: 'orderId',
      render: (text) => <span className="font-medium">{text}</span>
    },
    {
      title: t("Studentpayment.StudentName"),
      dataIndex: ['Student_ID', 'Username'],
      key: 'studentName',
      render: (text, record) => record.Student_ID?.Username || record.invoice?.studentName || 'N/A'
    },
    {
      title: t("Studentpayment.PackageName"),
      dataIndex: ['Package_ID', 'Package_Name'],
      key: 'packageName',
      render: (text, record) => record.Package_ID?.Package_Name || record.invoice?.packageName || 'N/A'
    },
    {
      title: t("Studentpayment.Status"),
      dataIndex: 'Status',
      key: 'status',
      render: (status) => getStatusTag(status)
    },
    {
      title: t("Studentpayment.Amount"),
      dataIndex: 'Purchase_Amount',
      key: 'amount',
      render: (amount) => (
        <span className="font-medium">${amount}</span>
      ),
      sorter: (a, b) => a.Purchase_Amount - b.Purchase_Amount,
    },
    {
      title: t("Studentpayment.Sessions"),
      dataIndex: 'Sessions',
      key: 'sessions'
    },
    {
      title: t("Studentpayment.Invoice"),
      key: 'invoice',
      render: (_, record) =>
        record.Status === 'COMPLETED' && (
          <Button
            onClick={() => handleDownloadInvoice(record)}
            className="bg-gradient-to-r from-rose-500 via-orange-400 to-amber-300 border-none text-white hover:opacity-90"
            style={{
              background: 'linear-gradient(to right, rgb(244, 63, 94), rgb(251, 146, 60), rgb(251, 191, 36))',
              transition: 'all 0.3s ease'
            }}
          >
            {t("Studentpayment.Invoice")}
          </Button>
        )
    }
  ];

  const renderMobileCard = (item) => (
    <div 
      className="backdrop-blur-sm bg-white/90 rounded-2xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl"
      style={{ marginBottom: '1rem' }}
    >
      <div className="bg-gradient-to-r from-rose-500 via-orange-400 to-amber-300 p-4">
        <div className="flex flex-col gap-2">
          <Text strong className="text-white text-lg">#{item.OrderID}</Text>
          <div className="flex items-center">
            {getStatusTag(item.Status)}
          </div>
        </div>
      </div>
      
      <div className="p-4 space-y-4">
        <div className="flex justify-between items-center">
          <Text type="secondary">{t("Studentpayment.PackageName")}</Text>
          <Text strong>{item.Package_ID?.Package_Name || 'N/A'}</Text>
        </div>
        <div className="flex justify-between items-center">
          <Text type="secondary">{t("Studentpayment.Amount")}</Text>
          <Text strong className="text-lg">${item.Purchase_Amount}</Text>
        </div>
        <div className="flex justify-between items-center">
          <Text type="secondary">{t("Studentpayment.Sessions")}</Text>
          <Text strong>{item.Sessions}</Text>
        </div>
        
        {item.Status === 'COMPLETED' && (
          <Button 
            block
            onClick={() => handleDownloadInvoice(item)}
            className="bg-gradient-to-r from-rose-500 via-orange-400 to-amber-300 border-none text-white hover:opacity-90"
          >
            {t("Studentpayment.Invoice")}
          </Button>
        )}
      </div>
    </div>
  );

  if (loading) {
    return (
      <Loader2 
        loading={true} 
        text={t("common.loading")} 
        fullScreen={true} 
        size={300} 
        customStyles={{ 
          backgroundColor: 'rgba(15, 15, 15, 0.5)'
        }} 
      />
    );
  }

  return (
    <div className="min-h-screen pb-16">
      <div className="mb-8">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          <div className="flex items-center gap-3">
            <div className="p-3 rounded-xl bg-gradient-to-br from-rose-500 via-orange-400 to-amber-300">
              <CreditCardOutlined className="text-xl text-white" />
            </div>
            <Title level={4} style={{ margin: 0 }}>{t("Studentpayment.header")}</Title>
          </div>
          
          <Space wrap className="w-full md:w-auto">
            <Search
              placeholder={t("Studentpayment.search")}
              allowClearSzukaj
              onChange={(e) => setSearchText(e.target.value)}
              className="min-w-[200px]"
              style={{
                borderRadius: '12px',
                overflow: 'hidden'
              }}
            />
            <Select
              defaultValue="all"
              onChange={(value) => setStatusFilter(value)}
              style={{ 
                width: 120,
                borderRadius: '12px'
              }}
              className="rounded-xl"
            >
              <Option value="all">{t("Studentpayment.AllStatus")}</Option>
              <Option value="completed">{t("Studentpayment.Completed")}</Option>
              <Option value="pending">{t("Studentpayment.Pending")}</Option>
              <Option value="failed">{t("Studentpayment.Failed")}</Option>
              <Option value="canceled">{t("Studentpayment.Canceled")}</Option>
            </Select>
          </Space>
        </div>
      </div>

      <div className="backdrop-blur-sm bg-white/90 rounded-2xl shadow-lg overflow-hidden mb-16">
        {isMobile ? (
          <div className="p-4">
            <List
  dataSource={filteredPayments}
  renderItem={renderMobileCard}
  pagination={{
    pageSize: 10,
    showSizeChanger: false,  // This removes the items per page selector
    showTotal: false         // This removes the total items display
  }}
/>
          </div>
        ) : (
          <Table
  columns={columns}
  dataSource={filteredPayments}
  rowKey="_id"
  pagination={{
    pageSize: 10,
    showSizeChanger: false,  // This removes the items per page selector
    showTotal: false,        // This removes the total items display
  }}
            onRow={(record) => ({
              onMouseEnter: () => setHoveredRow(record._id),
              onMouseLeave: () => setHoveredRow(null),
              style: {
                transition: 'all 0.3s ease',
                transform: hoveredRow === record._id ? 'translateX(8px)' : 'translateX(0)',
                backgroundColor: hoveredRow === record._id ? 'rgb(243 244 246 / 0.9)' : 'transparent'
              }
            })}
            className="payments-table"
          />
        )}
      </div>
    </div>
  );
};

export default Payments;